import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    currency: 287,
    titleCurrency:{}
}

const globalCurrencySlice = createSlice({
    name: "globalCurrency",
    initialState,
    reducers:{
        setcollectCurrency:(state,action)=>{
        state.currency = action.payload
        },
        setTitleCurrency:(state,action)=>{
            state.titleCurrency = action.payload
            }
    }
})
export const {setcollectCurrency,setTitleCurrency} = globalCurrencySlice.actions
export default globalCurrencySlice.reducer

