// import Chart from "chart.js/auto";
// import { CategoryScale } from "chart.js";
// import { useState } from "react";
// import { Doughnut } from "react-chartjs-2";
// // "#8E6BE6", "#7448E0", "#5925DA", "#4B1FB7", "#2E1371", "#2D204B", "#DDD3F7", "#D1C2F5", "#C3B0F2", "#A98EEC",
// export const chartColors = [
//     "#8E6BE6", "#7448E0", "#5925DA", "#4B1FB7", "#2E1371", "#2D204B", "#DDD3F7", "#D1C2F5", "#C3B0F2", "#A98EEC",
// ];

// //#DDD3F7, #D1C2F5, #C3B0F2, #A98EEC, #8E6BE6, #7448E0, #5925DA, #4B1FB7, #2E1371, #2D204B
// Chart.register(CategoryScale);

// export default function DonutCharts(props) {
//     // eslint-disable-next-line
//     const [dataDonut, setdataDonut] = useState({
//         responsive: true,
//         maintainAspectRatio: false,

//         labels: props?.data?.label,
//         datasets: [
//             {
//                 data: props?.data?.data,
//                 backgroundColor: [
//                     "#8E6BE6", "#7448E0", "#5925DA", "#4B1FB7", "#2E1371", "#2D204B", "#DDD3F7", "#D1C2F5", "#C3B0F2", "#A98EEC",

//                 ],

//                 borderWidth: 1
//             }
//         ]
//     });
//     const getTooltipLabel = (context) => {
//         if (context.datasetIndex === 0) {
//             const data = context.chart.data.datasets[0].data;

//             const value = data[context.dataIndex];
//             // const percentage = ((value / total) * 100).toFixed(2) + '%';
//             //return `${props?.data?.label[context.dataIndex]}: ${value} (${percentage})`;
//             return `${value.toFixed(2) + '%'}`;
//         }
//         return '';
//     };

//     return (
//         <>

//             <Doughnut

//                 data={dataDonut}
//                 height={"100vh"}

//                 options={{
//                     responsive: true,
//                     maintainAspectRatio: false,
//                     plugins: {

//                         tooltip: {
//                             callbacks: {
//                                 label: function (context) {
//                                     return getTooltipLabel(context);
//                                 },
//                             },
//                         },

//                         legend: {
//                             display: false
//                         },
//                         datalabels: {
//                             formatter: function (value, context) {
//                                 console.log("context", context)
//                                 return null//context.dataset.data[context.dataIndex].toFixed(2);

//                             },
//                             color: 'white',

//                         }
//                     },



//                     elements: {
//                         arc: {
//                             borderWidth: 2
//                         }
//                     }
//                 }}
//             />

//         </>
//     );
// }


import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useMediaQuery } from '@mui/material';

Chart.register(CategoryScale);

export default function DonutCharts(props) {
    const chartContainerRef = useRef(null);
    const [chartHeight, setChartHeight] = useState(200);
    const isMobileOrTablet = useMediaQuery('(max-width: 1279px)');
    const isMobileOrTabletBelow800 = useMediaQuery('(max-height: 850px)');

    useEffect(() => {
        const updateChartHeight = () => {
            if (chartContainerRef.current) {
                const containerHeight = chartContainerRef.current.clientHeight;
                console.log("containerHeight", containerHeight)
                if (isMobileOrTablet) {
                    setChartHeight(90);
                } else {
                    setChartHeight(containerHeight);
                }
            }
        };

        // Call updateChartHeight initially and whenever isMobileOrTablet changes
        updateChartHeight();

        window.addEventListener("resize", updateChartHeight);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener("resize", updateChartHeight);
        };
    }, [isMobileOrTablet]);

    // useEffect(() => {
    //     const updateChartHeight = () => {
    //         if (chartContainerRef.current) {
    //             const containerHeight = chartContainerRef.current.clientHeight;
    //             console.log("containerHeight", containerHeight)
    //             if (isMobileOrTablet) {
    //                 setChartHeight(90);
    //             }
    //             else {
    //                 setChartHeight(containerHeight);
    //             }

    //         }
    //     };

    //     updateChartHeight();
    //     window.addEventListener("resize", updateChartHeight);

    //     return () => {
    //         window.removeEventListener("resize", updateChartHeight);
    //     };// eslint-disable-next-line 
    // }, []);

    const dataDonut = {
        labels: props?.data?.label,
        datasets: [
            {
                data: props?.data?.data,
                backgroundColor: [
                    "#8E6BE6", "#7448E0", "#5925DA", "#4B1FB7", "#2E1371", "#2D204B", "#DDD3F7", "#D1C2F5", "#C3B0F2", "#A98EEC",
                ],
                borderWidth: 1
            }
        ]
    };

    const getTooltipLabel = (context) => {
        if (context.datasetIndex === 0) {
            const value = context.dataset.data[context.dataIndex];
            return `${value.toFixed(2) + '%'}`;
        }
        return '';
    };

    return (
        <div ref={chartContainerRef} style={{ width: isMobileOrTabletBelow800 ? '90%' : "100%", height: chartHeight }}>
            <Doughnut
                data={dataDonut}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    return getTooltipLabel(context);
                                },
                            },
                        },
                        legend: {
                            display: false
                        },
                        datalabels: {
                            formatter: function (value, context) {
                                return null;
                            },
                            color: 'white',
                        }
                    },
                    elements: {
                        arc: {
                            borderWidth: 2
                        }
                    }
                }}
            />
        </div>
    );
}
