
import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Grid } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';// eslint-disable-next-line
import ModalTableGridTable from "../../modules/Dashboard/DashboardModalController/DashboardModalWithGrid"// eslint-disable-next-line
import { closeModal } from "../../../redux/slices/dashboardModalSlice"
import { useDispatch, useSelector } from "react-redux"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { sm: "60%", xs: "90%" },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
    p: 2,

    overflow: "auto"
};




function ModalWithTable(props) {
    const checkStatus = useSelector((state) => state.modal)
    const dispatch = useDispatch()
    return (
        <div>
            <Modal
                open={checkStatus?.modalShow}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    maxHeight: { xs: "95vh", sm: "95vh" },
                    '& .MuiBackdrop-root': {

                        background: "none"
                    }
                }}
            >
                <Box sx={style}>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ marginBottom: "15px", fontSize: "16px" }}>
                            {checkStatus?.titleofModal}

                        </Typography>
                        <ClearIcon onClick={() => { dispatch(closeModal()) }} />

                    </Grid>

                    <Grid container >
                        <ModalTableGridTable />
                    </Grid>

                </Box>
            </Modal>
        </div>
    )
}

export default ModalWithTable