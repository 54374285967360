export const FormModalPromote = {
    formFieldPromotes: {
        gender: {
            name: 'gender',
            label: 'Gender',
            requiredErrorMsg: 'Gender is required'
        },
        age: {
            name: 'age',
            label: 'Age Range',
            requiredErrorMsg: 'Age is required'
        },
        location: {
            name: 'location',
            label: 'Location',
            requiredErrorMsg: 'Location is required'
        },
        tag: {
            name: 'tag',
            label: `Tag's`,
            requiredErrorMsg: 'Tag is required'
        },

    }
};







