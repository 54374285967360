import React, { useEffect, useState, useRef } from 'react'
import { hniGetDashboardList } from '../../../../services'

import Loader from '../../../common/Loader/Loader';
import { Box, } from '@mui/material';
import HniDashboardSectionone from "./HniDashboardSectiononetwo";
import HniDashboardSectionTwo from "./HniDashboardSectionThreeFour";
const LazyLoadingHNI = () => {
    const [sections, setSections] = useState([1]);
    const loaderRef = useRef(null);
    const User = JSON.parse(localStorage.getItem('user'))
    const [dashboardList, setDashboardList] = useState({})
    const [dashboardListsequencetwo, setDashboardListSequenceTwo] = useState({})
    const [dashboardListsequencethree, setDashboardListSequenceThree] = useState({})
    const [dashboardListsequencefour, setDashboardListSequenceFour] = useState({})
    // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false);
    const [cardList, setcardList] = useState([])


    const listapi = async () => {
        setLoading(true)
        const obj = {
            "loginUserId": User.userId,
            sequence: 1
        }
        console.time('listapi Start time==>')

        for (let [index, promise] of [{ ...obj, sequence: 1 }, { ...obj, sequence: 2 }, { ...obj, sequence: 3 }, { ...obj, sequence: 4 }].entries()) {
            try {

                const res = await hniGetDashboardList(promise);
                if (index === 0) {
                    setLoading(false)
                    if (res?.data?.status) {
                        setDashboardList(res?.data?.body)
                        // const cardObject = {
                        //     'Net Asset Value': `US $${formatNumber(res?.data?.body?.netAssetValue)}`,
                        //     'Investable Wealth': `US $${formatNumber(res?.data?.body?.investibleWealth)}`,
                        //     'Cash & Eq.': `US $${formatNumber(res?.data?.body?.cashAndEq)}`,
                        //     'Financing': `US $${formatNumber(res?.data?.body?.financing)}`,
                        //     'Current value': `US $${formatNumber(res?.data?.body?.currentValue)}`,
                        //     'Real Estate': `US $${formatNumber(res?.data?.body?.realEstate)}`,
                        // }

                        setcardList(res?.data?.body?.cardData)

                    }
                }
                if (index === 1) {
                    if (res?.data?.status)
                        setDashboardListSequenceTwo(res?.data?.body)
                }
                if (index === 3) {
                    if (res?.data?.status)
                        setDashboardListSequenceFour(res?.data?.body)
                }
                if (index === 2) {
                    if (res?.data?.status) {
                        setDashboardListSequenceThree(res?.data?.body)

                    }


                }
            } catch (error) {
                console.log(error.message);
            }
        }
        console.timeEnd('listapi Start time==>')
    }



    useEffect(() => {

        listapi();


        // eslint-disable-next-line
    }, [])

    const loadNextSection = () => {
        if (sections.length < 2) {
            setSections((prevSections) => [...prevSections, prevSections.length + 1]);
        }
    };

    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && target.target === loaderRef.current) {
            // When the loader is in view, load the next section
            loadNextSection();
        }
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(handleObserver, options);

        // Start observing the loader element
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            // Stop observing when the component unmounts
            if (loaderRef.current) { // eslint-disable-next-line
                observer.unobserve(loaderRef.current);
            }
        };// eslint-disable-next-line
    }, [sections]);

    return (

        <>  {isLoading && <Loader isLoading={isLoading} />}
            <Box minHeight="100vh" overflow="auto" style={{ padding: '20px', marginBottom: "100px" }}>
                {sections.map((sectionNumber, index) => (
                    <Box key={index} marginBottom={2}>

                        {index === 0 ? (
                            <HniDashboardSectionone User={User} cardList={cardList} dashboardList={dashboardList} dashboardListsequencetwo={dashboardListsequencetwo} />
                        ) : (
                            <HniDashboardSectionTwo User={User} dashboardListsequencetwo={dashboardListsequencetwo}
                                dashboardListsequencethree={dashboardListsequencethree}
                                dashboardListsequencefour={dashboardListsequencefour} />
                        )
                        }
                    </Box>
                ))}
                <div ref={loaderRef} id="lazyloadingVisible"></div>
            </Box></>
    );
};



export default LazyLoadingHNI;
