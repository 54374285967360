import React from 'react'
import "./Logout.css"

import Dialog from '@mui/material/Dialog';

import { useSelector, useDispatch } from "react-redux"
import { setOpenLogout, setCloseLogout } from "../../../redux/slices/navSlice"
import { useNavigate } from "react-router-dom"
import { logOut } from "../../../services/index"
import '../../../theme/styles/globalStyles.css'

function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const openLog = useSelector((state) => state.nav.openLogout)
    console.log(openLog, "open")
    const handleClose = () => {
        dispatch(setOpenLogout())
    };
    const user = JSON.parse(window.localStorage.getItem('user'))
    const accessToken = user.accessToken

    // const handleListItemClick = (value) => {
    //     onClose(value);
    // };
    const handleLogout = () => {
        console.log(accessToken, "accessToken")
        logOut({ "accessToken": accessToken, "userId": user?.userId }).then(res => {
            console.log(res.data.status, "status res")
            // if (res.data.status === true) {
            navigate(`/`);
            localStorage.removeItem("user");
            localStorage.removeItem('refreshToken');
            localStorage.clear();
            window.location.reload();
            // } 
        }).catch((error) => {
            navigate(`/`);
            localStorage.removeItem("user");
            localStorage.removeItem('refreshToken');
            localStorage.clear();
            window.location.reload();


        })
        // navigate(`/`);
        // localStorage.removeItem("user");



    };
    return (<>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open simple dialog
        </Button> */}
        <Dialog onClose={handleClose} open={openLog} hideBackdrop>
            <div className='PrimaryColor' style={{ padding: "30px 50px", textAlign: "center", borderRadius: "8px" }}>
                <h3 style={{ fontSize: "20px", color: "white" }}>LOG OUT</h3>
                <p style={{ fontSize: "14px", fontWeight: "bold", color: "white" }}>Are you sure you want to log out?</p>
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "45px" }}>
                    <button className="yes-b" onClick={handleLogout} style={{cursor: "pointer"}}>Yes</button>
                    <button className="no-b" onClick={() => dispatch(setCloseLogout())} style={{cursor: "pointer"}}>No</button>
                </div>

            </div>
        </Dialog>
    </>)
}

export default Logout