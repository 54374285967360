import React from 'react'
import { Modal, Typography, Box, Button, Chip, TextField, Tooltip, Grid } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid';
import { rejectwithReasonsorApproval } from "../../../../services/productManagement"// eslint-disable-next-line
import { managApproveorReject, approveRejectProductsServices, approveUserStatus, rejectUserStatus, compApproveorReject, getAdminDashboardDataWIthSection } from '../../../../services';
import { useNavigate } from 'react-router-dom';
import Loader from "../../../common/Loader/Loader"
import { ReactComponent as View } from '../../../../theme/images/View.svg'
import { ReactComponent as BidIcon } from '../../../../theme/images/Bid icon.svg'
import { submitVendorRejection } from "../../../../services/vendormanagement"
import { toast } from 'react-toastify';
// import moment from "moment"
import GridLayout from './gridLayout';
import { formatDate } from "../../../common/Utils/DateFormat"

import { formatNumber } from "../../../../constant/DollorFormats";
import { getIndividualTaskPaginations,getAUMLearderboardData, getRevenueLearderboardData, getProspectPaginationsData, getClientsPaginationsData } from "../../../../services/DashboardAPI"


function Cards({ readPermission, writePermission, approvePermission }) {
    const user = JSON.parse(localStorage.getItem('user'))
    const userDescription = user?.userTypeId?.description
    const [initialAPIResponnse, setInitialAPIResponnse] = React.useState({});
    const [initialAPIResponnseSecond, setInitialAPIResponnseSecond] = React.useState({});
    // const [searchParams, setsearchParams] = useSearchParams();
    const [page, setPage] = React.useState(1);
    // eslint-disable-next-line 
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalCount, setTotalCount] = React.useState()
    // eslint-disable-next-line 
    const [isLoading, setLoading] = React.useState(false);
    const [list, setList] = React.useState([]);
    // eslint-disable-next-line 
    const [leaderList, setLeaderList] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [rejId, setRejId] = React.useState('')
    // const [name, setName] = React.useState('')
    const [search, setSearch] = React.useState("");
    const [marketDetails, setmarketDetails] = React.useState([]);
    const [rejectpath, setRejectpath] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [aggregateSectorAllocation, setaggregateSectorAllocation] = React.useState({})
    const [aggregateAssetAllocation, setaggregateAssetAllocation] = React.useState({})
    const [geographicalAssetAllocation, setgeographicalAssetAllocation] = React.useState({})
    const [ttmAggregateAumBarChart, setttmAggregateAumBarChart] = React.useState({})
    const [ttmInvestedWealthBarChart, setttmInvestedWealthBarChart] = React.useState({})
    const [hniAssestsLeaderboard, sethniAssestsLeaderboard] = React.useState([])
    const [leaderbboardProduct, setleaderbboardProduct] = React.useState([])
    const [leaderboardService, setleaderboardService] = React.useState([])
    const [aumLeaderboardLocalState, setaumLeaderboardLocalState] = React.useState([])
    const [aumpage, setAumPage] = React.useState(1)
    const [cardList, setCardList] = React.useState([])
    const [revenueLeaderboardLocalState, setrevenueLeaderboardLocalState] = React.useState([])
    const [revenuepage, setrevenuepage] = React.useState(1)
    const [prospectTopNetWorthLocalState, setprospectTopNetWorthLocalState] = React.useState([])
    const [prospectPage, setprospectPage] = React.useState(1)
    const [clientTopNetWorthLocalState, setclientTopNetWorthLocalState] = React.useState([])
    const [clientPage, setclientPage] = React.useState(1)
    const navigate = useNavigate()
    const complianceUser = JSON.parse(localStorage.getItem('user'))
    const compliancevalue = complianceUser?.userSubtypeId?.description
    const column1 = [
        {
            field: 'customerName',
            headerName: ' Customer Name',
            width: 100,
            align: 'left',
            sortable: false
        },
        ...userDescription === "Kairos Admin" ? [{
            field: 'entityName',
            headerName: 'Entity Name',
            width: 90,
            align: 'left',
            sortable: false
        }] : [],
        {
            field: 'asset',
            headerName: 'Asset',
            width: 50.4,
            align: 'center',
            renderCell: (params) => {
                return formatNumber(params.row.asset)
            },
            sortable: false
    
    
        },
    ]
    const column2 = [
    
        {
            field: 'productName',
            headerName: 'Product Name',
            align: 'left',
            width: 90,
            sortable: false
        },
        ...userDescription === "Kairos Admin" ? [{
            field: 'entityName',
            headerName: 'Entity Name',
            align: 'left',
            width: 90,
            sortable: false
        }] : [],
        {
            field: 'revenue',
            headerName: 'Revenue',
            width: 63,
            align: 'center',
            renderCell: (params) => {
                return formatNumber(params.row.revenue)
            },
            sortable: false
        },
    ]
    const column3 = [
    
        {
            field: 'serviceName',
            headerName: 'Services Name',
            width: 92,
            align: 'left',
            sortable: false
        },
        ...userDescription === "Kairos Admin" ? [{
            field: 'entityName',
            headerName: 'Entity Name',
            width: 90,
            align: 'left',
            sortable: false
        }] : [],
        {
            field: 'revenue',
            headerName: 'Revenue',
            width: 61,
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return formatNumber(params.row.revenue)
            },
    
        },
    ]
    const column4 = [
        // {
        //     field: 'date',
        //     headerName: 'Date',
        //     renderCell: (params) => (
        //         <>
        //             {moment(params.value).format('DD-MMM-YYYY')}
        //         </>
        //     )
    
        // },
        {
            field: 'name',
            headerName: 'News',
            width: "100%",
            renderCell: (params) => (
                <>
    
                    {console.log(params, 'params')}
                    <a href={params?.row?.url} target='_' style={{
                        fontWeight: '500', color: 'black', textDecoration: 'none', whiteSpace: "nowrap",
                        width: "23%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }} title={params?.row?.description}>{params?.row?.name + "-" + params?.row?.description}</a>
                    {params?.row?.id <= 3 && <>&nbsp;&nbsp;<BidIcon /></>}
                </>
            )
    
        }
    ]
    React.useEffect(() => {
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: search

        }
        const timeOut = setTimeout(() => taskAPICALL(req), 1000);
        return () => clearTimeout(timeOut);
        // eslint-disable-next-line
    }, [search])

    React.useEffect(() => {
        // setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: ''
        }
        listapi(req)
        // eslint-disable-next-line 
    }, [])
    React.useEffect(() => {
        // if (search !== "") {
        //     setsearchParams({ pageNumber: page, ListCount: rowsPerPage, search: search })
        // } else if (search === "") {
        //     setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        // } else {
        //     setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        // }
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: search

        }
        taskAPICALL(req)
        //listapi(req)

        // eslint-disable-next-line 
    }, [])

    const handleClickReject = (id, path, message) => {
        setOpen(true);
        setRejId(id)
        setRejectpath(path)
        setStatus(message)
    }
    const handleApprove = (e, id, path, profilestatus) => {
        console.log(profilestatus, 'profilestatus')
        setLoading(true)
        e.preventDefault();
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: ''
        }
        const obj = {
            // userId: Number(id),
            status: "Approve",
            loginUserId: user?.userId
        }
        if (path.includes('/userManagement')) {

            approveUserStatus({ ...obj, userId: Number(id) }).then(response => {
                if (response.data.status === true) {
                    // setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
                    listapi(req)
                    toast.success(response.data.statusMessage)
                    // navigate("/dashboard")
                }
                else {
                    toast.error(response.data.statusMessage)
                    // navigate("/dashboard")
                }
                setLoading(false)

            }).catch(error => {
                console.log("errors inside catch", error)
            })

        } else if (path.includes('/lifestyleproducts') || path.includes('/financialproducts')) {
            const serviceobj = {
                productServiceId: Number(id),
                status: "Approve",
                userId: user.userId
            }
            rejectwithReasonsorApproval(serviceobj).then((resultOfAPI) => {
                if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                    listapi(req)
                    toast.success(resultOfAPI.data.statusMessage)

                }
                else {
                    toast.error(resultOfAPI.data.errorMessage)
                }
                setLoading(false)

            })
        } else if (path.includes('/financialservices') || path.includes('/lifestyleservices')) {
            console.log("approve obj", obj)
            const serviceobj = {
                productServiceId: Number(id),
                status: "Approve",
                userId: user.userId
            }
            approveRejectProductsServices(serviceobj).then(response => {
                if (response.data.status === true) {
                    listapi(req)
                    toast.success(response.data.statusMessage)
                }
                else {
                    toast.error(response.data.statusMessage)
                }
                setLoading(false)

            }).catch(error => {
                console.log("errors inside catch", error)
            })
        } else if (path.includes('/vendorManagement')) {
            const passingValue = {
                userId: user.userId,
                vendorId: Number(id),

                status: "Approve",
                reason: ''
            }
            submitVendorRejection(passingValue).then((resultOfAPI) => {
                if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                    listapi(req)
                    toast.success(resultOfAPI.data.statusMessage)
                }
                else {
                    toast.error(resultOfAPI.data.errorMessage)
                }
                setLoading(false)

            })


        } else if (path.includes('/clientprofile') || path.includes('/prospectprofile')) {

            if (profilestatus === "compliance-approve-or-reject" || profilestatus === 'Pending for Compliance Approval') {
                let profileobj = { "userId": user.userId, "prospectId": Number(id), "status": "Approve", "reason:": "" }
                compApproveorReject(profileobj).then(approveresponse => {
                    if (approveresponse?.data?.status) {
                        listapi(req)
                        toast.success(approveresponse?.data?.statusMessage)
                    } else {
                        toast.error(approveresponse?.data?.errorMessage)
                    }
                    setLoading(false)

                })
            }
            if (profilestatus === "management-approve-or-reject") {
                console.log("approve obj", obj)
                let profileobj = { "userId": user.userId, "prospectId": Number(id), "status": "Approve" }

                managApproveorReject(profileobj).then(approveresponse => {
                    if (approveresponse?.data?.status) {
                        listapi(req)
                        toast.success(approveresponse?.data?.statusMessage)
                    } else {
                        toast.error(approveresponse?.data?.errorMessage)
                    }
                    setLoading(false)

                })
            }
        }

    }
    // toast.success("An email with an embedded link is sent to the user’s registered email address")
    // navigate("/userManagement")
    const clientNetWorthPagination = async (e, value) => {
        setclientPage(value)
        //getAUMLearderboardData
        let tempPassPaginations = {

            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10

        }
        let responseFromAUM = await getClientsPaginationsData(tempPassPaginations)
        if (responseFromAUM?.data?.status) {
            setclientTopNetWorthLocalState(responseFromAUM?.data?.body?.clientTopNetworth)

        }

    }
    const revenuePaginations = async (e, value) => {
        setrevenuepage(value)
        let tempPassPaginations = {
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10

        }
        let responseFromAUM = await getRevenueLearderboardData(tempPassPaginations)
        if (responseFromAUM?.data?.status) {

            setrevenueLeaderboardLocalState(responseFromAUM?.data?.body?.revenueLeaderboard)
        }

    }
    const prospectNetWorthPagination = async (e, value) => {
        setprospectPage(value)
        //getAUMLearderboardData
        let tempPassPaginations = {

            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10

        }
        let responseFromAUM = await getProspectPaginationsData(tempPassPaginations)
        if (responseFromAUM?.data?.status) {

            setprospectTopNetWorthLocalState(responseFromAUM?.data?.body?.prospectTopNetworth)
        }

    }

    const aumPaginations = async (e, value) => {
        setAumPage(value)
        //getAUMLearderboardData
        let tempPassPaginations = {

            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10

        }
        let responseFromAUM = await getAUMLearderboardData(tempPassPaginations)
        if (responseFromAUM?.data?.status) {
            console.log(responseFromAUM?.data?.body?.aumLeaderboard, "responseFromAUM")
            setaumLeaderboardLocalState(responseFromAUM?.data?.body?.aumLeaderboard)
        }

    }

    const handleClickView = (path) => {
        navigate(path)
    }
    // const particularWord = 'approved';
    // const rejectedWord = 'rejected';

    const columns = [
        {
            field: 'updatedAt', headerName: 'Date',
            width: 90,
            renderCell: (params) => (
                <>
                    {formatDate(params.value, 'DD-MMM-YYYY')}
                </>
            )
        },
        ...userDescription === "Kairos Admin" ? [{
            field: 'entityName',
            headerName: 'Entity',
            // editable: true,
            width: 60,
        }] : [],
        {
            field: 'senderName',
            headerName: 'Request by',
            // editable: true,
            width: 85,
        },
        {
            field: 'typeOfApproval',
            headerName: 'Type of Approval',
            // editable: true,
            width: 110,
            // renderCell: (params) => (
            //         <><p style={{overflow: "hidden", textOverflow: "ellipsis"}}>{params?.value}</p></>
            // )
        },
        {
            field: 'message',
            headerName: 'Details',
            width: 140,
            // renderCell: (params) => {
            //     const text = params.value;
            //     const words = text.split(' ');
            //     for (let i = 0; i < words.length; i++) {
            //         if (words[i] === particularWord) {
            //             words[i] = '<span style="color: #1DB954;">' + words[i] + '</span>';
            //             const lastTwoWords = words.slice(-2).join(' ');

            //             const lastTwoWordsHighlighted = '<span style="color: #6442C4;">' + lastTwoWords + '</span>';

            //             words.splice(-2, 2, lastTwoWordsHighlighted);

            //             // const lastWord = words[words.length - 1];
            //             // words[words.length - 1] = '<span style="color: #6442C4;">' + lastWord + '</span>';
            //         } else if (words[i] === rejectedWord) {
            //             words[i] = '<span style="color: red;">' + words[i] + '</span>';
            //         }
            //     }
            //     const highlightedText = words.join(' ');
            //     console.log(highlightedText, 'highlightedText')
            //     // return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
            // },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            width: 200,
            align: 'left',
            renderCell: (params) => (
                <>
                    {console.log(params, 'messageType')}
                    <Grid container>
                        <Grid lg={10.5}>
                            {(params.row.messageType === 'Approved' || params.row.messageType === 'Rejected') ?
                                <><Tooltip title={`${params.row.messageType} by ${params.row.approverName}`} placement="top" arrow><Typography sx={{ cursor: "pointer", paddingLeft: "65px", fontSize: "0.75rem", color: params.row.messageType === 'Approved' ? "#1DB954" : "#E00000" }}>{params.row.messageType}</Typography></Tooltip></> :
                                <><GridActionsCellItem
                                    // params.row.messageType === "Pending for Compliance Approval" ||
                                    icon={
                                        (compliancevalue === 'COMPLIANCE') ? <Chip
                                            label="Approve"
                                            sx={{

                                                width: 'auto',
                                                height: '20px',
                                                borderRadius: '6px',
                                                color: "white",
                                                opactity: (params.row.messageType === 'Approved' || params.row.messageType === 'Rejected' || params.row.messageType === 'Pending for Management Approval' || isLoading) ? 0.3 : null,
                                                background: '#6442C4',
                                            }}
                                            disabled={params.row.messageType === 'Approved' || params.row.messageType === 'Pending for Management Approval' || params.row.messageType === 'Rejected' || isLoading}
                                            onClick={(e) => handleApprove(e, params.row.requestId, params.row.path, params.row.messageType === "Pending for Compliance Approval" ? "compliance-approve-or-reject" : "management-approve-or-reject")}
                                        /> :
                                            <Chip
                                                label="Approve"
                                                sx={{
                                                    width: 'auto',
                                                    height: '20px',
                                                    borderRadius: '6px',
                                                    color: "white",
                                                    background: '#6442C4',
                                                    opactity: (params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || params.row.messageType === ' Pending for Compliance Approval' || isLoading) ? 0.3 : null
                                                }}
                                                onClick={(e) => handleApprove(e, params.row.requestId, params.row.path, params.row.messageType === "Pending for Management Approval" ? "management-approve-or-reject" : "compliance-approve-or-reject")}
                                                disabled={params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || params.row.messageType === ' Pending for Compliance Approval' || isLoading}

                                            />}
                                // <Button onClick={() => handleApprove(status === "Pending for Compliance Approval" ? "compliance-approve-or-reject" : "management-approve-or-reject")} style={{ backgroundColor: '#66CC00', color: "white", margin: "10px", minWidth: 100, borderRadius: "12px", height: "40px" }}>

                                />
                                    <GridActionsCellItem
                                        icon={(compliancevalue === 'COMPLIANCE') ? <Chip
                                            label="Decline"
                                            sx={{
                                                width: 'auto',
                                                height: '20px',
                                                borderRadius: '6px',
                                                color: "white",
                                                background: 'black',
                                                opactity: (params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || params.row.messageType === 'Pending for Management Approval' || isLoading) ? 0.3 : null

                                            }}
                                            disabled={params.row.messageType === 'Pending for Management Approval' || params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || isLoading}
                                            onClick={() => handleClickReject(params.row.requestId, params.row.path, params.row.messageType)}
                                        /> : <Chip
                                            label="Decline"
                                            sx={{
                                                width: 'auto',
                                                height: '20px',
                                                borderRadius: '6px',
                                                color: "white",
                                                background: 'black',
                                                opactity: (params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || isLoading) ? 0.3 : null

                                            }}
                                            disabled={params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || isLoading}
                                            onClick={() => handleClickReject(params.row.requestId, params.row.path, params.row.messageType)}
                                        />}

                                    /></>}
                        </Grid>
                         <Grid lg={1.5} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Grid container  >
                                <View onClick={() => handleClickView(params.row.path)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ),
        },

    ];
    const handlePageChange = async (e, value) => {
        setPage(value)
        // setsearchParams({ pageNumber: value, ListCount: rowsPerPage })
        const listObj = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: rowsPerPage,
            search: search ? search : ''
            // "filter":2
        }
        // setLoading(true);
        taskAPICALL(listObj)
        // setLoading(false);
    }
    const handleReasonChange = (e) => {
        console.log("reason", e.target.value)
        setReason(e.target.value)
    }
    const handleReasonSubmission = (profilestatus) => {
        setLoading(true)
        // setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: ''
        }
        if (reason === "") {
            toast.error("Reason is mandatory")
            setOpen(true);
        }
        else {
            if (rejectpath.includes('/userManagement')) {
                const obj = {
                    userId: Number(rejId),
                    status: "Rejected",
                    reason: reason,
                    loginUserId: user?.userId

                }
                rejectUserStatus(obj).then(response => {
                    console.log("response for rejection", response)
                    if (response.data.status === true) {
                        toast.success(response.data.statusMessage)
                        setOpen(false);
                        taskAPICALL(req)
                        // navigate("/userManagement")
                    }
                    else {
                        toast.error(response.data.statusMessage)
                        setOpen(false);

                        // navigate("/userManagement")
                    }
                    setLoading(false)

                }).catch(error => {
                    console.log("errors inside catch of rejection", error)
                })
            } else if (rejectpath.includes('/lifestyleproducts') || rejectpath.includes('/financialproducts')) {
                const passingValue = {
                    userId: user.userId,
                    productServiceId: Number(rejId),
                    status: "Rejected",
                    reason: reason
                }
                if (reason.trim()?.length > 0) {
                    rejectwithReasonsorApproval(passingValue).then((resultOfAPI) => {
                        if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                            toast.success(resultOfAPI.data.statusMessage)
                            // navigate(`/financialproducts`)
                            taskAPICALL(req)

                        }
                        else {
                            toast.error(resultOfAPI.data.errorMessage)
                        }
                        setLoading(false)

                    })

                }

            } else if (rejectpath.includes('/financialservices') || rejectpath.includes('/lifestyleservices')) {

                const obj = {
                    userId: user?.userId,
                    status: "Rejected",
                    productServiceId: Number(rejId),
                    reason: reason
                }
                approveRejectProductsServices(obj).then(response => {
                    if (response.data.status === true) {
                        toast.success(response.data.statusMessage)
                        setOpen(false);
                    }
                    else {
                        toast.error(response.data.statusMessage)
                        setOpen(false);
                    }
                    setLoading(false)

                }).catch(error => {
                    console.log("errors inside catch of rejection", error)
                })
            } else if (rejectpath.includes('/vendorManagement')) {
                const passingValue = {
                    userId: user.userId,
                    vendorId: Number(rejId),
                    status: "Rejected",
                    reason: reason
                }
                if (reason.trim()?.length > 0) {
                    submitVendorRejection(passingValue).then((resultOfAPI) => {
                        if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                            toast.success(resultOfAPI.data.statusMessage)
                            taskAPICALL(req)
                            // navigate(`/vendorManagement`)
                        }
                        else {
                            toast.error(resultOfAPI.data.errorMessage)
                        }
                        setLoading(false)

                    })

                }
            } else if (rejectpath.includes('/clientprofile') || rejectpath.includes('/prospectprofile')) {
                if (profilestatus === "compliance-approve-or-reject" || profilestatus === 'Pending for Compliance Approval') {
                    let obj = { "userId": user.userId, "prospectId": Number(rejId), "status": "Rejected", "reason": reason }
                    compApproveorReject(obj).then(approveresponse => {
                        if (approveresponse?.data?.status) {
                            taskAPICALL(req)
                            toast.success(approveresponse?.data?.statusMessage)
                        } else {
                            toast.error(approveresponse?.data?.errorMessage)
                        }
                        setLoading(false)

                    })
                }
                console.log(profilestatus, 'profilestatus')
                if (profilestatus === "management-approve-or-reject" || profilestatus === 'Pending for Management Approval') {
                    let obj = { "userId": user.userId, "prospectId": Number(rejId), "status": "Rejected", "reason": reason }
                    console.log("approve obj", obj)
                    managApproveorReject(obj).then(approveresponse => {
                        if (approveresponse?.data?.status) {
                            taskAPICALL(req)
                            toast.success(approveresponse?.data?.statusMessage)
                        } else {
                            toast.error(approveresponse?.data?.errorMessage)
                        }
                        setLoading(false)

                    })
                }

            }

        }

        setOpen(false);

    }
    const handlefilter = (e) => {
        setPage(1)
        const keyword = e.target.value;
        const trimmedKeyword = keyword.trimStart();
        setSearch(trimmedKeyword)
        // if (trimmedKeyword.length > 2) {
        //     setSearch(trimmedKeyword)
        // } else {
        //     setName(trimmedKeyword)
        //     setSearch("")
        // }
    }

    const taskAPICALL = async (req) => {
        await getIndividualTaskPaginations(req).then((res) => {
            console.log("getIndividualTaskPaginations", res)
            if (res?.data?.status) {
                setList(res?.data?.body?.task?.notifications)
                setTotalCount(res?.data?.body?.task?.totalPages)

            } else if (res?.data?.statusMessage === "No Record Found") {
                setList([])
                setTotalCount([])
            }
        })

    }


    const listapi = async (req) => {
        setLoading(true)
        for (let [index, promise] of [{ ...req, sequence: 1 }, { ...req, sequence: 2 }, { ...req, sequence: 3 }, { ...req, sequence: 4 }].entries()) {
            try {
                const res = await getAdminDashboardDataWIthSection(promise);
                if (index === 0) {
                    if (res?.data?.status) {
                        setttmInvestedWealthBarChart(res?.data?.body?.ttmInvestedWealthBarChart);
                        setttmAggregateAumBarChart(res?.data?.body?.ttmAggregateAumBarChart)
                        // let temp1 = {
                        //     "Invested Wealth": `US $${formatNumber(res?.data?.body?.investedWealth)}`,
                        //     "Total AUM": `US $${formatNumber(res?.data?.body?.totalAUM)}`,
                        //     "Total Client Net Worth": `US $${formatNumber(res?.data?.body?.totalClientNetWorth)}`,
                        //     "No. of Clients": `${formatNumber(res?.data?.body?.numberOfHNI, 0)}`,
                        //     "No. of RMs": `${formatNumber(res?.data?.body?.numberOfRM, 0)}`,
                        //     "Total Partners/Vendor": `${formatNumber(res?.data?.body?.totalVendors, 0)}`,

                        // }

                        setCardList(res?.data?.body?.cardData);
                    }
                    else {
                        setttmInvestedWealthBarChart({});
                        setttmAggregateAumBarChart({})
                        // let temp1 = {
                        //     "Invested Wealth": `US $0`,
                        //     "Total AUM": `US $0`,
                        //     "Total Client Net Worth": `US $0`,
                        //     "No. of Clients": '0',
                        //     "No. of RMs": `0`,
                        //     "Total Partners/Vendor": `0`,

                        // }

                        setCardList([]);
                    }
                    setLoading(false)

                }
                if (index === 1) {
                    if (res?.data?.status) {
                        setaggregateSectorAllocation(res?.data?.body?.sectorAssetAllocation)
                        setaggregateAssetAllocation(res?.data?.body?.aggregateAssetAllocation)
                        setgeographicalAssetAllocation(res?.data?.body?.geographicalAssetAllocation);
                        setrevenueLeaderboardLocalState(res?.data?.body?.revenueLeaderboard);
                        setaumLeaderboardLocalState(res?.data?.body?.aumLeaderboard);
                        setInitialAPIResponnse({ ...initialAPIResponnse, revenueTotalPages: res?.data?.body?.revenueTotalPages, aumTotalPages: res?.data?.body?.aumTotalPages })

                    }
                    else {
                        setaggregateAssetAllocation({})
                        setaggregateSectorAllocation({})
                        setgeographicalAssetAllocation({});
                        setrevenueLeaderboardLocalState([]);
                        setaumLeaderboardLocalState([]);
                        setInitialAPIResponnse({ ...initialAPIResponnse, revenueTotalPages: 0, aumTotalPages: 0 })

                    }
                    setLoading(false)

                }
                if (index === 2) {
                    if (res?.data?.status) {
                        setprospectTopNetWorthLocalState(res?.data?.body?.prospectTopNetworth)
                        setclientTopNetWorthLocalState(res?.data?.body?.clientTopNetworth)
                        setList(res?.data?.body?.task?.notifications)
                        setTotalCount(res?.data?.body?.task?.totalPages)
                        setInitialAPIResponnseSecond({ ...initialAPIResponnse, prospectTopNetworthTotalPages: res?.data?.body?.prospectTopNetworthTotalPages, clientTopNetworthTotalPages: res?.data?.body?.clientTopNetworthTotalPages })


                    }
                    else {
                        setprospectTopNetWorthLocalState([])
                        setclientTopNetWorthLocalState([])
                        setList([])
                        setTotalCount(0)
                        setInitialAPIResponnseSecond({ ...initialAPIResponnse, prospectTopNetworthTotalPages: 0, clientTopNetworthTotalPages: 0 })

                    }
                    setLoading(false)

                }
                if (index === 3) {
                    if (res?.data?.status) {
                        sethniAssestsLeaderboard(res?.data?.body?.hniAsset)
                        setleaderbboardProduct(res?.data?.body?.productRevenue)
                        setleaderboardService(res?.data?.body?.serviceRevenue)
                        const marketlist = res?.data?.body?.marketNews?.marketNews
                        setmarketDetails(marketlist || [])
                    }
                    else {
                        sethniAssestsLeaderboard([])
                        setleaderbboardProduct([])
                        setleaderboardService([])

                        setmarketDetails([])
                    }
                    setLoading(false)

                }
            } catch (error) {
                console.log(error.message);
            }
        }

    }
    return (
        <>
            {isLoading && <Loader isLoading={isLoading} />}
            <GridLayout marketDetails={marketDetails} search={search} handlefilter={handlefilter} leaderList={leaderList} page={page} totalCount={totalCount} isLoading={isLoading} list={list} columns={columns} column1={column1} column2={column2} column3={column3} column4={column4} handlePageChange={handlePageChange} initialAPIResponnse={initialAPIResponnse} initialAPIResponnseSecond={initialAPIResponnseSecond} aumpage={aumpage} aumLeaderboardLocalState={aumLeaderboardLocalState} aumPaginations={aumPaginations} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} revenuepage={revenuepage} prospectTopNetWorthLocalState={prospectTopNetWorthLocalState} prospectPage={prospectPage} prospectNetWorthPagination={prospectNetWorthPagination} clientNetWorthPagination={clientNetWorthPagination} clientPage={clientPage} clientTopNetWorthLocalState={clientTopNetWorthLocalState} hniAssestsLeaderboard={hniAssestsLeaderboard} leaderbboardProduct={leaderbboardProduct} leaderboardService={leaderboardService} ttmInvestedWealthBarChart={ttmInvestedWealthBarChart} ttmAggregateAumBarChart={ttmAggregateAumBarChart} aggregateAssetAllocation={aggregateAssetAllocation} aggregateSectorAllocation={aggregateSectorAllocation} geographicalAssetAllocation={geographicalAssetAllocation} cardList={cardList} />


            <Modal
                open={open}
                disableAutoFocus={true}
                disableEscapeKeyDown
                // onBackdropClick
                onClose={() => setOpen(false)}
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: '45%',
                        backgroundColor: "#693BE1",
                        left: '45%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: "40%", minHeight: "40%",
                        borderRadius: "8px",
                        margin: "5%",
                    }}
                >
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                    </div>
                    <Typography id="open-open-title" sx={{ marginTop: "10px", padding: "10px", fontSize: "18px", textAlign: "center", color: "white" }} >
                        Reason for rejection
                    </Typography>
                    <TextField
                        sx={{
                            minHeight: "50%", width: "100%",
                            paddingLeft: "50px", paddingRight: "50px",
                            '& input::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: '#363636',
                            },
                            '& .MuiInputBase-root': {
                                background: '#FFFFFF 0% 0%',
                                borderRadius: '8px',
                            },
                        }}
                        onChange={(e) => handleReasonChange(e)}
                        //setReason
                        placeholder='Tell us the reason for rejection'
                        multiline
                        rows={4}
                    />
                    <Box style={{ textAlign: "center", mb: "30px", mt: "20px" }}>
                        {(status === "Pending for Compliance Approval" || status === "Pending for Management Approval") ? <Button style={{
                            width: "100px",
                            height: "35px",
                            borderRadius: 8,
                            backgroundColor: "#FFFFFF",
                            color: "#6A3BDE",
                            fontSize: "14px"
                        }} variant="contained"
                            onClick={() => handleReasonSubmission(status === "Pending for Compliance Approval" ? "compliance-approve-or-reject" : "management-approve-or-reject")}>
                            Confirm
                        </Button> : <Button style={{
                            width: "100px",
                            height: "35px",
                            borderRadius: 8,
                            backgroundColor: "#FFFFFF",
                            color: "#6A3BDE",
                            fontSize: "14px"
                        }} variant="contained"
                            onClick={() => handleReasonSubmission('')}>
                            Confirm
                        </Button>}

                        <Button style={{
                            marginLeft: 5,
                            width: "100px",
                            height: "35px",
                            borderRadius: 8,
                            backgroundColor: "#FFFFFF",
                            color: "#6A3BDE",
                            fontSize: "14px"
                        }} variant="contained" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>

    )
}

export default Cards;
