import React, { useState } from "react";
import GuestDashboard from "./GuestDashboard";
import GuestSideNavbar from "./guestSideNavbar";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography
} from "@mui/material";
import ContactUs from "./contactUs";
import GuestHeader from './guestHeader';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setGuestOpen } from "../../../redux/slices/navSlice";

const drawerWidth = "75%";
const GuestLayout = ({ children }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [contactPopUp, setContactPopUP] = useState(false);
  const guestOpen = useSelector((state) => (state.nav.guestOpen));

  const dispatch = useDispatch()
  const theme = useTheme();
  const handleBackgroundClick = () => {
    setShowPopUp(true);
  };

  const handleClose = () => {
    setShowPopUp(false);
  };
  const handleContactClose = () => {
    setContactPopUP(false);
  };
  const handleDialogClick = (e) => {
    e.stopPropagation();
  };

  console.log(showPopUp, "showPopUp");
  const handleProceed = () => {
    setShowPopUp(false);
    setContactPopUP(true);
  };
  const handleSideClose = () => {
    dispatch(setGuestOpen(false));
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '0px'
  }));

  const navigate = useNavigate()
  const handleNavigate = () => {
    localStorage.clear(); // Clear local storage
    navigate('/guestUser')
    // window.location.href = 'https://www.kairoswealth.com';
  };

  return (
    <div onClick={handleBackgroundClick}>
      <ContactUs open={contactPopUp} handleDialogClick={handleDialogClick} handleContactClose={handleContactClose} />
      {showPopUp && (
        <div className="popup">
          <Dialog
            open={showPopUp}
            onClose={handleClose}
            onClick={handleDialogClick}
          >
            <DialogTitle disableTypography>
              <div
                style={{
                  display: "flex",
                  //   alignItems: "center",
                  justifyContent: "end",
                  //   paddingRight: "16px",
                }}
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent
              sx={{
                minWidth: "300px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h4 style={{ font: "normal normal 900 16px/20px Mona Sans" }}>
                <b>Sign to kairos to navigate further</b>
              </h4>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "#683AE0",
                  width: "230px",
                  borderRadius: "6px",
                }}
                onClick={handleProceed}
              >
                Proceed
              </Button>
            </DialogActions>
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Go to&nbsp;
              <Link href="#" onClick={handleNavigate}>kairos Wealth.com</Link>  </Typography>

          </Dialog>
        </div>
      )}

      <Grid container>

        <Drawer

          sx={{
            width: drawerWidth,
            display: { sm: "none" },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: "#171B24",
            },
          }}
          variant="persistent"
          anchor="left"
          open={guestOpen}
        >
          <DrawerHeader >
            <IconButton onClick={handleSideClose} sx={{ top: "10px" }}>
              {theme.direction === 'ltr' ? <ClearIcon sx={{ color: 'white', postion: 'absolute' }} /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <GuestSideNavbar />
        </Drawer>
        <Grid item xl={2} lg={2} md={3} sm={3} sx={{ border: "1px solid #171B24", height: "100vh", background: '#171B24' }} display={{ xs: "none", lg: "block", xl: "block", md: "block", sm: "block" }}>
          <GuestSideNavbar />
        </Grid>

        <Grid item xl={10} lg={10} md={9} sm={9} xs={12}>
          {/* <Header /> */}
          <GuestHeader />

          <div
            style={{
              top: "5px",
              padding: "5px",
              overflow: "auto",
              height: "90vh",
              position: "relative",
            }}
          >
            {/* {children} */}
            <GuestDashboard />
            {/* <Logout /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuestLayout;
