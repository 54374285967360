import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography, Tooltip } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import PaginationComponent from '../../../common/Pagination/Pagination'
import { formatNumber } from "../../../../constant/DollorFormats"
function LeaderBoard({ aumrows, revenuerows, aumPaginations, aumTotalPage, aumPage, revenueTotalPages, revenuepage, revenuePaginations }) {
  const user = JSON.parse(localStorage.getItem('user'))
  const userDescription = user?.userTypeId?.description
  return (
    <>
      <div>
        <Typography sx={{ marginBottom: "10px", fontFamily: "Mona Sans", fontSize: "11px", fontWeight: 600 }}>RM Leaderboard - By AUM & Revenue</Typography>
        <Typography sx={{ fontFamily: "Mona Sans", fontSize: "11px", fontWeight: 600 }}>AUM LEADERBOARD</Typography>
        <hr />
        {(aumrows?.length <= 0 || !aumrows) && <>No Records</>}
        {aumrows?.length > 0 && <TableContainer>
          <Table sx={{
            minWidth: "220px", [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            },

            "& .MuiTableCell-root": {
              padding: "2px", borderBottom: "none", overflow: "hidden"
            }
          }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ minWidth: userDescription === "Kairos Admin" ? '40px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '40px' : '50px', display: "none" }}>Name</TableCell>
                {userDescription === "Kairos Admin" ? <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Entity Name</TableCell> : null}
                <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Revenue</TableCell>
                <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Ranking</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aumrows?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
                  <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: userDescription === "Kairos Admin" ? '40px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '40px' : '50px', fontSize: "12px", fontFamily: "Mona Sans" }}><span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${index + 1}.`}</span>{row.rmName}</TableCell>
                  {userDescription === "Kairos Admin" ? <Tooltip title={row.entityName} placement="right"><TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", minWidth: '20px', maxWidth: '25px', fontSize: "12px", fontFamily: "Mona Sans", fontWeight: 600 }}>{row.entityName}</TableCell></Tooltip> : null}
                  <TableCell align="middle" sx={{ whiteSpace: "nowrap", minWidth: '20px', maxWidth: '25px', fontSize: "12px", fontFamily: "Mona Sans", fontWeight: 600 }}>{`US$ ${formatNumber(row.aum, 0)}`}</TableCell>
                  <TableCell align="middle" sx={{ minWidth: '10px', maxWidth: '10px', fontFamily: "Mona Sans" }}>{row.ranking === "Dot" ? <FiberManualRecordIcon color='success' style={{ fontSize: "10px" }} /> : row?.ranking === "Up Arrow" ? <ArrowDropUpIcon color='success' style={{ fontSize: "10px" }} /> : <ArrowDropDownIcon color='error' style={{ fontSize: "10px" }} />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
        <div style={{ marginTop: "10px" }}>
          <PaginationComponent handlePageChange={aumPaginations} page={aumPage} totalCount={aumTotalPage} size={"small"} siblingCount={0} />
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Typography sx={{ fontFamily: "Mona Sans", fontSize: "11px", fontWeight: 600 }}>REVENUE LEADERBOARD</Typography>
        <hr />
        {(revenuerows?.length <= 0 || !revenuerows) && <>No Records</>}
        {revenuerows?.length > 0 &&
          <TableContainer>
            <Table sx={{
              minWidth: "220px", [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
              },

              "& .MuiTableCell-root": {
                padding: "2px", borderBottom: "none", overflow: "hidden"
              }
            }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ minWidth: userDescription === "Kairos Admin" ? '40px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '40px' : '50px', display: "none" }}>Name</TableCell>
                  {userDescription === "Kairos Admin" ? <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Entity Name</TableCell> : null}
                  <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Revenue</TableCell>
                  <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Ranking</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {revenuerows?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
                    <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: userDescription === "Kairos Admin" ? '40px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '40px' : '50px', fontSize: "12px", fontFamily: "Mona Sans" }}><span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${index + 1}.`}</span>{row.rmName}</TableCell>
                    {userDescription === "Kairos Admin" ? <Tooltip title={row.entityName} placement="right"><TableCell align="middle" sx={{ minWidth: '20px', whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: '25px', fontSize: "12px", fontFamily: "Mona Sans", fontWeight: 600 }}>{row?.entityName}</TableCell></Tooltip> : null}
                    <TableCell align="middle" sx={{ minWidth: '20px', maxWidth: '25px', fontSize: "12px", fontFamily: "Mona Sans", fontWeight: 600 }}>{`${row.revenue?.toFixed(3)}Bps`}</TableCell>
                    <TableCell align="middle" sx={{ minWidth: '10px', maxWidth: '10px', fontFamily: "Mona Sans" }}>{row.ranking === "Dot" ? <FiberManualRecordIcon color='success' style={{ fontSize: "10px" }} /> : row?.ranking === "Up Arrow" ? <ArrowDropUpIcon color='success' style={{ fontSize: "10px" }} /> : <ArrowDropDownIcon color='error' style={{ fontSize: "10px" }} />}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
        <div style={{ marginTop: "10px", marginBottom: "5px" }}>
          <PaginationComponent handlePageChange={revenuePaginations} page={revenuepage} totalCount={revenueTotalPages} size={"small"} siblingCount={0} />
        </div>
      </div>
    </>
  )
}

export default LeaderBoard