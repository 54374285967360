import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import Timer from "./TimerRun";
//import Loader from "../common/Loader/Loader";
// import { ReactComponent as Warning } from '../../theme/images/Warning.svg';

// const TimeOutWarning = ({ handlelogOut, open }) => {

//   return (
//     <div>
//       <Modal
//         open={open}
//       // onClose={handleClose}
//       >
//         <Box
//           style={{
//             position: 'absolute',
//             marginTop: "10px",
//             top: '50%',
//             backgroundColor: "#6A3BDE",
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             minWidth: 360,
//             height: "231px",
//             borderRadius: "8px"
//           }}
//         >
//           <div style={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>
//             <Warning />
//           </div>
//           <Typography id="modal-modal-title" variant="h1" sx={modalFirst}>
//             Session Timed Out!
//           </Typography>

//           <div style={{ display: 'flex', justifyContent: "center", alignItem: "center", textAlign: "center", marginTop: 65 }}>
//             <Button onClick={() => handlelogOut()} style={{
//               width: "120px",
//               height: "45px",
//               borderRadius: 8,
//               backgroundColor: "#FFFFFF",
//               color: "#6A3BDE",
//               fontSize: "18px"
//             }} variant="contained">
//               Ok
//             </Button>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

const TimeOutWarning = ({ handlelogOut, open, handleContinueSession }) => {
  //const [loadingTable, setloadingTable] = React.useState(false)

  const localValueMethod = async () => {
    // setloadingTable(true)
    // const timeoutDurations = 1000 * 60 * 14.3;
    // const currentTime = new Date().getTime();
    // const futureTimes = currentTime + timeoutDurations;
    // localStorage.setItem('onMountTime', futureTimes.toString());
    // setrefreshTokenCall(true)
    setTimeout(async () => {
      await handleContinueSession();
      //  setloadingTable(false)
    }, 900)

  }

  return (
    <div>
      {/* {(loadingTable) && <><Loader isLoading={loadingTable} /></>} */}
      <Modal open={open}>
        <Box
          style={{
            position: 'absolute',
            marginTop: '10px',
            top: '50%',
            backgroundColor: '#FFFFFF',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 300,
            // height: '231px',
            borderRadius: '8px',
            padding: '20px',
          }}
        >

          <div >
            <div>
              <Typography variant="h2" sx={{ color: '#000000', fontSize: '18px', marginBottom: '10px', fontWeight: "bold" }}>
                Your Session Is About To Expire
              </Typography>
              <Typography variant="p" sx={{ color: '#707070', fontSize: '16px', marginBottom: '20px' }}>
                You will be signed out automatically if you do not resume your session within next {<Timer handlelogOut={handlelogOut} />} Seconds.
              </Typography>
              {/* next {<Timer handlelogOut={handlelogOut} />} */}
            </div>
            <div style={{ display: 'flex', gap: '10px', marginTop: "10px", justifyContent: "space-between" }}>
              <Button
                onClick={() => handlelogOut()}
                variant="contained"
                sx={{
                  width: '120px',
                  height: '32px',
                  borderRadius: '8px',
                  backgroundColor: '##010001',
                  color: '#FFFFFF',
                  fontSize: '14px',
                  boxShadow: "1px 8px 29px #695F9724",

                  opacity: 1,
                }}
              >
                Sign Out
              </Button>
              <Button
                onClick={() => {
                  localValueMethod()

                }}
                variant="contained"
                sx={{
                  width: 'auto',
                  height: '32px',
                  letterSpacing: "0.35px",
                  boxShadow: "1px 8px 29px #695F9724",
                  opacity: 1,
                  borderRadius: '8px',
                  backgroundColor: '#6A3BDE',
                  color: '#FFFFFF',
                  padding: "5px",
                  fontSize: '14px',
                  '&:hover': { // Add hover styles
                    backgroundColor: '#6A3BDE', // Change background color on hover
                    color: '#FFFFFF', // Change text color on hover
                    opacity: 1 // Change opacity on hover
                  }
                }}
              >
                Resume Session
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default TimeOutWarning
