/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ProductBlackCard from "../../../../theme/images/blackpatterns1.png"
import { Grid, Button, Typography, Divider, Box, Card, CardContent, Modal, ListItem, List, ListItemAvatar, Avatar, useMediaQuery, ListItemText, AvatarGroup, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ListOfClients from '../DashboardList/ListOfClients';
import ListOfProspects from '../DashboardList/ListOfProspects';
import GridTable from '../DashboardList/GridTable';
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as BidIcon } from '../../../../theme/images/Bid icon.svg'
import FAQSCard from '../../../common/Grid/FAQSCard';
import { UseCurrencyHook } from '../../../common/GlobalCurrency/useCurrencyHook';
import { TitleConverstion } from '../../../../constant/DollorFormats';
import { useSelector } from 'react-redux';
import PurpleBackground from "../../../../theme/images/PurpleBG.png"// eslint-disable-next-line


const columnsHniLeaderBoard = [
    { field: 'customerName', headerName: 'Customer Name', width: 100, sortable: false, flex: 1 },
    { field: 'asset', headerName: 'Asset', width: 67, sortable: false, },
];
const columnsProductLeaderBoard = [
    { field: 'productName', headerName: 'Product Name', width: 100, sortable: false, flex: 1 },
    { field: 'revenue', headerName: 'Revenue', width: 67, sortable: false },
]
const columnsServiceLeaderBoard = [
    { field: 'serviceName', headerName: 'Service Name', width: 100, sortable: false, flex: 1 },
    { field: 'revenue', headerName: 'Revenue', width: 67, sortable: false, }
]

const column4 = [
    {
        field: 'name',
        headerName: 'News',
        width: "100%",
        renderCell: (params) => (
            <>

                {console.log(params, 'params')}
                <a href={params?.row?.url} target='_' style={{
                    fontWeight: '500', color: 'black', textDecoration: 'none', whiteSpace: "nowrap",
                    width: "22%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }} title={params?.row?.description}>{params?.row?.name + "-" + params?.row?.description}</a>
                {params?.row?.id <= 3 && <>&nbsp;&nbsp;<BidIcon /></>}
            </>
        )

    }
]
const RMDashboardSectionThree = (props) => {
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);
    const user = JSON.parse(localStorage.getItem('user'));// eslint-disable-next-line
    const [filteropen, setFilteropen] = useState(null);
    //const [isLoading, setLoading] = useState(true);
    const [cardList, setCardList] = useState([]);// eslint-disable-next-line
    const [aggAssetAllocation, setAggAssetAllocation] = useState({});// eslint-disable-next-line
    const [aggSectorAllocation, seAggSectorAllocation] = useState({});// eslint-disable-next-line
    const [aggGeoAllocation, setAggGeoAllocation] = useState({});
    const [aumleaderboard, setAumleaderboard] = useState([])
    const [revenueleaderboard, setRevenueleaderboard] = useState([]);
    const [aumpage, setAumpage] = useState(1);
    const [revenuepage, setRevenuepage] = useState(1);// eslint-disable-next-line
    const [ttmAggAum, setTtmAggAum] = useState({});// eslint-disable-next-line
    const [ttmInvWealth, setTtmInvWealth] = useState({});
    const [totalResponse, setTotalResponse] = useState({});
    const [hnileaderboard, setHnileaderboard] = useState([]);
    const [productleaderboard, setProductleaderboard] = useState([]);
    const [serviceleaderboard, setServiceleaderboard] = useState([]);
    const [marketNews, setMarketNews] = useState([]);
    const [recProducts, setRecProducts] = useState([]);
    const [recServices, setRecServices] = useState([]);
    const [lifportfolio, setLifportfolio] = useState([]);
    const [finportfolio, setFinportfolio] = useState([]);
    const [hniList, setHniList] = useState([]);
    const [modal, setModal] = useState(false);
    const [productTotalPages, setProductTotalPages] = useState(1);
    const [serviceTotalPages, setServiceTotalPages] = useState(1);
    const [prodpage, setProdpage] = useState(0);
    const [servpage, setServpage] = useState(0);
    const [portfolioIndex, setPortfolioIndex] = useState(0);
    const [portfolioType, setPortfolioType] = useState("");
    const [advsuite, setAdvsuite] = useState([]);
    const [finProdServLoader, setFinProdServLoader] = useState(false);
    const [lifProdServLoader, setLifProdServLoader] = useState(false);
    const navigate = useNavigate();
    const isMorethanLG = useMediaQuery('(min-width:1201px) and (max-width:1340px)');
    const isMorethanSM = useMediaQuery('(min-width:600px) and (max-width:900px)');
    const isLessthanSM = useMediaQuery('(min-width:300px) and (max-width:600px)');
    const isMediumTab = useMediaQuery('(min-width:900px) and (max-width:1200px)');



    useEffect(() => {
        // productleaderboard = { productleaderboard } serviceleaderboard = { serviceleaderboard } marketNews = { marketNews } lifportfolio = { lifportfolio } finportfolio = { finportfolio } hniList = { hniList } advsuite = { advsuite } hnileaderboard = { hnileaderboard }
        setFinportfolio(props?.finportfolio)
        setHnileaderboard(props?.hnileaderboard)
        setHniList(props?.hniList)
        setLifportfolio(props?.lifportfolio)
        setMarketNews(props?.marketNews)

        setProductleaderboard(props?.productleaderboard)

        setServiceleaderboard(props?.serviceleaderboard)
        setAdvsuite(props?.advsuite)
        setRecProducts(props?.recProducts)
        setProductTotalPages(props?.productTotalPages)
        setRecServices(props?.recServices)
        setServiceTotalPages(props?.serviceTotalPages)
        // recProducts = { recProducts } productTotalPages = { productTotalPages } serviceTotalPages = { serviceTotalPages } recServices = { recServices } 
        // eslint-disable-next-line
    }, [])



    const handleClickAddProspects = () => {
        navigate("/dashboard/AddProspects")
    }
    const handleClickViewProduct = (productid) => {
        navigate("/viewProductDetails/" + btoa(productid), { state: { recommentation: "dashboard" } })
    }
    const handleClickViewService = (serviceid) => {
        navigate("/viewServiceDetails/" + btoa(serviceid), { state: { recommentation: "dashboard" } })
    }


    const handleClickPortfolio = (index, type) => {
        setPortfolioIndex(index);
        setPortfolioType(type);
        setModal(true);
    }
    const handleCloseLifStylePortfolio = () => {
        setModal(false);
    }

    const handleClickViewPortfolio = (index, clientId) => {
        if (portfolioType === "financial") {
            navigate("/myPortfolio", { state: { setTab: index, financialPortfolio: "financialPortfolio", hniId: clientId } })
        } else {
            navigate("/myPortfolio", { state: { setTab: index, lifestylePortfolio: "lifestylePortfolio", hniId: clientId } })
        }
    }
    const isXL = useMediaQuery("(min-width: 2000px) and (max-width: 4000px)");

    return (
        <>

            <Modal open={modal} onClose={handleCloseLifStylePortfolio}>
                <Box
                    sx={{
                        width: 350,
                        boxShadow: 24,
                        borderRadius: "5px",
                        position: "absolute",
                        padding: 2,
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        border: "1px solid #ffffff",
                    }}
                >
                    <List
                        sx={{
                            maxHeight: 300, // Adjust the max height as needed
                            width: "100%",
                            overflowY: "auto", // Make the list scrollable
                        }}
                    >
                        <CloseIcon
                            sx={{ float: "right", fontSize: "20px", cursor: "pointer" }}
                            onClick={handleCloseLifStylePortfolio}
                        ></CloseIcon>
                        <Typography sx={{ fontSize: "14px", marginLeft: "20px" }}>Client's List</Typography>
                        {props?.hniList?.map((user, index) => {
                            return (
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Client" src={user?.profilePhotoUrl}></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={user?.firstName} sx={{ marginTop: "15px", fontSize: "10px" }}></ListItemText>
                                    <Button
                                        sx={{
                                            color: "white",
                                            float: "right",
                                            background: "#6442C4",
                                            border: "8px",
                                            marginTop: "10px",
                                            "&:hover": {
                                                color: "white",
                                                background: "#6442C4",
                                            },
                                        }}
                                        onClick={() => handleClickViewPortfolio(portfolioIndex, user?.clientId)}
                                    >
                                        View Portfolio
                                    </Button>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Modal>
            {props?.loadingSectionThree && <><p>Loading...</p></>}
            {!props?.loadingSectionThree && <div >
                <Grid container columnSpacing={1} sx={{ marginTop: "5px", marginBottom: '30px' }}>
                    <Grid item xs={12} lg={7.5}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={12} lg={4.75}>
                                <ListOfProspects handleClickAddProspects={handleClickAddProspects} />
                            </Grid>
                            <Grid item xs={12} lg={7.25}>
                                <ListOfClients />
                            </Grid>
                            <Grid item xs={12} lg={12} sx={{ marginTop: "10px", }}>
                                <Box
                                    sx={{
                                        height: "auto",
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "0.47px 3px 10px #77777733",
                                        // border: "1px solid #EAEAEA",
                                        borderRadius: "8px",
                                        opacity: 1,
                                    }}
                                >
                                    <Typography variant='h6' style={{ padding: "15px 10px 5px 10px", color: "#000000", fontSize: "14px", fontWeight: 600 }}
                                    >Leader board
                                    </Typography>
                                    <Grid container sx={{ flexGrow: 1 }} >
                                        <Grid item xs={12} lg={4} md={12} >
                                            <Grid container sx={{ p: 0.5 }}>
                                                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="body1" sx={{ p: "6px", fontWeight: 600, fontSize: "10px", height: "25px", whiteSpace: 'pre' }}>Top client's with respect to assets</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                </Grid>
                                            </Grid>
                                            <GridTable rows={props?.hnileaderboard} getRowId={(row) => row.hniId} columns={columnsHniLeaderBoard} height={"180px"} ></GridTable>
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={12}>
                                            <Grid container sx={{ p: 0.5 }}>
                                                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="body1" sx={{ p: "6px", fontWeight: 600, fontSize: "10px", height: "24px", whiteSpace: 'pre' }}>Top products in terms of revenue</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                </Grid>
                                            </Grid>
                                            <GridTable rows={props?.productleaderboard} getRowId={(row) => row.productId} columns={columnsProductLeaderBoard} height={"180px"}></GridTable>
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={12}>
                                            <Grid container sx={{ p: 0.5 }}>
                                                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="body1" sx={{ p: "6px", fontWeight: 600, fontSize: "10px", height: "24px", whiteSpace: 'pre' }}>Top services in terms of revenue</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                </Grid>
                                            </Grid>
                                            <GridTable rows={props?.serviceleaderboard} getRowId={(row) => row.serviceId} columns={columnsServiceLeaderBoard} height={"180px"}></GridTable>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4.5} md={12} sm={12}>
                        <div style={{ height: "100%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px" }}>
                            <GridTable margintop={"5px"} height={isLessthanSM || isMorethanSM || isMediumTab ? "200px" : "98%"} rows={props?.marketNews} columns={column4}></GridTable>
                        </div>
                    </Grid>




                </Grid>

                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' }
                }}>
                    <Grid item >
                        <Typography variant='h6'
                        >Top financial products and services recommended for your clients</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: 'black' }} />
                <div style={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>

                </div>
                <Grid container sx={{ marginBottom: '10px', paddingTop: '10px', justifyContent: "center", alignItems: "center", rowGap: "10px" }} >

                    {props?.recProducts?.map((product, index) => {
                        if (props?.finProdServLoader) {
                            return (
                                <Grid item lg={3} md={6} sm={12} spacing={1}>
                                    <Card variant="outlined" sx={{
                                        minWidth: isMorethanLG ? "235px" : "265px",
                                        maxWidth: isMorethanLG ? "235px" : "265px",
                                        borderRadius: "15px",
                                        backgroundSize: "cover", height: "95%", backgroundColor: `#000000`,
                                    }}>
                                        <CardContent sx={{ color: "white", "& .MuiCardContent-root": { padding: "8px" } }}>
                                            <CircularProgress color="inherit" sx={{ marginLeft: "100px" }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                        return (
                            <Grid item lg={3} md={6} sm={12} spacing={1} sx={{ flexBasis: isMorethanSM && 'auto !important' }}>
                                {product.productServiceTypeId.description === 'FINANCIAL PRODUCTS' ? <Card variant="outlined" sx={{
                                    // width: "290px",
                                    minWidth: isMorethanLG ? "235px" : "265px",
                                    maxWidth: isMorethanLG ? "235px" : "265px",
                                    borderRadius: "15px",
                                    backgroundSize: "cover",
                                    height: "95%",
                                    backgroundImage: `url(${ProductBlackCard})`,
                                }}>
                                    <CardContent sx={{
                                        color: "white",
                                        "& .MuiCardContent-root": {
                                            padding: "8px",
                                        }
                                    }}>
                                        <Grid container flexDirestion="row" justifyContent={"space-between"}>
                                            <Grid item xs={11} lg={11} md={9} >
                                                {<Typography title={moment(product.endDate).format("D MMMM  YYYY")} sx={{ fontSize: "10px" }} gutterBottom>
                                                    <span style={{ fontFamily: "Mona Sans", color: "#D9D9D9", fontSize: "10px" }}>Expiry</span>  {moment(product.endDate).format("D MMMM  YYYY")}

                                                </Typography>}
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }} flexDirection="column">
                                            <Grid item xs={11} lg={11} md={11}>
                                                <Typography noWrap title={product.productServiceName} sx={{ textTransform: "uppercase", fontSize: "14px" }}>
                                                    {product?.productServiceName}
                                                </Typography></Grid>
                                            <Grid item xs={11} lg={11} md={11}>
                                                <Typography noWrap title={product?.primaryCategoryId?.description} >
                                                    <span style={{
                                                        fontSize: "12px", fontFamily: "Mona Sans",

                                                        letterSpacing: "0.41px",
                                                        color: "#e8e8e8"
                                                    }}>
                                                        {product?.primaryCategoryId?.description}
                                                    </span>
                                                </Typography></Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }} flexDirection="row" justifyContent={"space-between"}>
                                            <Grid item>
                                                <Grid container flexDirection={"column"} sx={{ marginTop: "8px" }}>
                                                    <Grid item>
                                                        <Typography title={"Minimum Investment"} sx={{ fontSize: "10px", color: "#E8E8E8", fontFamily: "Mona Sans" }}>
                                                            Minimum Investment
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography title={TitleConverstion(currencyTitle?.currencyCode, product?.sellingPrice, user, currency)} sx={{ fontSize: "10px", fontFamily: "Mona Sans" }}>

                                                            <UseCurrencyHook val={product?.sellingPrice} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={() => handleClickViewProduct(product?.productServiceId)} sx={{
                                                  fontFamily: "Mona Sans",  color: "#ffffff", justifyContent: "center",  borderRadius: "6px", fontSize: "10px", fontWeight: 900, background: "#6442c4 0% 0% no-repeat padding-box", height: "60%", marginTop: "10px", "&:hover": {
                                                        background: "#6442c4 0% 0% no-repeat padding-box",
                                                    }
                                                }}>
                                                    View
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card> : <Card variant="outlined" sx={{
                                    // width: "290px",
                                    borderRadius: "15px",
                                    minWidth: isMorethanLG ? "235px" : "265px",
                                    backgroundImage: `url(${product?.productServicePhotoUrl})`,
                                    backgroundSize: "cover",
                                    height: "95%",
                                    maxHeight: "159px",
                                    maxWidth: isMorethanLG ? "235px" : "265px",
                                    backgroundColor: "#ddc9ff",
                                }}>
                                    <CardContent sx={{
                                        color: "white",
                                        padding: "8px 8px 8px 0px",

                                    }}>
                                        <Box sx={{ color: "rgb(255, 255, 255)", backgroundColor: "rgb(41, 41, 41, 0.44)", marginTop: "10px", borderRadius: "0px 8px 8px 0px", padding: "10px 5px 10px 5px" }}>
                                            <Typography title={moment(product.endDate).format("D MMMM  YYYY")} sx={{ fontSize: "10px" }} gutterBottom>
                                                <span style={{ fontFamily: "Mona Sans", color: "#D9D9D9", fontSize: "10px" }}>{product?.productServiceName}</span>
                                            </Typography>
                                            <Typography noWrap title={product?.primaryCategoryId?.description} sx={{ textTransform: "uppercase", fontSize: "10px" }}>
                                                <span style={{ color: "#E8E8E8", fontSize: "12px" }}>
                                                    {product?.primaryCategoryId?.description}
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Grid container flexDirection="row" justifyContent={"space-between"} mt={"10%"}>
                                            <Grid item>
                                            </Grid>
                                            <Grid item >
                                                <Button onClick={() => handleClickViewService(product?.productServiceId)} sx={{
                                                    color: "#ffffff", justifyContent: "center", fontFamily: "Mona Sans", borderRadius: "6px", fontSize: "10px", fontWeight: 900, background: "#6442c4 0% 0% no-repeat padding-box", height: "60%", marginTop: "10px",
                                                    "&:hover": {
                                                        background: "#6442c4 0% 0% no-repeat padding-box",
                                                    }
                                                }}>
                                                    View
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>}
                            </Grid>
                        )
                    })}
                </Grid>
                <div style={{ float: "right" }}>
                    {props?.productTotalPages > 1 && <Pagination sx={{
                        "& .MuiPaginationItem-root.Mui-selected ": {
                            backgroundColor: "black !important",
                            color: "white !important"
                        },
                    }} count={props?.productTotalPages} page={props?.prodpage + 1} onChange={props?.handlePageProdChange} />}
                </div>

                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' }
                }}>
                    <Grid item>
                        <Typography variant='h6'>Top lifestyle products and services recommended for your clients</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: 'black' }} />
                <div style={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>

                </div>
                <Grid container sx={{ marginBottom: '10px', paddingTop: '10px', justifyContent: "center", alignItems: "center", rowGap: "10px" }}>
                    {props?.recServices?.map((service, index) => {
                        if (props?.lifProdServLoader) {
                            return (
                                <Grid item lg={3} md={6} xs={12} spacing={1}>
                                    <Card variant="outlined" sx={{
                                        minWidth: isMorethanLG ? "235px" : "265px",
                                        maxWidth: isMorethanLG ? "235px" : "265px", borderRadius: "15px",
                                        backgroundSize: "cover", height: "95%", backgroundColor: `#000000`,
                                    }}>
                                        <CardContent sx={{ color: "white", "& .MuiCardContent-root": { padding: "8px" } }}>
                                            <CircularProgress color="inherit" sx={{ marginLeft: "100px" }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                        return (
                            <Grid item lg={3} md={6} sm={12} spacing={2} sx={{ flexBasis: isMorethanSM && 'auto !important' }}>
                                {service.productServiceTypeId.description === 'LIFESTYLE PRODUCTS' ? <Card variant="outlined" sx={{
                                    // width: "100%",
                                    minWidth: isMorethanLG ? "235px" : "265px",
                                    maxWidth: isMorethanLG ? "235px" : "265px",
                                    borderRadius: "15px",
                                    backgroundSize: "cover",
                                    height: "95%",
                                    backgroundImage: `url(${ProductBlackCard})`,
                                }}>
                                    <CardContent sx={{
                                        color: "white",
                                        "& .MuiCardContent-root": {
                                            padding: "8px",
                                        }
                                    }}>
                                        <Grid container flexDirestion="row" justifyContent={"space-between"}>
                                            <Grid item xs={11} lg={11} md={9} >
                                                {<Typography title={moment(service.endDate).format("D MMMM  YYYY")} sx={{ fontSize: "10px" }} gutterBottom>
                                                    <span style={{ fontFamily: "Mona Sans", color: "#D9D9D9", fontSize: "10px" }}>Expiry</span>  {moment(service.endDate).format("D MMMM  YYYY")}

                                                </Typography>}
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }} flexDirection="column">
                                            <Grid item xs={11} lg={11} md={11}>
                                                <Typography noWrap title={service.productServiceName} sx={{ textTransform: "uppercase", fontSize: "14px" }}>
                                                    {service?.productServiceName}
                                                </Typography></Grid>
                                            <Grid item xs={11} lg={11} md={11}>
                                                <Typography noWrap title={service?.primaryCategoryId?.description} >
                                                    <span style={{
                                                        fontSize: "12px", fontFamily: "Mona Sans",

                                                        letterSpacing: "0.41px",
                                                        color: "#e8e8e8"
                                                    }}>
                                                        {service?.primaryCategoryId?.description}
                                                    </span>
                                                </Typography></Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }} flexDirection="row" justifyContent={"space-between"}>
                                            <Grid item>
                                                <Grid container flexDirection={"column"} sx={{ marginTop: "8px" }}>
                                                    <Grid item>
                                                        <Typography title={"Minimum Investment"} sx={{ fontFamily: "Mona Sans",fontSize: "10px", color: "#E8E8E8", }}>
                                                            Minimum Investment
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography title={"$" + service.sellingPrice} sx={{ fontSize: "10px", fontFamily: "Mona Sans" }}>

                                                            <UseCurrencyHook val={service?.sellingPrice} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={() => handleClickViewProduct(service?.productServiceId)} sx={{
                                                    color: "#ffffff", justifyContent: "center", fontFamily: "Mona Sans", borderRadius: "6px", fontSize: "10px", fontWeight: 900, background: "#6442c4 0% 0% no-repeat padding-box", height: "60%", marginTop: "10px", "&:hover": {
                                                        background: "#6442c4 0% 0% no-repeat padding-box",
                                                    }
                                                }}>
                                                    View
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card> : <Card variant="outlined" sx={{
                                    // width: "100%",
                                    borderRadius: "15px",
                                    minWidth: isMorethanLG ? "235px" : "265px",
                                    maxWidth: isMorethanLG ? "235px" : "265px",
                                    backgroundSize: "cover",
                                    height: "95%",
                                    maxHeight: "159px",
                                    backgroundColor: "#ddc9ff",
                                    backgroundImage: `url(${service?.productServicePhotoUrl})`,
                                }}>
                                    <CardContent sx={{
                                        color: "white",
                                        padding: "8px 8px 8px 0px",

                                    }}>
                                        <Box sx={{ color: "rgb(255, 255, 255)", backgroundColor: "rgb(41, 41, 41, 0.44)", marginTop: "10px", borderRadius: "0px 8px 8px 0px", padding: "10px 5px 10px 5px" }}>
                                            <Typography title={moment(service.endDate).format("D MMMM  YYYY")} sx={{ fontSize: "10px" }} gutterBottom>
                                                <span style={{ fontFamily: "Mona Sans", color: "#D9D9D9", fontSize: "10px" }}>{service?.productServiceName}</span>
                                            </Typography>
                                            <Typography noWrap title={service?.primaryCategoryId?.description} sx={{ textTransform: "uppercase", fontSize: "10px" }}>
                                                <span style={{ color: "#E8E8E8", fontSize: "12px" }}>
                                                    {service?.primaryCategoryId?.description}
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Grid container flexDirection="row" justifyContent={"space-between"} mt={"9.6%"}>
                                            <Grid item>
                                            </Grid>
                                            <Grid item >
                                                <Button onClick={() => handleClickViewService(service?.productServiceId)} sx={{
                                                    color: "#ffffff", justifyContent: "center", fontFamily: "Mona Sans", borderRadius: "6px", fontSize: "10px", fontWeight: 900, background: "#6442c4 0% 0% no-repeat padding-box", height: "60%", marginTop: "10px",
                                                    "&:hover": {
                                                        background: "#6442c4 0% 0% no-repeat padding-box",
                                                    }
                                                }}>
                                                    View
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>}
                            </Grid>
                        )
                    })}
                </Grid>
                <div style={{ float: "right" }}>
                    {props?.serviceTotalPages > 1 && <Pagination sx={{
                        "& .MuiPaginationItem-root.Mui-selected ": {
                            backgroundColor: "black !important",
                            color: "white !important"
                        },
                    }} count={props?.serviceTotalPages} page={props?.servpage + 1} onChange={props?.handlePageServChange} />}
                </div>
                <br />
                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' }
                }}>
                    <Grid item>
                        <Typography variant='h6'>Manage your clients advisory suite</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: 'black' }} />
                <br />
                <FAQSCard faqsCard={props?.advsuite} />

                <br />
                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' }
                }}>
                    <Grid item>
                        <Typography variant='h6'>Manage your clients lifestyle portfolio suite</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: 'black' }} />

                <Grid container columnSpacing={2} rowSpacing={{ xs: 2, lg: 0 }} sx={{ marginBottom: '10px', paddingTop: '10px' }}>

                    {props?.lifportfolio?.map((item, index) => {
                        return (
                            <Grid item lg={3} md={6} xs={6} sm={6} sx={{ paddingBottom: "16px" }}>
                                <Card onClick={() => handleClickPortfolio(index, "lifestyle")} variant="outlined" sx={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    height: "100%",
                                    backgroundImage: `url(${item?.imagePath})`,
                                    backgroundSize: "cover",
                                    marginBottom: "5px",
                                    cursor: "pointer"
                                }}>
                                    <AvatarGroup sx={{
                                        padding: "5px",
                                        '& .MuiAvatar-root': { width: 15, height: 15, fontSize: 12 },
                                    }} max={2}>
                                        {props?.hniList?.map((user, index) => {
                                            return (
                                                <Avatar alt={user?.firstName} src={user?.profilePhotoUrl} />
                                            )
                                        })}
                                    </AvatarGroup>
                                    <CardContent sx={{
                                        color: "white",
                                        padding: "20px 0px 10px 0px"
                                    }}>
                                        <Box sx={{ color: "rgb(255, 255, 255)", backgroundColor: "rgb(41, 41, 41, 0.44)", marginTop: "10px", borderRadius: "0px 0px 0px 0px", padding: "10px 5px 10px 5px" }}>
                                            <Typography sx={{ fontSize: "12px", textAlign: "center" }}>Manage</Typography>
                                            <Typography sx={{ fontSize: "12px", textAlign: "center" }}>{item?.description}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' }
                }}>
                    <Grid item>
                        <Typography variant='h6'>Manage your clients financial portfolio suite</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: 'black' }} />
                <br />
                <Grid container columnSpacing={2} rowSpacing={2} sx={{ marginBottom: '10px',marginLeft: isXL ? "4%" : 0 }}>

                    {props?.finportfolio?.map((item, index) => {
                        return (
                            <Grid key={item?.id} item lg={3} md={6} xs={6} sm={6} sx={{ justifyItems: "center", alignItems: "center" }}>
                                <Grid onClick={() => handleClickPortfolio(index, "financial")} sx={{ backgroundImage: `url(${PurpleBackground})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", cursor: "pointer", height: "80px", justifyItems: "center", alignItems: "center", justifyContent: "center", display: "flex", justifySelf: "center", borderRadius: "9px",maxWidth: "280px" }}>
                                    <Typography sx={{ textAlign: 'center', justifyItems: "center", alignItems: "center", justifyContent: "center", display: "flex", justifySelf: "center", height: '100%', color: '#FFF' }}>{item?.description}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
                <br />



            </div>}
        </>
    )
}
export default RMDashboardSectionThree
