import moment from 'moment';

export const formatDate = (timestamp, formatText) => {


    if (timestamp === null) {
        return
    }
    var local = moment.utc(timestamp).local().format(formatText);
    console.log(local, "- UTC now to local");

    return local;
};

export const formatRelativeTime = (timestamp) => {
    const now = new Date().getTime();
    const d = new Date();
    const val = d.getTimezoneOffset();
    const diff = (now - (timestamp - (val * 60000))) / 1000; // difference in seconds

    if (diff < 60) {
        return "just now";
    } else if (diff < 60 * 60) {
        const minutes = Math.floor(diff / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (diff < 60 * 60 * 24) {
        return "yesterday";
    } else if (diff < 60 * 60 * 24 * 7) {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const day = new Date(timestamp).getDay();
        return days[day];
    } else {
        const date = new Date(timestamp);
        const month = date.toLocaleString("default", { month: "long" });
        const day = date.getDate();
        const year = date.getFullYear();
        // return ` ${day}${day > 3 && day < 21 ? "th" : day % 10 === 1 ? "st" : day % 10 === 2 ? "nd" : day % 10 === 3 ? "rd" : "th"} ${month}, ${year}`;
        return ` ${day} ${month} ${year}`;
    }
}