export const FormModalBordeauxFilter = {

    formFieldbordeauxFilters: {
        regionFilter: {
            name: 'regionFilter',
            label: 'Region',
            placeholder: 'Select Region',


        },
        vintageFilter: {
            name: 'vintageFilter',
            label: 'Vintage',
            placeholder: 'Select Vintage',


        },
        growerFilter: {
            name: 'growerFilter',
            label: 'Grower',
            placeholder: 'Select Grower',


        },
        colorFilter: {
            name: 'colorFilter',
            label: 'Colour',
            placeholder: 'Select Colour',


        },


    }
}