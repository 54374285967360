/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, Paper, Typography, Box, Tooltip, useMediaQuery } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import GridTable from './GridTable';
import DynamicDashboardCards from "../../../common/Grid/DynamicDashboardCards"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ReactComponent as NewIcon } from '../../../../theme/images/newicon.svg'
import PaginationComponent from '../../../common/Pagination/Pagination';// eslint-disable-next-line 
import IframeReports from "../../../common/ChartsComponent/ChartIframe"// eslint-disable-next-line
import BarChartController from "../../Dashboard/DashboardModalController/BarChartController";// eslint-disable-next-line
import PieChartController from "../../Dashboard/DashboardModalController/PieChartController"// eslint-disable-next-line
import SearchIcon from '@mui/icons-material/Search'
import { Search, SearchIconWrapper, StyledInputBaseDashboard } from "../../../common/Form/UserManagementSearch";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DashboardsCard from '../../../common/Grid/DashboardsCard';

import LeaderBoard from "../RmDashboard/LeaderBoard";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../../Style/fontStyle.css';
import { formatNumber } from "../../../../constant/DollorFormats";
// const cardList = {
//   'Invested Wealth': "300",
//   'Total AUM': "",
//   'Total Client Net Worth': "100",
//   'No. of Clients': "150",
//   'No. of RMs': "250",
//   'Total Partners/Vendors': "400",
// }
// eslint-disable-next-line
const rowsLeaderBoard = [
  { id: 1, productName: 'ICICI', revenue: '10.1M' },
  { id: 2, productName: 'Aditya Birla', revenue: '9.1M' },
  { id: 3, productName: 'Axis bank', revenue: '8.1M' },
  { id: 4, productName: 'HDFC monthly', revenue: '9.1M' },
  { id: 5, productName: 'L&T tax', revenue: '8.1M' },
  { id: 6, productName: 'ABT tax', revenue: '8.1M' },
];
// eslint-disable-next-line 
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  background: "#F7F7F7",
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
// eslint-disable-next-line 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#5021C6',
  textAlign: 'center',
  height: '100%',
  color: '#FFF',
  padding: '10px 0'
}));
// eslint-disable-next-line 
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#F7F7F7",

  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
// eslint-disable-next-line 
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  background: 'rgba(255, 255, 255, .05)'
}));



const Item1 = styled(Paper)(({ theme }) => ({
  height: '302px', background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px",
}));
// eslint-disable-next-line 
const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  height: '90%'
}));


function GridLayout({ marketDetails, column4, handlefilter, search, leaderList, initialAPIResponnseSecond, page, totalCount, taskList, list, columns, column1, column2, column3, handlePageChange, initialAPIResponnse, aumLeaderboardLocalState, aumPaginations, aumpage, revenuepage, revenuePaginations, revenueLeaderboardLocalState, prospectNetWorthPagination, prospectPage, prospectTopNetWorthLocalState, clientNetWorthPagination, clientPage, clientTopNetWorthLocalState, leaderbboardProduct, leaderboardService, hniAssestsLeaderboard, ttmInvestedWealthBarChart, ttmAggregateAumBarChart, aggregateSectorAllocation, aggregateAssetAllocation, geographicalAssetAllocation, cardList }) {

  const user = JSON.parse(localStorage.getItem('user'));
  const userDescription = user?.userTypeId?.description;
  const isMobileOrTablet = useMediaQuery('(max-width: 1279px)');
  const isMobileOrTabletBelow800 = useMediaQuery('(max-height: 700px)');
  return (
    <>


      <Box sx={{ height: "auto", padding: "10px", background: "#FFFFFF 0% 0% no-repeat padding-box", borderBottom: "1px solid #DFD2D2" }}>
        <Typography sx={{ height: "38px", fontWeight: 600 }} variant="h4" className='welcomeText'>{`Welcome ${user?.firstName}`}</Typography>
        {/* <DashboardsCard rows={cardList} /> */}
        <DynamicDashboardCards data={cardList} cardsPerRow={6} />
      </Box>
      {isMobileOrTablet ? <MobileAndTabletLayout aumrows={aumLeaderboardLocalState} revenuerows={revenueLeaderboardLocalState} revenueTotalPages={initialAPIResponnse?.revenueTotalPages} aumTotalPage={initialAPIResponnse?.aumTotalPages} aumPaginations={aumPaginations} aumPage={aumpage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} aggregateSectorAllocation={aggregateSectorAllocation} geographicalAssetAllocation={geographicalAssetAllocation} aggregateAssetAllocation={aggregateAssetAllocation} ttmAggregateAumBarChart={ttmAggregateAumBarChart} ttmInvestedWealthBarChart={ttmInvestedWealthBarChart} /> : <MyGridLayout aumrows={aumLeaderboardLocalState} revenuerows={revenueLeaderboardLocalState} revenueTotalPages={initialAPIResponnse?.revenueTotalPages} aumTotalPage={initialAPIResponnse?.aumTotalPages} aumPaginations={aumPaginations} isMobileOrTabletBelow800={isMobileOrTabletBelow800} aumPage={aumpage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} aggregateSectorAllocation={aggregateSectorAllocation} geographicalAssetAllocation={geographicalAssetAllocation} aggregateAssetAllocation={aggregateAssetAllocation} ttmAggregateAumBarChart={ttmAggregateAumBarChart} ttmInvestedWealthBarChart={ttmInvestedWealthBarChart} />}
      {/* <Grid container columnSpacing={1}>
        <Grid item xs={12} lg={9} md={12} sx={{ marginTop: "1vh", marginBottom: "1vh" }}>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} lg={6} md={12}  >

              <div sx={{ height: "60%" }}> <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} /></div>

            </Grid>
            <Grid item xs={12} lg={6} md={12} >

              <div sx={{ height: "60%" }}>   <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} /></div>

            </Grid>
            <br /><br />

            <Grid item xs={12} lg={4} mt={"5px"} >

              <div sx={{ height: "39%" }}>    <PieChartController title={"Aggregate Asset Allocation (%)"} data={aggregateAssetAllocation} category={"Asset"} /></div>

            </Grid>
            <Grid item xs={12} lg={4} mt={"5px"} >

              <div sx={{ height: "39%" }}>   <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} /></div>

            </Grid>
            <Grid item xs={12} lg={4} mt={"5px"} sx={{ height: "39%" }}>

              <div sx={{ height: "39%" }}>    <PieChartController title={"Aggregate Sector Allocation (%)"} data={aggregateSectorAllocation} category={"Sector"} /></div>

            </Grid>


          </Grid>
        </Grid>
        <Grid item xs={12} lg={3} sx={{ marginTop: "1vh", }}>

          <div style={{ height: "98.5%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}>
            <LeaderBoard aumrows={aumLeaderboardLocalState} revenuerows={revenueLeaderboardLocalState} revenueTotalPages={initialAPIResponnse?.revenueTotalPages} aumTotalPage={initialAPIResponnse?.aumTotalPages} aumPaginations={aumPaginations} aumPage={aumpage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} />
          </div>
        </Grid>
      </Grid>



      <Grid container columnSpacing={1} rowSpacing={1} height="100%">
        <Grid item xs={12} lg={9} md={12} sx={{ flexDirection: 'column' }}>
          <Grid container columnSpacing={1} style={{ height: '100%' }}>
            <Grid item xs={12} lg={12} md={12} style={{ height: '60%' }}>
              <Grid container columnSpacing={1} rowSpacing={1} style={{ height: '100%' }}>
                <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
                  <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} />
                </Grid>
                <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
                  <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12} md={12} style={{ height: '40%' }}>
              <Grid container columnSpacing={1} flexDirection={"row"} style={{ height: '100%' }}>
                <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
                  <PieChartController title={"Aggregate Asset Allocation (%)"} data={aggregateAssetAllocation} category={"Asset"} />
                </Grid>
                <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
                  <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} />
                </Grid>
                <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
                  <PieChartController title={"Aggregate Sector Allocation (%)"} data={aggregateSectorAllocation} category={"Sector"} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3} style={{ height: '100%' }}>
          <div style={{ height: "99.5%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}>
            <LeaderBoard aumrows={aumLeaderboardLocalState} revenuerows={revenueLeaderboardLocalState} revenueTotalPages={initialAPIResponnse?.revenueTotalPages} aumTotalPage={initialAPIResponnse?.aumTotalPages} aumPaginations={aumPaginations} aumPage={aumpage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} />
          </div>
        </Grid>
      </Grid> */}









      <Grid container columnSpacing={1} rowSpacing={{ xs: 2, lg: 0 }} sx={{ marginBottom: '10px' }}>
        <Grid item lg={7} xs={12}>
          <Item1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h6' sx={{
                padding: "13px 10px",
                fontWeight: 600
              }}>Task</Typography>
              <div style={{
                width: "150px"
              }} >
                <Search sx={{ height: '30px' }}>
                  <SearchIconWrapper >
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBaseDashboard
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={search}
                    onChange={(e) => { handlefilter(e) }}
                    sx={{ '&.MuiInputBase-input': { padding: '0px !important' } }}
                  />
                </Search>
              </div>
            </div>
            {list && <><GridTable data={'task'} rows={list} columns={columns}></GridTable>
              <div style={{ float: "right", alignItems: "end", justifyContent: "end", textAlign: "end", margin: '3px' }}>
                <PaginationComponent handlePageChange={handlePageChange} page={page} totalCount={totalCount} size={"small"} />
              </div></>}
          </Item1>
        </Grid>

        <Grid item lg={5} xs={12} >
          <Paper sx={{ height: "302px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}>
            <Grid container>
              <Grid item lg={6}>
                <div style={{ padding: "5px 0px 5px 5px" }}>
                  <Typography sx={{ marginBottom: "15px", fontWeight: 600 }}>Prospect Top Net Worth (US$)</Typography>
                  {(prospectTopNetWorthLocalState?.length <= 0 || !prospectTopNetWorthLocalState) && <>No Records</>}
                  {prospectTopNetWorthLocalState?.length > 0 &&
                    <div style={{ height: "220px", overflowY: "auto", borderRight: "1px solid #e0e0e0" }}>

                      <TableContainer>
                        <Table sx={{
                          minWidth: "220px", [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                          },

                          "& .MuiTableCell-root": {
                            padding: "2px", borderBottom: "none", overflow: "hidden"
                          }
                        }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left" sx={{ minWidth: '10px', maxWidth: '10px', display: "none" }}>Name</TableCell>
                              <TableCell align="left" sx={{ minWidth: userDescription === "Kairos Admin" ? '30px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '50px', display: "none" }}>Revenue</TableCell>
                              {userDescription === "Kairos Admin" ? <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Entity Name</TableCell> : null}
                              <TableCell align="left" sx={{ minWidth: '20px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '25px', display: "none" }}>Ranking</TableCell>
                              <TableCell align="left" sx={{ minWidth: '10px', maxWidth: '10px', display: "none" }}>New</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {prospectTopNetWorthLocalState?.map((row, index) => (
                              <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
                                <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '10px', maxWidth: '10px', fontSize: "11px", fontFamily: "Mona Sans" }}>{(row?.riskAppetite === "RISK AVERSE" || row?.riskAppetite === "CONSERVATIVE") ? <FiberManualRecordIcon color='success' style={{ fontSize: "10px" }} /> : row?.riskAppetite === "MODERATE" ? <FiberManualRecordIcon color='warning' style={{ fontSize: "10px" }} /> : <FiberManualRecordIcon color='error' style={{ fontSize: "10px" }} />}</TableCell>
                                <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: userDescription === "Kairos Admin" ? '30px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '50px', fontSize: "11px", fontFamily: "Mona Sans" }} title={row.hniName} >{row.hniName}</TableCell>
                                {userDescription === "Kairos Admin" ? <Tooltip title={row.entityName} placement="right"><TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: '25px', fontSize: "11px", fontFamily: "Mona Sans", fontWeight: 600 }}>{row?.entityName}</TableCell></Tooltip> : null}
                                <TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '25px', fontSize: "11px", fontFamily: "Mona Sans", fontWeight: 600 }} title={formatNumber(row.networth, 0)}>{`US$ ${formatNumber(row.networth, 0)}`}</TableCell>
                                <TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '10px', maxWidth: '10px', fontFamily: "Mona Sans" }}>{row.newItem === "Yes" ? <NewIcon /> : null}</TableCell>
                                {/* <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '10px', maxWidth: '10px', fontSize: "12px", fontFamily: "Mona Sans" }}>{(row?.riskAppetite === "RISK AVERSE" || row?.riskAppetite === "CONSERVATIVE") ? <FiberManualRecordIcon color='success' style={{ fontSize: "10px" }} /> : row?.riskAppetite === "MODERATE" ? <FiberManualRecordIcon color='warning' style={{ fontSize: "10px" }} /> : <FiberManualRecordIcon color='error' style={{ fontSize: "10px" }} />}</TableCell> */}
                                {/* <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: userDescription === "Kairos Admin" ? '30px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '50px', fontSize: "12px", fontFamily: "Mona Sans" }} title={row.hniName} >{row.hniName}</TableCell> */}
                                {/* {userDescription === "Kairos Admin" ? <Tooltip title={row.entityName} placement="right"><TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: '25px', fontSize: "12px", fontFamily: "Mona Sans", fontWeight: 900 }}>{row?.entityName}</TableCell></Tooltip> : null} */}
                                {/* <TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '25px', fontSize: "12px", fontFamily: "Mona Sans", fontWeight: 900 }} title={formatNumber(row.networth, 0)}>{`US$ ${formatNumber(row.networth, 0)}`}</TableCell> */}
                                {/* <TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '10px', maxWidth: '12px', fontFamily: "Mona Sans" }}>{row.newItem === "Yes" ? <NewIcon /> : null}</TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer></div>}
                  <div style={{ width: "100%" }}>
                    <PaginationComponent handlePageChange={prospectNetWorthPagination} page={prospectPage} totalCount={initialAPIResponnseSecond?.prospectTopNetworthTotalPages} size={"small"} siblingCount={0} />
                  </div>


                </div>
              </Grid>
              <Grid item lg={6}>
                <div style={{ padding: "5px 0px 5px 5px" }}>
                  <Typography sx={{ marginBottom: "15px", fontWeight: 600 }}>Client Top Net Worth (US$)</Typography>

                  {(clientTopNetWorthLocalState?.length <= 0 || !clientTopNetWorthLocalState) && <>No Records</>}
                  {clientTopNetWorthLocalState?.length > 0 &&
                    <div style={{ height: "220px", overflowY: "auto" }}>
                      <TableContainer>
                        <Table sx={{
                          minWidth: "220px", [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                          },

                          "& .MuiTableCell-root": {
                            padding: "2px", borderBottom: "none", overflow: "hidden"
                          }
                        }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left" sx={{ minWidth: '10px', maxWidth: '10px', display: "none" }}>Name</TableCell>
                              <TableCell align="left" sx={{ minWidth: userDescription === "Kairos Admin" ? '30px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '50px', display: "none" }}>Revenue</TableCell>
                              {userDescription === "Kairos Admin" ? <TableCell align="left" sx={{ minWidth: '20px', maxWidth: '25px', display: "none" }}>Entity Name</TableCell> : null}
                              <TableCell align="left" sx={{ minWidth: '20px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '25px', display: "none" }}>Ranking</TableCell>
                              <TableCell align="left" sx={{ minWidth: '10px', maxWidth: '10px', display: "none" }}>New</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clientTopNetWorthLocalState?.map((row, index) => (
                              <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
                                <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '10px', maxWidth: '10px', fontSize: "11px", fontFamily: "Mona Sans" }}>{(row?.riskAppetite === "RISK AVERSE" || row?.riskAppetite === "CONSERVATIVE") ? <FiberManualRecordIcon color='success' style={{ fontSize: "10px" }} /> : row?.riskAppetite === "MODERATE" ? <FiberManualRecordIcon color='warning' style={{ fontSize: "10px" }} /> : <FiberManualRecordIcon color='error' style={{ fontSize: "10px" }} />}</TableCell>
                                <TableCell align="left" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: userDescription === "Kairos Admin" ? '30px' : '50px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '50px', fontSize: "11px", fontFamily: "Mona Sans" }} title={row.hniName}>{row.hniName}</TableCell>
                                {userDescription === "Kairos Admin" ? <Tooltip title={row.entityName} placement="right"><TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: '25px', fontSize: "11px", fontFamily: "Mona Sans", fontWeight: 600 }}>{row?.entityName}</TableCell></Tooltip> : null}
                                <TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: userDescription === "Kairos Admin" ? '30px' : '25px', fontSize: "11px", fontFamily: "Mona Sans", fontWeight: 600 }} title={formatNumber(row.networth, 0)}>{`US$ ${formatNumber(row.networth, 0)}`}</TableCell>
                                <TableCell align="middle" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '10px', maxWidth: '10px', fontFamily: "Mona Sans" }}>{row.newItem === "Yes" ? <NewIcon /> : null}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer></div>}
                  <div style={{ width: "100%" }}>
                    <PaginationComponent handlePageChange={clientNetWorthPagination} page={clientPage} totalCount={initialAPIResponnseSecond?.clientTopNetworthTotalPages} size={"small"} siblingCount={0} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>

        </Grid>
        {/* <Grid item lg={3} xs={12} >
        </Grid> */}
      </Grid>

      <Grid container columnSpacing={1} rowSpacing={{ xs: 2, lg: 0 }}>
        <Grid item lg={6} xs={12} sx={{ height: "300px" }}>

          <Box sx={{
            height: '90%',
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 8px #77777733",
            border: "0.699999988079071px solid #DFD2D280",
            borderRadius: "12px",
            padding: "5px",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'

          }}>
            <Typography variant='h6' sx={{ padding: '5px 8px', fontWeight: 600 }}>Leader Board</Typography>
            <Grid container sx={{ flexGrow: 1 }}  >
              <Grid item xs={12} lg={4}>
                <div style={{ display: 'flex', paddingLeft: '10px' }}>
                  <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '95%', padding: '2px', fontWeight: 600 }} title={"Top Client's with respect to Invested assets"}><span style={{ fontWeight: 'bold' }}>Assets</span> - Top Clients</Typography>

                </div>
                <GridTable rows={hniAssestsLeaderboard} getRowId={(row) => row?.hniId} columns={column1} data={"height"}></GridTable>
              </Grid>
              <Grid item xs={12} lg={4} >
                <div style={{ display: 'flex', paddingLeft: '10px' }}>
                  <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '95%', padding: '2px', fontWeight: 600 }} title={'Top Products in terms of revenue'}><span style={{ fontWeight: 'bold' }}>Revenue</span> - Top Products</Typography>

                </div>
                <GridTable rows={leaderbboardProduct} getRowId={(row) => row?.productId} columns={column2} data={"height"}></GridTable>
              </Grid>
              <Grid item xs={12} lg={4}>
                <div style={{ display: 'flex', padding: '0px 10px', justifyContent: 'space-between' }}>
                  <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '95%', padding: '2px', fontWeight: 600 }} title={'Top Services in terms of revenue'}><span style={{ fontWeight: 'bold' }}>Revenue</span> - Top Services</Typography>

                </div>
                <GridTable rows={leaderboardService} getRowId={(row) => row?.serviceId} columns={column3} data={"height"}></GridTable>
              </Grid>
            </Grid>
          </Box>

        </Grid >
        <Grid item lg={6} xs={12} sx={{ height: "300px" }}>
          <Box sx={{
            height: '90%',
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 8px #77777733",
            border: "0.699999988079071px solid #DFD2D280",
            borderRadius: "12px",
            padding: "5px",
            display: 'flex',
            flexDirection: 'column',
          }}>
            {/* <Item2> */}
            <Typography variant='h6' sx={{
              padding: "5px 8px",
              fontWeight: 600
            }}>Market News</Typography>
            {marketDetails && <GridTable data={'height'} rows={marketDetails} columns={column4}></GridTable>}
            {/* </Item2> */}
          </Box>

        </Grid>
      </Grid >
    </>

  )
}



const MobileAndTabletLayout = ({ aumrows, revenuerows, revenueTotalPages, aumTotalPage, aumPaginations, aumPage, revenuepage, revenuePaginations, revenueLeaderboardLocalState, aggregateSectorAllocation, geographicalAssetAllocation, aggregateAssetAllocation, ttmAggregateAumBarChart, ttmInvestedWealthBarChart }) => {
  return (
    <Grid container columnSpacing={1} mt={"5px"} mb={"5px"}>
      <Grid item xs={12} lg={12} md={12} sx={{ marginTop: '1vh', marginBottom: '1vh' }}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={12} lg={6} >
            <div sx={{ height: '100%' }}>
              <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6} >
            <div sx={{ height: '100%' }}>
              <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={4} mt={"5px"}>
            <div sx={{ height: '100%' }}>
              <PieChartController title={"Aggregate Asset Allocation (%)"} data={aggregateAssetAllocation} category={"Asset"} />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={4} mt={"5px"}>
            <div sx={{ height: '100%' }}>
              <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={4} mt={"5px"} sx={{ height: '39%' }}>
            <div sx={{ height: '100%' }}>
              <PieChartController title={"Aggregate Sector Allocation (%)"} data={aggregateSectorAllocation} category={"Sector"} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} sx={{ marginTop: '1vh' }}>
        <div style={{ height: '98.5%', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 3px 8px #77777733', border: '0.699999988079071px solid #DFD2D280', borderRadius: '12px', padding: '10px' }}>
          <LeaderBoard aumrows={aumrows} revenuerows={revenuerows} revenueTotalPages={revenueTotalPages} aumTotalPage={aumTotalPage} aumPaginations={aumPaginations} aumPage={aumPage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} />
        </div>

      </Grid>
    </Grid>
  );
};

// For web and big screens
const WebAndBigScreenLayout = ({ aumrows, revenuerows, revenueTotalPages, aumTotalPage, aumPaginations, aumPage, revenuepage, revenuePaginations, revenueLeaderboardLocalState, aggregateSectorAllocation, geographicalAssetAllocation, aggregateAssetAllocation, ttmAggregateAumBarChart, ttmInvestedWealthBarChart, isMobileOrTabletBelow800 }) => {
  const [containerHeight, setContainerHeight] = React.useState("90vh");

  const getContainerHeight = () => {
    const windowHeight = window.innerHeight;
    console.log(windowHeight, "windowHeight hook windowHeightv");
    if (windowHeight >= 1200 && windowHeight <= 1299) {
      return '90vh';
    } else if (windowHeight >= 1300 && windowHeight <= 1400) {
      return '85vh';
    }
    else if (windowHeight >= 1400 && windowHeight <= 2000) {
      return '80vh';
    }
    else if (windowHeight >= 2001 && windowHeight <= 3000) {
      return '80vh';
    }
    else if (windowHeight >= 800 && windowHeight <= 900) {
      return '95vh';
    }
    else if (windowHeight >= 700 && windowHeight <= 799) {
      return '92vh';
    }
    else if (windowHeight <= 500) {
      return '90vh';
    }


    return '90vh'; // Default height
  };

  // Update height on window resize
  React.useEffect(() => {
    const handleResize = () => {
      const newHeight = getContainerHeight();
      console.log("New Styles", newHeight)
      setContainerHeight(newHeight);
    };

    // Initial call to set height
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };// eslint-disable-next-line
  }, []);
  return (
    <Grid container columnSpacing={1} rowSpacing={1} mt={"5px"} mb={"5px"} style={{ height: "650px" }}>
      <Grid item xs={12} lg={9} md={12} sx={{ flexDirection: 'column' }}>
        <Grid container columnSpacing={1} style={{ height: '100%' }}>
          <Grid item xs={12} lg={12} md={12} style={{ height: '60%' }}>
            <Grid container columnSpacing={1} rowSpacing={1} style={{ height: '100%' }}>
              <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
                <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} type={true} />
              </Grid>
              <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
                <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} type={true} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12} md={12} style={{ height: '40%' }}>
            <Grid container columnSpacing={1} flexDirection={"row"} style={{ height: '100%' }}>
              <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
                <PieChartController title={"Aggregate Asset Allocation (%)"} data={aggregateAssetAllocation} category={"Asset"} />
              </Grid>
              <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
                <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} />
              </Grid>
              <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
                <PieChartController title={"Aggregate Sector Allocation (%)"} data={aggregateSectorAllocation} category={"Sector"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3} style={{ height: '100%' }}>
        <div style={{ height: '99.5%', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 3px 8px #77777733', border: '0.699999988079071px solid #DFD2D280', borderRadius: '12px', padding: '10px' }}>
          <LeaderBoard aumrows={aumrows} revenuerows={revenuerows} revenueTotalPages={revenueTotalPages} aumTotalPage={aumTotalPage} aumPaginations={aumPaginations} aumPage={aumPage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} />
        </div>
      </Grid>
    </Grid>
  );
};

// const MyGridLayout = ({ aumrows, revenuerows, revenueTotalPages, aumTotalPage, aumPaginations, aumPage, revenuepage, revenuePaginations, revenueLeaderboardLocalState, aggregateSectorAllocation, geographicalAssetAllocation, aggregateAssetAllocation, ttmAggregateAumBarChart, ttmInvestedWealthBarChart, isMobileOrTabletBelow800 }) => {
//   return (
//     <Box sx={{ height: '650px' }}>


//       <Grid container spacing={2} >
//         {/* Left Side (lg={9}) */}
//         <Grid item xs={12} lg={9} xl={9}>
//           {/* Top Section (2 Grid Layouts) */}
//           <Grid container spacing={2} sx={{ height: '60%', marginBottom: '10px' }}>
//             <Grid item xs={12} md={6} lg={6} xl={6}>
//               <Box sx={{ height: '100%', padding: '5px' }}>
//                 {/* Content for Top Left */}
//                 <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} type={true} />
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6} lg={6} xl={6}>
//               <Box sx={{ height: '100%', padding: '5px' }}>
//                 {/* Content for Top Right */}
//                 <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} type={true} />
//               </Box>
//             </Grid>
//           </Grid>

//           {/* Bottom Section (3 Grid Layouts) */}
//           <Grid container spacing={2} sx={{ height: '40%' }}>
//             <Grid item xs={12} md={4} lg={4} xl={4}>
//               <Box sx={{ height: '100%', }}>
//                 {/* Content for Bottom 1 */}
//                 <PieChartController title={"Aggregate Asset Allocation (%)"} data={aggregateAssetAllocation} category={"Asset"} />
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={4} lg={4} xl={4}>
//               <Box sx={{ height: '100%', }}>
//                 {/* Content for Bottom 2 */}
//                 <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} />
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={4} lg={4} xl={4}>
//               <Box sx={{ height: '100%', }}>
//                 {/* Content for Bottom 3 */}
//                 <PieChartController title={"Aggregate Sector Allocation (%)"} data={aggregateSectorAllocation} category={"Sector"} />
//               </Box>
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* Right Side (lg={3}) */}
//         <Grid item xs={12} lg={3} xl={3}>
//           <Box sx={{ height: '100%', }}>
//             <div style={{ height: '99.5%', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 3px 8px #77777733', border: '0.699999988079071px solid #DFD2D280', borderRadius: '12px', padding: '10px' }}>
//               <LeaderBoard aumrows={aumrows} revenuerows={revenuerows} revenueTotalPages={revenueTotalPages} aumTotalPage={aumTotalPage} aumPaginations={aumPaginations} aumPage={aumPage} revenuepage={revenuepage} revenuePaginations={revenuePaginations} revenueLeaderboardLocalState={revenueLeaderboardLocalState} />
//             </div>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };


const MyGridLayout = ({
  aumrows,
  revenuerows,
  revenueTotalPages,
  aumTotalPage,
  aumPaginations,
  aumPage,
  revenuepage,
  revenuePaginations,
  revenueLeaderboardLocalState,
  aggregateSectorAllocation,
  geographicalAssetAllocation,
  aggregateAssetAllocation,
  ttmAggregateAumBarChart,
  ttmInvestedWealthBarChart,
  isMobileOrTabletBelow800
}) => {
  return (
    <Box sx={{ padding: "15px" }}>
      <Grid container spacing={2} sx={{
        height: '700px',
        // "&. .MuiGrid-item": {
        //   paddingLeft: '0px', paddingTop: "0px"
        // }
      }} >
        {/* Left Side (lg={9}) */}
        <Grid container item xs={12} lg={9} xl={9} spacing={2} >
          {/* Top Section (2 Grid Layouts) */}
          <Grid container spacing={2} sx={{ maxHeight: '360px', minHeight: "360px", paddingTop: "15px" }}>
            <Grid item xs={12} md={6} sx={{}}>
              {/* <Box sx={{ height: '100%', }}> */}
              {/* Content for Top Left */}
              <BarChartController
                title={"TTM Aggregate AUM (US $)"}
                data={ttmAggregateAumBarChart}
                category={"AUM"}
                type={true}
              />
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Box sx={{ height: '100%', }}> */}
              {/* Content for Top Right */}
              <BarChartController
                title={"TTM Invested Wealth (US $)"}
                data={ttmInvestedWealthBarChart}
                category={"Invested"}
                type={true}
              />
              {/* </Box> */}
            </Grid>
          </Grid>

          {/* Bottom Section (3 Grid Layouts) */}
          <Grid container spacing={2} sx={{ maxHeight: '320px', minHeight: "310px", paddingTop: "5px", marginTop: "5px" }}>
            <Grid item xs={12} md={4}>
              {/* <Box sx={{ height: '100%' }}> */}
              {/* Content for Bottom 1 */}
              <PieChartController
                title={"Aggregate Asset Allocation (%)"}
                data={aggregateAssetAllocation}
                category={"Asset"}
              />
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <Box sx={{ height: '100%' }}> */}
              {/* Content for Bottom 2 */}
              <PieChartController
                title={"Aggregate Geographical Allocation (%)"}
                data={geographicalAssetAllocation}
                category={"Geographical"}
              />
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <Box sx={{ height: '100%' }}> */}
              {/* Content for Bottom 3 */}
              <PieChartController
                title={"Aggregate Sector Allocation (%)"}
                data={aggregateSectorAllocation}
                category={"Sector"}
              />
              {/* </Box> */}
            </Grid>
          </Grid>

        </Grid>

        {/* Right Side (lg={3}) */}
        <Grid item xs={12} lg={3} xl={3}>
          <Box sx={{ height: '670px', }}>
            <div style={{ height: '100%', background: '#FFFFFF', boxShadow: '0px 3px 8px #77777733', border: '1px solid #DFD2D2', borderRadius: '12px', padding: '13px' }}>
              <LeaderBoard
                aumrows={aumrows}
                revenuerows={revenuerows}
                revenueTotalPages={revenueTotalPages}
                aumTotalPage={aumTotalPage}
                aumPaginations={aumPaginations}
                aumPage={aumPage}
                revenuepage={revenuepage}
                revenuePaginations={revenuePaginations}
                revenueLeaderboardLocalState={revenueLeaderboardLocalState}
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};


export default GridLayout;

