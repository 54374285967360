import React, { useEffect, useState } from 'react'
import { Button, Box, Grid, Typography, TextField, Select, MenuItem } from "@mui/material"
import { getAlldropdownChatgpt, getAllhistoryChatgpt } from '../../../services/index'
import { useFormik } from 'formik';
import moment from 'moment'

// import ChatGptHistory from './ChatGptHistory'
import { setHistoryMessages, setpaginationObj, setTotalCount, setPage } from '../../../redux/slices/ChatGptSlice'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import FormHelperText from '@mui/material/FormHelperText';
import "./chat.css"
import '../Style/fontStyle.css';
import colorKairos from "../../../theme/images/GuestKairos Logo.svg";
import { useNavigate } from "react-router-dom"


const validSchema = Yup.object().shape({
    fromDate: Yup.date().required('From Date is required'),
    hniValue: Yup.string().test(
        "conditional-validation",
        "Client is Required",
        function (value) {
            const hniValues = this.resolve(Yup.ref("user"));
            if (hniValues === "Client") {
                return !!value;
            }
            return true;
        }
    ).nullable(),
    toDate: Yup.date()
        .min(Yup.ref('fromDate'), 'To Date must be after From Date'),
    // hniValue: Yup.string()
    //     .required('Client is Required')
    //     .nullable(),

});


const HistoryList = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member") ? true : false;
    const page = useSelector((state) => state.chatGpt.page)
    const targetValue = useSelector((state) => state.nav.targetValue)

    const [hnidropdown, setHniDropdowm] = useState([])
    const UserList = [{ name: 'My History' }, { name: 'Client' }]
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
            toDate: moment(new Date()).format('YYYY-MM-DD'),
            user: 'My History',
            hniValue: ''

        },
        validationSchema: validSchema,//(!isHni && (targetValue === 'ChatGpt' || targetValue !== 'llm')) ? validSchema : '',
        onSubmit: async (values, e) => {
            console.log('Form submitted!', values);
            dispatch(setPage(0))
            let userValue = ''
            if (values.user === 'My History') {
                userValue = user?.userId
            } else {

                userValue = values?.hniValue
            }
            const obj = {
                userId: userValue,
                target: targetValue,
                fromDate: values.fromDate,
                toDate: values.toDate,
                pageNo: 0,
                pageSize: 3,

            }
            const history = await getAllhistoryChatgpt(obj)
            if (history?.data?.status) {
                console.log(history?.data?.body, 'ress')
                dispatch(setHistoryMessages(history?.data?.body ? history?.data?.body : []))
                dispatch(setTotalCount(history?.data?.totalPages))
            } else {
                dispatch(setHistoryMessages([]))
                dispatch(setTotalCount(''))

            }
            dispatch(setpaginationObj({
                userId: userValue,
                fromDate: values.fromDate,
                toDate: values.toDate,
            }))
        }
    })
    useEffect(() => {
        console.log('dropdown')
        getAlldropdownChatgpt(user?.userId).then((res) => {
            if (res?.data?.status) {
                console.log(res?.data?.body, 'ress')
                setHniDropdowm(res?.data?.body)
            }
        })
        const obj = {
            userId: user?.userId,
            fromDate: formik.values.fromDate,
            toDate: formik.values.toDate,
            pageNo: page,
            pageSize: 3,
            target: targetValue,

        }


        dispatch(setpaginationObj({
            userId: user?.userId,
            fromDate: formik.values.fromDate,
            toDate: formik.values.toDate,

        }))
        fetchData(obj);
        // eslint-disable-next-line
    }, [])
    const fetchData = async (obj) => {
        console.log('fetch', obj)
        const history = await getAllhistoryChatgpt(obj)
        if (history?.data?.status) {
            console.log(history?.data?.body, 'ress')
            dispatch(setHistoryMessages(history?.data?.body ? history?.data?.body : []))
            dispatch(setTotalCount(history?.data?.totalPages))
        }
    };
    const handleHni = (event) => {
        formik.setFieldValue('user', event.target.value)
        if (formik.values.user === 'My History') {
            formik.setFieldValue('hniValue', null)
        }
    }
    console.log(formik, 'formik')
    return (
        <>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography ml={3} sx={{ marginX: "auto", fontSize: { sm: "22px", xs: "16px" }, fontWeight: "550", margin: { xs: "-18%", sm: "auto" }, marginBottom: "25px", cursor: "pointer !important" }} >
                        <img src={colorKairos} alt="Indium" sx={{ cursor: "pointer", display: { xs: "none", sm: "block" } }} onClick={() => { navigate("/dashboard") }} />
                    </Typography>
                </Grid>
            </Grid>

            <Box className="navList" sx={{ color: '#FFFFFF', marginTop: "-30px" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container pr={2} pl={2}>
                        <Grid>
                            <p style={{ fontSize: "19px", fontWeight: "700", marginBottom: "15px" }}> Filters<span style={{ marginLeft: "5px" }}>:</span></p>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant="h6" style={{ color: '#FFFFFF', marginBottom: "8px", marginTop: "12px" }}>
                                {'From Date'}
                            </Typography>
                            <TextField
                                id='fromDate'
                                name='fromDate'
                                type="date"

                                onChange={formik.handleChange}
                                value={formik.values.fromDate}
                                sx={{
                                    width: "100%",

                                    // gridColumn: "span 2",
                                    // height: '30px',
                                    color: '#FFFFFF !important',
                                    backgroundColor: 'transparent',
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#FF0000 !important', // Change the color value here
                                        opacity: 1,
                                    },
                                    '& .MuiInputBase-root': {
                                        height: '33px !important',
                                        // maxWidth: "auto",
                                        color: '#FFFFFF !important',
                                        // minWidth: "250px",
                                        background: '#434343 0% 0% no-repeat padding-box',
                                        borderRadius: "6px",
                                        // filter: brightness(100%) saturate(0%) invert(100%);


                                    },
                                }}

                                InputProps={{ endAdornment: null }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant="h6" style={{ color: '#FFFFFF', marginBottom: "8px", marginTop: "12px" }}>
                                {'To Date'}
                            </Typography>
                            <TextField
                                id='toDate'
                                name='toDate'
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.toDate}
                                // inputProps={{
                                //     min: new Date().toISOString()?.split("T")[0]
                                // }}
                                // defaultValue={moment(new Date()).format('yyyy-mm-dd')}
                                sx={{
                                    width: "100%",
                                    // gridColumn: "span 2",
                                    // height: '40px',
                                    color: '#FFFFFF',
                                    backgroundColor: 'transparent',
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#FF0000 !important', // Change the color value here
                                        opacity: 1,
                                    },
                                    '& .MuiInputBase-root': {
                                        height: '33px !important',
                                        maxWidth: "auto",
                                        // color: 'white ',
                                        // color: "transparent",
                                        width: "100% !important",
                                        // minWidth: "250px",
                                        background: '#434343 0% 0% no-repeat padding-box',
                                        borderRadius: "6px"

                                    },
                                    '& .MuiInputBase-input': {
                                        color: "white !important"
                                    },
                                    '&::-webkit-calendar-picker-indicator': {
                                        // display: 'none',
                                        color: "white ",
                                        // '-webkit-appearance': 'none'
                                    }
                                }}



                            />
                            <FormHelperText error={formik.errors.toDate ? true : false} id="hniValue-helper">{formik.touched.toDate && formik.errors.toDate ? formik.errors.toDate : null}</FormHelperText>

                        </Grid>
                        {(!isHni && (targetValue === 'ChatGpt' || targetValue !== 'llm')) &&
                            <>
                                <Grid item xs={12} >
                                    <p className='title' variant="h6" style={{ marginBottom: "8px", marginTop: "12px", fontSize: "14px" }}>
                                        {'User'}
                                    </p>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name='user'
                                        // error={formik.touched.currencyCode && Boolean(formik.errors.currencyCode)}
                                        onChange={handleHni}
                                        value={formik.values.user}
                                        className='subTitle'
                                        sx={{
                                            height: "33px",
                                            // gridColumn: "span 2",
                                            width: "100%",
                                            iconFilled: "filled",
                                            // backgroundColor: 'transparent',
                                            background: '#434343',
                                            borderRadius: "6px",
                                            '& .MuiSelect-icon': {
                                                color: 'white',
                                            },

                                            "& input::placeholder": {
                                                textOverflow: "ellipsis !important",
                                                color: "red !important",
                                                opacity: 1
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                // height: "45px !important",
                                                borderRadius: "8px 0 0 8px"
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                // paddingRight: "75px !important",
                                                color: '#FFFFFF !important',
                                                fontSize: "13px !important",
                                                overflow: "inherit"
                                            },
                                            '& .MuiSelect-select ': {
                                                overflow: "inherit !important",
                                            }
                                        }}   >
                                        <MenuItem selected disabled value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {UserList && UserList?.map((item) => (
                                            <MenuItem key={item?.name} value={item?.name}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>


                                </Grid>
                                <Grid item xs={12} >
                                    <p className='title' variant="h6" style={{ marginBottom: "8px", marginTop: "12px", fontSize: "14px" }}>
                                        {'Client'}
                                    </p>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name='hniValue'
                                        className='subTitle'
                                        value={formik.values.user !== 'My History' ? formik.values.hniValue : ""}
                                        onChange={formik.handleChange}
                                        disabled={formik.values.user === 'My History' ? true : false}
                                        error={formik.touched.hniValue && Boolean(formik.errors.hniValue)}
                                        sx={{
                                            height: "33px",
                                            width: "100%",
                                            iconFilled: "filled",
                                            background: 'transparent',
                                            borderRadius: "6px",
                                            border: '2px solid #4C4C4C',
                                            '& .MuiSelect-icon': {
                                                color: 'white',
                                            },

                                            "& input::placeholder": {
                                                textOverflow: "ellipsis !important",
                                                color: "red !important",
                                                opacity: 1
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                // height: "45px !important",
                                                borderRadius: "8px 0 0 8px"
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                // paddingRight: "75px !important",
                                                color: '#FFFFFF !important',
                                                fontSize: "13px !important",
                                                overflow: "inherit"
                                            },
                                            '& .MuiSelect-select ': {
                                                overflow: "inherit !important",
                                            }
                                        }}   >
                                        <MenuItem selected disabled value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {hnidropdown?.map((item) => (
                                            <MenuItem key={item?.hniId} value={item?.hniId}>
                                                {console.log(item?.hniId)}
                                                {item?.hniName}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {formik.values.user !== 'My History' && <FormHelperText error={formik.errors.hniValue ? true : false} id="hniValue-helper">{formik.touched.hniValue && formik.errors.hniValue ? formik.errors.hniValue : null}</FormHelperText>}

                                </Grid></>}

                    </Grid>
                    <Grid xs={10}>
                        <Button variant='contained' sx={{
                            width: '100%',
                            // position: 'absolute',
                            // top: '93vh',
                            marginTop: "18px",
                            left: '16px',
                            background: '#6442C4'
                        }}
                            type="submit"
                        // disabled={!(formik.isValid && formik.dirty)}

                        >Apply</Button>
                    </Grid>

                </form >
            </Box>

        </>
    )
};
export default HistoryList;