import * as React from "react";
import CatalogIndex from "./CatalogIndex";
import { useParams } from 'react-router-dom';
import { getAlldropDownForCatalog } from "../../../services/catalogProductandService"
import withPermissionsHoc from "../../Layout/PermissionsHoc/PermissionsHoc"
import { FormModalProducts } from "./FormModal/FormModal";

import Loader from "../../common/Loader/Loader";
import "../../../theme/styles/Catalog.css"
function ProductsServiceCatalog(props) {
    const [isLoading, setisLoading] = React.useState(true)
    const [passingValueForrender, setpassingValueForrender] = React.useState({})
    const { id } = useParams()
    const { formFieldProducts } = FormModalProducts;
    const user = JSON.parse(localStorage.getItem('user'))
    //const userType = user?.userTypeId?.description
    const urls = window.location.pathname;

    React.useEffect(() => {
        setisLoading(true)
        getDropDownValue()
        // eslint-disable-next-line
    }, [urls])

    const getDropDownValue = async () => {
        const listDropDown = await getAlldropDownForCatalog({
            catalogue: true,
            loginUserId: user?.userId,
            clientId: user?.userId,

        });
        const filterDropDown = listDropDown?.data?.body;
        if (filterDropDown) {
            setpassingValueForrender(filterDropDown)
            setTimeout(async () => {
                setisLoading(false)
            }, 900)
        }
    }

    return (<>
        {isLoading && <Loader isLoading={isLoading} />}
        {!isLoading &&
            <CatalogIndex url={props?.url} passingValueForrender={passingValueForrender} formFieldProducts={formFieldProducts} recommentation={props?.recommentation} id={id ? atob(id) : null} portfolio={props?.portfolio} portfoliaChanges={props?.portfoliaChanges} />
        }</>);
}


export default withPermissionsHoc(ProductsServiceCatalog, "Product");
