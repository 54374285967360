import React from 'react';
import { Grid, Typography, Paper, styled } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setText, setButton } from "../../../redux/slices/navSlice"
import BlackCard from "../../../theme/images/BlackBG.png"
import useMediaQuery from '@mui/material/useMediaQuery';

const FAQSCard = ({ setFaqModelData, faqsCard, handleOpen }) => {
    const rmUser = JSON.parse(localStorage.getItem("user"));
    const rmvalue = rmUser?.userTypeId?.description
    const dispatch = useDispatch()

    const Item = styled(Paper)(({ theme }) => ({
        backgroundImage: `url(${BlackCard})`,
        textAlign: 'center',
        height: theme.breakpoints.down('lg') ? '80px' : '100%',
        color: '#FFF',
        // padding: '20px 0',
        borderRadius: '8px',
        backgroundRepeat: "no-repeat",backgroundSize: "cover",
        maxWidth: "280px"
    }));
    
    const navigate = useNavigate()
    const isXL = useMediaQuery("(min-width: 2000px) and (max-width: 4000px)");

    return (
        <div>
            <Grid container columnSpacing={2} rowSpacing={2} sx={{ marginBottom: '10px',marginLeft: isXL ? "3%" : "0" }}>
                {faqsCard?.map((key) => (
                    <Grid key={`gridItem_${key?.id}`} item lg={3} xs={6} sm={6} md={6} style={{ cursor: "pointer" }}>
                        {rmvalue === 'Entity RM' ? <Item onClick={() => {
                            dispatch(setButton({
                                index: 26,
                                text: "FAQs",
                                path: "/enquiry"
                            }))
                            dispatch(setText("FAQs"))
                            navigate('/enquiry')
                        }} sx={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <div style={{ display: 'flex', justifyContent: 'end', right: '10px', marginTop: "4px" }}>
                                <Badge badgeContent={key?.enquiryCount} sx={{ right: '20px' }} color="error" anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                                </Badge>
                            </div>
                            <Typography sx={{ marginTop: '5px' }} >{key?.description}</Typography>
                        </Item> : <Item onClick={() => { handleOpen(); setFaqModelData(key) }} sx={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <Typography sx={{ textTransform: 'capitalize' }}>{key?.description}</Typography>
                        </Item>}
                    </Grid>
                ))}
            </Grid >
        </div>
    )
}
export default FAQSCard;
