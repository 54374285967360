/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    setSelectedHni,
    setIsLoading,
    setResponseList
} from '../../../../redux/slices/ChatGptSlice'

import {
    Autocomplete,
    Typography,
    Grid,
    TextField,
    Button
}
    from '@mui/material'

import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { formatDate } from "../../../common/Utils/DateFormat"

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        color: "white",
    },
    "& .MuiInputLabel-shrink": {
        color: "white"
    },
    "& .MuiSvgIcon-root": {
        color: "white"
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "white"
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "white",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    }
});

const StyledTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({

    // input label when focused
    "& label.Mui-focused": {
        color: "#fff"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        borderColor: "#fff",
        "&.Mui-focused fieldset": {
            borderColor: "#fff"
        }
    }
}));


export default function Portfolio() {

    let auth_header = ""
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user")
        if (user) {
            auth_header = `Bearer ${JSON.parse(user).idToken}`;
        }
    }
    let headers = {
        'authorization': auth_header,
        "env": process.env.REACT_APP_DOMAIN_NAME,
        "content-type": "application/json",
    }
    const base_url = "https://gaia.kairoswealth.com/"

    const dispatch = useDispatch();

    const hniList = useSelector((state) => state.chatGpt.hniList)
    const selectedHni = useSelector((state) => state.chatGpt.selectedHni)
    const isLoading = useSelector((state) => state.chatGpt.isLoading)

    const handleHniChange = (event) => {
        dispatch(setSelectedHni(event.target.value))
        console.log({selectedHni})
    };

    const handleGetRecommendationsClick = (event) => {
        console.log("GET recommendations")
        console.log({selectedHni})
        let url = base_url + "ask_recommendations/?clientId=" + selectedHni
        fetch(url, {
            method: "GET", 
            headers: headers, 
        }).then(response => {
            return response.json()
        }).then((res) => {
            const currentDate = new Date();
            let updatedResponseList = [{
                'date': formatDate(currentDate, 'DD-MMM-YYYY'),
                'question': "Portfolio Recommendations",
                'bookmark': "No",
                'answer': res,
                'ticker': ""
            }]
            dispatch(setResponseList(updatedResponseList))
        })
        .catch((e) => {
            toast.error("There has been an error processing your request. Please contact your administrator. Error code 710")
            console.log({ e })
        });
    }

    const handlePortfolioRebalancingAssetClassesClick = (event) => {
        console.log("GET Portfolio Rebalancing Asset Classes")
        console.log({selectedHni})
        let url = base_url + "ask_rebalancing/?clientId=" + selectedHni + "&type=assets"
        fetch(url, {
            method: "GET", 
            headers: headers, 
        }).then(response => {
            return response.json()
        }).then((res) => {
            const currentDate = new Date();
            let updatedResponseList = [{
                'date': formatDate(currentDate, 'DD-MMM-YYYY'),
                'question': "Robo-advisor (Asset Classes)",
                'bookmark': "No",
                'answer': res,
                'ticker': ""
            }]
            dispatch(setResponseList(updatedResponseList))
        })
        .catch((e) => {
            toast.error("There has been an error processing your request. Please contact your administrator. Error code 710")
            console.log({ e })
        });
    }

    const handlePortfolioRebalancingEquitiesClick = (event) => {
        console.log("GET Portfolio Rebalancing Equities")
        console.log({selectedHni})
        let url = base_url + "ask_rebalancing/?clientId=" + selectedHni + "&type=equities"
        fetch(url, {
            method: "GET", 
            headers: headers, 
        }).then(response => {
            return response.json()
        }).then((res) => {
            const currentDate = new Date();
            let updatedResponseList = [{
                'date': formatDate(currentDate, 'DD-MMM-YYYY'),
                'question': "Robo-advisor (Asset Classes)",
                'bookmark': "No",
                'answer': res,
                'ticker': ""
            }]
            dispatch(setResponseList(updatedResponseList))
        })
        .catch((e) => {
            toast.error("There has been an error processing your request. Please contact your administrator. Error code 710")
            console.log({ e })
        });
    }


    return (
        <>
            {/** HNI selector */}
            <Grid display="flex" flexDirection="row" justifyContent="space-between" sx={{ marginX: 2 }}>
                <Typography color='white' mb={0}>Select a Client's portfolio</Typography>
                <Grid>
                    <AddIcon sx={{ cursor: "pointer", color: "white" }} />
                </Grid>
            </Grid>
            <StyledAutocomplete
                id="hni-selector"
                width="100%"
                size="small"
                sx={{ marginX: 2 }}
                options={hniList.map((hni) => hni.firstName + hni.lastName)}
                renderInput={(params) => <StyledTextField {...params} label="Client" />}
                onChange={handleHniChange}
            />

            {/** Action buttons */}
            <Button
                sx={{
                    textTransform: 'none',
                    width: '45%',
                    marginX: '2%',
                    marginY: '1px',
                    color: 'white',
                    borderColor: '#707070',
                    ':hover': {
                        borderColor: '#6442c4',
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        marginY: '0px',
                        backgroundColor: '#6442c4'
                    },
                    backgroundColor: '#6442c4'
                }}
                onClick={handleGetRecommendationsClick}
                variant='contained'
            >
                Get Portfolio Recommendations
            </Button>
            <Button
                sx={{
                    textTransform: 'none',
                    width: '45%',
                    marginX: '2%',
                    marginY: '1px',
                    color: 'white',
                    borderColor: '#707070',
                    ':hover': {
                        borderColor: '#6442c4',
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        marginY: '0px',
                        backgroundColor: '#6442c4'
                    },
                    backgroundColor: '#6442c4'
                }}
                onClick={handlePortfolioRebalancingAssetClassesClick}
                variant='contained'
            >
                Robo-Advisor (Asset Classes)
            </Button>
            <Button
                sx={{
                    textTransform: 'none',
                    width: '45%',
                    marginX: '2%',
                    marginY: '1px',
                    color: 'white',
                    borderColor: '#707070',
                    ':hover': {
                        borderColor: '#6442c4',
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        marginY: '0px',
                        backgroundColor: '#6442c4'
                    },
                    backgroundColor: '#6442c4'
                }}
                onClick={handlePortfolioRebalancingEquitiesClick}
                variant='contained'
            >
                Robo-Advisor (Equities)
            </Button>
        </>
    )
}
