import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";


const GuestDashboard = () => {


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#5021C6",
    textAlign: "center",
    height: "100%",
    color: "#FFF",
    padding: "10px 0",
  }));
  const Item1 = styled(Paper)(({ theme }) => ({
    backgroundColor: "white",
    height: "100%",
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: "white",
    height: "90%",
  }));
  return (
    <div>
      <Grid
        sx={{
          display: {
            xs: "block",
            lg: "block",
            xl: "block",
            md: "block",
            sm: "block",
          },
        }}
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={{ xs: 2, lg: 0 }}
          sx={{ marginBottom: "10px" }}
        >
          <Grid item lg={3} xs={6}>
            <Item>
              <Typography>
                {"28M $"}
              </Typography>
              <Typography sx={{}}>Invested Wealth</Typography>
            </Item>
          </Grid>
          <Grid item lg={3} xs={6}>
            <Item>
              <Typography>
                {"36M $"}
              </Typography>
              <Typography sx={{}}>Current value of Invested Wealth</Typography>
            </Item>
          </Grid>
          <Grid item lg={3} xs={6}>
            <Item>
              <Typography>{"36M $"}</Typography>
              <Typography>Investable Wealth</Typography>
            </Item>
          </Grid>
          <Grid item lg={3} xs={6}>
            <Item>
              <Typography>{"2B $"}</Typography>
              <Typography sx={{}}>Total Net Worth</Typography>
            </Item>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={{ xs: 2, lg: 0 }}
        sx={{ height: "35vh", marginBottom: "10px" }}>
        <Grid item lg={6} xs={12}>
          <Item1></Item1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={{ xs: 2, lg: 0 }}
        sx={{ height: "40vh" }}
      >
        <Grid item lg={6} xs={12}>
          <Box
            sx={{
              height: "90%",
              background: "#FFFFFF",
              bolghadow: "0.47px 3px 10px #7777771A",
              border: "1px solid #EAEAEA",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Item2></Item2>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuestDashboard;
