import { Axios } from "./config";// eslint-disable-next-line

let vendorIDVALUE = {
    vendorIDDetails: {
        vendorId: null,
        vendorName: "",
        vendorCode: "",
        vendorTypeId: null,
        vendorSubtypeId: null,
        businessIdentityNo: "",
        taxId: "",
        takeRate: null,
        takeRateParameterId: null,
        keyPocPrefix: null,
        emailId: '',
        keyPointOfContact: '',
        contactDetails: '',
        countryPrifixId: null,
        registeredAddress: '',
        city: '',
        state: '',
        country: '',
        zip: "",
    }
};

function setVendorIDDetails(value) {

    vendorIDVALUE.vendorIDDetails = value;
}

export {
    vendorIDVALUE,
    setVendorIDDetails
}

export const vendormanagementDropDownList = async () => {
    const promise1 = Axios.get("/api/v1/vendor/getalldropdown-vendor");
    const promise2 = Axios.get("/api/v1/admin/getsubtype-reference/28");

    var resultService = await Promise.all([promise1, promise2]).then(function (values) {
        return values;
    });
    return resultService;




}

export const vendorManagementGetDatalist = async (passingValue) => {

    //  "/api/v1/vendor/getall-vendors"
    var resultService = await Axios
        .post(`/api/v1/vendor/getall-vendors`, passingValue)

    return resultService?.data;

}

export const vendorManagementGetDataByID = async (id) => {


    var resultService = await Axios
        .get(`/api/v1/vendor/get-vendor/${id}`)

    if (resultService.data.status) {

        await setVendorIDDetails(resultService.data.body);
        return vendorIDVALUE.vendorIDDetails;
    }



}

export const addVendorsNew = async (passingValue) => {

    var resultService = await Axios
        .post(`/api/v1/vendor/add-vendors`, passingValue)

    return resultService;
}

export const editVendorsUpdate = async (passingValue) => {

    var resultService = await Axios
        .put(`/api/v1/vendor/update-vendor`, passingValue)

    return resultService;
}
export const resubmitVendor = async (passingValue) => {
    var resultsService = await Axios
        .post(`/api/v1/vendor/resubmit-vendor`, passingValue)
    return resultsService;
}
export const deleteVendorId = async (passingValue) => {

    var resultService = await Axios
        .post(`/api/v1/vendor/update-vendor-status`, passingValue)

    return resultService;
}

export const submitVendorRejection = async (passingValue) => {

    var resultService = await Axios
        .post(`/api/v1/vendor/submit-vendor`, passingValue)

    return resultService;
}



export const downloadVendorManagement = async (passingValue) => {

    const payload = await Axios.post(`/api/v1/vendor/vendors/export/excel`, passingValue, { responseType: 'blob' });
    return payload;
}


//"/api/v1/admin/users/export/excel"