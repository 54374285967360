import React, { useState, useEffect } from "react";
import { Paper, Button, TableCell, Typography, Box, InputAdornment, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { getListOfProspects } from "../../../../services/index";
import { useSearchParams, Link } from 'react-router-dom';
import PaginationComponent from "../../../common/Pagination/Pagination";
import PropTypes from "prop-types";
import TableSortLabel from "@mui/material/TableSortLabel";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { visuallyHidden } from "@mui/utils";
import SearchIcon from '@mui/icons-material/Search'
import { formatNumber } from "../../../../constant/DollorFormats";
import { prospectscolumnCells } from "../../../common/Columns";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: "#ffff !important",
    },
    overrides: {
        MuiTableHead: {
            root: {
                height: "50px",
                width: "100px",
            },
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:root": {
        borderBottom: "none",
    },
}));
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparatorData(order, orderby) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderby) : (a, b) => -descendingComparator(a, b, orderby);
}

function stableSortData(array, comparator) {
    const stabilizedThisValue = array?.map((el, index) => [el, index]);
    stabilizedThisValue.sort((a, b) => {
        const sortOrder = comparator(a[0], b[0]);
        if (sortOrder !== 0) {
            return sortOrder;
        }
        return a[1] - b[1];
    });
    return stabilizedThisValue.map((el) => el[0]);
}

function ListOfProspects({ handleClickAddProspects }) {

    const [searchParams, setsearchParams] = useSearchParams();
    const [prospectList, setProspectList] = useState([]);
    const [totalRecord, setTotalRecord] = useState();
    const [page, setPage] = useState(parseInt(searchParams.get('pageNumber')) || 1)
    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState("asc");
    // eslint-disable-next-line 
    const [selected, setSelected] = useState([]);
    // eslint-disable-next-line 
    const [pages, setPages] = useState(0)
    const [search, setSearch] = useState("");
    // const [name, setName] = useState('')
    const [condtion, setcondtion] = useState(false)

    // eslint-disable-next-line 
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(searchParams.get('ListCount')) || 10);
    const loginUser = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        const timeOut = condtion ? setTimeout(() => listApi(), 1000) : null;
        return () => clearTimeout(timeOut);
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        listApi()

        // eslint-disable-next-line 
    }, [])
    const listApi = async () => {
        if (search !== "") {
            setsearchParams({
                pageNumber: page,
                ListCount: rowsPerPage,
                search: search
            })
        } else if (search === "") {
            setsearchParams({
                pageNumber: page,
                ListCount: rowsPerPage
            })
        } else {
            setsearchParams({
                pageNumber: page,
                ListCount: rowsPerPage
            })
        }
        const requestObj = {
            pageNo: page - 1,
            pageSize: rowsPerPage,
            rmId: loginUser.userId,
            search: search
        }
        const response = await getListOfProspects(requestObj)
        if (response?.data?.status) {
            setProspectList(response?.data?.body === null ? [] : response?.data?.body)
            setTotalRecord(response?.data?.totalPages)
            setcondtion(true)
        } else {
            setProspectList([])
        }

    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handlePageChange = async (event, value) => {
        console.log(value, 'aaaaavalue')
        setPage(value);
        setsearchParams({ pageNumber: value, ListCount: rowsPerPage })
        const pageObj = {
            "pageNo": value - 1,
            "pageSize": rowsPerPage,
            "rmId": loginUser.userId,
            "search": ""
        }
        const response = await getListOfProspects(pageObj);
        if (response?.data?.status) {
            setProspectList(response?.data?.body);
            setTotalRecord(response?.data?.totalPages);
        }
    };
    console.log(prospectList, 'prospectList')
    const handleCreateSort = (property) => (event) => {
        handleRequestSortData(event, property);
    };
    const handleRequestSortData = (event, property) => {
        const isAscending = orderBy === property && order === "asc";
        setOrder(isAscending ? "desc" : "asc");
        setOrderBy(property);
    };
    const handlefilterData = (e) => {
        setPage(1)
        const keywordValue = e.target.value;
        const trimmedKeywordValue = keywordValue.trimStart();
        setSearch(trimmedKeywordValue)


    }
    return (
        <>
            <Box
                sx={{
                    // overflowY: 'auto',
                    height: "398px",
                    boxShadow: "0.47px 3px 10px #77777733",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    opacity: 1,
                    position: 'relative'
                }}
            >
                <Box sx={{ flexGrow: 1, p: "13px" }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Typography
                            variant="h6"
                            style={{
                                width: "113px",
                                height: "22px",
                                // textAlign: "left",
                                font: "normal normal 900 14px 20px Mona Sans",
                                letterSpacing: "0.14px",
                                color: "#000000",
                                opacity: 1,
                                fontSize: "13px",
                                fontWeight: 600
                            }}
                        >
                            List Of Prospects
                        </Typography>
                        <Button
                            onClick={handleClickAddProspects}
                            sx={{
                                width: "auto",
                                height: "24px",
                                background: "#6A3BE2 0% 0% no-repeat padding-box",
                                borderRdius: "6px",
                                opacity: "1",
                                // bottom: "19px",
                                // left: "122px",
                                textTransform: "capitalize",
                                whiteSpace: "nowrap"
                            }}
                            variant="contained"
                        >
                            {/* <Typography
                            sx={{
                                width: "74px",
                                height: "19px",
                                textAlign: "left",
                                font: "normal normal medium 12px 15px Mona Sans",
                                letterSpacing: "0px",
                                color: "#F8F8F8",
                                opacity: "1",
                                whiteSpace: "nowrap"
                            }}
                        > */}
                            Add Prospect
                            {/* </Typography> */}
                        </Button>
                    </div>
                    {/* <div style={{
                      marginLeft:'-12px', marginTop: '14px', width: "205px"
                    }} >
                        <Search >
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                value={search === "" ? name : search}
                                onChange={(e) => { handlefilterData(e) }}
                            />
                        </Search>
                    </div> */}
                    <div style={{
                        marginTop: '14px',
                        //  width: "205px"
                    }} >
                        <TextField
                            variant="outlined"
                            type="text"
                            value={search}
                            placeholder={"Search"}
                            // onChange={(e) => setValuesForSearch(e.target.value)}
                            onChange={(e) => handlefilterData(e)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon style={{ fill: "black" }} /></InputAdornment>,
                            }}
                            sx={{
                                height: '32px',
                                width: "100%",
                                // width: '320px',
                                '& input::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: '#BBC5D5 !important',
                                    opacity: 1,
                                    fontFamily: "Mona Sans",

                                },
                                '& .MuiInputBase-root': {
                                    // height: '40px !important',
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    height: '32px',
                                    borderRadius: '8px'



                                },
                            }}
                        />
                    </div>
                </Box>
                <Paper sx={{ ml: 1.5, mr: 1.5, maxHeight: "250px", minHeight: "200px" }}>
                    <TableContainer sx={{ maxHeight: "250px", minHeight: "200px", overflowX: "hidden" }}>
                        <Table aria-label="customized table">
                            <TableHead sx={{ width: "205px", height: "35px", whiteSpace: "nowrap" }}>
                                <TableRow>
                                    {prospectscolumnCells?.map((headCell) => (
                                        <StyledTableCell
                                            key={headCell.id}
                                            align={'center'}
                                            // padding={headCell.disablePadding ? "none" : "normal"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            size='small'
                                            sx={{ whiteSpace: 'nowrap', padding: "6px 5px" }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                // direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={handleCreateSort(headCell.id)}
                                                IconComponent={UnfoldMoreIcon}
                                                sx={{
                                                    '& .MuiTableSortLabel-icon': {
                                                        color: 'white !important',
                                                        padding: '1px'
                                                    },
                                                }}
                                            >
                                                <span style={{ color: 'white' }}>{headCell.label}</span>
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    ))}
                                    {/* <StyledTableCell align="left" size="small" >
                                        Prospect Name
                                    </StyledTableCell>
                                    <StyledTableCell align="left" size="small">
                                        Net Worth($)
                                    </StyledTableCell> */}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {stableSortData(prospectList, getComparatorData(order, orderBy))
                                    .slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage)
                                    .map((data, index) => {
                                        const isItemSelected = isSelected(data.label);
                                        return (
                                            // {prospectList?.map((data) => {
                                            <StyledTableRow
                                                key={data?.prospectId}
                                                sx={{ width: "205px", height: "35px" }}
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}

                                            >
                                                <TableCell
                                                    align="left"
                                                    size="small"
                                                    sx={{
                                                        // width: "68px",
                                                        // height: "19px",
                                                        textAlign: "left",
                                                        padding: "6px 16px",
                                                        // textDecoration: "underline",
                                                        font: "normal normal normal 12px 21px Mona Sans",
                                                        fontFamily: "Mona Sans",
                                                        letterSpacing: "0px",
                                                        // color: "#1159D5",
                                                        opacity: "1",
                                                    }}
                                                >
                                                    <Link style={{
                                                        color: "#1159D5"
                                                    }} to={`/dashboard/EditProspects/${btoa(data?.prospectId)}`}>{data?.prospectName}</Link>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    size="small"
                                                    sx={{
                                                        width: "43px",
                                                        height: "19px",
                                                        textAlign: "left",
                                                        font: "normal normal 900 12px 15px Mona Sans",
                                                        letterSpacing: "0px",
                                                        color: "#000000",
                                                        opacity: "1",
                                                    }}
                                                >
                                                    {data?.netWorth === null ? `US $0` : `US $${formatNumber(data?.netWorth)}`}
                                                </TableCell>
                                            </StyledTableRow>

                                            // })}
                                        )

                                    })}
                            </TableBody>

                            {
                                prospectList.length === 0 && (<TableRow><TableCell>No prospect details found</TableCell></TableRow>)
                            }
                        </Table>
                    </TableContainer>
                </Paper>
                <div
                    style={{
                        float: "right",
                        // marginTop: "10px",
                        // marginBottom: "10px",
                        // alignItems: "end",
                        // justifyContent: "end",
                        // textAlign: "end",
                        // zIndex: 1,
                        // marginTop: "20.5px"
                        position: 'absolute',
                        bottom: 5,
                        right: 0
                    }}
                >
                    <PaginationComponent
                        sx={{ width: "120px", height: "25px", opacity: "1" }}
                        handlePageChange={handlePageChange}
                        page={page}
                        totalCount={totalRecord}
                    />
                </div>
                {/* </div> */}
            </Box>
        </ >
    );
}

export default ListOfProspects;
ListOfProspects.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};
