/* eslint-disable no-useless-escape */
import React from "react";
import { Box, Stack, Grid, Typography } from "@mui/material";
//import Kairos from "../../../theme/images/GuestKairos Logo.svg";
import Dashboard from "../../../theme/images/GuestDasboard icon.svg";
import { useNavigate } from 'react-router-dom'


const navButtons = [
  {

    index: 1,
    text: "Dashboard",
    path: "/guestdashboard",
    icon: Dashboard,
  },
];

function GuestSideNavbar() {
  const navigate = useNavigate()
  return (
    <>
      <Box>
        <Stack direction="column" alignItems="center">
          <Box pr={4}>
            <Grid container style={{ marginTop: "37px" }}>
              <Grid item xl={11} lg={11}>
                {" "}
                <Typography
                  ml={3}
                  style={{ fontSize: "22px", fontWeight: "550" }}
                >
                  {/* <img src={Kairos} alt="KAIROS" /> */}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Stack spacing={0.1} mt={6} direction="column">
            {navButtons.map((button) =>
              // button.text === "Divider" ?
              //  (
              //   <Divider key={button.index} style={{ margin: "20px 0px" }} />
              // ) : (
              <Box
                key={button.index}
                onClick={() => {
                  // dispatch(setButton(button))
                  // dispatch(setText(button.text))
                  // props.LayoutCallBack(button.text)
                  navigate(button.path)
                }}
                className="navList"
                sx={{
                  backgroundColor: "#5021C6",
                  color: "#FFFFFF",
                  padding: "4px 0px 4px 0px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <Grid container pr={2} pl={2}>
                  <Grid
                    item
                    xs={2}
                    xl={2}
                    lg={2}
                    sm={2}
                    md={2}
                    pt={0}
                    pr={1}
                  >
                    <Typography className="icon">
                      <img
                        src={button.icon}
                        alt=""
                        style={{
                          width: "15px",
                          height: "15px",
                          // filter:
                          //   button.path === Button?.path
                          //     ? "invert(1)"
                          //     : "invert(0)",
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item pt={0.2}>
                    <Typography
                      pt={0}
                      style={{ fontSize: "12px", whiteSpace: "nowrap", paddingLeft: '10px' }}
                    >
                      {button.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              // )
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default GuestSideNavbar;
