/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
    setBookmark,
    setBookmarkMessage,
    setClose,
    setNotificationClick,
    setReceivedNotification,
    setNotificationCount,
    setPage,
    setSelectedButton,
    setTickersList,
    setFolders
} from '../../../redux/slices/ChatGptSlice'
import { getRmNotificationList } from "../../../services/index"

import logo from "../../../theme/images/GuestKairos Logo.svg"
import { ReactComponent as Bookmarkoutline } from '../../../theme/images/Bookmark outline.svg'

import {
    Box,
    Typography,
    Grid,
    Button,
    Tabs,
    Tab,
    AppBar
}
    from '@mui/material'
import SwipeableViews from 'react-swipeable-views';

import { setTargetValue } from '../../../redux/slices/navSlice';
import SidebarStocks from "./Sidebar/stocks"
import SidebarInternalData from "./Sidebar/internalData"
import Modals from "./Sidebar/modals"
import PrivateEquity from "./Sidebar/privateEquity"
import SidebarPortfolio from "./Sidebar/portfolio"



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Sidebar() {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [value, setValue] = React.useState(0);


    const user = JSON.parse(localStorage.getItem("user"));
    const hniUser = user?.userTypeId?.description;
    const isHni = (hniUser === "Client Head" || hniUser === "Client Family Member") ? true : false;
    const notificationCount = useSelector((state) => state.chatGpt.notificationCount)
    const targetValue = useSelector((state) => state.nav.targetValue)
    const bookmarkList = useSelector((state) => state?.chatGpt?.bookmarkList)
    const selectedButton = useSelector((state) => state.chatGpt.selectedButton)
    const privateEquity = useSelector((state) => state.chatGpt.privateEquity)

    if (isHni && targetValue === "llm") {
        navigate("/chatgpt")
    }

    const handleButtonClick = (buttonIndex) => {
        // Update the selected button index
        dispatch(setSelectedButton(buttonIndex));
    };

    const buttons = ['Stocks', 'Internal Data', 'Portfolio', 'Private Equity'];
    //const buttons = ['Stocks', 'Internal Data'];

    const financeBookmarkList = [
        { chatGptId: '1', question: 'What are Apple source of revenue?' }
    ]

    const lifestyleBookmarkList = [
        { chatGptId: '1', question: 'What are the best golf courses?' }
    ]

    const internalDataBookmarks = [
        { chatGptId: '1', question: 'What is the probability of a market recession in Europe in 2024?' }
    ]

    const portfolioBookmarks = [
        { chatGptId: '1', question: 'What are my total positions for Fixed Income?' }
    ]

    const privateEquityBookmarks = [
        { chatGptId: '1', question: 'When is the next capital call for my X investment?' }
    ]


    useEffect(() => {
        // Check if the data is already in local storage
        let storedData = localStorage.getItem('tickersList');
        if (storedData !== null && storedData !== undefined && storedData !== 'undefined') {
            // If data is in local storage, parse and set it in the state
            dispatch(setTickersList(JSON.parse(storedData)));
        } else {
            // If data is not in local storage, fetch it
            queryAPI("tickers", "GET", "", "")
                .then(res_tickers => {
                    localStorage.setItem('tickersList', JSON.stringify(res_tickers.tickers));
                    dispatch(setTickersList(res_tickers.tickers))
                });
        }
        queryAPI("indexes", "GET", "", "")
            .then(res_indexes => {
                console.log("resindex1", res_indexes)
                if (res_indexes[0].length > 0) {
                    let options = [""]
                    res_indexes[0].forEach(index => {
                        options.push(index.payload["index_id"])
                    })
                    dispatch(setFolders(options))
                    console.log("Dispatched options")
                }
            })// eslint-disable-next-line 
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === 1) {
            navigate("/chatgpt")
        } else {
            navigate("/kairosbot")
        }
    };

    const handleChangeIndex = (index) => {
        console.log("handleChangeIndex")
        //queryAPI("")
        setValue(index);
    };

    const handleBookmark = (list, index) => {
        dispatch(setBookmarkMessage(list?.question))
        dispatch(setClose(list?.chatGptId))
    }

    const handleHistoryClick = () => {
        dispatch(setPage(0))
        if (targetValue === 'llm') {
            navigate("/kairosbot/history")
        } else {
            navigate("/chatgpt/history")

        }
        dispatch(setBookmark(true))
    };

    const handleNotificationsClick = () => {
        if (user?.userTypeId?.description !== "RM") {
            getRmNotificationList(user?.userId, targetValue).then((res) => {
                if (res?.data?.status) {
                    dispatch(setReceivedNotification(res?.data?.body))
                    dispatch(setNotificationCount(0))
                }
            })
        }
        dispatch(setNotificationClick(true))
    }


    const queryAPI = (object, method, query, payload) => {
        let auth_header = ""
        if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
            const user = window.localStorage.getItem("user")
            if (user) {
                auth_header = `Bearer ${JSON.parse(user).idToken}`;
            }
        }

        const env = process.env.REACT_APP_DOMAIN_NAME;
        console.log("process.env.REACT_APP_DOMAIN_NAME", env)
        //const env = "https://preprod.kairoswealth.com/"

        const base_api_url = "https://gaia.kairoswealth.com/"
        let headers = {}
        if (object === "document" && method === "POST") {
            headers = {
                'authorization': auth_header,
                "env": env,
                //"content-type": "multipart/form-data"
            }
        } else {
            headers = {
                'authorization': auth_header,
                "env": env,
                "content-type": "application/json",
            }
        }
        const url = base_api_url + object + "/" + query
        console.log(payload)
        console.log({ url })
        let data = {}
        if (payload !== "") {
            data = {
                method: method,
                headers: headers,
                body: payload
            }
        } else {
            data = {
                method: method,
                headers: headers,
            }
        }
        return fetch(
            url,
            data
        ).then(response => {
            if (response.status === 200) {
                const res = response.json()
                console.log({ res })
                return res
            }
            else {
                return 1
            }
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <Grid container id="SidebarContainer" sx={{ rowGap: 2, bgcolor: "#313034", overflowY: "none", maxHeight: "100%" }} width="100%" height="100%" direction="column" alignItems="center" justifyContent="space-between" wrap="nowrap">
            {/** Group all the top elements */}
            <Grid display="flex" flexDirection="column" width="100%" wrap="nowrap" maxHeight="100%" sx={{ overflowY: 'auto' }}  >
                {/** logo */}
                <Grid item sx={{ marginX: "auto" }} mb={1} mt={1}>
                    {/* <img src={logo} style={{ width: '125px', cursor: 'pointer' }} alt="Indium" onClick={() => { navigate("/dashboard") }} /> */}
                </Grid>
                <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                {/** Financial / lifestyle selector */}
                <Box sx={{ width: '100%' }}>
                    <AppBar position="static">
                        {!isHni ?
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="finacial and lifestyle selector"
                                sx={{
                                    backgroundColor: '#6c6c6c',
                                }}
                            >
                                <Tab sx={{ textTransform: 'none' }} label="Financial" {...a11yProps(0)} />
                                <Tab sx={{ textTransform: 'none' }} label="Lifestyle" {...a11yProps(1)} />
                            </Tabs>
                            :
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="finacial and lifestyle selector"
                                sx={{
                                    backgroundColor: '#6c6c6c',
                                }}
                            >
                                <Tab sx={{ textTransform: 'none' }} label="Lifestyle" {...a11yProps(1)} />
                            </Tabs>
                        }
                    </AppBar>
                    {!isHni ?
                        <SwipeableViews
                            axis={'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            height="100%"
                        >
                            {/** Financial tab section (only accessible by RM */}
                            <TabPanel value={value} index={0} dir={'x'} height="100%'">
                                <Grid container flexDirection="column" sx={{ rowGap: 1.5 }} wrap="nowrap">
                                    {/** Financial menu */}
                                    <Grid container width="100%" display="flex" justifyContent="center" alignItems="center" margin="0" sx={{ rowGap: 1.5, marginTop: 1.5 }}>
                                        {buttons.map((label, index) => (
                                            <Button
                                                key={index}
                                                sx={{
                                                    textTransform: 'none',
                                                    width: '45%',
                                                    marginX: '2%',
                                                    marginY: '1px',
                                                    color: 'white',
                                                    borderColor: '#707070',
                                                    ':hover': {
                                                        borderColor: '#6442c4',
                                                        fontWeight: 'bold',
                                                        borderWidth: '2px',
                                                        marginY: '0px',
                                                        backgroundColor: selectedButton === label ? '#6442c4' : ''
                                                    },
                                                    backgroundColor: selectedButton === label ? '#6442c4' : ''
                                                }}
                                                onClick={() => handleButtonClick(label)}
                                                variant={selectedButton === label ? 'contained' : 'outlined'}
                                            >
                                                {label}
                                            </Button>
                                        ))}
                                    </Grid>

                                    <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                    {/** Financial menu sub display : Stocks */}
                                    {selectedButton === 'Stocks' && <>

                                        {/** Main sub component */}
                                        <SidebarStocks />

                                        {/** Divider */}
                                        <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                        {/** Bookmarks component */}
                                        <Grid display="flex" flexDirection="column" width="100%">
                                            <Typography pr={2} pl={2} pb={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>Bookmarks</Typography>
                                            {/** Display bookmarks */}
                                            {bookmarkList?.map((bookmark) => {
                                                return (
                                                    <Box
                                                        key={bookmark?.chatGptId}
                                                        display="flex" flexDirection="row"
                                                        sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer' }}
                                                        onClick={() => handleBookmark(bookmark)}
                                                    >
                                                        <Bookmarkoutline />
                                                        <Grid item xs={10} sm={10} md={10} lg={10} pt={0.2} pl={2}>
                                                            {<Typography pt={0} style={{
                                                                fontSize: "12px", textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                width: '100%',
                                                            }} title={bookmark?.question}
                                                            >{bookmark?.question}</Typography>}
                                                        </Grid>
                                                    </Box>)
                                            })}
                                        </Grid>
                                    </>}

                                    {/** Financial menu sub display : Internal Data */}
                                    {selectedButton === 'Internal Data' && <>

                                        {/** Main sub component */}
                                        <SidebarInternalData queryAPI={queryAPI} />

                                        {/** Divider */}
                                        <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                        {/** Bookmarks component */}
                                        <Grid display="flex" flexDirection="column" width="100%">
                                            <Typography pr={2} pl={2} pb={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>Bookmarks</Typography>
                                            {bookmarkList?.map((bookmark) => {
                                                return (
                                                    <Box key={bookmark?.chatGptId} display="flex" flexDirection="row" sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer' }} onClick={() => handleBookmark(bookmark)}>
                                                        <Bookmarkoutline />
                                                        <Grid item xs={10} sm={10} md={10} lg={10} pt={0.2} pl={2}>
                                                            {<Typography pt={0} style={{
                                                                fontSize: "12px", textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                width: '100%',
                                                            }} title={bookmark?.question}
                                                            >{bookmark?.question}</Typography>}
                                                        </Grid>
                                                    </Box>)
                                            })}
                                        </Grid>
                                    </>}

                                    {/** Financial menu sub display : Portfolio */}
                                    {selectedButton === 'Portfolio' && <>
                                        {/** Main sub component */}
                                        <SidebarPortfolio queryAPI={queryAPI} />

                                        {/** Divider */}
                                        <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                        {/** Bookmarks component */}
                                        <Grid display="flex" flexDirection="column" width="100%">
                                            <Typography pr={2} pl={2} pb={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>Bookmarks</Typography>
                                            {bookmarkList?.map((bookmark) => {
                                                return (
                                                    <Box key={bookmark?.chatGptId} display="flex" flexDirection="row" sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer' }} onClick={() => handleBookmark(bookmark)}>
                                                        <Bookmarkoutline />
                                                        <Grid item xs={10} sm={10} md={10} lg={10} pt={0.2} pl={2}>
                                                            {<Typography pt={0} style={{
                                                                fontSize: "12px", textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                width: '100%',
                                                            }} title={bookmark?.question}
                                                            >{bookmark?.question}</Typography>}
                                                        </Grid>
                                                    </Box>)
                                            })}
                                        </Grid>
                                    </>}

                                    {/** Financial menu sub display : Private equity */}
                                    {selectedButton === 'Private Equity' && <>

                                        {/** Private Equity Sub Component */}
                                        <PrivateEquity queryAPI={queryAPI} />

                                    </>}
                                </Grid>
                            </TabPanel>
                            {/** Lifestyle tab section */}
                            <TabPanel value={value} index={1} dir={'x'}>
                                <Grid display="flex" flexDirection="column" width="100%">
                                    <Typography pr={2} pl={2} pb={2} mt={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>Bookmarks</Typography>
                                    {bookmarkList?.map((bookmark) => {
                                        return (
                                            <Box key={bookmark?.chatGptId} display="flex" flexDirection="row" sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer' }} onClick={() => handleBookmark(bookmark)}>
                                                <Bookmarkoutline />
                                                <Grid item xs={10} sm={10} md={10} lg={10} pt={0.2} pl={2}>
                                                    {<Typography pt={0} style={{
                                                        fontSize: "12px", textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        width: '100%',
                                                    }} title={bookmark?.question}
                                                    >{bookmark?.question}</Typography>}
                                                </Grid>
                                            </Box>)
                                    })}
                                </Grid>
                            </TabPanel>
                        </SwipeableViews>
                        :
                        <>
                            {/** Lifestyle tab section */}
                            <Grid display="flex" flexDirection="column" width="100%">
                                <Typography pr={2} pl={2} pb={2} mt={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>Bookmarks</Typography>
                                {bookmarkList?.map((bookmark) => {
                                    return (
                                        <Box key={bookmark?.chatGptId} display="flex" flexDirection="row" sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer' }} onClick={() => handleBookmark(bookmark)}>
                                            <Bookmarkoutline />
                                            <Grid item xs={10} sm={10} md={10} lg={10} pt={0.2} pl={2}>
                                                {<Typography pt={0} style={{
                                                    fontSize: "12px", textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    width: '100%',
                                                }} title={bookmark?.question}
                                                >{bookmark?.question}</Typography>}
                                            </Grid>
                                        </Box>)
                                })}
                            </Grid>
                        </>
                    }
                </Box>
            </Grid>
            <Grid container>
                {/** Footer of the sidebar */}
                <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>
                <Grid display="flex" flexDirection="row" flexWrap="wrap" mt={2} mb={2} justifyContent="center" rowGap={2} columnGap={2} sx={{ marginX: "auto" }}>
                    <Button variant='contained' sx={{
                        width: '140px',
                        background: '#6442C4'
                    }} onClick={handleHistoryClick}>History</Button>
                    <Button variant='contained' sx={{
                        width: '140px',
                        color: "white",
                        border: "2px solid #6442C4",
                        background: 'transparent',
                        textTransform: 'none'
                    }} onClick={handleNotificationsClick}>Notifications</Button>
                </Grid>
            </Grid>

            {/** Component containing all the modals */}
            <Modals queryAPI={queryAPI} />

        </Grid>
    );
}