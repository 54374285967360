/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SideNavbar from "./SideNavBar";
import Header from "./HeaderSection";
import Logout from "../modules/Profile/Logout"
import ChatBotSidebar from './ChatGpt/ChatGptSidebar'
import { Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';


import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import ChatBotIcon from '../modules/Enquiry/chatbot/chatBotIcon';
import { styled, useTheme } from '@mui/material/styles';
import { setOpenSide, setchatBot, setTargetValue } from "../../redux/slices/navSlice";
import ModalSurvey from "../common/ModalSurvey/ModalSurvey";
import useMediaQuery from '@mui/material/useMediaQuery';
import colorKairosForMobile from "../../theme/images/MobileKairosLogo.png";
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RMPerfomance from "../modules/RMPerfomance/RMPerfomance";

const drawerWidth = "75%";



const Layout = ({ children }) => {
    const chat = useSelector((state) => (state.nav.chatBot));
    const openSide = useSelector((state) => (state.nav.openSide));
    const surveyId = useSelector((state) => state.nav.surveyId)
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const user = JSON.parse(window.localStorage.getItem("user"))
    const navigate = useNavigate()
    const modalValue = useSelector(state => state.nav.modalState);
    const targetValue = useSelector((state) => state.nav.targetValue)

    const userRights = user?.userRights;
    const chatWithRM = userRights.find(item => item.module === "Chat with RM" && item.create === true);
    const dispatch = useDispatch()
    const { pathname } = useLocation();
    const isMd = useMediaQuery("(max-width:1024px)");

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleSideClose = () => {
        dispatch(setOpenSide(false));
    };

    console.log(user)
    const isMobile = window.innerWidth < 600;
    let userRole = user?.userTypeId?.description;
    let paymentStatusPage = (window?.location?.pathname?.includes('paymentSuccess') || window?.location?.pathname?.includes('paymentFailed'))

    if (window.location.href.includes('/chatgpt')) {
        dispatch(setchatBot(true))
        dispatch(setTargetValue('ChatGpt'))

    } else if (window.location.href.includes('/kairosbot')) {
        dispatch(setTargetValue('llm'))
        dispatch(setchatBot(true))
    }


    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        position: 'absolute',
        right: '0px'
    }));

    const getOverflowValue = () => {
        if (isMd) {
            switch (true) {
                case pathname.includes("viewuser"):
                    return "hidden";
                case pathname.includes("viewService"):
                    return "auto";
                default:
                    return "auto";
            }
        } else {
            return "auto";
        }
    };

    return (
        <>
            {chat === false ? <Grid container >
                {surveyId !== null && <ModalSurvey />}
                <Drawer

                    sx={{
                        width: drawerWidth,
                        display: { sm: "none" },
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: "#171B24",
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={openSide}
                >
                    <DrawerHeader >
                        <IconButton onClick={handleSideClose} sx={{ top: "10px" }}>
                            {theme.direction === 'ltr' ? <ClearIcon sx={{ color: 'white', postion: 'absolute' }} /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <SideNavbar />

                </Drawer>
                <Grid item xl={2} lg={2} md={3} sm={3} sx={{ border: "1px solid #171B24", height: "100vh", background: '#171B24' }} display={{ xs: "none", lg: "block", xl: "block", md: "block", sm: "block" }}>
                    <SideNavbar />
                </Grid>

                <Grid item xl={10} lg={10} md={9} sm={9} xs={12} >
                    <Header />
                    <Grid sx={{ top: "5px", padding: "5px", overflow: getOverflowValue(), height: { xs: "100vh", sm: "100vh", md: "95vh", lg: "90vh" }, position: "relative", backgroundColor: (paymentStatusPage ? 'black' : 'transparent'), marginBottom: "50px", }}>
                        <Grid
                            // container
                            //justifyContent="center"
                            // alignItems="center"
                            // alignContent="center"
                            sx={{
                                maxWidth: '1440px',
                                width: '100%',
                                margin: '0 auto', // Center horizontally

                            }}
                        >
                            {children}
                        </Grid>

                        {/* <Loader /> */}
                        <Logout />
                    </Grid>
                    {modalValue && <RMPerfomance modalValue={modalValue} />}
                </Grid>

                {/* <Grid item xl={10} lg={10} md={9} sm={9} xs={12}>{children} </Grid> */}
                {user && userRole !== 'Entity RM' && userRole !== "Kairos Admin" && userRole !== "Entity Admin" && chatWithRM && <ChatBotIcon />}
            </Grid> :
                <Grid container sx={{ overflow: 'auto', height: '100vh' }} >

                    <Grid item sx={{ height: "100vh", background: '#313034', overflowY: "none" }} display={{ xs: "block", sm: "block", md: "block" }} md={3} lg={3}>
                        <ChatBotSidebar />
                    </Grid>

                    <Grid item xs={12} md={9} lg={9} id="responseContainer" sx={{
                        background: "black"
                    }} >
                        {window.location.href.includes('/history') && (
                            <Grid
                                sx={{
                                    background: "black",
                                    position: "sticky",
                                    height: "10vh",
                                    top: 0,
                                    display: "flex",
                                    gap: "25px",
                                    width: "95%",
                                    margin: "auto",
                                    alignItems: "center",
                                    justifyContent: { sm: "start" },
                                }}
                            >
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen} // Make sure this function is defined
                                    edge="start"
                                    sx={{
                                        display: { xs: "block", sm: "none" },
                                        paddingBlock: "0px",
                                        marginBlock: "0px",
                                        mr: 2,
                                        zIndex: 1000,
                                        color: "white !important",
                                        ...(open && { display: "none" }),
                                    }}
                                >
                                    <MenuIcon sx={{
                                        color: "white !important", background: "black", fontSize: "26px"
                                    }} />
                                    {/* <img src={colorKairosForMobile} sx={{ marginLeft: "15px", fontSize: "22px", fontWeight: "550", display: { sm: "none" }, width: '25px', objectFit: 'contain', cursor: 'pointer !important' }} alt="INDIUm" onClick={() => { navigate("/dashboard") }} /> */}
                                </IconButton>

                                <h1 style={{ color: "white", display: "flex", alignItems: "center" }}><ArrowBackIosIcon sx={{
                                    fontSize: 22, marginLeft: { sm: "5px" }, display: { xs: "none", sm: "flex" }
                                }} onClick={() => { navigate(-1) }} />'Chat History'</h1>
                                <ClearIcon sx={{ color: 'white', display: { sm: "none" } }} onClick={() => { navigate(-1) }} />

                            </Grid>
                        )}


                        <Grid display="flex" style={{ overflow: "auto", height: window.location.href.includes('/history') ? "90vh" : "100vh", width: "100%", background: '#000000', }} id="responseContainerLayout">
                            {children}
                        </Grid>

                    </Grid>


                </Grid>}
        </>
    );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;