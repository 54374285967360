import React, { useState, useEffect } from 'react'
import {getFamilyMemberDetails, updateFamilyMemberDetails, getDropdownProspectsFamily, getClientListOfAssetsInstitutions, clientRefreshAssetInstitution, delinkClientBankDetails} from "../../../../services/index";//eslint-disable-next-line
import { Card, Grid, Box, Typography, FormControl, Select, MenuItem, TextField, FormHelperText, Tab, IconButton, Button } from '@mui/material';
import Textfield from '../../Prospects/Family/FormInputs/TextField';
import Singleselect from '../../Prospects/Family/FormInputs/Singleselect';
import "../../Style/textField.css";
import "../../Style/fontStyle.css";
import { ReactComponent as Edit } from '../../../../theme/images/Edit.svg'
import { toast } from 'react-toastify';
import Loader from '../../../common/Loader/Loader';
import Institutions from '../../Prospects/Financial/FormInputs/Institutions';
import { useLocation } from 'react-router-dom';

const fontStyles = {
    headerFont: "Mona Sans",
}

function MemberProfile() {
    const [readOnly, setReadOnly] = useState(true);
    const [memberDetail, setMemberDetail] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [dropdownvalues, setDropdownvalues] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const logInUserId = JSON.parse(window.localStorage.getItem("user"))?.userId
    const reqBody = {
        "loginUserId": logInUserId
    }
    const location = useLocation();
    let profileType = "";
    if ((location.pathname.includes("clientprofile") || location.pathname.includes("hniprofile"))) profileType = "client"
    if (location.pathname.includes("prospectprofile") || location.pathname.includes("EditProspects")) profileType = "prospect"
    const [assetDetails, setAssetDetails] = useState([]);
    const [categories, setCategories] = useState({});
    const [isdelink, setIsdelink] = useState(false);
    const [save, setSave] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const getMemberInfo = (request) => {
        getFamilyMemberDetails(request).then((response) => {
            if(response?.data?.status) {
                console.log("response", response)
                setIsEditable(response?.data?.body?.editable);
                const memberArray = [];
                memberArray.push(response?.data?.body?.memberDetails ? response?.data?.body?.memberDetails : {})
                memberArray?.forEach(obj => {
                    for (const value in obj) {
                        for (const value1 in obj[value]) {
                            if (typeof (obj[value][value1]) === "object") {
                                obj[value][value1] = obj[value][value1]?.id
                            }
                        }
                    }
                })
                setMemberDetail(memberArray)
                setIsLoading(false);
            }else{
                toast.error(response?.data?.errorMessage)
                setMemberDetail([]);
                setIsLoading(true);
            }
        })
    }
    useEffect(() => {
        getClientListOfAssetsInstitutions(logInUserId).then((response) => {
            if(response?.data?.status) {
                const arrayValues = response?.data?.body?.myInstitutionDetailsDto
                setCategories(response?.data?.body)
                arrayValues?.forEach(val => {
                    val["bankDetailsDtoList"] = val.clientBankDetailsDtoList
                    delete val.prospectBankDetailsDtoList
                    delete val.clientBankDetailsDtoList
                })
                setAssetDetails(arrayValues)
            }
        })
        getDropdownProspectsFamily().then((getresponse) => {
            if(getresponse?.data?.status) {
                setDropdownvalues(getresponse?.data?.body)
            }
        })
        
        setIsLoading(true);
        getMemberInfo(reqBody);
        // getFamilyMemberDetails(reqBody).then((response) => {
        //     if(response?.data?.status) {
        //         console.log("response", response)
        //         const memberArray = [];
        //         memberArray.push(response?.data?.body?.memberDetails ? response?.data?.body?.memberDetails : {})
        //         memberArray?.forEach(obj => {
        //             for (const value in obj) {
        //                 for (const value1 in obj[value]) {
        //                     if (typeof (obj[value][value1]) === "object") {
        //                         obj[value][value1] = obj[value][value1]?.id
        //                     }
        //                 }
        //             }
        //         })
        //         setMemberDetail(memberArray)
        //         setIsLoading(false);
        //     }else{
        //         toast.error(response?.data?.errorMessage)
        //         setMemberDetail([]);
        //         setIsLoading(true);
        //     }
        // })
        //eslint-disable-next-line
    }, [save, isdelink])
    const handleInputChange = (currentindex, title, value) => {
        updateStateObj(title, value, currentindex)
    }
    const updateStateObj = (title, value, currentindex) => {
        const newState = memberDetail?.map((obj, index) => {
            // if index equal to currentindex, update member name property
            if (index === currentindex) {
                if (title === "Name") return { ...obj, personalDetails: { ...obj.personalDetails, memberName: value } };
                if (title === "Relation") return { ...obj, personalDetails: { ...obj.personalDetails, relation: value } };
                if (title === "Profession") return { ...obj, personalDetails: { ...obj.personalDetails, profession: value } };
                if (title === "Age") return { ...obj, personalDetails: { ...obj.personalDetails, age: Number(value) } };
                if (title === "Education") return { ...obj, personalDetails: { ...obj.personalDetails, education: value } };
                if (title === "Email") return { ...obj, personalDetails: { ...obj.personalDetails, email: value } };
                if (title === "Mobile") return { ...obj, personalDetails: { ...obj.personalDetails, mobile: value } };
                if (title === "Countrycode") return { ...obj, personalDetails: { ...obj.personalDetails, countryCodeMobile: value } };
                if (title === "Shorttermgoals") return { ...obj, financialGoals: { ...obj.financialGoals, shortTermGoals: value } };
                if (title === "Longtermgoals") return { ...obj, financialGoals: { ...obj.financialGoals, longTermGoals: value } };
                if (title === "Investmentobjectives") return { ...obj, financialGoals: { ...obj.financialGoals, investmentObjectives: value } };
                if (title === "Risktolerance") return { ...obj, financialGoals: { ...obj.financialGoals, riskTolerance: value } };
                if (title === "Investmentknowledge") return { ...obj, financialGoals: { ...obj.financialGoals, investmentKnowledgeAndExperience: value } };
                if (title === "Investmenttime") return { ...obj, financialGoals: { ...obj.financialGoals, investmentTimeHorizon: value } };
                if (title === "Financialinterest") return { ...obj, financialGoals: { ...obj.financialGoals, areasOfFinancialInterest: value } };
                if (title === "Interesthobbies") return { ...obj, financialGoals: { ...obj.financialGoals, interestsAndHobbies: value } };
                if (title === "Annualincome") return { ...obj, incomeAndAssets: { ...obj.incomeAndAssets, annualIncome: Number(value) } };
                if (title === "Sourceofincome") return { ...obj, incomeAndAssets: { ...obj.incomeAndAssets, sourcesOfIncome: value } };
                if (title === "Liquidassets") return { ...obj, incomeAndAssets: { ...obj.incomeAndAssets, liquidAssets: value } };
                if (title === "Investments") return { ...obj, incomeAndAssets: { ...obj.incomeAndAssets, investments: value } };
                if (title === "Retirementaccounts") return { ...obj, incomeAndAssets: { ...obj.incomeAndAssets, retirementAccounts: value } };
                if (title === "Otherassets") return { ...obj, incomeAndAssets: { ...obj.incomeAndAssets, otherSignificantAssets: value } };
                if (title === "Mortages") return { ...obj, liabilitiesAndDebts: { ...obj.liabilitiesAndDebts, mortgages: value } };
                if (title === "Loans") return { ...obj, liabilitiesAndDebts: { ...obj.liabilitiesAndDebts, loans: value } };
                if (title === "Creditcard") return { ...obj, liabilitiesAndDebts: { ...obj.liabilitiesAndDebts, creditCardDebt: value } };
                if (title === "Otherdebts") return { ...obj, liabilitiesAndDebts: { ...obj.liabilitiesAndDebts, otherOutstandingDebts: value } };
                if (title === "monthlyHouseholdExpenses") return { ...obj, expenseAnalysis: { ...obj.expenseAnalysis, monthlyHouseholdExpenses: value } };
                if (title === "educationExpenses") return { ...obj, expenseAnalysis: { ...obj.expenseAnalysis, educationExpenses: value } };
                if (title === "insurancePremiums") return { ...obj, expenseAnalysis: { ...obj.expenseAnalysis, insurancePremiums: value } };
                if (title === "debtRepaymentObligations") return { ...obj, expenseAnalysis: { ...obj.expenseAnalysis, debtRepaymentObligations: value } };
                if (title === "otherSignificantRecurrentExpenses") return { ...obj, expenseAnalysis: { ...obj.expenseAnalysis, otherSignificantRecurrentExpenses: value } };
                if (title === "primaryResidence") return { ...obj, realEstateHoldings: { ...obj.realEstateHoldings, primaryResidence: value } };
                if (title === "secondaryOrVacationHomes") return { ...obj, realEstateHoldings: { ...obj.realEstateHoldings, secondaryOrVacationHomes: value } };
                if (title === "investmentProperties") return { ...obj, realEstateHoldings: { ...obj.realEstateHoldings, investmentProperties: value } };
                if (title === "commercialProperties") return { ...obj, realEstateHoldings: { ...obj.realEstateHoldings, commercialProperties: value } };
                //risk management and insurance
                if (title === "healthInsuranceCoverage") return { ...obj, riskManagementAndInsurance: { ...obj.riskManagementAndInsurance, healthInsuranceCoverage: value } };
                if (title === "lifeInsurancePolicies") return { ...obj, riskManagementAndInsurance: { ...obj.riskManagementAndInsurance, lifeInsurancePolicies: value } };
                if (title === "disabilityInsuranceCoverage") return { ...obj, riskManagementAndInsurance: { ...obj.riskManagementAndInsurance, disabilityInsuranceCoverage: value } };
                if (title === "longTermCareInsuranceCoverage") return { ...obj, riskManagementAndInsurance: { ...obj.riskManagementAndInsurance, longTermCareInsuranceCoverage: value } };
                if (title === "otherInsurancePolicies") return { ...obj, riskManagementAndInsurance: { ...obj.riskManagementAndInsurance, otherInsurancePolicies: value } };
                //estate planning
                if (title === "willAndTrusts") return { ...obj, estatePlanning: { ...obj.estatePlanning, willAndTrusts: value } };
                if (title === "powerOfAttorneyDesignation") return { ...obj, estatePlanning: { ...obj.estatePlanning, powerOfAttorneyDesignation: value } };
                if (title === "beneficiaryDesignations") return { ...obj, estatePlanning: { ...obj.estatePlanning, beneficiaryDesignations: value } };
                if (title === "charitableGivingIntentions") return { ...obj, estatePlanning: { ...obj.estatePlanning, charitableGivingIntentions: value } };
                //tax considerations
                if (title === "taxFilingStatus") return { ...obj, taxConsiderations: { ...obj.taxConsiderations, taxFilingStatus: value } };
                if (title === "taxObligationsAndLiabilities") return { ...obj, taxConsiderations: { ...obj.taxConsiderations, taxObligationsAndLiabilities: value } };
                if (title === "taxPlanningStrategies") return { ...obj, taxConsiderations: { ...obj.taxConsiderations, taxPlanningStrategies: value } };
                //financial relationships
                if (title === "otherFinancialInstitutions") return { ...obj, financialRelationships: { ...obj.financialRelationships, otherFinancialInstitutions: value } };
                if (title === "financialAdvisorsOrProfessionals") return { ...obj, financialRelationships: { ...obj.financialRelationships, financialAdvisorsOrProfessionals: value } };
                //wellness and health
                if (title === "fitnessRoutineAndPreferences") return { ...obj, wellnessAndHealth: { ...obj.wellnessAndHealth, fitnessRoutineAndPreferences: value } };
                if (title === "dietaryPreferencesOrRestrictions") return { ...obj, wellnessAndHealth: { ...obj.wellnessAndHealth, dietaryPreferencesOrRestrictions: value } };
                if (title === "wellnessPractices") return { ...obj, wellnessAndHealth: { ...obj.wellnessAndHealth, wellnessPractices: value } };
                //social networking activities
                if (title === "professionalAssociations") return { ...obj, socialNetworkingActivities: { ...obj.socialNetworkingActivities, professionalAssociations: value } };
                if (title === "socialClubsAndOrganizations") return { ...obj, socialNetworkingActivities: { ...obj.socialNetworkingActivities, socialClubsAndOrganizations: value } };
                if (title === "networkingPreferences") return { ...obj, socialNetworkingActivities: { ...obj.socialNetworkingActivities, networkingPreferences: value } };
                //family heritage and culture
                if (title === "culturalTraditions") return { ...obj, familyHeritageAndCulturalBackground: { ...obj.familyHeritageAndCulturalBackground, culturalTraditions: value } };
                if (title === "languagesSpoken") return { ...obj, familyHeritageAndCulturalBackground: { ...obj.familyHeritageAndCulturalBackground, languagesSpoken: value } };
                if (title === "heritageRelatedInterests") return { ...obj, familyHeritageAndCulturalBackground: { ...obj.familyHeritageAndCulturalBackground, heritageRelatedInterests: value } };
            }
            // otherwise return the object as is
            // setMemberDetail(obj)
            return obj;
        });
        setMemberDetail(newState);
    }
    const handleProfileEdit = () => {
        setIsEdit(true)
        setReadOnly(false);
    }
    const handleCloseEdit = () => {
        setIsEdit(false);
        setReadOnly(true);
        getMemberInfo(reqBody);
    }
    const handleSaveMemberData = () => {
        const requestObj = memberDetail[0];
        setIsLoading(true);
        updateFamilyMemberDetails(requestObj).then((response) => {
            if(response?.data?.status) {
                setIsEdit(false);
                setReadOnly(true)
                setIsLoading(false);
                toast.success(response?.data?.statusMessage)
                getMemberInfo(reqBody);
            }else{
                setIsLoading(true);
                toast.error(response?.data?.errorMessage)
            }
        })
    }
    const handleRefresh = (id) => {
        setSave(false);
        clientRefreshAssetInstitution(id).then(response => {
            if(response?.data?.status) {
                toast.success(response?.data?.statusMessage)
                setSave(true);
            }else{
                toast.error(response?.data?.errorMessage)
                setSave(false);
            }
        })
    }
    const handleDelinkBankDetails = (itemid, name, accountnumber) => {
        const delinkObj = {
            "id": itemid,
            "clientId": logInUserId,
            "bankName": name,
            "bankAccountNumber": accountnumber
        }
        delinkClientBankDetails(delinkObj).then(response => {
            if(response?.data?.status){
                toast.success(response?.data?.statusMessage)
                setIsdelink(true);
            }else{
                toast.error(response?.data?.errorMessage)
                setIsdelink(false);
            }
        })
    }
    console.log("memberDetail", memberDetail)
    return (
        <>
        {isLoading && <Loader isLoading={isLoading} />}
        <div>
            <Card elevation={0} sx={{ p: 1, background: isEdit ? "#FFFFFF 0% 0% no-repeat padding-box" : "#F9F7F7 0% 0% no-repeat padding-box", border: "1px solid #EAEAEA", borderRadius: "4px 4px 0px 0px", opacity: 1 }}>
                <Grid container sx={{ borderBottom: 1, borderColor: "#D1D3D6", height: "55px" }}>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Tab label={(<Typography sx={{ fontSize: "15px" }}>Profile Details</Typography>)} sx={{ borderBottom: 3, borderColor: "#66C824", padding: "12px 16px 5px 0px", opacity: 1, height: "55px" }} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                        {!isEdit && <IconButton disabled={!isEditable} sx={{ padding: "0px", opacity: isEditable ? 1 : 0.3, "&:disabled": { cursor: "not-allowed", pointerEvents: "all !important" } }} onClick={handleProfileEdit}>
                            <Edit></Edit>
                        </IconButton>}
                    </Grid>
                </Grid>
                {memberDetail?.map((val, index) => {
                    return (
                        <>
                        <Grid container spacing={2.5} sx={{mt: 1}}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Name</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Textfield title={"Name"} textvalue={val?.personalDetails?.memberName} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Relation</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Singleselect title={"Relation"} dropdownvalues={dropdownvalues?.relation} selectedvalue={val?.personalDetails?.relation} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Profession</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Singleselect title={"Profession"} dropdownvalues={dropdownvalues?.professiontype} selectedvalue={val?.personalDetails?.profession} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Age</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Textfield title={"Age"} textvalue={val?.personalDetails?.age} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Education</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Singleselect title={"Education"} dropdownvalues={dropdownvalues?.education} selectedvalue={val?.personalDetails?.education} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant="subtitle1" sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>{isEdit ? "Email Address (Not Editable) *" : "Email Address *"}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Textfield title={"Email"} textvalue={val?.personalDetails?.email} handleInputChange={handleInputChange} readOnly={true} index={index} />
                                    {(isEdit && val?.personalDetails?.email === "") ? <FormHelperText style={{color: "red"}}>Email is mandatory</FormHelperText> : null}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Typography variant="subtitle1" sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Phone number *</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <FormControl size="small" >
                                            <Select
                                                disabled={readOnly}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={val?.personalDetails?.countryCodeMobile}
                                                onChange={(e) => handleInputChange(index, "Countrycode", e.target.value)}
                                                className="adduserdropdown subTitle"
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 200, marginLeft: "40px" } } }}
                                                sx={{
                                                    iconFilled: "filled",
                                                    '& .MuiOutlinedInput-input': {
                                                        backgroundColor: '#FFFFFF !important',
                                                        paddingRight: "75px !important",
                                                        fontSize: "13px !important",
                                                        overflow: "inherit"
                                                    },
                                                }}
                                            >
                                                <MenuItem selected disabled value="">
                                                    <em>Select</em>
                                                </MenuItem>
                                                {dropdownvalues?.phoneCodes && dropdownvalues?.phoneCodes?.map((item, index) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        +{item?.phoneCode}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            variants="outlined"
                                            type="number"
                                            disabled={readOnly}
                                            value={val?.personalDetails?.mobile}
                                            onChange={(e) => handleInputChange(index, "Mobile", e.target.value)}
                                            // className='subTitle mobileTextField'
                                            sx={{
                                                gridColumn: "span 6",
                                                height: "40px",
                                                fontFamily: "Mona Sans",
                                                '& .MuiInputBase-root': {
                                                    height: "40px",
                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                    borderRadius: "0px 8px 8px 0px",
                                                    width: { xs: "100%", sm: "100%", md: "75%", lg: "75%" },
                                                },
                                                '& input::placeholder': {
                                                    textOverflow: "ellipsis",
                                                    color: "#BBC5D5",
                                                    fontFamily: "Mona Sans",
                                                    opacity: 1,
                                                }
                                            }}
                                            // onChange={handleMobileChange}
                                            inputProps={{ min: 0 }}
                                            onKeyPress={(e) => {
                                                if (e.key === '-') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </Box>
                                    {(isEdit && val?.personalDetails?.mobile === "") ? <FormHelperText style={{color: "red"}}>Phonenumber is mandatory</FormHelperText> : null}
                                </Box>
                            </Grid>
                        </Grid>
                        {val?.personalDetails?.age > 18 && <div style={{marginBottom: "50px"}}>
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Financial goals & Aspirations</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Short term goals</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Shorttermgoals"} dropdownvalues={dropdownvalues?.shorttermgoals} selectedvalue={val?.financialGoals?.shortTermGoals} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Long term goals</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Longtermgoals"} dropdownvalues={dropdownvalues?.longtermgoals} selectedvalue={val?.financialGoals?.longTermGoals} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Investment Objectives</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"Investmentobjectives"} textvalue={val?.financialGoals?.investmentObjectives} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Risk Tolerance</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Risktolerance"} dropdownvalues={dropdownvalues?.risktolerance} selectedvalue={val?.financialGoals?.riskTolerance} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Investment Knowledge & Experience</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Investmentknowledge"} dropdownvalues={dropdownvalues?.investmentknowledgeandexperience} selectedvalue={val?.financialGoals?.investmentKnowledgeAndExperience} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Investment Time Horizon</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Investmenttime"} dropdownvalues={dropdownvalues?.investmenttimehorizon} selectedvalue={val?.financialGoals?.investmentTimeHorizon} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Areas of Financial Interest</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Financialinterest"} dropdownvalues={dropdownvalues?.areasoffinancialinterest} selectedvalue={val?.financialGoals?.areasOfFinancialInterest} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Interests & Hobbies</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Interesthobbies"} dropdownvalues={dropdownvalues?.interestsandhobbies} selectedvalue={val?.financialGoals?.interestsAndHobbies} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Income & Assets inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Income & Assets</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Annual Income</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"Annualincome"} textvalue={val?.incomeAndAssets?.annualIncome} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Source of income</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Sourceofincome"} dropdownvalues={dropdownvalues?.sourcesofincome} selectedvalue={val?.incomeAndAssets?.sourcesOfIncome} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Liquid assets</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Liquidassets"} dropdownvalues={dropdownvalues?.liquidassets} selectedvalue={val?.incomeAndAssets?.liquidAssets} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Investments</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Investments"} dropdownvalues={dropdownvalues?.investments} selectedvalue={val?.incomeAndAssets?.investments} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Retirement accounts</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Retirementaccounts"} dropdownvalues={dropdownvalues?.retirementaccounts} selectedvalue={val?.incomeAndAssets?.retirementAccounts} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Other significant assets</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Otherassets"} dropdownvalues={dropdownvalues?.othersignificantassets} selectedvalue={val?.incomeAndAssets?.otherSignificantAssets} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*liabilities inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Liabilities & Debt</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Mortgages</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"Mortages"} textvalue={val?.liabilitiesAndDebts?.mortgages} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Loans</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"Loans"} dropdownvalues={dropdownvalues?.loans} selectedvalue={val?.liabilitiesAndDebts?.loans} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Credit card Debt</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"Creditcard"} textvalue={val?.liabilitiesAndDebts?.creditCardDebt} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Other Outstanding Debts</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"Otherdebts"} textvalue={val?.liabilitiesAndDebts?.otherOutstandingDebts} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*expense inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Expense Analysis</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Monthly household expenses</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"monthlyHouseholdExpenses"} dropdownvalues={dropdownvalues?.monthlyhouseholdexpenses} selectedvalue={val?.expenseAnalysis?.monthlyHouseholdExpenses} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Education Expenses</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"educationExpenses"} textvalue={val?.expenseAnalysis?.educationExpenses} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Insurance Premiums</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"insurancePremiums"} textvalue={val?.expenseAnalysis?.insurancePremiums} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Debt repayment obligations</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"debtRepaymentObligations"} textvalue={val?.expenseAnalysis?.debtRepaymentObligations} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Other significant recurrent expenses</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"otherSignificantRecurrentExpenses"} textvalue={val?.expenseAnalysis?.otherSignificantRecurrentExpenses} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*real estate inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Real estate holdings</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Primary residence</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"primaryResidence"} textvalue={val?.realEstateHoldings?.primaryResidence} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Secondary or Vacation homes</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"secondaryOrVacationHomes"} textvalue={val?.realEstateHoldings?.secondaryOrVacationHomes} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Investment Properties</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"investmentProperties"} textvalue={val?.realEstateHoldings?.investmentProperties} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Commercial Properties</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"commercialProperties"} textvalue={val?.realEstateHoldings?.commercialProperties} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Risk Management inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Risk Management & Insurance</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Health Insurance Coverage</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"healthInsuranceCoverage"} textvalue={val?.riskManagementAndInsurance?.healthInsuranceCoverage} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Life Insurance Policies</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"lifeInsurancePolicies"} textvalue={val?.riskManagementAndInsurance?.lifeInsurancePolicies} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Disability Insurance Coverage</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"disabilityInsuranceCoverage"} textvalue={val?.riskManagementAndInsurance?.disabilityInsuranceCoverage} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Long-term care insurance coverage</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"longTermCareInsuranceCoverage"} textvalue={val?.riskManagementAndInsurance?.longTermCareInsuranceCoverage} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Other Insurance policies</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Singleselect title={"otherInsurancePolicies"} dropdownvalues={dropdownvalues?.otherinsurancepolicies} selectedvalue={val?.riskManagementAndInsurance?.otherInsurancePolicies} handleSelectChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*estate planning inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Estate Planning</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Will and Trusts</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"willAndTrusts"} textvalue={val?.estatePlanning?.willAndTrusts} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Power of Attorney designation</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"powerOfAttorneyDesignation"} textvalue={val?.estatePlanning?.powerOfAttorneyDesignation} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Beneficiary Designations</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"beneficiaryDesignations"} textvalue={val?.estatePlanning?.beneficiaryDesignations} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Charitable giving intentions</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"charitableGivingIntentions"} textvalue={val?.estatePlanning?.charitableGivingIntentions} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Tax consideration inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Tax Considerations</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Tax filing status</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"taxFilingStatus"} textvalue={val?.taxConsiderations?.taxFilingStatus} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Tax obligations & Liabilities</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"taxObligationsAndLiabilities"} textvalue={val?.taxConsiderations?.taxObligationsAndLiabilities} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Tax planning strategies</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"taxPlanningStrategies"} textvalue={val?.taxConsiderations?.taxPlanningStrategies} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Financial relationship inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Financial Relationships</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Other financial institutions where
                                        they hold accounts or investments</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"otherFinancialInstitutions"} textvalue={val?.financialRelationships?.otherFinancialInstitutions} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Financial advisors or professionals
                                        they currently work with</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"financialAdvisorsOrProfessionals"} textvalue={val?.financialRelationships?.financialAdvisorsOrProfessionals} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Wellness inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Wellness & Health</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Fitness routine & preferences</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"fitnessRoutineAndPreferences"} textvalue={val?.wellnessAndHealth?.fitnessRoutineAndPreferences} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Dietary preferences or restrictions</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"dietaryPreferencesOrRestrictions"} textvalue={val?.wellnessAndHealth?.dietaryPreferencesOrRestrictions} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Wellness practices</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"wellnessPractices"} textvalue={val?.wellnessAndHealth?.wellnessPractices} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Social & Networking inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Social & Networking activities</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Professional associations</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"professionalAssociations"} textvalue={val?.socialNetworkingActivities?.professionalAssociations} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Social clubs and organizations</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"socialClubsAndOrganizations"} textvalue={val?.socialNetworkingActivities?.socialClubsAndOrganizations} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Networking preferences</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"networkingPreferences"} textvalue={val?.socialNetworkingActivities?.networkingPreferences} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*Family heritage & cultural background inputs*/}
                            <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>Family heritage & cultural background</Typography>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Cultural traditions</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"culturalTraditions"} textvalue={val?.familyHeritageAndCulturalBackground?.culturalTraditions} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Languages spoken</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"languagesSpoken"} textvalue={val?.familyHeritageAndCulturalBackground?.languagesSpoken} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Typography variant='subtitle1' sx={{ mb: 1, ml: 0.5, fontFamily: fontStyles.headerFont }}>Heritage-related interests</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Textfield title={"heritageRelatedInterests"} textvalue={val?.familyHeritageAndCulturalBackground?.heritageRelatedInterests} handleInputChange={handleInputChange} readOnly={readOnly} index={index} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>}
                        </>
                    )
                })}
                {!isEdit && <Institutions assetdetails={assetDetails} financialCategories={categories?.financialProductCategories} lifestyleCategories={categories?.lifestyleProductCategories} producttype={categories?.productTypes} handleDelinkBankDetails={handleDelinkBankDetails} profileType={profileType} sendRefreshIdToParent={handleRefresh} />}
                {isEdit && <div style={{ marginBottom: "25px", marginTop: "25px" }}>
                    <Button onClick={handleSaveMemberData} style={{ background: '#6442C4 0% 0% no-repeat padding-box', color: "white", margin: "10px", minWidth: 100, borderRadius: "6px", height: "32px" }}>
                        Save
                    </Button>
                    <Button onClick={handleCloseEdit} style={{ color: "#7A86A1", background: '#F9F7F7 0% 0% no-repeat padding-box', border: "1px solid #7A86A1", borderRadius: "6px", opacity: 1, minWidth: 100, height: "32px" }}>
                        Cancel
                    </Button>
                </div>}
            </Card>
        </div>     
        </>
    )
}

export default MemberProfile