import React, { useState, useEffect } from "react";
import { Paper, TableCell, Box, Grid, TextField, InputAdornment, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import PaginationComponent from "../../../common/Pagination/Pagination";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { getListOfClients } from "../../../../services/index";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import TableSortLabel from "@mui/material/TableSortLabel";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { visuallyHidden } from "@mui/utils";
import { clienctColumns } from "../../../common/Columns";
import SearchIcon from '@mui/icons-material/Search'
import SquareIcon from '@mui/icons-material/Square'
import { formatNumber } from "../../../../constant/DollorFormats";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: "#ffff !important",
        backgroundColor: theme.palette.common.black,
    },
    overrides: {
        MuiTableHead: {
            root: {
                width: "100px",
                height: "50px",
            },
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:root": {
        borderBottom: "none",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },

}));
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function ListOfClients() {
    const navigate = useNavigate()
    const [searchParams, setsearchParams] = useSearchParams();
    const [clientList, setClientList] = useState([]);
    const [totalRecord, setTotalRecord] = useState();
    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState("asc");
    const [dataid, setDataid] = useState("");
    // eslint-disable-next-line 
    const [pages, setPages] = useState(0)
    const [search, setSearch] = useState("");
    // const [name, setName] = useState('')
    const [condtion, setcondtion] = useState(false)

    const [page, setPage] = useState(
        parseInt(searchParams.get("pageNumber")) || 1
    );
    // eslint-disable-next-line 
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(searchParams.get("ListCount")) || 10
    );
    const loginUser = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        const timeOut = condtion ? setTimeout(() => listApi(), 1000) : null;
        return () => clearTimeout(timeOut);
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        listApi()
        // if (search !== "") {
        //     setsearchParams({ pageNumber: page, ListCount: rowsPerPage, search: search })
        // } else if (search === "") {
        //     setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        // } else {
        //     setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        // }
        // const reqObj = {
        //     pageNo: page - 1,
        //     pageSize: rowsPerPage,
        //     rmId: loginUser.userId,
        //     search: search
        // }
        // getListOfClients(reqObj).then((res) => {
        //     if (res?.data?.status) {
        //         console.log(res?.data?.body, "res?.data?.body")
        //         setClientList(res?.data?.body === null ? [] : res?.data?.body)
        //         setTotalRecord(res?.data?.totalPages)
        //     } else {
        //         setClientList([])
        //     }

        // })
        // eslint-disable-next-line 
    }, [])
    // useEffect(()=>{
    //     handleNavigate()
    // },[])
    const listApi = async () => {
        if (search !== "") {
            setsearchParams({ pageNumber: page, ListCount: rowsPerPage, search: search })
        } else if (search === "") {
            setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        } else {
            setsearchParams({ pageNumber: page, ListCount: rowsPerPage })
        }
        const reqObj = {
            pageNo: page - 1,
            pageSize: rowsPerPage,
            rmId: loginUser.userId,
            search: search
        }
        const res = await getListOfClients(reqObj)
        if (res?.data?.status) {
            setClientList(res?.data?.body === null ? [] : res?.data?.body)
            setTotalRecord(res?.data?.totalPages)
            setcondtion(true)
        } else {
            setClientList([])
        }

    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event, id) => {
        setDataid(id)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handlePageChange = async (event, value) => {
        setPage(value);
        const pageObj = {
            "pageNo": value - 1,
            "pageSize": rowsPerPage,
            "rmId": loginUser.userId,
            "search": ""
        }
        const response = await getListOfClients(pageObj);
        if (response?.data?.status) {
            setClientList(response?.data?.body);
            setTotalRecord(response?.data?.totalPages);
        }
    };
    const createSortHandlerVal = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handlefilter = (e) => {
        setPage(1)
        const keyword = e.target.value;
        const trimmedKeyword = keyword.trimStart();
        setSearch(trimmedKeyword)

    }
    return (
        <div>
            <Box
                sx={{
                    // width: "400px",
                    position: 'relative',
                    height: "398px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0.47px 3px 10px #77777733",
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    opacity: "1",
                    '& ::-webkit-scrollbar': {
                        width: '0.4em',
                    },
                    '& ::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                    },
                    '& ::-webkit-scrollbar-thumb': {
                        backgroundColor: '#6A3BE2',
                    },
                    '& ::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    }
                }}
            >
                <Box sx={{ flexGrow: 1, p: "15px" }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography
                               variant="h6"
                                style={{
                                    // width: "92px",
                                    // height: "22px",
                                    textAlign: "left",
                                    // font: "normal normal 900 14px 20px Mona Sans",
                                    letterSpacing: "0.14px",
                                    color: "#000000",
                                    opacity: 1,
                                    fontSize: "13px",
                                    fontWeight: 600
                                }}
                            >
                                List Of Clients
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>

                            <TextField
                                id="search-bar"
                                className="text"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ fill: "black" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={search}
                                onChange={(e) => { handlefilter(e) }}
                                variant="outlined"
                                placeholder="Search"
                                size="small"
                                sx={{
                                    width: '100% !important',
                                    mt: "8px",
                                    '& .MuiInputBase-input': {
                                        font: 'normal normal normal 14px/15px',
                                        // fontFamily: "Mona Sans",
                                        color: '#000000 !important',

                                    },
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#BBC5D5 !important',
                                        opacity: 1,
                                        fontFamily: "Mona Sans",

                                    },
                                    '& .MuiInputBase-root': {
                                        height: '32px',
                                        borderRadius: '8px',


                                    }
                                }}
                            />


                        </Grid>
                    </Grid>
                </Box>
                <TableContainer
                    component={Paper}
                    style={{
                        maxHeight: "250px",
                        width: "auto",
                        marginTop: "20.5px",
                        margin: "0 10px 0 10px",
                        overflowX: "hidden"
                    }}
                >
                    <Table aria-label="customized table">
                        <TableHead
                            sx={{ width: "360px", height: "35px", whiteSpace: "nowrap" }}
                        >
                            <TableRow>
                                {clienctColumns?.map((headCell) => (
                                    <StyledTableCell
                                        key={headCell.id}
                                        align={'center'}
                                        // padding={headCell.disablePadding ? "none" : "normal"}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        size='small'
                                        sx={{ whiteSpace: headCell.id === "investable" ? 'normal' : 'nowrap', padding: "6px 6px", minWidth: headCell.id === "employeeId" ? '55px' : '50px', maxWidth: headCell.id === "employeeId" ? '60px' : '50px' }}
                                    >
                                        <TableSortLabel
                                            onClick={createSortHandlerVal(headCell.id)}
                                            active={orderBy === headCell.id}
                                            IconComponent={UnfoldMoreIcon}
                                            sx={{
                                                '& .MuiTableSortLabel-icon': {
                                                    padding: '1px',
                                                    color: 'white !important',
                                                },
                                            }}
                                        >
                                            <span style={{ color: 'white' }}>{headCell.label}</span>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort(clientList, getComparator(order, orderBy))
                                .slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage)
                                .map((data, index) => {
                                    return (

                                        <StyledTableRow
                                            key={data?.id}
                                            sx={{ width: "43px", padding: "6px 6px" }}
                                        >
                                            <TableCell align="center" size="small" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", minWidth: '20px', maxWidth: '75px', fontSize: "9px" }}>
                                                {(data.riskAppetite.description === "RISK AVERSE" || data.riskAppetite.description === "CONSERVATIVE") ? <SquareIcon color='success' style={{ fontSize: "15px" }} /> : data.riskAppetite.description === "AGGRESSIVE" ? <SquareIcon color='error' style={{ fontSize: "15px" }} /> : <SquareIcon color='warning' style={{ fontSize: "15px" }} />}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                size="small"
                                                sx={{
                                                    textAlign: "left",

                                                    // textDecoration: "underline",
                                                    fontFamily: "Mona Sans",
                                                    font: "normal normal normal 12px 21px Mona Sans",
                                                    letterSpacing: "0px",
                                                    color: "#1159D5",
                                                    opacity: "1",
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    minWidth: '20px',
                                                    maxWidth: '80px'
                                                }}
                                                title={data?.clientName}
                                            >
                                                <Link style={{
                                                    color: "#1159D5",
                                                }} to={`/clientprofile/${btoa(data?.id)}`} state={data} >{data?.clientName}</Link>
                                            </TableCell>
                                            <TableCell align="left" size="small">
                                                US ${formatNumber(data?.investable)}
                                            </TableCell>

                                            <TableCell align="left" size="small" sx={{}}>

                                                <div>
                                                    <MoreHorizIcon
                                                        aria-describedby={id}
                                                        variant="contained"
                                                        onClick={(e) => handleClick(e, data?.id)}

                                                    />
                                                </div>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                >
                                                    <MenuItem onClick={() => { navigate(`/clientprofile/${btoa(dataid)}`) }}>View Profile</MenuItem>
                                                    <MenuItem onClick={() => { navigate(`/transactions/${btoa(dataid)}`) }}>View Transactions</MenuItem>
                                                    <MenuItem onClick={() => { navigate(`/clientPortfolio/${btoa(dataid)}`) }}> View Portfolio </MenuItem>
                                                    <MenuItem onClick={() => { navigate(`/clientSubscriptions/${btoa(dataid)}`) }}> View Subscription</MenuItem>
                                                </Popover>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                        {clientList.length === 0 && (<TableRow><TableCell colSpan={6}>No client details found</TableCell></TableRow>)}
                    </Table>
                </TableContainer>
                <div
                    style={{
                        float: "right",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end",
                        zIndex: 1,
                        position: 'absolute',
                        bottom: 5,
                        right: 0
                    }}
                >
                    <PaginationComponent
                        handlePageChange={handlePageChange}
                        page={page}
                        totalCount={totalRecord}
                    />
                </div>

            </Box>
        </div>
    );
}

export default ListOfClients;