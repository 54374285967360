

export const FormModalProducts = {

    formFieldProducts: {
        locationId: {
            name: 'locationId',
            label: 'Location',
            placeholder: 'Select Location',


        },
        tags: {
            name: 'tags',
            label: 'Hashtags',
            placeholder: 'Select Tags',


        },


    },
    formFieldConfirm: {
        quantity: {
            name: 'quantity',
            label: 'Quantity',


        },
        amount: {
            name: 'amount',
            label: 'Amounts($)',


        },
        price: {
            name: 'price',
            label: 'Price($)',


        },
        productId: {
            name: 'productId',
            label: "productServiceId"
        }


    }
};







