import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Grid } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import Form from "./Form"
import Buttons from "./Buttons"
import { getSurveyDetails, saveAnswerDetails } from '../../../services/index';
import { toast } from 'react-toastify';
import { setSurveyId, setSurveyIDsValue,sethniCall } from "../../../redux/slices/navSlice"
import { useDispatch, useSelector } from "react-redux"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { sm: "60%", xs: "90%" },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
    p: 2,

    overflow: "auto"
};




function ModalSurvey({ header }) {
    //eslint-disable-next-line
    const [selectedRadio, setSelectedRadio] = useState(null)

    const [array, setArray] = useState([])
    const [answers, setAnswers] = useState({});
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userId = user?.userId
    const surveyId = useSelector((state) => state.nav.surveyId)
    //eslint-disable-next-line
    const surveyIdAPIFRESH = useSelector((state) => state.nav.refreshAPI)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [surveyList, setSurveyList] = useState([]);
    const [totalPages, setTotalPages] = useState(null)
    const [totalQuestionCount, setTotalQuestionCount] = useState(0)

    const dispatch = useDispatch()
    useEffect(() => {

        if (surveyId !== null) {
            const obj = {
                "surveyId": surveyId,
                "pageNo": 0,
                "pageSize": 2
            }
            getSurveyDetails(obj).then(res => {
                setSurveyList(res.data.body)
                setTotalPages(res?.data?.totalPages)
                setTotalQuestionCount(res?.data?.totalQuestionCount)
            })
        }
        //eslint-disable-next-line
    }, [])
    function handleSubmit() {
        dispatch(sethniCall(true))
        const obj = {
            "surveyId": surveyId,
            "loginUserId": userId,
            "clientId": userId,
            "surveyAnswers": array
        }
        if (array?.length > 0) {
            saveAnswerDetails(obj).then(res => {
                console.log(res)
                if (res?.data?.status === true) {

                    if (surveyIdAPIFRESH) {
                        let tempValues = []
                        console.log(res?.data?.body, "Servay respsopnse ")
                        tempValues.push(res?.data?.body)
                        dispatch(setSurveyIDsValue({ surveyId: null, refreshAPI: false, surveyTempValue: tempValues }))

                    }
                    else {
                        dispatch(setSurveyId({ surveyId: null, refreshAPI: false }))
                    }
                    toast.success(res?.data?.statusMessage)

                } else {
                    toast.error(res?.data?.statusMessage)
                }
            })
        } else {
            dispatch(setSurveyId({ surveyId: null, refreshAPI: false }))
        }







    }
    function handleRadioChange(e, qId, question) {
        console.log(e.target.value, qId, "valueeee")

        console.log(question, "questionnnn")

        setAnswers((ps) => ({ ...ps, [qId]: e.target.value }))

        const answerId = question?.answers.filter(obj => obj?.answer === e.target.value)[0]?.answerId
        const point = question?.answers.filter(obj => obj?.answer === e.target.value)[0]?.point

        const existingAnswer = array?.find((item) => item?.questionId === qId);
        if (existingAnswer) {
            const updatedArray = array?.map((item) => item?.questionId === qId ? { ...item, answerId: answerId, point: point } : item);
            setArray(updatedArray);
        } else {

            const obj = {
                "questionId": qId,
                "answerId": answerId,
                "point": point

            }
            setArray((ps) => ([...ps, obj]))
        }
    }
    console.log(array, selectedRadio, "arrayyyyyy")

    useEffect(() => {

        const obj = {
            "surveyId": surveyId,
            "pageNo": currentIndex,
            "pageSize": 2
        }
        getSurveyDetails(obj).then(res => {
            console.log(res?.data?.body, res, "Surveyyyyyyy")
            setSurveyList(res.data.body)
            setTotalPages(res?.data?.totalPages)
        })
        //eslint-disable-next-line
    }, [currentIndex])

    // console.log(pages, "pages")
    function handleValueChange(e, qId, question) {
        setAnswers((ps) => ({ ...ps, [qId]: e.target.value }))

        const answer = e.target.value

        const existingAnswer = array?.find((item) => item?.questionId === qId);
        if (existingAnswer) {
            const updatedArray = array?.map((item) => item?.questionId === qId ? { ...item, answer: answer } : item);
            setArray(updatedArray);
        } else {

            const obj = {
                "questionId": qId,
                "answer": answer,
            }
            setArray((ps) => ([...ps, obj]))

        }

    }

    function handleCounterChange(e, qId, question, rating) {
        console.log(qId, question, rating, "hello")
        setAnswers((ps) => ({ ...ps, [qId]: rating }))

        const answer = rating
        console.log(answer, "answerrrrrrrr")
        const existingAnswer = array?.find((item) => item?.questionId === qId);
        if (existingAnswer) {
            const updatedArray = array?.map((item) => item?.questionId === qId ? { ...item, answer: answer } : item);
            setArray(updatedArray);
        } else {

            const obj = {
                "questionId": qId,
                "answer": answer,
            }
            setArray((ps) => ([...ps, obj]))

        }
    }

    function handleCounterNpsChange(e, qId, question, rating) {
        console.log(qId, question, rating, "hello")
        setAnswers((ps) => ({ ...ps, [qId]: e.target.value }))
        console.log(e.target.value, "eeeeeeeee")
        const answer = e.target.value
        console.log(answer, "answerrrrrrrr")
        const existingAnswer = array?.find((item) => item?.questionId === qId);
        if (existingAnswer) {
            const updatedArray = array?.map((item) => item?.questionId === qId ? { ...item, answer: answer } : item);
            setArray(updatedArray);
        } else {

            const obj = {
                "questionId": qId,
                "answer": answer,
            }
            setArray((ps) => ([...ps, obj]))

        }
    }
    console.log(surveyList, "surveyyyyyyyyyyyyyyyyy")
    console.log(answers, "newwwwwwAnser")
    return (
        <div>
            <Modal
                open={(surveyId !== null && surveyId !== 5) ? true : false}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    maxHeight: { xs: "90vh", sm: "90vh" },
                    overflowY: "auto",
                    '& .MuiBackdrop-root': {

                        background: "none"
                    }
                }}
            >
                <Box sx={style}>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ marginBottom: "15px", fontSize: "16px" }}>
                            {surveyList?.[0]?.surveyName}
                            {totalPages !== currentIndex + 1 ? <Typography sx={{ fontFamily: "Mona Sans !important", fontWeight: 200 }}>  {(currentIndex + 1) * 2} of {totalQuestionCount} Questions</Typography> :
                                <Typography sx={{ fontFamily: "Mona Sans !important", fontWeight: 200 }}>  {totalQuestionCount} of {totalQuestionCount} Questions</Typography>}
                        </Typography>
                        <ClearIcon onClick={() => dispatch(setSurveyId({ surveyId: null, refreshAPI: false }))} />

                    </Grid>

                    {surveyList?.map((data) => {
                        return (<Form
                            // key={`question-${index}`}
                            // index={index}
                            // radioButtons={radioButtons}
                            // field={field}
                            // onSubmit={handleFormSubmit(index)}
                            // onPreviousClick={() => setPageIndex(index - 1)}
                            // options={options}
                            // show={pageIndex === Math.floor(index / 2)}
                            // submitButtonText={submitButtonText}
                            global={surveyList}
                            surveyList={data}
                            onRadioChange={handleRadioChange}
                            onValueChange={handleValueChange}
                            selectedRadio={answers[data?.surveyQuestionId] || ""}
                            value={answers[data?.surveyQuestionId]}
                            onCounterChange={handleCounterChange}
                            counterValue={answers[data?.surveyQuestionId]}
                            array={array}
                        // value={answers[data?.]}
                        // required={required}
                        />
                        )
                    })}
                    <Buttons index={currentIndex} setIndex={setCurrentIndex} question={surveyList} array={array} totalPages={totalPages} handleSubmit={handleSubmit} />
                    {/* <p>{currentIndex}</p> */}

                </Box>
            </Modal>

            <Modal
                open={(surveyId !== null && surveyId === 5) ? true : false}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    maxHeight: { xs: "95vh", sm: "95vh" },
                    '& .MuiBackdrop-root': {

                        background: "none"
                    }
                }}
            >
                <Box sx={style}>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ marginBottom: "15px", fontSize: "16px" }}>
                            {surveyList?.[0]?.surveyName}
                            {surveyList?.length > 1 && (<Typography sx={{ fontFamily: "Mona Sans !important", fontWeight: 200 }}>  {(currentIndex + 1) * 2} of {totalQuestionCount} Questions</Typography>)}
                            {surveyList?.length === 1 && (<Typography sx={{ fontFamily: "Mona Sans !important", fontWeight: 200 }}>  {surveyList.length} of {totalQuestionCount} Questions</Typography>)}
                        </Typography>

                        <ClearIcon onClick={() => dispatch(setSurveyId({ surveyId: null, refreshAPI: false }))} />

                    </Grid>

                    {surveyList?.map((data) => {
                        return (<Form

                            global={surveyList}
                            surveyList={data}

                            onNpsCounterChange={handleCounterNpsChange}
                            counterNpsValue={answers[data?.surveyQuestionId]}
                        // value={answers[data?.]}
                        // required={required}
                        />
                        )
                    })}
                    <Buttons index={currentIndex} setIndex={setCurrentIndex} question={surveyList} totalPages={totalPages} handleSubmit={handleSubmit} />
                    {/* <p>{currentIndex}</p> */}

                </Box>
            </Modal>
        </div >
    )
}

export default ModalSurvey