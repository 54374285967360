import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/index";

export const login = createAsyncThunk(
  "auth/login",
  async ({ formvalues, navigate, toast }) => {
    try {
      const response = await api.logIn(formvalues);
      if (response) {
        if (response?.data?.status === false) {
          toast.error(`${response?.data?.errorMessage}`);
          navigate(`/login`);
        }
        else {

          window.location.replace(`${window.location.origin}/dashboard`)
          toast.success("Successfully logged in");
          return response.data.body;
        }
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        // setUserEmailId(formValues.email);
        // toast.error("Invalid Username/Password");
        console.log("error", err)
      } else if (err?.response?.status === 401) {
        toast.error("Invalid Username/Password");
      } else if (err.response.status !== 200 && err.response.status !== 201) {
        toast.error(err.response.data.message);
        navigate(`/`);
      } else {
        return err.response.data.errorMessage;
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    error: "",
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state, action) => {
      // localStorage.removeItem('user');
      localStorage.clear();
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      if (state.user?.idToken) {
        window.localStorage.setItem("user", JSON.stringify({ ...action.payload }));
        window.localStorage.setItem("entityId", btoa(action?.payload?.entityId));
        window.localStorage.setItem("userColumnChooser", JSON.stringify(action?.payload?.columnList?.userManagement || []));
        window.localStorage.setItem("CampaignHistoryColumnChooser", JSON.stringify(action?.payload?.columnList?.campaignHistory || []));
        window.localStorage.setItem("CampaignColumnChooser", JSON.stringify(action?.payload?.columnList?.ongoingCampaign || []));
        window.localStorage.setItem("ClientColumnChooser", JSON.stringify(action?.payload?.columnList?.clients || []));
        window.localStorage.setItem("feedbackColumnChooser", JSON.stringify(action?.payload?.columnList?.customerFeedback || []));


        window.localStorage.setItem("columnslocal", action?.payload?.columnList?.vendorManagement);


        window.localStorage.setItem("columnsLocalProducts", action?.payload?.columnList?.financialProducts);


        window.localStorage.setItem("serviceColumnChooser", JSON.stringify(action?.payload?.columnList?.financialServices || []));
        window.localStorage.setItem("enquiryColumnChooser", JSON.stringify(action?.payload?.columnList?.enquires || []));



      }
    })
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    })
  },
  // extraReducers: {
  //   [login.pending]: (state, action) => {
  //     state.loading = true;
  //   },
  //   [login.fulfilled]: (state, action) => {
  //     state.loading = false;
  //     state.user = action.payload;
  //     if (state.user?.idToken) {
  //       window.localStorage.setItem("user", JSON.stringify({ ...action.payload }));
  //       window.localStorage.setItem("userColumnChooser", JSON.stringify(action?.payload?.columnList?.userManagement || []));
  //       window.localStorage.setItem("CampaignHistoryColumnChooser", JSON.stringify(action?.payload?.columnList?.campaignHistory || []));
  //       window.localStorage.setItem("CampaignColumnChooser", JSON.stringify(action?.payload?.columnList?.ongoingCampaign || []));
  //       window.localStorage.setItem("ClientColumnChooser", JSON.stringify(action?.payload?.columnList?.clients || []));
  //       window.localStorage.setItem("feedbackColumnChooser", JSON.stringify(action?.payload?.columnList?.customerFeedback || []));


  //       window.localStorage.setItem("columnslocal", action?.payload?.columnList?.vendorManagement);


  //       window.localStorage.setItem("columnsLocalProducts", action?.payload?.columnList?.financialProducts);


  //       window.localStorage.setItem("serviceColumnChooser", JSON.stringify(action?.payload?.columnList?.financialServices || []));
  //       window.localStorage.setItem("enquiryColumnChooser", JSON.stringify(action?.payload?.columnList?.enquires || []));



  //     }
  //   },
  //   [login.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action?.payload?.message;
  //   },
  // },
});

export const { setUser, setLogout } = authSlice.actions;

export default authSlice.reducer;
