import axios from "axios";
import { toast } from 'react-toastify';
import moment from "moment";
import { reactRouterUtility } from "../constant/helpersUtility";
import { jwtDecode } from 'jwt-decode';
//import DataUser from "../testUtility/data.json";
const apiHost = process.env.REACT_APP_BASE_URL;
const productApiHost = process.env.REACT_APP_PRODUCT_BASE_URL;
//const apiHost = process.env.REACT_APP_BASE_URL;
//const productApiHost = process.env.REACT_APP_PRODUCT_BASE_URL;
//https://3jm3vyie94.execute-api.ap-southeast-1.amazonaws.com/helix-test-products-survey/
//https://qzk5eqpue7.execute-api.ap-southeast-1.amazonaws.com/helix-test
// const apiHost = 'https://qzk5eqpue7.execute-api.ap-southeast-1.amazonaws.com/helix-test';
// const productApiHost = 'https://3jm3vyie94.execute-api.ap-southeast-1.amazonaws.com/helix-test-products-survey';


const Axios = axios.create({ baseURL: apiHost });
const Axios1 = axios.create({ baseURL: productApiHost });
const Axios0 = axios.create({ baseURL: apiHost });
//let isRefreshing = false; // Add this flag to track ongoing refresh
let isFirstTime = false
let failedAPIs = [];
//let checkSuperAdmin = JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin" ? true : false;//atob(localStorage.getItem("entityId")) === "null" || atob(localStorage.getItem("entityId")) === "undefined" ? true : false

const excludedUrls = ["/api/v1/user_rights/update-user-rights", "/api/v1/prospect/add-prospect-kyc", "/api/v1/prospect/add-bank-details", "/api/v1/client/update-client-asset-details", "/api/v1/prospect/update-asset-details", "/api/v1/client/add-client-bank-details", "api/v1/client/update-client-kyc", '/api/v1/productservice/upload-file'];


Axios.interceptors.request.use(async (req) => {
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user") //|| DataUser;
        if (user) {
            req.headers.Authorization = `Bearer ${JSON.parse(user).idToken}`;
            //console.log('FormData' in req?.data, "req====>1")
            //&& "/api/v1/user_rights/update-user-rights"
            if (['PUT', 'POST', 'DELETE'].includes(req.method.toUpperCase()) && !excludedUrls.includes(req?.url)) {
                // Check if payload has entityId property
                if (!req.data?.entityId) {
                    // Assuming the payload is an object, you can customize this based on localstorage value
                    req.data = { ...req.data, entityId: await JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin" && (atob(localStorage.getItem("entityId")) === 'null' || atob(localStorage.getItem("entityId")) === 'undefined' || atob(localStorage.getItem("entityId")) === null || atob(localStorage.getItem("entityId")) === undefined) ? "0" : atob(localStorage.getItem("entityId")) }
                }
            }
            console.log("req.method.toUpperCase() === 'GET' ", req.method.toUpperCase())
            // Check if the request method is GET and has query parameters

            if (req.method.toUpperCase() === 'GET' || req.params) {
                // Assuming entityId is the key you want to bind to the header
                req.headers['userentity-id'] = await JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin" && (atob(localStorage.getItem("entityId")) === 'null' || atob(localStorage.getItem("entityId")) === 'undefined' || atob(localStorage.getItem("entityId")) === null || atob(localStorage.getItem("entityId")) === undefined) ? "0" : atob(localStorage.getItem("entityId"));
            }
        }
    }
    return req;

});
Axios.interceptors.response.use((response) => {
    if (response?.status?.code === 401) {
        if (response.request.responseURL.includes('/api/v1/admin/user-logout')) {
            window.localStorage.clear()
        } else {
            toast.error(response.data.statusMessage)
        }
    } else if (response?.status?.code !== 200) {
        return response;

    }
    return response;
}, async (error) => {
    if (error?.response?.status === 401) {
        handleCognitoSessionExpire(error, 'Axios');
    }
})

Axios0.interceptors.request.use((req) => {


    return req;

});
Axios0.interceptors.response.use((response) => {

    return response;
})

Axios1.interceptors.request.use(async (req) => {
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user") //|| DataUser;
        if (user) {
            req.headers.Authorization = `Bearer ${JSON.parse(user).idToken}`;
            console.log(req, "req====>2")
            if (['PUT', 'POST', 'DELETE'].includes(req.method.toUpperCase()) && !excludedUrls.includes(req?.url)) {
                // Check if payload has entityId property
                if (!req.data?.entityId) {
                    // Assuming the payload is an object, you can customize this based on your payload structure
                    req.data = { ...req.data, entityId: await JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin" && (atob(localStorage.getItem("entityId")) === 'null' || atob(localStorage.getItem("entityId")) === 'undefined' || atob(localStorage.getItem("entityId")) === null || atob(localStorage.getItem("entityId")) === undefined) ? "0" : atob(localStorage.getItem("entityId")) };
                }
            }
            console.log("req.method.toUpperCase() === 'GET' ", req.method.toUpperCase())
            if (req.method.toUpperCase() === 'GET' || req.params) {
                // Assuming entityId is the key you want to bind to the header
                req.headers['userentity-id'] = await JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin" && (atob(localStorage.getItem("entityId")) === 'null' || atob(localStorage.getItem("entityId")) === 'undefined' || atob(localStorage.getItem("entityId")) === null || atob(localStorage.getItem("entityId")) === undefined) ? "0" : atob(localStorage.getItem("entityId"));
            }
        }
    }

    return req;

});
Axios1.interceptors.response.use((response) => {
    if (response?.status?.code === 401) {
        if (response.request.responseURL.includes('/api/v1/admin/user-logout')) {
            window.localStorage.clear()
        } else {
            toast.error(response.data.statusMessage)
        }
    } else if (response?.status?.code !== 200) {
        return response;

    }
    return response;
}, async (error) => {
    if (error?.response?.status === 401) {
        handleCognitoSessionExpire(error, 'Axios1');
    }
})



// const handleCognitoSessionExpire = async (error, triggerPoint) => {
//     try {
//         if (!isRefreshing) {
//             isRefreshing = true; // Set the flag to indicate that refresh is in progress

//             const userObj = JSON.parse(window.localStorage.getItem('user'));
//             const refreshToken = JSON.parse(window.localStorage.getItem('refreshToken'));
//             const currentTime = moment(new Date());
//             const minutes = (refreshToken && refreshToken.time) ?
//                 moment.duration(moment(new Date(refreshToken.time)).diff(currentTime)).asMinutes() :
//                 null;

//             if ((minutes > 5) || (!isFirstTime && refreshToken === null) || (!isFirstTime && refreshToken && minutes < 0)) {
//                 isFirstTime = true;
//                 const res = await getRefreshToken(userObj);
//                 if (res?.data?.status === true) {
//                     // Refresh the token and update userObj
//                     userObj.idToken = res?.data?.body?.idToken;
//                     userObj.accessToken = res?.data?.body?.accessToken;

//                     // Update the original request headers with the new token
//                     const originalRequest = error.config;
//                     originalRequest.headers.Authorization = `Bearer ${res?.data?.body?.idToken}`;
//                     window.localStorage.setItem("user", JSON.stringify(userObj));
//                     window.localStorage.setItem("refreshToken", JSON.stringify({ ...res?.data?.body, time: new Date() }));
//                     isFirstTime = false;
//                     failedAPIs?.length > 0 && failedAPIs.forEach((req) => {
//                         req.headers.Authorization = `Bearer ${res?.data?.body?.idToken}`;
//                     });

//                     let AxiosAPIList = (failedAPIs?.length > 0 ? failedAPIs.map(req => (triggerPoint === 'Axios') ? Axios(req) : Axios1(req)) : [])
//                     AxiosAPIList?.push((triggerPoint === 'Axios') ? Axios(originalRequest) : (triggerPoint === 'Axios1') ? Axios1(originalRequest) : null)

//                     if (AxiosAPIList?.length > 0) {
//                         // Use Promise.all to wait for all requests to complete
//                         await Promise.all(AxiosAPIList).then((res) => { reactRouterUtility.navigate(0) });
//                     }
//                 } else {
//                     toast.warning("Sorry for the inconvenience, please try again.");
//                 }
//             } else if (minutes > 0 && minutes < 5) {
//                 isFirstTime = false;
//                 failedAPIs = [];
//                 const originalRequest1 = error.config;
//                 originalRequest1.headers.Authorization = `Bearer ${refreshToken?.idToken}`;
//                 return (triggerPoint === 'Axios') ? Axios(originalRequest1) : (triggerPoint === 'Axios1') ? Axios1(originalRequest1) : null;
//             } else if (isFirstTime) {
//                 const ApiRequests = error.config;
//                 failedAPIs.push(ApiRequests);
//             }

//             isRefreshing = false; // Reset the refresh flag when the process is completed
//         }
//     } catch (refreshError) {
//         isRefreshing = false; // Reset the refresh flag in case of an error
//         failedAPIs = [];

//         // Clear the authentication token and redirect to the login page if token refresh fails
//         window.localStorage.removeItem('user');
//         window.localStorage.removeItem('refreshToken');
//         window.location.href = '/login';
//         return Promise.reject(refreshError);
//     }
// }


const handleCognitoSessionExpire = async (error, triggerPoint) => {
    // try {
    //  if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
    let userObj = JSON.parse(window.localStorage.getItem('user')); //|| DataUser
    sessionStorage.setItem("initial", "1")

    const decodedToken = jwtDecode(userObj?.idToken);
    const refreshToken = decodedToken;//JSON.parse(window.localStorage.getItem('refreshToken')); //|| DataUser
    const currentTime = moment(new Date());
    let minutes = (decodedToken && refreshToken?.exp) ? (moment.duration(moment(new Date(refreshToken?.exp)).diff(currentTime)).asMinutes()) : null;

    if ((minutes > 15) || ((!isFirstTime) && refreshToken === null) || ((!isFirstTime) && refreshToken && minutes < 0)) {
        isFirstTime = true;

        const res = await getRefreshToken(userObj);


        if (res?.data?.status === true) {

            userObj.idToken = res?.data?.body?.idToken;
            userObj.accessToken = res?.data?.body?.accessToken;
            let temp2 = { ...error.config, data: error?.config?.data ? JSON.parse(error?.config?.data) : error?.config?.data }
            const originalRequest = temp2;
            // originalRequest.data = JSON.parse(originalRequest?.data)
            originalRequest.headers.Authorization = `Bearer ${res?.data?.body?.idToken}`;
            window.localStorage.setItem("user", JSON.stringify(userObj));
            window.localStorage.setItem("entityId", btoa(userObj?.entityId));
            // window.localStorage.setItem("refreshToken", JSON.stringify({ ...res?.data?.body, time: new Date() } || null));
            isFirstTime = false;

            failedAPIs?.length > 0 && failedAPIs.forEach((req) => {
                req.headers.Authorization = `Bearer ${res?.data?.body?.idToken}`;
            });
            let AxiosAPIList = (failedAPIs?.length > 0 ? failedAPIs.map(req => (triggerPoint === 'Axios') ? Axios(req) : Axios1(req)) : [])


            AxiosAPIList?.push((triggerPoint === 'Axios') ? Axios(originalRequest) : (triggerPoint === 'Axios1') ? Axios1(originalRequest) : null)


            AxiosAPIList?.length > 0 && Promise.all(AxiosAPIList).then((res) => {
                console.log(res[0], "resposnses===> 43432")

                for (let i = 0; i < res?.length; i++) {
                    if (res[i].data?.status) {
                        if (res?.length === 1 && res[0].config.url === "/api/v1/admin/getall-notification-bg") {
                            console.log("errors")
                        }

                        else {
                            toast.success(res[i]?.data?.statusMessage);
                        }

                    }
                    else {
                        toast.error(res[i]?.data?.errorMessage);
                    }

                }
                setTimeout(() => {
                    // if (((res?.length === 1) && (res[0].config.url === "/api/v1/admin/getall-notification-bg") || (res[0].config.url === "/api/v1/productservice/upload-file") || (res[0].config.url === "/api/v1/productservice/download-file") || (res[0].config.url === "/api/v1/productservice/delete-file"))) {
                    if ((res?.length === 1) && (
                        res[0].config.url === "/api/v1/admin/getall-notification-bg" ||
                        res[0].config.url === "/api/v1/productservice/upload-file" ||
                        res[0].config.url === "/api/v1/productservice/download-file" ||
                        res[0].config.url === "/api/v1/productservice/delete-file"
                    )) {
                        console.log("errors")
                    }
                    else {
                        reactRouterUtility.navigate(0)
                    }

                }, 1000)

                // reactRouterUtility.navigate(0)
            })

            // (AxiosAPIList?.length === 1 && AxiosAPIList[0] !== null) && Promise.all(AxiosAPIList);
            // reactRouterUtility.navigate(0)
            // reactRouterUtility.forceUpdate(true);
            // setTimeout(()=>{
            //     reactRouterUtility.location();
            // },10);
        } else {

            toast.warning("Sorry for the inconvience, please try again. ");
            localStorage.clear();
            window.location.reload();
        }
    } else if ((minutes > 0 && minutes < 15)) {

        let userObjRef = JSON.parse(window.localStorage.getItem('user'));
        isFirstTime = false;
        failedAPIs = []
        let temp1 = { ...error.config, data: error?.config?.data ? JSON.parse(error?.config?.data) : error?.config?.data }
        const originalRequest1 = temp1;
        // originalRequest1.data = JSON.parse(originalRequest1?.data)
        originalRequest1.headers.Authorization = `Bearer ${userObjRef?.idToken}`;

        return (triggerPoint === 'Axios') ? Axios(originalRequest1) : (triggerPoint === 'Axios1') ? Axios1(originalRequest1) : null;
    } else if (isFirstTime) {

        let temp = { ...error.config, data: error?.config?.data ? JSON.parse(error?.config?.data) : error?.config?.data }
        const ApiRequests = temp;

        failedAPIs.push(ApiRequests);


    }


    // } catch (refreshError) {
    //     console.log("refresh token error refreshError", refreshError);

    //     isFirstTime = false;
    //     failedAPIs = []
    //     // Clear the authentication token and redirect to login page if token refresh fails

    //     window.localStorage.removeItem('user');
    //     window.localStorage.removeItem('refreshToken');
    //     window.location.href = '/login';
    //     return Promise.reject(refreshError);
    // }
}

export const getRefreshToken = async (userObj) => {
    // try {
    // sessionStorage.setItem("response TOken 0.0", "0.0")
    if (userObj && Object.keys(userObj)?.length > 0) {
        let requestObj = {
            userId: userObj?.userId,
            refreshToken: userObj?.refreshToken
        }

        const response = await axios.post(`${apiHost}/api/v1/common/refresh-token`, requestObj);

        return response;
    }
    // }
    // catch (error) {

    //     console.log("refresh token error", error);
    //     console.log("refresh token error" + JSON.parse(JSON.stringify(JSON.parse(error))));
    //     console.log("refresh token error" + JSON.stringify(JSON.stringify(JSON.parse(error))));
    //     console.log("refresh token error" + JSON.stringify(JSON.parse(JSON.stringify(JSON.parse(error)))));
    //     // debugger;
    // }
}

export { Axios, Axios1, Axios0 };
