import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography, Grid, TextField, Select, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import { TextareaAutosize } from '@mui/base';
import * as yup from "yup";
import { GetCountries, addGuestUserEnquiries } from "../../../services/guestUserServices";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
// import InputAdornment from "@mui/material/InputAdornment";
import '../Style/fontStyle.css';


const validationSchema = yup.object({
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),

    mobileNumber: yup
        .string("Enter your password")
        .required("Mobile Number is required")
        .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    firstName: yup
        .string("Enter your password")
        .required("First Name  is required"),
    lastName: yup
        .string("Enter your password")
        .required("First Name  is required"),
});

const ContactUs = ({ open, handleDialogClick, handleContactClose }) => {
    const guestUser = JSON.parse(localStorage.getItem("guestUser"));
    const [Country, setCountry] = useState([]);
    const [phoneCodes, setPhoneCodes] = useState([])
    const [messagePopup, setMessagePopup] = useState(false)
    const navigate = useNavigate()
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        right: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        pt: 2,
        px: 4,
        pb: 3,
        background: "#FFFFFF",
        boxShadow: "0.47px 3px 10px #7777771A",
        borderRadius: "8px",
        opacity: "1",
        zIndex: 1, // Ensure the modal is on top of the backdrop

        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // right: "50%",
        // transform: 'translate(-50%, -50%)',
        // width: 400,

        // pt: 2,
        // px: 4,
        // pb: 3,
        // background: '#FFFFFF 0% 0% no-repeat padding-box',
        // boxShadow: '0.47px 3px 10px #7777771A',
        // borderRadius: '8px',
        // opacity: '1'
    };
    useEffect(() => {
        GetCountries().then((res) => {
            if (res?.data?.status) {
                setCountry(res?.data?.body?.countries);
                setPhoneCodes(res?.data?.body?.phoneCodes)
            }
        });
        formik.setFieldValue("firstName", guestUser?.firstName);
        formik.setFieldValue("lastName", guestUser?.lastName);
        formik.setFieldValue("email", guestUser?.email);
        formik.setFieldValue("mobileNumber", guestUser?.mobileNumber);
        formik.setFieldValue("currencyCode", guestUser?.currencyCode);
        formik.setFieldValue('date', moment(new Date(guestUser?.date)).format('DD MMM YYYY'))
        // eslint-disable-next-line
    }, []);
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            category: "WEALTH MANAGEMENT",
            enquries: "",
            currencyCode: "",
            date: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const guestUserobj = {
                email: values.email,

                mobile: values.mobileNumber,

                mobileCountryCode: Number(values.currencyCode),

                firstName: values.firstName,

                lastName: values.lastName,
                date: moment(new Date(guestUser?.date)).format('YYYY-MM-DD'),

                category: "WEALTH MANAGEMENT",

                enquiry: values.enquries,
            };
            const res = await addGuestUserEnquiries(guestUserobj);
            if (res?.data?.status) {
                setMessagePopup(true)
                handleContactClose()

            }
            console.log(res, 'resresresresresresres');
        },
    });
    console.log(formik, "formik");
    const handleProceed = () => {
        localStorage.clear()
        navigate('/guestUser')
        window.location.reload();

    }
    //eslint-disable-next-line
    function countryCodeNumber(selectVal) {
        console.log(Country, 'Countries')
        let phoneCodes = Country?.filter(i => i.id === selectVal)
        console.log(phoneCodes, "phoneCode")
        if (phoneCodes.length > 0) {
            return "+" + phoneCodes[0]?.phoneCode
        }
        else {
            return null
        }

    }
    return (
        <div>
            <Modal
                hideBackdrop
                open={open}
                // onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{
                    backdropFilter: "blur(1px)",
                    backgroundColor: "rgb(163 156 156 / 10%)",
                }}
                onClick={handleDialogClick}
            >
                <Box
                    sx={{ ...style, width: "60%", height: "auto", alignItems: "center" }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography

                            id="child-modal-title"
                            variant="h6"
                            sx={{ fontWeight: "bold" }}
                        >
                            Contact Us
                        </Typography>
                        <IconButton onClick={handleContactClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3} sx={{ margin: "0px" }}>
                            <Grid xs={12} lg={4} md={6}>
                                <Typography
                                    sx={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    Date
                                </Typography>
                                <TextField
                                    size="small"
                                    fullWidth
                                    id="date"
                                    name="date"
                                    // label="First Name"
                                    //   label={
                                    //     <Typography variant="inherit">
                                    //       First Name<span style={{ color: "red" }}>*</span>
                                    //     </Typography>
                                    //   }
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    error={formik.touched.date && Boolean(formik.errors.date)}
                                    helperText={formik.touched.date && formik.errors.date}
                                    disabled={true}
                                    sx={{
                                        marginBottom: "10%",
                                        width: "90%",
                                        // borderColor: matches ? "white" : "black",

                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            //   color: matches ? "black !important" : "",
                                            opacity: 1,
                                        },

                                        "& .MuiInputBase-root": {
                                            height: "40px !important",
                                            background: "#DDDDDD 0% 0% no-repeat padding-box",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} lg={4} md={6}>
                                <Typography
                                    sx={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    First Name
                                </Typography>
                                <TextField
                                    size="small"
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    // label="First Name"
                                    // label={
                                    //     <Typography variant="inherit">
                                    //         First Name<span style={{ color: 'red' }}>*</span>
                                    //     </Typography>
                                    // }
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.firstName && Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName && formik.errors.firstName
                                    }
                                    sx={{
                                        marginBottom: "10%",
                                        width: "90%",
                                        // borderColor: matches ? "white" : "black",

                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            // color: matches ? "black !important" : "",
                                            opacity: 1,
                                        },

                                        "& .MuiInputBase-root": {
                                            height: "40px !important",
                                            //  background: "#F9F9F9 0% 0% no-repeat padding-box",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid xs={12} lg={4} md={6}>
                                <Typography
                                    sx={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    Last Name
                                </Typography>
                                <TextField
                                    size="small"
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    // label="First Name"
                                    // label={
                                    //     <Typography variant="inherit">
                                    //         First Name<span style={{ color: 'red' }}>*</span>
                                    //     </Typography>
                                    // }
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.lastName && Boolean(formik.errors.lastName)
                                    }
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    sx={{
                                        marginBottom: "10%",
                                        width: "90%",

                                        // borderColor: matches ? "white" : "black",

                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            // color: matches ? "black !important" : "",
                                            opacity: 1,
                                        },

                                        "& .MuiInputBase-root": {
                                            height: "40px !important",
                                            //  background: "#F9F9F9 0% 0% no-repeat padding-box",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} lg={4} md={6}>
                                <Typography
                                    sx={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    Email Address
                                </Typography>
                                <TextField
                                    size="small"
                                    type="email"
                                    fullWidth
                                    id="email"
                                    name="email"
                                    // label="Email address"
                                    // sx={{color: matches ? '#white':'#6E6E6E'}}
                                    //   label={
                                    //     <Typography variant="inherit">
                                    //       Email address<span style={{ color: "red" }}>*</span>
                                    //     </Typography>
                                    //   }
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={{
                                        marginBottom: "10%",
                                        width: "90%",
                                        // borderColor: matches ? "white" : "black",

                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            //   color: matches ? "black !important" : "",
                                            opacity: 1,
                                        },

                                        "& .MuiInputBase-root": {
                                            height: "40px !important",
                                            //  background: "#F9F9F9 0% 0% no-repeat padding-box",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} lg={4} md={6}>
                                <Typography
                                    className="title"
                                    style={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    Mobile Number
                                </Typography>
                                <Box
                                    sx={{ display: "flex", width: "90%", flexDirection: "row" }}
                                >
                                    <FormControl size="small">
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="currencyCode"
                                            className="subTitle"

                                            value={formik.values.currencyCode}
                                            // error={formik.touched.currencyCode && Boolean(formik.errors.currencyCode)}

                                            // value={mobcountry ? mobcountry : ""}
                                            onChange={formik.handleChange}
                                            sx={{
                                                height: "40px",
                                                iconFilled: "filled",

                                                // borderColor: matches ? "white" : "black",
                                                "& input::placeholder": {
                                                    textOverflow: "ellipsis !important",
                                                    //   color: matches ? "black !important" : "",
                                                    opacity: 1,
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: "8px 0 0 8px",
                                                },
                                                "& .MuiOutlinedInput-input": {
                                                    //  backgroundColor: "#EAEAEA !important",
                                                    paddingRight: "75px !important",
                                                    //   padding: "15px 75px 8px 75px",
                                                    fontSize: "13px !important",
                                                    overflow: "inherit",
                                                },
                                                "& .MuiSelect-select ": {
                                                    overflow: "inherit !important",
                                                },
                                            }}
                                        >
                                            <MenuItem selected disabled value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            {phoneCodes &&
                                                phoneCodes?.map((item, index) => (
                                                    <MenuItem key={item?.id} value={item?.id}>
                                                        +{item?.phoneCode}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        type="number"
                                        value={formik.values.mobileNumber}
                                        onChange={formik.handleChange}
                                        // InputProps={{
                                        //     startAdornment: <InputAdornment position="start">{countryCodeNumber(formik.values.currencyCode)}</InputAdornment>,
                                        // }}
                                        error={
                                            formik.touched.mobileNumber &&
                                            Boolean(formik.errors.mobileNumber)
                                        }
                                        helperText={
                                            formik.touched.mobileNumber && formik.errors.mobileNumber
                                        }
                                        sx={{
                                            marginBottom: "10%",
                                            width: "90%",
                                            "& input::placeholder": {
                                                textOverflow: "ellipsis !important",
                                                color: "#363636",
                                                opacity: 1,
                                            },
                                            "& .MuiInputBase-root": {
                                                height: "40px !important",
                                                //  background: "#F9F9F9 0% 0% no-repeat padding-box",
                                                // borderRadius: "8px",
                                                borderRadius: "0px 8px 8px 0px",
                                            },
                                        }}
                                    />
                                    {/* <FormHelperText error={formik.errors.myQuote ? true : false} id="minimumBidValue-helper">{formik.touched.myQuote && formik.errors.myQuote ? formik.errors.myQuote : null}</FormHelperText> */}
                                </Box>
                            </Grid>

                            <Grid xs={12} lg={4} md={6}>
                                <Typography
                                    sx={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    Category
                                </Typography>
                                <Select
                                    onChange={formik.handleChange}
                                    value={formik.values.category}
                                    id="contact-simple-select"
                                    className="subTitle"
                                    sx={{
                                        height: "40px",
                                        width: "90%",
                                        iconFilled: "filled",
                                        // width: '320px',
                                        //   background: '#F9F9F9',
                                        "& input::placeholder": {
                                            textOverflow: "ellipsis !important",
                                            color: "red !important",
                                            opacity: 1,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            height: "43px !important",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    name="category"
                                    error={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    disabled={true}

                                //    value={selectedValue ? selectedValue : ""}
                                >
                                    {/* <MenuItem selected disabled default value="">
                                        <em>Select</em>
                                    </MenuItem> */}

                                    <MenuItem default value={"WEALTH MANAGEMENT"}>
                                        {"WEALTH MANAGEMENT"}
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid xs={12} lg={4} md={6}></Grid>
                            <Grid xs={12} lg={8}>
                                <Typography
                                    sx={{
                                        font: "normal normal normal 12px/15px Mona Sans",
                                        letterSpacing: "0px",
                                        margin: "6px 0px",
                                    }}
                                    variant="h6"
                                >
                                    Enquiry
                                </Typography>
                                <TextareaAutosize
                                    onChange={formik.handleChange}
                                    value={formik.values.enquries}
                                    name="enquries"
                                    style={{
                                        width: "94%",
                                        height: "100px",
                                        // background: "#F9F9F9",
                                        border: "1px solid #DDDDDD",
                                        borderRadius: "8px",
                                        //   background: '#F9F9F9'
                                    }}

                                    maxRows={4}
                                    aria-label="maximum height"
                                    maxLength={1000}
                                    placeholder="1000 Character Limit"

                                // placeholder="Maximum 4 rows"
                                />
                            </Grid>
                        </Grid>
                        <Box
                            sx={{ flexGrow: 1, mt: 2, flexDirection: "row" }}
                            xs={12}
                            lg={4}
                            md={4}
                        >
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ backgroundColor: "#6A3BDE", color: "white" }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#ffffff", color: "#7A86A1" }}
                                        onClick={handleContactClose}
                                    >
                                        {"Cancel"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>

                    {/* <Button onClick={handleClose}>Close Child Modal</Button> */}
                </Box>
            </Modal>

            <Modal
                open={messagePopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: '40%' }}>


                    {/* <Grid xs={12} sx={{
                        background: '#FEFAE2 0% 0% no-repeat padding-box',
                        borderRadius: '5px 5px 4px 4px',
                        opacity: 1,
                        margin: '10px 0px',
                        paddingBottom: '10px'
                    }}> */}
                    {/* <Typography id="modal-modal-description" sx={{ m: 1, fontWeight: 'bold' }}>Note :
                        </Typography> */}
                    <Typography id="modal-modal-description" sx={{ m: 1, color: '#5A5A5A' }}>Thank you for Signing up with Indium. One of our admins will contact you soon</Typography>
                    {/* </Grid> */}
                    <Box sx={{ display: "flex", justifyContent: 'center' }} xs={12} lg={4} md={4}>
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }} >
                            <Grid item >
                                <Button sx={{
                                    width: '100px',
                                    height: '32px',
                                    textAlign: 'left',
                                    background: '#683AE0',
                                    // font: 'normal normal medium 14px/15px Mona Sans',
                                    // letterSpacing: '0px',
                                    // color: '#F8F8F8',
                                    // opacity: 1
                                    textTransform: 'capitalize'
                                }} variant="contained"
                                    type='submit'
                                    onClick={() => handleProceed()}
                                // onClick={handleParticipateCampaign(participentDetails.campaignId,)}
                                >{'Ok'}</Button>
                            </Grid>


                        </Grid>
                    </Box>

                </Box>
            </Modal >
        </div>
    );
};

export default ContactUs;
