

// Format the price above to USD using the locale, style, and currency.
// export const USDollar = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//     minimumFractionDigits: 0,
// });
// export const JDollar = new Intl.NumberFormat('ja', {
//     style: 'currency',
//     currency: 'JPY',
//     minimumFractionDigits: 0,
// });
export function DollarConverstion(type, value) {
    if (type === "USD") {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
        return 'USD ' + formatter.format(value)
    }
    if (type === "JPY") {
        const formatter = new Intl.NumberFormat('ja', {
            style: 'currency',
            currency: 'JPY',
            minimumFractionDigits: 0,
        });
        return 'JPY ' + formatter.format(value)
    }
    if (type === "GBP") {
        const formatter = new Intl.NumberFormat('en-gb', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
        });
        return 'GBP ' + formatter.format(value)
    }
    if (type === "CAD") {
        const formatter = new Intl.NumberFormat('en-ca', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 0,
        });
        return 'CAD ' + formatter.format(value)
    }
    if (type === "EUR") {
        const formatter = new Intl.NumberFormat('de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
        });
        return 'EUR ' + formatter.format(value)
    }
    if (type === "SGD") {
        const formatter = new Intl.NumberFormat('zh-sg', {
            style: 'currency',
            currency: 'SGD',
            minimumFractionDigits: 0,
        });
        return 'SGD ' + formatter.format(value)
    }

}
export function TitleConverstion(type, value, userObject, currency) {
    const exchangeRateObject = userObject && userObject?.g7Countries?.find((item) => item.id === currency)
    const Value = value * exchangeRateObject?.exchangeRate
    if (type === "USD") {
        const formatevalue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
        return 'USD ' + formatevalue.format(Value)
    }
    if (type === "CAD") {
        const formatevalue = new Intl.NumberFormat('en-ca', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 0,
        });
        return 'CAD ' + formatevalue.format(Value)
    } if (type === "GBP") {
        const formatevalue = new Intl.NumberFormat('en-gb', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
        });
        return 'GBP ' + formatevalue.format(Value)
    }
    if (type === "JPY") {
        const formatevalue = new Intl.NumberFormat('ja', {
            style: 'currency',
            currency: 'JPY',
            minimumFractionDigits: 0,
        });
        return 'JPY ' + formatevalue.format(Value)
    }
    if (type === "EUR") {
        const formatevalue = new Intl.NumberFormat('de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
        });
        return 'EUR ' + formatevalue.format(Value)
    }
    if (type === "SGD") {
        const formatevalue = new Intl.NumberFormat('zh-sg', {
            style: 'currency',
            currency: 'SGD',
            minimumFractionDigits: 0,
        });
        return 'SGD ' + formatevalue.format(Value)
    }
}
export function formatNumber(num, precision = 2) {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'B', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 },
        { suffix: '', threshold: 1 },
    ];

    const found = map?.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        const formatted = (num / found.threshold)?.toFixed(precision) + found?.suffix;
        return formatted;
    }
    if (num < 1) {
        const formattedValue = (num)?.toFixed(precision)
        return formattedValue;
    }

    return num;
}