/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BarChartController from "../../Dashboard/DashboardModalController/BarChartController";// eslint-disable-next-line
import PieChartController from "../../Dashboard/DashboardModalController/PieChartController"
import { Grid, Button, Typography, Box, Modal, ListItem, List, ListItemAvatar, Avatar, useMediaQuery, ListItemText, AvatarGroup, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../../common/Loader/Loader";
import DynamicDashboardCards from "../../../common/Grid/DynamicDashboardCards"
import LeaderBoard from "./LeaderBoard";
import { useSelector } from 'react-redux';


const RMDashboardSectionOne = (props) => {
    //cardList={cardList} aggAssetAllocation={aggAssetAllocation} aggSectorAllocation={aggSectorAllocation} aggGeoAllocation={aggGeoAllocation} aumleaderboard={aumleaderboard} revenueleaderboard={revenueleaderboard} totalResponse={totalResponse} ttmAggAum={ttmAggAum} ttmInvWealth={ttmInvWealth}
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);
    const user = JSON.parse(localStorage.getItem('user'));// eslint-disable-next-line
    const [filteropen, setFilteropen] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [cardList, setCardList] = useState([]);// eslint-disable-next-line
    const [aggAssetAllocation, setAggAssetAllocation] = useState({});// eslint-disable-next-line
    const [aggSectorAllocation, seAggSectorAllocation] = useState({});// eslint-disable-next-line
    const [aggGeoAllocation, setAggGeoAllocation] = useState({});
    const [aumleaderboard, setAumleaderboard] = useState([])
    const [revenueleaderboard, setRevenueleaderboard] = useState([]);
    const [aumpage, setAumpage] = useState(1);
    const [revenuepage, setRevenuepage] = useState(1);// eslint-disable-next-line
    const [ttmAggAum, setTtmAggAum] = useState({});// eslint-disable-next-line
    const [ttmInvWealth, setTtmInvWealth] = useState({});
    const [totalResponse, setTotalResponse] = useState({});
    const [hnileaderboard, setHnileaderboard] = useState([]);
    const [productleaderboard, setProductleaderboard] = useState([]);
    const [serviceleaderboard, setServiceleaderboard] = useState([]);
    const [marketNews, setMarketNews] = useState([]);
    const [recProducts, setRecProducts] = useState([]);
    const [recServices, setRecServices] = useState([]);
    const [lifportfolio, setLifportfolio] = useState([]);
    const [finportfolio, setFinportfolio] = useState([]);
    const [hniList, setHniList] = useState([]);
    const [modal, setModal] = useState(false);
    const [productTotalPages, setProductTotalPages] = useState(1);
    const [serviceTotalPages, setServiceTotalPages] = useState(1);
    const [prodpage, setProdpage] = useState(0);
    const [servpage, setServpage] = useState(0);
    const [portfolioIndex, setPortfolioIndex] = useState(0);
    const isMobileOrTablet = useMediaQuery('(max-width: 1279px)');
    const [portfolioType, setPortfolioType] = useState("");
    const [advsuite, setAdvsuite] = useState([]);
    const [finProdServLoader, setFinProdServLoader] = useState(false);
    const [lifProdServLoader, setLifProdServLoader] = useState(false);
    const navigate = useNavigate();
    const isMorethanLG = useMediaQuery('(min-width:1201px) and (max-width:1340px)');
    const isMobileOrTabletBelow800 = useMediaQuery('(max-height: 700px)');
    const isMorethanSM = useMediaQuery('(min-width:600px) and (max-width:900px)');
    const data = [
        { header: 'Header 1', value: '3673687637', format: "currency" },
        { header: 'Header 2', value: '5433332', format: "number" },
        { header: 'Header 3', value: 'Value 3' },
        { header: 'Header 4', value: '823762873', format: "currency" },
        { header: 'Header 5', value: 'Value 5' },
        { header: 'Header 6', value: 'Value 6' },



    ];



    useEffect(() => {

        setLoading(true)
        setCardList(props?.cardList)
        setAggAssetAllocation(props?.aggAssetAllocation)
        seAggSectorAllocation(props?.aggSectorAllocation)
        setAggGeoAllocation(props?.aggGeoAllocation)


        setAumleaderboard(props?.aumleaderboard)
        setRevenueleaderboard(props?.revenueleaderboard)
        setTotalResponse(props?.totalResponse)
        setTtmAggAum(props?.ttmAggAum)
        setTtmInvWealth(props?.ttmInvWealth)


        setLoading(false)

        // eslint-disable-next-line
    }, [])





    const handleCloseLifStylePortfolio = () => {
        setModal(false);
    }

    const handleClickViewPortfolio = (index, clientId) => {
        if (portfolioType === "financial") {
            navigate("/myPortfolio", { state: { setTab: index, financialPortfolio: "financialPortfolio", hniId: clientId } })
        } else {
            navigate("/myPortfolio", { state: { setTab: index, lifestylePortfolio: "lifestylePortfolio", hniId: clientId } })
        }
    }

    return (
        <>

            <Modal open={modal} onClose={handleCloseLifStylePortfolio}>
                <Box
                    sx={{
                        width: 350,
                        backgroundColor: "white",
                        borderRadius: "5px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        border: "1px solid #ffffff",
                        boxShadow: 24,
                        padding: 2,
                    }}
                >
                    <List
                        sx={{
                            width: "100%",
                            maxHeight: 300, // Adjust the max height as needed
                            overflowY: "auto", // Make the list scrollable
                        }}
                    >
                        <CloseIcon
                            sx={{ float: "right", fontSize: "20px", cursor: "pointer" }}
                            onClick={handleCloseLifStylePortfolio}
                        ></CloseIcon>
                        <Typography sx={{ fontSize: "14px", marginLeft: "20px" }}>Client's List</Typography>
                        {hniList?.map((user, index) => {
                            return (
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Client" src={user?.profilePhotoUrl}></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={user?.firstName} sx={{ marginTop: "15px", fontSize: "10px" }}></ListItemText>
                                    <Button
                                        sx={{
                                            float: "right",
                                            marginTop: "10px",
                                            background: "#6442C4",
                                            border: "8px",
                                            color: "white",
                                            "&:hover": {
                                                background: "#6442C4",
                                                color: "white",
                                            },
                                        }}
                                        onClick={() => handleClickViewPortfolio(portfolioIndex, user?.clientId)}
                                    >
                                        View Portfolio
                                    </Button>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Modal>

            <div >
                {isLoading && <Loader isLoading={isLoading} />}
                <Box sx={{ height: "auto", padding: "10px", background: "#FFFFFF 0% 0% no-repeat padding-box", borderBottom: "1px solid #DFD2D2" }}>
                    <Typography sx={{ height: "38px", fontWeight: 600 }} variant="h4" className='welcomeText'>{`Welcome ${user?.firstName}`}</Typography>

                    {/* <DashboardsCard rows={props?.cardList} /> */}
                    <DynamicDashboardCards data={props?.cardList} cardsPerRow={6} />
                </Box>
                {!isMobileOrTablet && <Box sx={{ height: "auto", padding: "2px" }}>
                    <ResponsiveLayout props={props} isLoading={isLoading} isMobile={isMobileOrTablet} isMobileOrTabletBelow800={isMobileOrTabletBelow800} />
                </Box>
                }

                {isMobileOrTablet && <Grid container columnSpacing={1}>
                    <Grid item xs={12} lg={9} md={12} sx={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={12} lg={4} sx={{ marginTop: "5px" }}>
                                <PieChartController title={"Aggregate Asset Allocation (%)"} data={props?.aggAssetAllocation} category={"Asset"} isloading={isLoading} />
                            </Grid>
                            <Grid item xs={12} lg={4} sx={{ marginTop: "5px" }}>
                                <PieChartController title={"Aggregate Geographical Allocation (%)"} data={props?.aggGeoAllocation} category={"Geographical"} isloading={isLoading} />
                            </Grid>
                            <Grid item xs={12} lg={4} sx={{ marginTop: "5px" }}>
                                <PieChartController title={"Aggregate Sector Allocation (%)"} data={props?.aggSectorAllocation} category={"Sector"} isloading={isLoading} />
                            </Grid>
                            <br /><br />
                            <Grid item xs={12} lg={6} sx={{ marginTop: "5px" }}>
                                <BarChartController title={"TTM Aggregate AUM (US $)"} data={props?.ttmAggAum} category={"AUM"} isloading={isLoading} />
                            </Grid>
                            <Grid item xs={12} lg={6} sx={{ marginTop: "5px" }}>
                                <BarChartController title={"TTM Invested Wealth (US $)"} data={props?.ttmInvWealth} category={"Invested"} isloading={isLoading} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ marginTop: "5px", marginBottom: "5px" }}>

                        <div style={{ height: "100%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}>
                            <LeaderBoard aumrows={props?.aumleaderboard} revenuerows={props?.revenueleaderboard} revenueTotalPages={props?.totalResponse?.revenueTotalPages} aumTotalPage={props?.totalResponse?.aumTotalPages} aumPaginations={props?.handleAumPagination} aumPage={props?.aumpage} revenuepage={props?.revenuepage} revenuePaginations={props?.handleRevenuePagination} revenueLeaderboardLocalState={props?.revenueleaderboard} />
                        </div>
                    </Grid>
                </Grid>}


                {/* <Grid item xs={12} lg={12} md={12}>
                                <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}>
                                    <IframeReports url={"https://app.powerbi.com/view?r=eyJrIjoiNTVjZmEzMjItODUwZC00MWEyLWExODQtMjFhMWZmZjk2ODg3IiwidCI6IjhmNWYzZTg0LTAxZWQtNGFlMC1iNWI2LWZjNTlkNWIyNDliMyIsImMiOjF9&pageName=ReportSectionc2d4d787166b50080b37"} height={"280px"} />
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} mt={"5px"}>
                                <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}>
                                    <IframeReports url={"https://app.powerbi.com/view?r=eyJrIjoiNTVjZmEzMjItODUwZC00MWEyLWExODQtMjFhMWZmZjk2ODg3IiwidCI6IjhmNWYzZTg0LTAxZWQtNGFlMC1iNWI2LWZjNTlkNWIyNDliMyIsImMiOjF9&pageName=ReportSection9de64ae10e79edda350f"} height={"280px"} />
                                </div>
                            </Grid> */}


            </div>
        </>
    )
}



// const ResponsiveLayout = ({ props, isLoading, isMobile, isMobileOrTabletBelow800 }) => {
//     const [containerHeight, setContainerHeight] = React.useState("90vh");

//     const getContainerHeight = () => {
//         const windowHeight = window.innerHeight;
//         console.log(windowHeight, "windowHeight hook windowHeightv");
//         if (windowHeight >= 1200 && windowHeight <= 1299) {
//             return '87vh';
//         } else if (windowHeight >= 1300 && windowHeight <= 1400) {
//             return '83vh';
//         }
//         else if (windowHeight >= 1400 && windowHeight <= 2000) {
//             return '78vh';
//         }
//         else if (windowHeight >= 2001 && windowHeight <= 3000) {
//             return '88vh';
//         }
//         else if (windowHeight >= 800 && windowHeight <= 900) {
//             return '92vh';
//         }
//         else if (windowHeight >= 700 && windowHeight <= 799) {
//             return '90vh';
//         }
//         else if (windowHeight <= 500) {
//             return '88vh';
//         }


//         return '88vh'; // Default height
//     };

//     // Update height on window resize
//     React.useEffect(() => {
//         const handleResize = () => {
//             const newHeight = getContainerHeight();
//             console.log("New Styles", newHeight)
//             setContainerHeight(newHeight);
//         };

//         // Initial call to set height
//         handleResize();

//         // Event listener for window resize
//         window.addEventListener('resize', handleResize);

//         // Cleanup function to remove event listener
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };// eslint-disable-next-line
//     }, []);
//     return (
//         <Grid container columnSpacing={1} rowSpacing={1} mt={"5px"} mb={"5px"} style={{ height: isMobileOrTabletBelow800 ? "650px" : containerHeight }}>
//             <Grid item xs={12} lg={isMobile ? 12 : 9} md={12} sx={{ flexDirection: isMobile ? 'column' : 'row' }}>
//                 <Grid container columnSpacing={1} rowSpacing={1} style={{ height: '100%' }}>

//                     <Grid item xs={12} lg={12} md={12} style={{ height: isMobile ? 'auto' : '40%', }}>
//                         <Grid container columnSpacing={1} style={{ height: '100%' }}>
//                             <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
//                                 <PieChartController title={"Aggregate Asset Allocation (%)"} data={props?.aggAssetAllocation} category={"Asset"} isloading={isLoading} />
//                             </Grid>
//                             <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
//                                 <PieChartController title={"Aggregate Geographical Allocation (%)"} data={props?.aggGeoAllocation} category={"Geographical"} isloading={isLoading} />
//                             </Grid>
//                             <Grid item xs={12} lg={4} md={12} style={{ height: '100%' }}>
//                                 <PieChartController title={"Aggregate Sector Allocation (%)"} data={props?.aggSectorAllocation} category={"Sector"} isloading={isLoading} />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     <Grid item xs={12} lg={12} md={12} style={{ height: '60%', mt: "10px" }}>
//                         <Grid container columnSpacing={1} rowSpacing={0} style={{ height: '100%' }}>
//                             <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
//                                 <BarChartController title={"TTM Invested Wealth (US $)"} data={props?.ttmInvWealth} category={"Invested"} isloading={isLoading} type={true} />
//                             </Grid>
//                             <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
//                                 <BarChartController title={"TTM Invested Wealth (US $)"} data={props?.ttmInvWealth} category={"Invested"} isloading={isLoading} type={true} />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>
//             {/* {!isMobile && ( */}
//             <Grid item xs={12} lg={3} md={12} style={{ height: '100%' }}>
//                 <div style={{ height: '98.5%', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 3px 8px #77777733', border: '0.699999988079071px solid #DFD2D280', borderRadius: '12px', padding: '10px' }}>
//                     <LeaderBoard aumrows={props?.aumleaderboard} revenuerows={props?.revenueleaderboard} revenueTotalPages={props?.totalResponse?.revenueTotalPages} aumTotalPage={props?.totalResponse?.aumTotalPages} aumPaginations={props?.handleAumPagination} aumPage={props?.aumpage} revenuepage={props?.revenuepage} revenuePaginations={props?.handleRevenuePagination} revenueLeaderboardLocalState={props?.revenueleaderboard} />
//                 </div>
//             </Grid>
//             {/* // )} */}
//         </Grid>
//     );
// };

const ResponsiveLayout = ({
    props, isLoading, isMobile, isMobileOrTabletBelow800v
}) => {
    return (
        <Box sx={{ padding: "15px" }}>
            <Grid container spacing={2} sx={{
                height: '700px',
                // "&. .MuiGrid-item": {
                //   paddingLeft: '0px', paddingTop: "0px"
                // }
            }} >
                {/* Left Side (lg={9}) */}
                <Grid container item xs={12} lg={9} xl={9} spacing={2} >
                    {/* Top Section (2 Grid Layouts) */}


                    {/* Bottom Section (3 Grid Layouts) */}
                    <Grid container spacing={2} sx={{ maxHeight: '320px', minHeight: "310px", paddingTop: "15px", }}>
                        <Grid item xs={12} md={4}>
                            {/* <Box sx={{ height: '100%' }}> */}
                            {/* Content for Bottom 1 */}
                            <PieChartController title={"Aggregate Asset Allocation (%)"} data={props?.aggAssetAllocation} category={"Asset"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* <Box sx={{ height: '100%' }}> */}
                            {/* Content for Bottom 2 */}
                            <PieChartController title={"Aggregate Geographical Allocation (%)"} data={props?.aggGeoAllocation} category={"Geographical"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* <Box sx={{ height: '100%' }}> */}
                            {/* Content for Bottom 3 */}
                            <PieChartController title={"Aggregate Sector Allocation (%)"} data={props?.aggSectorAllocation} category={"Sector"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ maxHeight: '360px', minHeight: "360px", }}>
                        <Grid item xs={12} md={6} sx={{}}>
                            {/* <Box sx={{ height: '100%', }}> */}
                            {/* Content for Top Left */}
                            <BarChartController title={"TTM Invested Wealth (US $)"} data={props?.ttmInvWealth} category={"Invested"} isloading={isLoading} type={true} />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <Box sx={{ height: '100%', }}> */}
                            {/* Content for Top Right */}
                            <BarChartController title={"TTM Invested Wealth (US $)"} data={props?.ttmInvWealth} category={"Invested"} isloading={isLoading} type={true} />
                            {/* </Box> */}
                        </Grid>
                    </Grid>

                </Grid>

                {/* Right Side (lg={3}) */}
                <Grid item xs={12} lg={3} xl={3}>
                    <Box sx={{ height: '670px', }}>
                        <div style={{ height: '100%', background: '#FFFFFF', boxShadow: '0px 3px 8px #77777733', border: '1px solid #DFD2D2', borderRadius: '12px', padding: '10px' }}>
                            <LeaderBoard aumrows={props?.aumleaderboard} revenuerows={props?.revenueleaderboard} revenueTotalPages={props?.totalResponse?.revenueTotalPages} aumTotalPage={props?.totalResponse?.aumTotalPages} aumPaginations={props?.handleAumPagination} aumPage={props?.aumpage} revenuepage={props?.revenuepage} revenuePaginations={props?.handleRevenuePagination} revenueLeaderboardLocalState={props?.revenueleaderboard} />
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
};


export default RMDashboardSectionOne
