import { FormModalProducts } from '../FormModal/FormModal';// eslint-disable-next-line


const {
    formFieldProducts: {
        locationId,
        tags,



    }
} = FormModalProducts;

const {
    formFieldConfirm: {
        quantity,
        amount, price, productId



    }
} = FormModalProducts;


export const fieldValuesConfirmPayments = {
    [quantity.name]: '',
    [amount.name]: '',
    [price.name]: '',
    [productId.name]: ""



};


export const fieldValuesProduct = {
    [locationId.name]: [],
    [tags.name]: [],



};




