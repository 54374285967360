
import Grid from '@mui/system/Unstable_Grid';
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import image from "../theme/images/Mobile-BG.png"
import imageBG from "../theme/images/BG2.png"
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";
//import KairosWithLabel from "../theme/images/Kairos Logo-Future.png"
//import NewPassword from '../components/modules/login/CreatePassword'
import { ColorModeContext, useMode } from "../theme";
import { PublicRouteConfig } from "./publiceRoutesConfig"
//import DataGridTable from "../common/DataGridTable/DataGridTable"
//import { Instagram, LinkedIn } from '@mui/icons-material';
//import homeAnimation from '../theme/images/HomeBackroundAnimation.png';
//import homeImage from '../theme/images/Home.png';
import "./PublicRoute.css"
//import homeImageHighResolution from "../theme/images/Double size.png"
//import useMediaQuery from '@mui/material/useMediaQuery';
//i//mport { useTheme } from '@mui/material/styles';


export function PublicRoute() {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const myStyle = {
        backgroundRepeat: "no-repeat",
        backgroundColor: "black",
        width: windowSize[0],
        height: windowSize[1],
        padding: "20px",
        // display: "flex",
        // justifyContent: "center",
        backgroundImage: windowSize[0] >= 750 ? `url(${imageBG})` : `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        overflowY: windowSize[1] <= 700 ? "auto" : "none",
        filter: "brightness(0.9)",
    };

    const animationStyle = {
        display: windowSize[0] >= 900 ? "flex" : "none",
        justifyContent: "space-around",
        // height: "55vh",
    }

    const [theme, colorMode] = useMode();
    //const themes = useTheme();
    //const isBigScreen = useMediaQuery(themes.breakpoints.up('xl'));// eslint-disable-next-line 
    //const currentYear = new Date();



    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center" >
                        <div style={myStyle}>
                            {/* style={{ position: "absolute", top: windowSize[0] >= 900 ? "3%" : "5%", left: windowSize[0] >= 900 ? "3%" : windowSize[0] >= 500 && windowSize[0] <= 900 ? "40%" : "20%" }} */}
                            <div style={{
                                display: "flex",
                                //justifyContent: windowSize[0] <= 900 ? "center" : "left",
                                justifyContent: windowSize[0] <= 1200 ? "center" : "left",
                                marginTop: windowSize[0] >= 900 ? "1%" : "5%",
                                marginLeft: windowSize[0] >= 750 ? "3%" : "0%"
                            }}>
                                <Typography gutterBottom variant="h1" style={{ color: "white", fontSize: "33px" }}>
                                    {/* <Link href="https://kairoswealth.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', cursor: 'pointer' }} > */}
                                    <img src="https://www.indiumsoftware.com/wp-content/uploads/2023/11/logo_fixed.png" alt="indium" style={{ width: "100%", height: "auto" }} />
                                    {/* </Link> */}
                                </Typography>

                            </div>
                            <div>
                                <Grid sx={{ display: { xs: 'none', md: 'none', lg: 'block', } }} >
                                    <div style={animationStyle} >
                                        <div className='animationContainer'>
                                            {/* <img src={homeAnimation} className='backgroundImage' alt='backgroundImage' style={{ objectFit: 'contain', width: "30vw", height: "58vh", position: "absolute", zIndex: 1, left: "3%" }} /> */}
                                            {/* <img src={isBigScreen ? homeImageHighResolution : homeImage} alt='foregroundImage' style={{ objectFit: 'contain', width: "27vw", height: isBigScreen ? "58vh" : "60vh", position: "absolute", zIndex: 2, left: "5%", backgroundSize: "cover" }} /> */}
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignContent: "center" }}>

                                            <Grid item sx={{ display: { xs: 'none', md: 'none', lg: 'block', }, alignContent: "center", mt: windowSize[0] >= 900 ? "1%" : "20%", minWidth: "430px" }}>
                                                <Paper sx={{ borderRadius: "8px" }}>
                                                    <Grid container spacing={0} direction="column" alignItems={"center"} justifyContent="center" >
                                                        <Routes>
                                                            {PublicRouteConfig.map(({ name, path, element }) => (
                                                                <Route key={name + 'first'} path={path} element={element} />
                                                            ))}

                                                        </Routes>

                                                    </Grid>
                                                </Paper>

                                            </Grid>
                                        </div>
                                    </div>

                                </Grid>

                                {/* mr: windowSize[0] > 900 ? "15%" : windowSize[0] > 500 && windowSize[0] < 900 ? "10%" : "0%", mt: windowSize[0] > 900 ? "10%" : "30%", minWidth: "350px" */}
                                <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignContent: "center" }}>

                                    <Grid item sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'none', }, minWidth: "400px !important", alignContent: "center", mt: windowSize[0] < 900 ? "10%" : "20%" }}>
                                        <Paper sx={{ borderRadius: "8px" }}>
                                            {/* <div style={{
                                    position: "absolute",
                                    top: "35%",
                                    left: "30%",

                                    transform: "translate(0, -50%)"
                                }}> */}
                                            <Grid container spacing={0} direction="column" alignItems={"center"} justifyContent="center">

                                                <Routes>
                                                    {PublicRouteConfig.map(({ name, path, element }) => (
                                                        <Route key={name + 'second'} path={path} element={element} />
                                                    ))}

                                                </Routes>

                                            </Grid>
                                        </Paper>

                                    </Grid>


                                    <Grid item sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', }, justifyContent: "center", alignItems: "center", }}>
                                        {/* <div style={{
                                    position: "absolute",
                                    top: "15%",
                                    // right: windowSize[0] < 500 ? "10%" : "20%",
                                    // display: "flex",
                                    // justifyContent: "center",
                                    transform: "translate(0, 5%)",

                                }}> */}

                                        <Grid container spacing={0} direction="column" alignItems={"center"} justifyContent="center"  >
                                            <Routes>
                                                {PublicRouteConfig.map(({ name, path, element }) => (
                                                    <Route key={name + 'mobile'} path={path} element={element} />
                                                ))}

                                            </Routes>

                                        </Grid>
                                        <br />
                                        <br />
                                        {/* <Grid container justifyContent={"space-between"} marginBottom={"20px"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                            Terms
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                            Privacy and Security
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                            Contact
                                        </Typography>
                                    </Grid>

                                </Grid> */}

                                        {/* </div> */}
                                    </Grid>
                                </div>
                                {/* <div style={{ position: "absolute", top: "97%", bottom: windowSize[1] / 15, left: "3%", display: windowSize[0] > 350 ? "block" : "none" }} >
                                <div style={{ width: "300px" }}>
                                    <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                        ©2023 kairos
                                    </Typography>
                                </div>


                            </div>
                            <div style={{ position: "absolute", top: "97%", bottom: windowSize[1] / 15, left: "30%", display: windowSize[0] > 350 ? "block" : "none" }}>
                                <div style={{ width: "700px" }}>
                                    <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                        Privacy & Policy &emsp; Legal &emsp; Contact Us
                                    </Typography>
                                </div>


                            </div> */}
                                <br />
                                <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignContent: "center", color: "white", paddingTop: "70px" }} >
                                    {/* <IconButton
                                        sx={{ color: "whitesmoke" }}
                                        component="a"
                                        // href="https://www.instagram.com/kairoswealth.ai/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        name='InstagramIcon'
                                        alt='Instagram Logo'
                                    >
                                        <Instagram sx={{ fontSize: 36 }} />
                                    </IconButton>
                                    <IconButton
                                        sx={{ color: "whitesmoke" }}
                                        component="a"
                                        // href="https://www.linkedin.com/company/kairoswealth/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        name='LinkedInIcon'
                                        alt='LinkedIn Logo'
                                    >
                                        <LinkedIn sx={{ fontSize: 36 }} />
                                    </IconButton> */}
                                </div><br />
                                <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignContent: "center", color: "white", fontSize: "14px" }}>
                                    {/* <div> Copyright © {currentYear.getFullYear()} Kairoswealth (Helix Systems) - All Rights Reserved. </div> */}
                                </div>
                            </div>

                        </div>

                    </Grid>



                    {/* <Grid container className="app" lg={12} md={12} style={{ height: windowSize[1], }}>
                        <Grid md={5} lg={5} sx={{ display: { xs: 'none', md: 'block', lg: 'block', backgroundColor: "black" } }}>
                            <div >
                              
                                <img src={image} style={{ backgroundSize: "contain", position: "absolute", width: windowSize[0] / 2.3, height: windowSize[1], objectFit: "contain", backgroundColor: "black" }} alt="leftImage"></img>
                                <div style={{ position: "absolute", top: "3%", left: "3%" }}>
                                    <Typography gutterBottom variant="h1" style={{ color: "white", fontSize: "33px" }}>
                                        <img src={Kairos} alt="KAIROS" />
                                    </Typography>

                                </div>
                                <div style={{ position: "absolute", bottom: "159px", top: "70%", right: "60%", left: "3%" }}>
                                   
                                    <div>
                                        <Typography gutterBottom variant="h1" style={{ color: "white", fontSize: "50px", fontFamily: "The Seasons", textAlign: "center" }}>
                                            The future of wealth.
                                        </Typography>
                                    </div>


                                </div>
                                <div style={{ position: "absolute", top: "95%", bottom: windowSize[1] / 15, left: "3%" }}>
                                    <div style={{ width: "300px" }}>
                                        <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                            ©2023 kairos
                                        </Typography>
                                    </div>


                                </div>
                                <div style={{ position: "absolute", top: "95%", bottom: windowSize[1] / 15, left: "20%" }}>
                                    <div style={{ width: "700px" }}>
                                        <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                            Privacy & Policy &emsp; Legal &emsp; Contact Us
                                        </Typography>
                                    </div>


                                </div>
                            </div>

                        </Grid>
                        <Grid md={'auto'} lg={'auto'} sx={{ display: { xs: 'block', md: 'block', lg: 'block', } }}>

                            <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }} >
                                <Grid >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            '& > :not(style)': {
                                                width: windowSize[0],
                                                height: windowSize[1] //+ 50,
                                            },
                                        }}
                                    >
                                        <Paper elevation={3} style={myStyle} >
                                            <div style={{ position: "absolute", top: "3%", left: "3%" }}>
                                                <Typography gutterBottom variant="h1" style={{ color: "white", fontSize: "33px" }}>
                                                    <img src={Kairos} alt="KAIROS" />
                                                </Typography>

                                            </div>
                                            <div style={{ padding: 20 }}>
                                                <main className="content" style={{ marginTop: "100px", marginLeft: "2px", borderRadius: "8px", padding: 20 }}>

                                                    <Routes>
                                                        {PublicRouteConfig.map(({ name, path, element }) => (
                                                            <Route key={name} path={path} element={element} />
                                                        ))}
                                                       
                                                    </Routes>
                                                </main>
                                            </div>
                                            <div style={{ position: "absolute", bottom: 5, left: "3%" }}>
                                                <div style={{ width: "300px" }}>
                                                    <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                                        ©2023 kairos
                                                    </Typography>
                                                </div>


                                            </div>
                                            <div style={{ position: "absolute", bottom: 5, right: "10%" }}>
                                                <div >
                                                    <Typography gutterBottom variant="h6" style={{ color: "white", fontSize: "12px" }}>
                                                        Privacy & Policy &emsp; Legal &emsp; Contact Us
                                                    </Typography>
                                                </div>


                                            </div>
                                        </Paper>

                                    </Box>
                                </Grid>
                            </Box>
                          
                            <Box ml={windowSize[0] / 15} mb={windowSize[1] <= 600 ? "40px" : windowSize[1] / 500} mt={windowSize[1] <= 600 ? "1px" : windowSize[1] / 500} sx={{ display: { xs: 'none', md: 'block', lg: 'block', border: "1px solid #ffffff" }, }}>
                                <div style={{ height: windowSize[1] / 10 * 5, width: windowSize[0] / 10 * 3, }}>
                                    <main style={{
                                        height: "100%",
                                        width: "100%",

                                    }}>

                                        <Routes>
                                            {PublicRouteConfig.map(({ name, path, element }) => (
                                                <Route key={name} path={path} element={element} />
                                            ))}
                                           
                                        </Routes>

                                    </main>
                                </div>
                            </Box>
                        </Grid>
                    </Grid> */}
                </ThemeProvider>
            </ColorModeContext.Provider >
        </>
    );
};


