/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Grid, Stack } from "@mui/material"
import { useDispatch } from 'react-redux';
import { setchatBot } from "../../../redux/slices/navSlice"
import { setNotificationClick } from '../../../redux/slices/ChatGptSlice'
import '../layout.css'
import BookMarkList from '../../modules/ChatGpt/BookMarkList';
import HistoryList from '../../modules/ChatGpt/HistoryList'
import SidebarLayout from "./SidebarLayout.js"

function ChatBotSidebar() {
    const locationPath = window.location.pathname

    const dispatch = useDispatch();
    useEffect(() => {


        if (!locationPath.includes('/chatgpt') && !locationPath.includes('/kairosbot')) {
            dispatch(setchatBot(false));
            dispatch(setNotificationClick(false))
        }

        // eslint-disable-next-line
    }, [locationPath]);



    return (
        <>
            <Grid container sx={{ rowGap: 2, bgcolor: "#313034", overflowY: "none" }} pt={1} width="100%" height="100%" direction="column" alignItems="center" justifyContent="space-between">


                {/** TODO implement historylist within SidebarLayout */}
                {(locationPath.includes('/chatgpt/history') || locationPath.includes('/kairosbot/history')) ?
                    <Stack spacing={0.1} mt={3} direction="column" sx={{ maxHeight: "80vh", overflowY: "auto" }}>
                        <HistoryList />
                    </Stack>
                    :
                    <SidebarLayout />
                }
            </Grid>
        </>
    )
}

export default ChatBotSidebar
