export const FormModal = {
    formField: {
        vendorId: {
            name: 'vendorId',
            label: 'Vendor Id *',

            requiredErrorMsg: 'Vendor Id is required'
        },
        vendorName: {
            name: 'vendorName',
            placeholder: 'Enter vendor name',
            label: 'Vendor name *',
            requiredErrorMsg: 'Vendor name is required'
        },
        vendorCode: {
            name: 'vendorCode',
            label: 'Vendor Code *',
            placeholder: 'Enter vendor code',
            requiredErrorMsg: 'Vendor Code is required'
        },
        vendorTypeId: {
            name: 'vendorTypeId',
            placeholder: 'Choose vendor type',
            label: 'Vendor Type *',
            requiredErrorMsg: 'Vendor Type is required'
        },
        vendorSubtypeId: {
            name: 'vendorSubtypeId',
            label: 'Vendor Sub Type *',
            placeholder: 'Select vendor sub type',
            requiredErrorMsg: 'Vendor Subtype is required'
        },
        businessIdentityNo: {
            name: 'businessIdentityNo',
            placeholder: 'Enter identity number',
            label: 'Business Identity Number *',
            requiredErrorMsg: 'Business Identity No is required'
        },
        taxId: {
            name: 'taxId',
            placeholder: 'Enter tax id',
            label: 'Tax Id *',
            requiredErrorMsg: 'Tax Id is required',

        },

        takeRate: {
            name: 'takeRate',
            label: 'Take Rate *',
            placeholder: 'Enter tax rate',
            requiredErrorMsg: 'Take Rate is required'
        },
        takeRateParameterId: {
            name: 'takeRateParameterId',
            requiredErrorMsg: 'Take rate type is required'
        },
        emailId: {
            name: 'emailId',
            label: 'Email Id *',
            placeholder: 'Enter email id',
            requiredErrorMsg: 'Email ID is required'
        },

        keyPocPrefix: {
            name: 'keyPocPrefix',

            requiredErrorMsg: 'Key Point Of Contact Prefix is required'
        },
        keyPointOfContact: {
            name: 'keyPointOfContact',
            label: 'Key Point Of Contact *',
            placeholder: 'Enter  name',
            requiredErrorMsg: 'Key Point Of Contact is required'
        },
        contactDetails: {
            name: 'contactDetails',
            label: 'Contact Number *',
            placeholder: 'Contact number',
            requiredErrorMsg: 'Contact Number is required',

        },
        countryPrifixId: {
            name: 'countryPrifixId',
            requiredErrorMsg: 'Country code is required'
        },

        registeredAddress: {
            name: 'registeredAddress',
            label: 'Registered Address *',
            placeholder: 'Enter address',
            requiredErrorMsg: 'Registered Address is required',

        },
        city: {
            name: 'city',
            label: 'City',
            placeholder: 'Select city',
            requiredErrorMsg: 'City is required',

        },
        state: {
            name: 'state',
            label: 'State',
            placeholder: 'Select state',
            requiredErrorMsg: 'State is required',

        },
        country: {
            name: 'country',
            label: 'Country *',
            placeholder: 'Select country',
            requiredErrorMsg: 'Country is required',

        },
        zip: {
            name: 'zip',
            label: 'Zip *',
            placeholder: 'Enter zip code',
            requiredErrorMsg: 'Zip code is required',

        },

    }
};







