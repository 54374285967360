
import { Axios1 } from "./config";// eslint-disable-next-line


export const getAUMLearderboardData = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-admin-dashboard-datas-aum`, passingValue)

    return resultService;




}
export const getRevenueLearderboardData = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-admin-dashboard-datas-revenue`, passingValue)

    return resultService;




}
export const getProspectPaginationsData = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-admin-dashboard-datas-prospect-networth`, passingValue)

    return resultService;




}
export const getClientsPaginationsData = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-admin-dashboard-datas-client-networth`, passingValue)

    return resultService;

}
export const getClientswiseAUMPaginationsData = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-dashboard-ttm-invested-wealth-aum`, passingValue)

    return resultService;

}
export const getClientswiseInvestedPaginationsData = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-dashboard-ttm-invested-wealth`, passingValue)

    return resultService;

}
export const getClientsAssestAllocations = async (passingValue) => {


    var resultService = await Axios1
        .post(`/api/v1/productservice/get-dashboard-aggregate-asset-allocation`, passingValue)

    return resultService;

}

export const getClientGeoGraphicalAllocations = async (passingValue) => {

    var resultService = await Axios1
        .post(`/api/v1/productservice/get-dashboard-client-wise-aggregate-geographical-allocation`, passingValue)

    return resultService;
}

export const getIndividualTaskPaginations = async (passingValue) => {

    var resultService = await Axios1
        .post(`/api/v1/productservice/get-admin-dashboard-task`, passingValue)

    return resultService;
}

// 