/* eslint-disable no-unused-vars */
import React from 'react'
import { Typography, Grid, Paper, Divider, Button, Modal, Box, useMediaQuery} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import './hniDashboard.css'
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

import { ReactComponent as ContactIcon } from '../../../../theme/images/contactIcon.svg';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useDispatch, useSelector } from 'react-redux';
import { setText, setButton, setchatBot, setChatWindowShow, setTargetValue } from "../../../../redux/slices/navSlice"
import ClearIcon from '@mui/icons-material/Clear';
import FAQSCard from '../../../common/Grid/FAQSCard'
import EnquiryModel from './enquiryModel'
import ProductsCard from '../../CatalogPortfolioSubsciptions/ProductsCard'
import ServiceCards from "../../CatalogPortfolioSubsciptions/ServiceCards"
import Carousel from "react-elastic-carousel";
import { toast } from 'react-toastify';
import '../../../../theme/styles/carosuelstyles.css'
import BannerCard from "./BannerRM"
import { hniAddEnquiry } from '../../../../services'
import PurpleCard from "../../../../theme/images/PurpleBG.png"

const userChecks = JSON.parse(window.localStorage.getItem("user"))
const isRmChecks = userChecks?.userTypeId?.description === "Entity RM" ? true : false
const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1120, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1250, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5, itemsToScroll: 1 },
    { width: 1750, itemsToShow: 5, itemsToScroll: 1 },
];
const breakPointsBanner = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
];
const assistanceButtons = [
    {
        index: 26,
        text: "FAQs",
        path: "/enquiry"
    },
    {
        index: 27,
        text: isRmChecks ? "Chat" : "Chat with RM",
        path: "/chatqueries"
    },
    {
        index: 28,
        text: "Ask Gaia",
        path: "/chatgpt"
    },

]
const marks = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
];

function HniDashboardSectionTwo({ loader, dashboardListsequencetwo, dashboardListsequencethree, dashboardListsequencefour, cardList, User, handleServicePageChange, dashboardServiceList, dashboardList, page, rowsPerPage, handlePageChange, totalCount }) {


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#5021C6',
        textAlign: 'center',
        height: '100%',
        color: '#FFF',
        padding: '27px 0',
        borderRadius: '8px'
    }));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [enquiryModelOpen, setEnquiryModelOpen] = React.useState(false)
    const [helpOpen, setHelpOpen] = React.useState(false);
    const handleHelpClose = () => setHelpOpen(false);
    const handleHelpOpen = () => setHelpOpen(true);
    const handleEnquiryClose = () => setEnquiryModelOpen(false);
    const handleEnquiryOpen = () => setEnquiryModelOpen(true);
    const user = JSON.parse(localStorage.getItem('user'))
    // const hniUser = user?.userTypeId;
    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member") ? true : false;
    const members = dashboardListsequencefour?.manageYourFamilyMember;
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const targetValue = useSelector((state) => state.nav.targetValue)
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    // const isMEdiumOnly = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const isMorethanLG = useMediaQuery('(min-width:1201px) and (max-width:2600px)');
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));


    let cols // Default number of columns
    const [faqModelData, setFaqModelData] = React.useState()
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);
    const [description, setDescription] = React.useState()

    const carouselRefL = React.useRef();
    const carouselRef = React.useRef();

    const slidePrev = () => {
        carouselRef.current.slidePrev();
    };

    const slideNext = () => {
        carouselRef.current.slideNext();
    };
    const slidePrevL = () => {
        carouselRefL.current.slidePrev();
    };

    const slideNextL = () => {
        carouselRefL.current.slideNext();
    };

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        //dispatch(setText("Custom Header"))
        window.addEventListener('resize', handleWindowResize);
        dispatch(setTargetValue(''))


        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });// eslint-disable-next-line 
    const myArrow = () => {
        return <></>;
    };
    const navigationCorrespondingTab = async (index) => {
        navigate("/myPortfolio", { state: { setTab: index, lifestylePortfolio: "lifestylePortfolio" } })
    }
    const navigationCorrespondingFinancialTab = async (index) => {
        navigate("/myPortfolio", { state: { setTab: index, financialPortfolio: "financialPortfolio" } })
    }
    const handleClick = (e, memberId) => {
        if (e.detail === 1) {
            navigate("/hniprofile", { state: { memberid: memberId } })
        }
    }
    if (isLargeScreen) {
        cols = 4; // Number of columns for large screens
    } else if (isMediumScreen) {
        cols = 4; // Number of columns for medium screens
    } else if (isSmallScreen) {
        cols = 2; // Number of columns for small screens
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        padding: "20px 16px 30px 16px",
    };
    const firstName = User.firstName;
    const lastName = User.lastName;
    const name = firstName + " " + lastName
    console.log(dashboardList?.survey?.totalPoint, 'totalPoint')
    const handleFaqs = (button) => {
        if (button.path === "/chatgpt") {

            dispatch(setchatBot(true))
            dispatch(setTargetValue('ChatGpt'))
            navigate(button.path)

        }
        else if (button.text === "Chat with RM" || button.text === "Chat") {
            dispatch(setButton(button))

            if (!isHni) {
                dispatch(setText(button.text))
            }
            if (isHni) {
                dispatch(setChatWindowShow(true))
            }
            else {
                navigate(button.path)
            }

        }
        // else if (button.text === 'Kairos Bot') {
        //     dispatch(setchatBot(true))
        //     dispatch(setTargetValue('llm'))
        //     navigate(button.path)

        // }
        // else if( targetValue ==='llm'){
        //    if(button.path === "/chatgpt/history"){
        //     dispatch(setchatBot(true))
        //     dispatch(setTargetValue('llm'))
        //     navigate(button.path)
        //    }
        // }
        else if (targetValue === 'ChatGpt') {
            if (button.path === "/chatgpt/history") {
                dispatch(setchatBot(true))
                dispatch(setTargetValue('ChatGpt'))
                navigate(button.path)
            }
        }

        else {
            console.log(button, "buttonnnnnnnn")
            dispatch(setButton(button))

            dispatch(setText(button.text))

            dispatch(setchatBot(false))
            navigate(button.path)

        }
        // handleSideClose()
        // if (button.path === "/chatgpt" || button.path === "/chatgpt/history") {
        //   dispatch(setchatBot(true))
        //   navigate(button.path)
        // }
        // else if (button.text === "Chat with RM") {
        //   dispatch(setChatWindowShow(true))
        // }
        // else {

        //   dispatch(setchatBot(false))
        //   navigate(button.path)
        // }
        // handleSideClose()

    }

    const handleSubmit = async () => {
        const req = {
            loginUserId: user.userId,
            enquiry: description,
            subject: faqModelData?.id
        }
        const res = await hniAddEnquiry(req)
        if (res?.data?.status) {
            handleEnquiryClose()
            toast.success(res?.data?.statusMessage)

        }
    }

    const isXL = useMediaQuery("(min-width: 2000px) and (max-width: 4000px)");

    return (
        <div >
            <Grid container sx={{
                display: { xs: 'flex', lg: 'flex' },
                justifyContent: { xs: 'center', lg: 'start' }
            }}>
                <Grid item >
                    {dashboardListsequencethree && dashboardListsequencethree?.cmsRecommendedFinancialData && <Typography variant='h6'>Top {dashboardListsequencethree?.cmsRecommendedFinancialData[0]?.category[0].toLowerCase() + dashboardListsequencethree?.cmsRecommendedFinancialData[0]?.category.slice(1).toLowerCase()}  recommended for you</Typography>}
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: 'black', marginBottom: "10px" }} />
            <>

                {isMorethanLG &&
                    <Carousel breakPoints={breakPointsBanner} renderArrow={myArrow} renderPagination={({ pages, activePage, onClick }) => {
                        return <></>;
                    }} >

                        {dashboardListsequencethree && dashboardListsequencethree?.cmsRecommendedFinancialData && dashboardListsequencethree?.cmsRecommendedFinancialData[0]?.cmsRecommended.map((item) => (

                            <BannerCard imagesPath={item?.productServicePhotoUrl} TitleBanner={item?.bannerTitle + " content"} id={item?.id} productorService={"product"} />

                        ))}
                    </Carousel>}
                {isMorethanLG && <><br /><br /></>}

                <Carousel //outerSpacing={windowSize[0] <= 900 ? 40 : 0}
                    showEmptySlots
                    ref={carouselRef}
                    breakPoints={breakPoints}
                    renderArrow={myArrow}//windowSize[0] <= 900 ? myArrow : dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.length > 0 ? null : myArrow}
                    renderPagination={({ pages, activePage, onClick }) => {
                        return <></>;
                    }}
                >


                    {dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.map((item) => (

                        item.productServiceTypeId.description === 'FINANCIAL SERVICES' ? <ServiceCards key={item?.productServiceId} data={item} url={"service"} /> :
                            <ProductsCard key={item?.productServiceId} data={item} url={"product"} />
                    ))}


                </Carousel>
                {dashboardListsequencethree?.recommendedProducts?.recommendedProducts && <Grid container justifyContent={"center"} flexDirection={"row"} spacing={0} mt={"10px"}>
                    <Grid item>
                        <ArrowCircleLeftRoundedIcon onClick={slidePrev} color={"disabled"} className='rec rec-arrow' style={{ fontSize: 10, cursor: "pointer" }}></ArrowCircleLeftRoundedIcon>

                    </Grid>
                    <Grid item>
                        <ArrowCircleRightRoundedIcon onClick={slideNext} className='rec rec-arrow' style={{ color: "disabled", fontSize: 10, cursor: "pointer" }}></ArrowCircleRightRoundedIcon>

                    </Grid>


                </Grid>}

                {!dashboardListsequencethree?.recommendedProducts?.recommendedProducts && <>
                    <Grid item xs={12}>
                        <Typography>No records found</Typography>
                    </Grid></>}
            </>
            <br />
            <Grid container sx={{
                display: { xs: 'flex', lg: 'flex' },
                justifyContent: { xs: 'center', lg: 'start' }
            }}>
                <Grid item >
                    {dashboardListsequencethree && dashboardListsequencethree?.cmsRecommendedLifestyleData && <Typography variant='h6'>Top {dashboardListsequencethree?.cmsRecommendedLifestyleData[0]?.category[0].toLowerCase() + dashboardListsequencethree?.cmsRecommendedLifestyleData[0]?.category.slice(1).toLowerCase()}  recommended for you</Typography>}
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: 'black', marginBottom: "10px" }} />
            <>


                {isMorethanLG && <Carousel breakPoints={breakPointsBanner} renderArrow={myArrow} renderPagination={({ pages, activePage, onClick }) => {
                    return <></>;
                }} >

                    {dashboardListsequencethree && dashboardListsequencethree?.cmsRecommendedLifestyleData && dashboardListsequencethree?.cmsRecommendedLifestyleData[0]?.cmsRecommended.map((item) => (

                        <BannerCard imagesPath={item?.productServicePhotoUrl} TitleBanner={item?.bannerTitle + " content"} id={item?.id} productorService={"service"} />

                    ))}
                </Carousel>}
                {isMorethanLG && <><br /><br /></>}

                <Carousel //outerSpacing={windowSize[0] <= 900 ? 40 : 0}
                    showEmptySlots
                    ref={carouselRefL}

                    breakPoints={breakPoints}
                    renderArrow={myArrow}//windowSize[0] <= 900 ? myArrow : dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.length > 0 ? null : myArrow}
                    renderPagination={({ pages, activePage, onClick }) => {
                        return <></>;
                    }}
                >

                    {dashboardListsequencethree?.recommendedServices?.recommendedServices?.map((item) => (
                        item.productServiceTypeId.description === 'LIFESTYLE SERVICES' ? <ServiceCards key={item?.productServiceId} data={item} url={"service"} /> : <ProductsCard key={item?.productServiceId} data={item} url={"service"} />
                    ))}


                </Carousel>
                {dashboardListsequencethree?.recommendedServices?.recommendedServices && <Grid container justifyContent={"center"} flexDirection={"row"} spacing={0} mt={"10px"}>
                    <Grid item>
                        <ArrowCircleLeftRoundedIcon onClick={slidePrevL} color={"disabled"} className='rec rec-arrow' style={{ fontSize: 10, cursor: "pointer" }}></ArrowCircleLeftRoundedIcon>

                    </Grid>
                    <Grid item>
                        <ArrowCircleRightRoundedIcon onClick={slideNextL} className='rec rec-arrow' style={{ color: "disabled", fontSize: 10, cursor: "pointer" }}></ArrowCircleRightRoundedIcon>

                    </Grid>


                </Grid>}
                <br />

                {!dashboardListsequencethree?.recommendedServices?.recommendedServices && <>
                    <Grid item xs={12}>
                        <Typography>No records found</Typography>
                    </Grid></>}
            </>


            {user?.userTypeId?.description === "Client Head" && <Grid container sx={{ border: '1px solid #B1B1B1', paddingBottom: '10px' }}>
                <Grid item sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' }
                }}>
                    <Typography variant='h6' sx={{ padding: '10px' }}>Manage your family member</Typography>
                </Grid>
                <Grid container columnSpacing={2} rowSpacing={2} sx={{ padding: '0px 10px 10px 10px',marginLeft: isXL ? "4%" : 0 }}>
                    {members?.map((val, index) => {
                        return (
                            <Grid key={val?.memberId} sx={{ cursor: "pointer" }} onClick={(e) => handleClick(e, val?.memberId)} item lg={3} xs={6} >
                                <Item sx={{backgroundImage: `url(${PurpleCard})`,backgroundRepeat: "no-repeat",backgroundSize: "cover",height: "80px",maxWidth: "280px"}}>
                                    <Typography><ContactIcon /><span style={{ paddingRight: '10px', paddingLeft: '10px' }}>{val.memberName}</span></Typography>
                                </Item>
                            </Grid>
                        )
                    })}
                    <br />
                    {(members?.length === 0 || members === null) && (
                        <Grid item xs={12}>
                            <Typography>No records found</Typography>
                        </Grid>)}
                </Grid>
            </Grid>}
            <br />
            <Grid container sx={{
                display: { xs: 'flex', lg: 'flex' },
                justifyContent: { xs: 'center', lg: 'start' }
            }}>
                <Grid item>
                    <Typography variant='h6'>Manage your advisory suite</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: 'black' }} />
            <br />
            <FAQSCard setFaqModelData={setFaqModelData} faqsCard={dashboardListsequencefour?.manageYourClientAdvisorySuite} handleOpen={handleEnquiryOpen} handleClose={handleEnquiryClose} />
            <br />
            <Grid container sx={{
                display: { xs: 'flex', lg: 'flex' },
                justifyContent: { xs: 'center', lg: 'start' }
            }}>
                <Grid item>
                    <Typography variant='h6'>Manage your lifestyle portfolio</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: 'black' }} />
            <ImageList cols={cols} sx={{ width: "100%", height: "100%", columnGap: '14px !important', }}  >
                {dashboardListsequencethree?.lifestylePortfolio?.map((item, index) => (
                    <ImageListItem key={item.img} onClick={() => { navigationCorrespondingTab(index) }} sx={{ cursor: "pointer" }}>
                        <img
                            src={`${item.imagePath}`}
                            srcSet={`${item.imagePath}`}
                            alt={item.title}
                            loading="lazy"
                            style={{
                                borderRadius: '10px',
                                height: '147px',
                                backgroundSize: "cover"
                            }}
                        />
                        <ImageListItemBar
                            title={"Manage " + (item.description).toLowerCase() + " portfolio "}
                            // title={item.title}
                            position="bottom"
                            sx={{
                                // "& .MuiImageListItemBar-positionBottom": {
                                //   marginBottom: "20%",
                                // top: "10%",
                                // width: '90%',
                                bottom: "20%",
                                alignItems: "center",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column"

                                // }
                            }}


                        />
                    </ImageListItem>
                ))}
            </ImageList>
            {
                (dashboardListsequencethree?.lifestylePortfolio?.length === 0 || dashboardListsequencethree?.lifestylePortfolio === null) && (
                    <Grid item xs={12}>
                        <Typography>No records found</Typography>
                    </Grid>)
            }
            <Grid container sx={{
                display: { xs: 'flex', lg: 'flex' },
                justifyContent: { xs: 'center', lg: 'start' }
            }}>
                <Grid item>
                    <Typography variant='h6'>Manage your financial portfolio</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: 'black' }} />

            <br />
            <Grid container columnSpacing={2} rowSpacing={2} sx={{ marginBottom: '10px',marginLeft: isXL ? "1.5%":"-10px" }}>
                {dashboardListsequencethree?.financialPortfolio?.map((val, index) => {
                    return <Grid key={val.id} item lg={2.4} xs={6} onClick={() => { navigationCorrespondingFinancialTab(index) }} sx={{ cursor: "pointer", justifyItems: "center", alignItems: "center", }}>
                        <Grid sx={{ background:  `url(${PurpleCard})`, height: "80px", justifyItems: "center", alignItems: "center", justifyContent: "center", display: "flex", justifySelf: "center", borderRadius: "9px",backgroundRepeat: "no-repeat",backgroundSize: "cover",maxWidth: "280px" }}>
                            <Typography sx={{
                                textAlign: 'center',
                                justifyItems: "center", alignItems: "center", justifyContent: "center", display: "flex", justifySelf: "center",
                                height: '100%',
                                color: '#FFF',
                            }}>{val?.description}</Typography>
                        </Grid>

                    </Grid>

                })}
                {(dashboardListsequencethree?.financialPortfolio?.length === 0 || dashboardListsequencethree?.financialPortfolio === null) && (
                    <Grid item xs={12}>
                        <Typography>No records found</Typography>
                    </Grid>)}
            </Grid>
            <br />
            <Grid container sx={{ background: "#E2E2E2", padding: "13px 15px", alignItems: "center", marginTop: "15px", marginBottom: "10px", borderRadius: "6px", display: { xs: "none", sm: "flex" } }}>
                <Grid item lg={4} sx={{ fontSize: "16px" }}>Need Help?</Grid>
                <Grid item lg={8} sx={{ display: "flex", justifyContent: "space-around" }}>
                    {assistanceButtons?.map(button => {
                        return (
                            <Grid key={assistanceButtons?.index} item sx={{ width: "200px", borderRadius: "6px", background: "#000000", color: "white", padding: "5px", cursor: "pointer" }}>
                                <Typography sx={{ textAlign: "center" }} item pt={0} style={{ fontSize: "12px" }} onClick={() => handleFaqs(button)}>{button.text}</Typography>
                            </Grid>
                        )
                    })
                    }

                </Grid>

            </Grid>
            <Grid container sx={{ paddingLeft: "15px", marginTop: "20px", display: { sm: "none" } }}>
                <Button variant="contained" sx={{ fontSize: "14px", background: "#171B23", color: "white", borderRadius: "20px", width: "100%", padding: "10px 0px", marginBottom: "30px" }} onClick={handleHelpOpen}>Need Help?</Button>
            </Grid>
            <Modal
                open={helpOpen}
                onClose={handleHelpClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ backgroundColor: "none" }}
            >
                <Box sx={style}>
                    <Grid sx={{ marginBottom: "14px", display: "flex", justifyContent: "space-around" }}>
                        <Typography sx={{ fontSize: "14px" }}>
                            Need Help?
                        </Typography>
                        <ClearIcon onClick={handleHelpClose} />
                    </Grid>
                    <Grid container sx={{ background: "#E2E2E2", display: "flex", alignItems: "center", flexDirection: "column", padding: "10px 10px" }}>
                        {assistanceButtons?.map(button => {
                            return (
                                <Grid key={assistanceButtons.index} item sx={{ width: "90%", borderRadius: "6px", background: "#000000", color: "white", padding: "10px 5px", cursor: "pointer", margin: "10px 0px" }}>
                                    <Typography sx={{ textAlign: "center" }} item pt={0} style={{ fontSize: "12px" }} onClick={() => { handleFaqs(button); handleHelpClose() }}>{button.text}</Typography>
                                </Grid>
                            )
                        })
                        }

                    </Grid>
                </Box>
            </Modal>
            <EnquiryModel setDescription={setDescription} handleSubmit={handleSubmit} addEnquiry={false} faqModelData={faqModelData} enquiryModelOpen={enquiryModelOpen} handleClose={handleEnquiryClose} />
        </div >

    )
}
export default HniDashboardSectionTwo;