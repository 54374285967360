import React from 'react'
import { FormControl, MenuItem, Select, Typography, Grid } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { setcollectCurrency } from '../../../redux/slices/globalCurrencySlice';

const GlobalCurrency = () => {

  const currecyValue = useSelector(state => state.globalCurrency.currency);
  const userObject = JSON.parse(localStorage.getItem("user"))

  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(setcollectCurrency(e.target.value))

  }



  return (
    <div>
      <Grid sx={{
        display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
      }}>
        <div style={{
          font: 'normal normal 900 12px/60px Mona Sans',
          display: "flex", alignItems: "center", border: "1px solid #DDDDDD", backgroundColor: "#F0F0F0", borderRadius: "4px", height: "33px"
        }}>

          <Typography sx={{ paddingLeft: '7px' }}>Currency</Typography>
          <FormControl
            sx={{ m: 1, minWidth: 90, }}
            size="small"
          >
            {/* <InputLabel
            sx={{ color: "white !important", fontFamily: "Mona Sans" }}
          >USD-$</InputLabel> */}
            <Select
              IconComponent={KeyboardArrowDownIcon}
              id="country-simple-select"
              onChange={handleChange}
              value={currecyValue}
              sx={{
                color: "white ! important",
                fontFamily: "Mona Sans",
                width: "100%",
                height: "24px",
                backgroundColor: "black",
                borderRadius: "8px",
                font: 'normal normal 900 12px/60px Mona Sans',
                "& .MuiSelect-icon": {
                  color: "#FFFFFF"
                }
              }}
            >
              {
                userObject?.g7Countries?.map((item, index) => {
                  return <MenuItem value={item.id} key={item.id}>{item.currencyCode} - {item.currencySymbol}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </div>
      </Grid>

      <Grid sx={{
        display: { xs: 'block', sm: 'none', md: 'none' }

      }}>
        <FormControl
          sx={{ m: 1, minWidth: 90, }}
          size="small"
        >
          <Select
            IconComponent={KeyboardArrowDownIcon}
            onChange={handleChange}
            id="country-simple-select-versions"
            value={currecyValue}
            sx={{
              color: "black ! important",
              fontFamily: "Mona Sans",
              width: "100%",
              height: "24px",
              backgroundColor: "white",
              borderRadius: "8px",
              font: 'normal normal 900 12px/60px Mona Sans',
              "& .MuiSelect-icon": {
                color: "black"
              }
            }}
          >
            {
              userObject?.g7Countries?.map((item, index) => {
                return <MenuItem value={item.id} key={item.id}>{item.currencyCode} - {item.currencySymbol}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Grid>
    </div>
  )
}

export default GlobalCurrency