/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import {  Box, useMediaQuery,} from '@mui/material'
import Loader from "../../../common/Loader/Loader";
import LeaderBoard from "./LeaderBoard";
import RMDashboardSectionThree from "./RMDashboardSectionThree"
import { ReactComponent as BidIcon } from '../../../../theme/images/Bid icon.svg'
import { getDashboardDataForRM, getRecProductsForRM, getRecServicesForRM } from '../../../../services';
import { getAUMLearderboardData, getRevenueLearderboardData } from '../../../../services/DashboardAPI';
import { formatNumber } from '../../../../constant/DollorFormats';
import { useSelector } from 'react-redux';
import RMDashboardSectionOne from "./RMDashboardSectionOne"
const columnsHniLeaderBoard = [
    { field: 'customerName', headerName: 'Customer Name', width: 100, sortable: false, flex: 1 },
    { field: 'asset', headerName: 'Asset', width: 67, sortable: false, },
];
const columnsProductLeaderBoard = [
    { field: 'productName', headerName: 'Product Name', width: 100, sortable: false, flex: 1 },
    { field: 'revenue', headerName: 'Revenue', width: 67, sortable: false },
]
const columnsServiceLeaderBoard = [
    { field: 'serviceName', headerName: 'Service Name', width: 100, sortable: false, flex: 1 },
    { field: 'revenue', headerName: 'Revenue', width: 67, sortable: false, }
]
// eslint-disable-next-line
const columnsMarketData = [
    { field: 'productName', headerName: 'Top Products', width: 100 },
    { field: 'revenue', headerName: 'Revenue', width: 100 },
];
const column4 = [
    {
        field: 'name',
        headerName: 'News',
        width: "100%",
        renderCell: (params) => (
            <>

                {console.log(params, 'params')}
                <a href={params?.row?.url} target='_' style={{
                    fontWeight: '500', color: 'black', textDecoration: 'none', whiteSpace: "nowrap",
                    width: "22%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }} title={params?.row?.description}>{params?.row?.name + "-" + params?.row?.description}</a>
                {params?.row?.id <= 3 && <>&nbsp;&nbsp;<BidIcon /></>}
            </>
        )

    }
]// eslint-disable-next-line
const prodArray = [
    {
        endDate: "23-02-2021",
        productServiceName: "Test1",
        primaryCategoryId: {
            description: "ART"
        },
        sellingPrice: "123"
    },
    {
        endDate: "12-01-2022",
        productServiceName: "Test2",
        primaryCategoryId: {
            description: "EQUITES"
        },
        sellingPrice: "123"
    },
    {
        endDate: "13-05-2023",
        productServiceName: "Test3",
        primaryCategoryId: {
            description: "REALESTATE"
        },
        sellingPrice: "123"
    },
    {
        endDate: "13-05-2023",
        productServiceName: "Test3",
        primaryCategoryId: {
            description: "REALESTATE"
        },
        sellingPrice: "123"
    },
]

const LazyLoadingRM = () => {
    const [sections, setSections] = useState([1]);
    const loaderRef = useRef(null);
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);
    const user = JSON.parse(localStorage.getItem('user'));// eslint-disable-next-line
    const [filteropen, setFilteropen] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [cardList, setCardList] = useState([]);// eslint-disable-next-line  
    const [aggAssetAllocation, setAggAssetAllocation] = useState({});// eslint-disable-next-line
    const [aggSectorAllocation, seAggSectorAllocation] = useState({});// eslint-disable-next-line
    const [aggGeoAllocation, setAggGeoAllocation] = useState({});
    const [aumleaderboard, setAumleaderboard] = useState([])
    const [revenueleaderboard, setRevenueleaderboard] = useState([]);
    const [aumpage, setAumpage] = useState(1);
    const [revenuepage, setRevenuepage] = useState(1);// eslint-disable-next-line
    const [ttmAggAum, setTtmAggAum] = useState({});// eslint-disable-next-line 
    const [ttmInvWealth, setTtmInvWealth] = useState({});
    const [totalResponse, setTotalResponse] = useState({});
    const [hnileaderboard, setHnileaderboard] = useState([]);
    const [productleaderboard, setProductleaderboard] = useState([]);
    const [serviceleaderboard, setServiceleaderboard] = useState([]);
    const [marketNews, setMarketNews] = useState([]);
    const [recProducts, setRecProducts] = useState([]);
    const [recServices, setRecServices] = useState([]);
    const [lifportfolio, setLifportfolio] = useState([]);
    const [finportfolio, setFinportfolio] = useState([]);
    const [hniList, setHniList] = useState([]);
    const [modal, setModal] = useState(false);
    const [productTotalPages, setProductTotalPages] = useState(1);
    const [serviceTotalPages, setServiceTotalPages] = useState(1);
    const [prodpage, setProdpage] = useState(0); // prodpage={prodpage} servpage={servpage}
    const [servpage, setServpage] = useState(0);
    const [portfolioIndex, setPortfolioIndex] = useState(0);
    const [portfolioType, setPortfolioType] = useState("");
    const [advsuite, setAdvsuite] = useState([]);
    const [finProdServLoader, setFinProdServLoader] = useState(false);
    const [lifProdServLoader, setLifProdServLoader] = useState(false);
    const navigate = useNavigate();
    const isMorethanLG = useMediaQuery('(min-width:1201px) and (max-width:1340px)');
    const isMorethanSM = useMediaQuery('(min-width:600px) and (max-width:900px)');


    useEffect(() => {
        const obj = {
            "loginUserId": user?.userId,
            "pageNo": 0,
            "pageSize": 10,
        }
        // setLoading(true);
        getDashboardDataForRM({ ...obj, "sequence": 1 }).then(seqresponse1 => {
            setLoading(true);
            if (seqresponse1?.data?.status) {
                console.log("seqresponse1?.data?.body", seqresponse1?.data?.body)
                // let cardValues = {
                //     "Total AUM": `US $${formatNumber(seqresponse1?.data?.body?.totalAUM)}`,
                //     "Total Invested Wealth": `US $${formatNumber(seqresponse1?.data?.body?.investedWealth)}`,
                //     "Total Client Net Worth": `US $${formatNumber(seqresponse1?.data?.body?.totalClientNetWorth)}`,
                //     "Cash & Equivalent": `US $${formatNumber(seqresponse1?.data?.body?.cashAndEq)}`,
                //     "Total Debt": `US $${formatNumber(seqresponse1?.data?.body?.totalDebt)}`,
                //     "TTM Revenue": `US $${formatNumber(seqresponse1?.data?.body?.ttmRevenue)}`
                // }
                setCardList(seqresponse1?.data?.body?.cardData)
                setAggAssetAllocation(seqresponse1?.data?.body?.aggregateAssetAllocation)
                seAggSectorAllocation(seqresponse1?.data?.body?.sectorAssetAllocation)
                setAggGeoAllocation(seqresponse1?.data?.body?.geographicalAssetAllocation)
                setLoading(false);
            }
        })
        getDashboardDataForRM({ ...obj, "sequence": 2 }).then(seqresponse2 => {

            if (seqresponse2?.data?.status) {
                console.log("seqresponse2?.data?.body", seqresponse2?.data?.body)
                setAumleaderboard(seqresponse2?.data?.body?.aumLeaderboard)
                setRevenueleaderboard(seqresponse2?.data?.body?.revenueLeaderboard)
                setTotalResponse(seqresponse2?.data?.body)
                setTtmAggAum(seqresponse2?.data?.body?.ttmAggregateAumBarChart)
                setTtmInvWealth(seqresponse2?.data?.body?.ttmInvestedWealthBarChart)

            }
        })
        getDashboardDataForRM({ ...obj, "sequence": 3 }).then(seqresponse3 => {

            if (seqresponse3?.data?.status) {

                setFinportfolio(seqresponse3?.data?.body?.financialPortfolio)
                const formatValues = seqresponse3?.data?.body?.hniAsset
                formatValues?.forEach(val => {
                    val.asset = formatNumber(val.asset)
                })
                setHnileaderboard(formatValues)
                setHniList(seqresponse3?.data?.body?.hniList)
                setLifportfolio(seqresponse3?.data?.body?.lifestylePortfolio)
                setMarketNews(seqresponse3?.data?.body?.marketNews?.marketNews)
                const formatProductValues = seqresponse3?.data?.body?.productRevenue
                formatProductValues?.forEach(val => {
                    val.revenue = formatNumber(val.revenue)
                })
                setProductleaderboard(formatProductValues)
                const formatServiceValues = seqresponse3?.data?.body?.serviceRevenue
                formatServiceValues?.forEach(val => {
                    val.revenue = formatNumber(val.revenue)
                })
                setServiceleaderboard(formatServiceValues)

            }
        })
        getDashboardDataForRM({ ...obj, "sequence": 4, "pageSize": 4 }).then(seqresponse4 => {

            if (seqresponse4?.data?.status) {

                setAdvsuite(seqresponse4?.data?.body?.manageYourClientAdvisorySuite)
                setRecProducts(seqresponse4?.data?.body?.rmDashboardRecommendedProducts?.rmDashboardRecommendedProducts)
                setProductTotalPages(seqresponse4?.data?.body?.rmDashboardRecommendedProducts?.totalPages)
                setRecServices(seqresponse4?.data?.body?.rmDashboardRecommendedServices?.rmDashboardRecommendedServices)
                setServiceTotalPages(seqresponse4?.data?.body?.rmDashboardRecommendedServices?.totalPages)

            }
        })
        // const productObj = {
        //     "loginUserId": user?.userId,
        //     "pageNo": 0,
        //     "pageSize": 4,
        // }
        // getRecProductsForRM(productObj).then((prodresponse) => {
        //     if (prodresponse?.data?.status) {
        //         setRecProducts(prodresponse?.data?.body?.rmDashboardRecommendedProducts?.rmDashboardRecommendedProducts)
        //         setProductTotalPages(prodresponse?.data?.body?.rmDashboardRecommendedProducts?.totalPages)
        //     }
        // })
        // const serviceObj = {
        //     "loginUserId": user?.userId,
        //     "pageNo": 0,
        //     "pageSize": 4,
        // }
        // getRecServicesForRM(serviceObj).then((servresponse) => {
        //     if (servresponse?.data?.status) {
        //         setRecServices(servresponse?.data?.body?.rmDashboardRecommendedServices?.rmDashboardRecommendedServices)
        //         setServiceTotalPages(servresponse?.data?.body?.rmDashboardRecommendedServices?.totalPages)
        //     }
        // })

        // eslint-disable-next-line
    }, [])


    const loadNextSection = () => {
        if (sections.length < 2) {
            setSections((prevSections) => [...prevSections, prevSections.length + 1]);
        }
    };

    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && target.target === loaderRef.current) {
            // When the loader is in view, load the next section
            loadNextSection();
        }
    };
    const handlePageProdChange = async (e, value) => {
        console.log("CALLED")
        setProdpage(value - 1)
        const productObj = {
            "loginUserId": user?.userId,
            "pageNo": value - 1,
            "pageSize": 4,
        }
        setFinProdServLoader(true)
        await getRecProductsForRM(productObj).then((prodresponse) => {
            if (prodresponse?.data?.status) { //rmDashboardRecommendedProducts
                setRecProducts(prodresponse?.data?.body?.rmDashboardRecommendedProducts?.rmDashboardRecommendedProducts)
                setProductTotalPages(prodresponse?.data?.body?.rmDashboardRecommendedProducts?.totalPages)
                setFinProdServLoader(false)
            }
        })
    }
    const handlePageServChange = async (e, value) => {
        console.log("CALLED")
        setServpage(value - 1)
        const serviceObj = {
            "loginUserId": user?.userId,
            "pageNo": value - 1,
            "pageSize": 4,
        }
        setLifProdServLoader(true)
        await getRecServicesForRM(serviceObj).then((servresponse) => {
            if (servresponse?.data?.status) {
                setRecServices(servresponse?.data?.body?.rmDashboardRecommendedServices?.rmDashboardRecommendedServices)
                setServiceTotalPages(servresponse?.data?.body?.rmDashboardRecommendedServices?.totalPages)
                setLifProdServLoader(false)
            }
        })
    }
    const handleAumPagination = async (e, value) => {
        setAumpage(value);
        let paginateObj = {
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10
        }
        let aumResponse = await getAUMLearderboardData(paginateObj)
        if (aumResponse?.data?.status) {
            setAumleaderboard(aumResponse?.data?.body?.aumLeaderboard)
        }
    }
    //
    const handleRevenuePagination = async (e, value) => {
        setRevenuepage(value);
        let paginateObj = {
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10
        }
        let revenueResponse = await getRevenueLearderboardData(paginateObj);
        if (revenueResponse?.data?.status) {
            setRevenueleaderboard(revenueResponse?.data?.body?.revenueLeaderboard)
        }
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(handleObserver, options);

        // Start observing the loader element
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            // Stop observing when the component unmounts
            if (loaderRef.current) { // eslint-disable-next-line
                observer.unobserve(loaderRef.current);
            }
        };// eslint-disable-next-line
    }, [sections]);

    return (

        <>
            <Box minHeight="100vh" overflow="auto" style={{ padding: '20px', marginBottom: "100px" }}>
                {sections.map((sectionNumber, index) => (
                    <Box key={index} marginBottom={2}>
                        {index === 0 ? (
                            <RMDashboardSectionOne cardList={cardList} aggAssetAllocation={aggAssetAllocation} aggSectorAllocation={aggSectorAllocation} aggGeoAllocation={aggGeoAllocation} aumleaderboard={aumleaderboard} revenueleaderboard={revenueleaderboard} totalResponse={totalResponse} ttmAggAum={ttmAggAum} ttmInvWealth={ttmInvWealth} handleAumPagination={handleAumPagination} handleRevenuePagination={handleRevenuePagination} />
                        ) : (
                            <RMDashboardSectionThree productleaderboard={productleaderboard} serviceleaderboard={serviceleaderboard} marketNews={marketNews} lifportfolio={lifportfolio} finportfolio={finportfolio} hniList={hniList} advsuite={advsuite} hnileaderboard={hnileaderboard} recProducts={recProducts} productTotalPages={productTotalPages} serviceTotalPages={serviceTotalPages} recServices={recServices} handlePageProdChange={handlePageProdChange} handlePageServChange={handlePageServChange} finProdServLoader={finProdServLoader} lifProdServLoader={lifProdServLoader} prodpage={prodpage} servpage={servpage} />
                        )}
                    </Box>
                ))}
                <div ref={loaderRef} id="lazyloadingVisible"></div>
            </Box></>
    );
};




export default LazyLoadingRM;
