import React from 'react'
import { FormControl, Select, MenuItem } from '@mui/material';

function Singleselect({ title, dropdownvalues, selectedvalue, handleSelectChange, readOnly, index }) {
    const handleChangeSelect = (event) => {
        handleSelectChange(index, title, event.target.value)
    }
    return (
        <FormControl>
            <Select
                disabled={readOnly}
                labelId="single-select-label"
                id="single-select-label"
                onChange={handleChangeSelect}
                value={selectedvalue ? selectedvalue : ""}
                sx={{
                    width: { xs: '100%', sm: '100%', md: '80%', lg: '80%' }, height: "38px", fontFamily: "Mona Sans !important", iconFilled: "filled", backgroundColor: "white", borderRadius: "8px",
                    "& input::placeholder": {
                        textOverflow: "ellipsis !important", color: "red !important", opacity: 1,
                        fontFamily: "Mona Sans !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        height: "43px !important", fontFamily: "Mona Sans !important",
                        borderRadius: "8px"
                    },
                    '& .MuiSelect-select .notranslate::after': {
                        content: `"Select ${title}"`,
                        opacity: 1,
                        color: '#BBC5D5',
                        fontFamily: "Mona Sans",
                    },
                    '& .MuiOutlinedInput-input': {
                        fontWeight: 600
                    },
                }}
            >
                <MenuItem value="" selected default disabled><em>Select</em></MenuItem>
                {dropdownvalues?.map((option, index) => (
                    <MenuItem key={option.key} value={title === "Marital status" ? option.description : option.id}>
                        {option.description}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default Singleselect