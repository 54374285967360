import * as React from 'react';// eslint-disable-next-line 
import { Grid, Button, Card, Typography } from "@mui/material";
// import "./ProductsCard.css"
import '../../../theme/styles/ProductsCard.css'
import { useNavigate } from 'react-router-dom';
export default function ServiceCards(props) {// eslint-disable-next-line 

    const navigate = useNavigate();

    const navigateToproducts = async (value) => {

        if (props?.id) {

            navigate("/viewServiceDetails/" + btoa(value), { state: { recommentation: "recommentation", hniId: props?.id } });

        }
        else {
            navigate('/viewServiceDetails/' + btoa(value))
        }
    }

    return (
        <Card variant="outlined" sx={{
            backgroundImage: `url(${props?.data?.productServicePhotoUrl})`,
            "& .MuiCardContent-root": {
                paddingBottom: "0px !important",
                padding: '15px !important'
            },
        }} className='cardForServices'  >

            <Grid item pt={"30px"} sx={{ position: "relative" }}>
                <Grid item className='gridStylesd'>
                    <Grid container flexDirection={"column"} justifyContent={"space-around"} pl={"8px"} >
                        <Grid item sx={{ marginTop: "10px", }}>
                            <Typography className='servicesTypo'>{props?.data?.productServiceName} </Typography>
                        </Grid>
                        <Grid item sx={{ marginTop: "10px" }}>
                            <Typography className='servicesTypo'>{props?.data?.primaryCategoryId?.description} {props?.data?.secondaryCategoryId?.description}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <Grid container flexDirection={"row"} justifyContent={"end"} pl={"2px"} pr={"8px"} pt={"2px"} pb={"2px"}>
                        <Grid item sx={{ marginTop: "8px", }}>
                            <Button className='serviceCardsuttonStyle' onClick={() => navigateToproducts(props?.data?.productServiceId)}>
                                View
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
