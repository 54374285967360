/* eslint-disable no-unused-vars */
import React from 'react'
import { Typography, Grid, Divider, useMediaQuery, Box } from '@mui/material';
import './hniDashboard.css'
import { Link, useNavigate } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import PieChartController from '../DashboardModalController/PieChartController';
import BarChartController from '../DashboardModalController/BarChartController';

import DynamicDashboardCards from "../../../common/Grid/DynamicDashboardCards"
import { useDispatch, useSelector } from 'react-redux';
import { setText, setButton, setchatBot, setChatWindowShow, setTargetValue } from "../../../../redux/slices/navSlice"
import '../../../../theme/styles/carosuelstyles.css'


const marks = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
];

function HniDashboardSectionone({ loader, dashboardListsequencetwo, dashboardListsequencethree, dashboardListsequencefour, cardList, User, handleServicePageChange, dashboardServiceList, dashboardList, page, rowsPerPage, handlePageChange, totalCount }) {



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobileOrTabletBelow800 = useMediaQuery('(max-height: 700px)');
    const [helpOpen, setHelpOpen] = React.useState(false);
    const [enquiryModelOpen, setEnquiryModelOpen] = React.useState(false)
    const handleHelpOpen = () => setHelpOpen(true);
    const handleHelpClose = () => setHelpOpen(false);
    const handleEnquiryOpen = () => setEnquiryModelOpen(true);
    const handleEnquiryClose = () => setEnquiryModelOpen(false);
    const user = JSON.parse(localStorage.getItem('user'))
    // const hniUser = user?.userTypeId;
    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member") ? true : false;
    const targetValue = useSelector((state) => state.nav.targetValue)
    const members = dashboardListsequencefour?.manageYourFamilyMember;
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    // const isMEdiumOnly = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const isMobileOrTablet = useMediaQuery('(max-width: 1279px)');
    const isMorethanLG = useMediaQuery('(min-width:1201px) and (max-width:2600px)');

    let cols // Default number of columns
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);
    const [faqModelData, setFaqModelData] = React.useState()
    const [description, setDescription] = React.useState()

    const carouselRef = React.useRef();
    const carouselRefL = React.useRef();



    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        //dispatch(setText("Custom Header"))
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });// eslint-disable-next-line 

    if (isLargeScreen) {
        cols = 4; // Number of columns for large screens
    } else if (isMediumScreen) {
        cols = 4; // Number of columns for medium screens
    } else if (isSmallScreen) {
        cols = 2; // Number of columns for small screens
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        padding: "20px 16px 30px 16px",
    };
    const firstName = User.firstName;
    const lastName = User.lastName;
    const name = lastName === null ? firstName : firstName + " " + lastName
    console.log(dashboardList?.survey?.totalPoint, 'totalPoint')
    const handleFaqs = (button) => {
        if (button.path === "/chatgpt") {
            dispatch(setchatBot(true))
            dispatch(setTargetValue('ChatGpt'))
            navigate(button.path)

        }
        else if (button.text === "Chat with RM" || button.text === "Chat") {
            dispatch(setButton(button))

            if (!isHni) {
                dispatch(setText(button.text))
            }
            if (isHni) {
                dispatch(setChatWindowShow(true))
            }
            else {
                navigate(button.path)
            }

        }

        else if (targetValue === 'ChatGpt') {
            if (button.path === "/chatgpt/history") {
                dispatch(setchatBot(true))
                dispatch(setTargetValue('ChatGpt'))
                navigate(button.path)
            }
        }

        else {
            console.log(button, "buttonnnnnnnn")
            dispatch(setButton(button))

            dispatch(setText(button.text))

            dispatch(setchatBot(false))
            navigate(button.path)

        }
    }


    return (
        <div >
            <Typography variant="h4" className='welcomeText' sx={{ fontWeight: 600 }}>Welcome {name}</Typography>
            <br />
            <DynamicDashboardCards data={cardList} cardsPerRow={6} />

            <Divider />
            <br />

            {user?.userTypeId?.description === "Client Head" && <><Grid container className='secondContainer'>
                <Grid item >
                    <Typography>Current Investment Approach: <span style={{ color: '#6A3BE0' }}>{dashboardList?.survey?.surveyInference}</span></Typography>
                    <Slider
                        value={dashboardList?.survey?.totalPoint || 0}
                        // onChange={handleChange}
                        marks={marks}
                        step={null}
                        min={10}
                        max={40}
                        sx={{
                            left: '5px',
                            '& .MuiSlider-thumb': {
                                color: '#FFFFFF',
                                width: 8,
                                height: 8,
                                border: '1px solid #5D5D5D'


                            },
                            '& .MuiSlider-track': {
                                color: '#6A3BE0',
                            },
                            '& .MuiSlider-markLabel': {
                                color: '#737373',
                                // '& .MuiSlider-markLabelMark': {
                                //   display: 'none', // Hide the dots in the marks
                                // },


                            },
                            '& .MuiSlider-mark': {
                                color: 'transparent'
                            },
                            '& .MuiSlider-rail': {
                                color: '#E2E0E0', // Non-active color
                            },

                        }}
                    />

                </Grid>
                <Grid item>
                    <Typography>To update financial overview {user?.userTypeId?.description === "Client Head" ? <Link style={{ color: '#7037E7' }} to={'/hniprofile'} state={{ "finacial": true }}>click here</Link> : <span style={{ fontStyle: "italic", textDecoration: "underline", color: "#7037e7" }}>click here</span>}</Typography>
                </Grid>
            </Grid>
                <Divider />
            </>
            }


            {!isMobileOrTablet && <Box sx={{ height: "auto", padding: "2px" }}>
                <ResponsiveLayout yourCurrentPortfolioAssetAllocation={dashboardListsequencetwo?.yourCurrentPortfolioAssetAllocation} isMobileOrTabletBelow800={isMobileOrTabletBelow800} geographicalAssetAllocation={dashboardListsequencetwo?.geographicalAssetAllocation} sectorAssetAllocation={dashboardListsequencetwo?.sectorAssetAllocation} ttmAggregateAumBarChart={dashboardList?.ttmAggregateAumBarChart} ttmInvestedWealthBarChart={dashboardList?.ttmInvestedWealthBarChart} isLoading={loader} />
            </Box>}

            {isMobileOrTablet &&
                <Grid container columnSpacing={1}>
                    <Grid item xs={12} lg={4} mt={"5px"}>
                        <PieChartController title={"Aggregate Asset Allocation (%)"} data={dashboardListsequencetwo?.yourCurrentPortfolioAssetAllocation} category={"Asset"} isloading={loader} />
                    </Grid>
                    <Grid item xs={12} lg={4} mt={"5px"}>
                        <PieChartController title={"Aggregate Geographical Allocation (%)"} data={dashboardListsequencetwo?.geographicalAssetAllocation} category={"Geographical"} isloading={loader} />
                    </Grid>
                    <Grid item xs={12} lg={4} mt={"5px"}>
                        <PieChartController title={"Aggregate Sector Allocation (%)"} data={dashboardListsequencetwo?.sectorAssetAllocation} category={"Sector"} isloading={loader} />
                    </Grid>
                    <br /><br />
                    <Grid item xs={12} lg={6} md={12} mt={"5px"} >
                        {/* <div style={{ height: "355px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}> */}
                        <BarChartController title={"TTM Aggregate AUM (US $)"} data={dashboardList?.ttmAggregateAumBarChart} category={"AUM"} isloading={loader} />
                    </Grid>
                    <Grid item xs={12} lg={6} md={12} mt={"5px"} >
                        {/* <div style={{ height: "355px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 8px #77777733", border: "0.699999988079071px solid #DFD2D280", borderRadius: "12px", padding: "10px" }}> */}
                        <BarChartController title={"TTM Invested Wealth (US $)"} data={dashboardList?.ttmInvestedWealthBarChart} category={"Invested"} isloading={loader} />
                    </Grid>
                </Grid>}
            <br />

        </div >

    )
}
// sectorAssetAllocation={dashboardListsequencetwo?.sectorAssetAllocation} ttmAggregateAumBarChart={dashboardList?.ttmAggregateAumBarChart} ttmInvestedWealthBarChart={dashboardList?.ttmInvestedWealthBarChart}
// const ResponsiveLayout = ({ yourCurrentPortfolioAssetAllocation, sectorAssetAllocation, geographicalAssetAllocation, ttmInvestedWealthBarChart, ttmAggregateAumBarChart, isLoading, isMobileOrTabletBelow800 }) => {
//     return (
//         <Grid container columnSpacing={1} rowSpacing={1} mt={"5px"} mb={"5px"} sx={{ height: isMobileOrTabletBelow800 ? "690px" : "100%" }}>
//             <Grid item xs={12} lg={12} md={12} sx={{ flexDirection: 'row' }}>
//                 <Grid container columnSpacing={1} rowSpacing={1} style={{ height: '100%' }}>

//                     <Grid item xs={12} lg={12} md={12} style={{ height: '36%', }}>
//                         <Grid container columnSpacing={1} style={{ height: '100%' }}>
//                             <Grid item xs={12} lg={4} md={12} style={{ height: '100%', p: "5px" }}>
//                                 <PieChartController title={"Aggregate Asset Allocation (%)"} data={yourCurrentPortfolioAssetAllocation} category={"Asset"} isloading={isLoading} />
//                             </Grid>
//                             <Grid item xs={12} lg={4} md={12} style={{ height: '100%', p: "5px" }}>
//                                 <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} isloading={isLoading} />
//                             </Grid>
//                             <Grid item xs={12} lg={4} md={12} style={{ height: '100%', p: "5px" }}>
//                                 <PieChartController title={"Aggregate Sector Allocation (%)"} data={sectorAssetAllocation} category={"Sector"} isloading={isLoading} />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     <Grid item xs={12} lg={12} md={12} style={{ height: isMobileOrTabletBelow800 ? "70%" : '64%', mt: "5px" }}>
//                         <Grid container columnSpacing={1} rowSpacing={0} style={{ height: '100%' }}>
//                             <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
//                                 <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} isloading={isLoading} />
//                             </Grid>
//                             <Grid item xs={12} lg={6} md={12} style={{ height: '100%' }}>
//                                 <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} isloading={isLoading} />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>

//         </Grid>
//     );
// };


const ResponsiveLayout = ({
    yourCurrentPortfolioAssetAllocation, sectorAssetAllocation, geographicalAssetAllocation, ttmInvestedWealthBarChart, ttmAggregateAumBarChart, isLoading, isMobileOrTabletBelow800
}) => {
    return (
        <Box sx={{ padding: "15px", mb: "15px" }}>
            <Grid container spacing={2} sx={{
                height: '650px',
                // "&. .MuiGrid-item": {
                //   paddingLeft: '0px', paddingTop: "0px"
                // }
            }} >
                {/* Left Side (lg={9}) */}
                <Grid container item xs={12} lg={12} xl={12} spacing={2} >
                    <Grid container spacing={2} sx={{ maxHeight: '320px', minHeight: "310px", paddingTop: "15px", marginTop: "5px" }}>
                        <Grid item xs={12} md={4}>
                            {/* <Box sx={{ height: '100%' }}> */}
                            {/* Content for Bottom 1 */}
                            <PieChartController title={"Aggregate Asset Allocation (%)"} data={yourCurrentPortfolioAssetAllocation} category={"Asset"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* <Box sx={{ height: '100%' }}> */}
                            {/* Content for Bottom 2 */}
                            <PieChartController title={"Aggregate Geographical Allocation (%)"} data={geographicalAssetAllocation} category={"Geographical"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* <Box sx={{ height: '100%' }}> */}
                            {/* Content for Bottom 3 */}
                            <PieChartController title={"Aggregate Sector Allocation (%)"} data={sectorAssetAllocation} category={"Sector"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                    </Grid>

                    {/* Top Section (2 Grid Layouts) */}
                    <Grid container spacing={2} sx={{ maxHeight: '360px', minHeight: "360px", paddingTop: "15px" }}>
                        <Grid item xs={12} md={6} sx={{}}>
                            {/* <Box sx={{ height: '100%', }}> */}
                            {/* Content for Top Left */}
                            <BarChartController title={"TTM Aggregate AUM (US $)"} data={ttmAggregateAumBarChart} category={"AUM"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <Box sx={{ height: '100%', }}> */}
                            {/* Content for Top Right */}
                            <BarChartController title={"TTM Invested Wealth (US $)"} data={ttmInvestedWealthBarChart} category={"Invested"} isloading={isLoading} />
                            {/* </Box> */}
                        </Grid>
                    </Grid>




                </Grid>


            </Grid>
        </Box >
    );
};

export default HniDashboardSectionone;
