import { Axios, Axios1, Axios0 } from "./config";
import axios from "axios";
//authentication services
export const signIn = (data) => Axios.post("/api/sign-in", data);
export const logIn = (data) => Axios0.post("/api/v1/common/hni-login", data)
export const addUser = async (data) => {
    try {
        const userData = await Axios.post("/api/v1/admin/add-user", data)
        if (userData) return userData;
    }
    catch (error) {
        return error;
    }
}
export const getDropdownValues = async () => {
    try {
        const dropdownValues = await Axios.get("/api/v1/admin/getalldropdown-user");

        if (dropdownValues) return dropdownValues;
    }
    catch (error) {
        return error;
    }

}
export const fetchUserDetails = async (id, type) => {
    try {
        const userData = await Axios.get(`/api/v1/admin/get-user/${id}/${type}`);

        if (userData) return userData;
    }
    catch (error) {

        return error;
    }

}
export const updateUserDetails = async (body) => {
    try {
        const userData = await Axios.put(`/api/v1/admin/update-user`, body);

        if (userData) return userData;
    }
    catch (error) {

        return error;
    }

}
export const approveUserStatus = async (body) => {
    try {
        const approveData = await Axios.post(`/api/v1/admin/submit-user`, body);

        if (approveData) return approveData;
    }
    catch (error) {
        return error;
    }
}
export const rejectUserStatus = async (body) => {
    try {
        const rejectData = await Axios.post(`/api/v1/admin/submit-user`, body);

        if (rejectData) return rejectData;
    }
    catch (error) {
        return error;
    }
}
export const resubmitUserDetails = async (body) => {
    try {
        const resubmitData = await Axios.post(`/api/v1/admin/resubmit-user`, body);

        if (resubmitData) return resubmitData;
    }
    catch (error) {

        return error;
    }
}
export const getSubUserTypeValues = async (value, id) => {
    try {
        const subuserValues = await Axios.get(`/api/v1/admin/getsubtype-reference/${id}`);

        if (subuserValues) return subuserValues;
    }
    catch (error) {
        return error;
    }

}
export const forgotPassword = (data) => Axios0.post("/api/v1/common/forgot-password", data);
export const verifyUserOtp = (data) => Axios0.post("/api/verify-otp", data);

export const newPasswordApi = async (newPassword) => {
    try {
        const payload = await Axios0.post(`/api/v1/common/check-password-history`, newPassword);
        if (payload) return payload;
    } catch (error) {
        return error
    }
}
export const otpVerified = async (passingPayload) => {

    const resultService = await Axios0
        .post("/api/v1/common/verify-otp", passingPayload)


    return resultService;

}



export const generateOTP = async (data) => {

    // toastr.success('Have fun storming the castle!', 'Miracle Max Says')

    // // Display an error toast, with a title
    // toastr.error('I do not think that word means what you think it means.', 'Inconceivable!')


    // Axios.post("/api/generate-otp", data)
    const resultService = await Axios0
        .post('/api/v1/common/generate-otp', data)
    // .then(async (res) => {
    //     if (res.status === 200) {
    //         console.log(res.data.data)
    //         let successMSG = {
    //             status: true,
    //             response: res.data
    //         }
    //         // toastr.success(res.data.data.message)
    //         return successMSG
    //     }
    //     else {
    //         toastr.error('Something Went Wrong! Please try Again!')
    //     }
    // })
    // .catch((error) => {
    //     console.log("error :>> ", error);
    //     let errorMSG = {
    //         status: false,
    //         response: error.response.data.errors[0]
    //     }
    //     toastr.error('Something Went Wrong! Please try Again!')
    //     return errorMSG
    // });

    return resultService;

};
export const userManagementTableApi = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/getall-users`, body);
        return payload;
    } catch (error) {
        return error
    }
}
export const userTypeDropdownApi = async () => {
    try {
        const payload = await Axios.get(`/api/v1/admin/getalldropdown-user`);
        if (payload) return payload;
    } catch (error) {
        return error
    }
}
export const userStatusDeleteApi = async (status) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/update-user-status`, status);
        if (payload) return payload;
    } catch (error) {
        return error
    }

}
export const userViewApi = async (userId, type) => {
    try {
        const payload = await Axios.get(`/api/v1/admin/get-user/${userId}/${type}`);
        if (payload) return payload;
    } catch (error) {
        return error
    }

}
export const clientGetAllUserApi = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/client/getall-clients`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const clientUpdateApi = async (data) => {
    try {
        const payload = await Axios.post(`api/v1/client/update-clients/`, data);
        return payload;
    } catch (error) {
        return error
    }
}
export const checkEmaliLinkApi = async (databody) => {
    try {
        const payload = await Axios.post(`api/v1/common/check-email-link`, databody);
        return payload;
    } catch (error) {
        return error
    }
}

export const logOut = async (data) => {
    const response = await Axios.post("/api/v1/admin/user-logout", data);
    return response
}
export const feedbackGetAllApi = async (pageNumber, pageList) => {
    try {
        const payload = await Axios.get(`api/v1/admin/getall-feedback/${pageNumber}/${pageList}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const userRightsGetAllApi = async () => {
    try {
        const payload = await Axios.get(`api/v1/user_rights/get-user-types`);
        return payload;
    } catch (error) {
        return error
    }
}
export const userRightsGetuserTypeIdApi = async (userTypeId) => {
    try {
        const payload = await Axios.get(`api/v1/user_rights/get-user-rights/${userTypeId}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const UpdateUserRightsApi = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/user_rights/update-user-rights`, req);
        return payload;
    } catch (error) {
        return error
    }
}

//API's for services
export const getAllProductsService = async (object) => {
    try {
        const allProductsService = await Axios1.post(`/api/v1/productservice/getall-products-services`, object);

        if (allProductsService) return allProductsService;
    }
    catch (error) {
        return error;
    }

}
export const updateProductServiceStatus = async (status) => {
    try {
        const updateStatus = await Axios1.post(`/api/v1/productservice/update-productservice-status`, status);
        if (updateStatus) return updateStatus;
    } catch (error) {
        return error
    }

}
export const downloadServiceStatus = async (value) => {
    try {
        const downloadStatus = await Axios.get(`/api/v1/services/financial-services/export/excel`, value);
        if (downloadStatus) return downloadStatus;
    } catch (error) {
        return error
    }

}
export const deleteService = async (value) => {
    try {
        const deleteStatus = await Axios1.post(`/api/v1/productservice/update-productservice-status`, value);
        if (deleteStatus) return deleteStatus;
    } catch (error) {
        return error
    }

}
export const getServiceTags = async (value) => {
    try {
        const serviceTags = await Axios1.get(`/api/v1/productservice/get-all-tags`, value);
        if (serviceTags) return serviceTags;
    } catch (error) {
        return error
    }
}
export const getChatAccessToken = async (url, reqBody) => {
    try {
        const response = await axios.post(url, reqBody);
        if (response.status === 200) return response.data;
    } catch (error) {
        return error
    }
}

export const applyPromote = async (value) => {
    try {
        const applyStatus = await Axios1.post(`/api/v1/productservice/promote-by`, value);
        if (applyStatus) return applyStatus;
    } catch (error) {
        return error
    }
}
export const startPromote = async (value) => {
    try {
        const startStatus = await Axios1.post(`/api/v1/productservice/start-promote`, value);
        if (startStatus) return startStatus;
    } catch (error) {
        return error
    }
}
export const stopPromote = async (value) => {
    try {
        const stopStatus = await Axios1.post(`/api/v1/productservice/update-promote`, value);
        if (stopStatus) return stopStatus;
    } catch (error) {
        return error
    }
}

export const getAllNotification = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/getall-notification`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const getAllNotificationBG = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/getall-notification-bg`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const updateNotification = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/update-notification`, req);
        return payload;
    } catch (error) {
        return error
    }
}


export const getAllFinancialServiceDropdown = async () => {
    try {
        const payload = await Axios1.get(`/api/v1/productservice/getall-dropdown-productservice`);
        return payload;
    } catch (error) {
        return error
    }
}

export const addFinancialService = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/add-productservice`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getFinancialService = async (productserviceId) => {
    try {
        const payload = await Axios1.get(`/api/v1/productservice/get-productservice/${productserviceId}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const editFinancialService = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/update-productservice`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const resubmitProductsServices = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/resubmit-productservice`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const approveRejectProductsServices = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/submit-productservice`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const downloadUser = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/users/export/excel`, req, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }
}

export const downloadVendor = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/vendor/vendors/export/excel`, req, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }
}

export const getAllCampaignList = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/get-all-campaign`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const createCampaignApi = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/create-campaign`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const getDropdownCampaignApi = async () => {
    try {
        const payload = await Axios1.get(`api/v1/campaign/get-dropdown-campaign`);
        return payload;
    } catch (error) {
        return error
    }

}
export const getDropdownProductServiceCampaign = async (id) => {
    try {
        const payload = await Axios1.get(`api/v1/campaign/get-dropdown-product-campaign/${id}`);
        return payload;
    } catch (error) {
        return error
    }

}
export const downloadCampaign = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/campaign/export/excel`, reqBody, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}
export const promoteCampaign = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/promote-by`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}
export const getAllTags = async () => {
    try {
        const payload = await Axios1.get(`api/v1/productservice/get-all-tags`);
        return payload;
    } catch (error) {
        return error
    }
}
export const getCampaign = async (id, loginId) => {
    try {
        const payload = await Axios1.get(`api/v1/campaign/get-campaign/${id}/${loginId}`);
        return payload;
    } catch (error) {
        return error
    }

}
export const getbidcompletion = async (id) => {
    try {
        const payload = await Axios1.get(`api/v1/campaign/bid-completion/${id}`);
        return payload;
    } catch (error) {
        return error
    }

}
export const updatecompletion = async (reqObj) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/update-campaign`, reqObj);
        return payload;
    } catch (error) {
        return error
    }

}

export const getProfessionalDropDown = async () => {
    try {
        const payload = await Axios.get(`api/v1/prospect/getall-dropdown-prospect-professional`);
        return payload;
    } catch (error) {
        return error
    }

}
export const getProspectProfessional = async (id) => {
    try {
        const payload = await Axios.get(`api/v1/prospect/get-prospect-professional/${id}`);
        return payload;
    } catch (error) {
        return error
    }

}
export const addProspectProfessional = async (reqObj) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/add-prospect-professional`, reqObj);
        return payload;
    } catch (error) {
        return error
    }

}
export const editProspectProfessional = async (reqObj) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/update-prospect-professional`, reqObj);
        return payload;
    } catch (error) {
        return error
    }

}
export const editProspectPersonal = async (reqObj) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/update-prospect-personal`, reqObj);
        return payload;
    } catch (error) {
        return error
    }

}
export const getDropDownProspectPersonal = async () => {
    try {
        const payload = await Axios.get(`api/v1/prospect/get-dropdown-prospect-personal`)
        return payload;
    } catch (error) {
        return error
    }

}
export const getDropDownProspectState = async (id) => {
    try {
        if (id) {
            const payload = await Axios.get(`api/v1/prospect/get-dropdown-prospect-state/${id}`)
            return payload;
        }
        return []
    } catch (error) {
        return error
    }
}
export const getDropDownProspectCity = async (id) => {
    try {
        if (id) {
            const payload = await Axios.get(`api/v1/prospect/get-dropdown-prospect-city/${id}`)
            return payload;
        }
        else {
            return []
        }
    } catch (error) {
        return error
    }
}
export const addProspectPersonal = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/add-prospect-personal`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}
export const addNotes = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/add-notes`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}
export const getNotes = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/get-notes`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}
export const editNotes = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/edit-notes`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const addProspectFinancial = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/add-prospect-financial`, body);
        return payload;
    } catch (error) {
        return error
    }
}

export const getProspectsFinancial = async (prospectId) => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/get-prospect-financial/${prospectId}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const getDropdownProspectsFamily = async () => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/getall-dropdown-prospect-family`);
        return payload;
    } catch (error) {
        return error
    }
}

export const getProspectsFamilyById = async (prospectid) => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/get-prospect-family/${prospectid}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const addProspectFamily = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/add-prospect-family`, body);
        return payload;
    } catch (error) {
        return error
    }
}
export const getDropdownProspects = async () => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/getall-dropdown-prospect-financial`);
        return payload;
    } catch (error) {

        return error
    }
}
export const getProspectPersonal = async (id) => {
    try {
        const payload = await Axios.get(`api/v1/prospect/get-prospect-personal/${id}`);
        return payload;
    } catch (error) {

        return error
    }
}
export const getallRecommendations = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/get-recommendations`, reqBody);
        return payload;
    } catch (error) {

        return error
    }
}
export const getServicesRecommendations = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/get-services-recommendations`, reqBody);
        return payload;
    } catch (error) {

        return error
    }
}
export const getListOfProspects = async (pagerequest) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/get-prospect-list`, pagerequest);
        return payload;
    } catch (error) {
        return error
    }

}
export const getListOfClients = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/client/get-clients-list`, req);
        return payload;
    } catch (error) {
        return error
    }

}

export const getRmProspects = async (req) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/get-rm-prospects`, req);
        return payload;
    } catch (error) {

        return error
    }
}

export const myProspectDeleteApi = async (status) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/delete-prospect`, status);
        if (payload) return payload;
    } catch (error) {
        return error
    }

}

export const myProspectsDropdown = async () => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/get-networth`);
        return payload;
    } catch (error) {

        return error
    }
}

export const myProspectsDownload = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/prospect/export/excel`, reqBody, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}

export const getAllEnquiries = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/enquiry/getall-enquiries`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getProspectInfoForProfile = async (prospectid) => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/get-prospect-details/${prospectid}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const getClientInfoForProfile = async (clientid, isProfile) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-hni-details/${clientid}/${isProfile}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const reqCompApproval = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/request-compliance-approval`, body);
        return payload;
    } catch (error) {
        return error
    }
}

export const compApproveorReject = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/compliance-approve-or-reject`, body);
        return payload;
    } catch (error) {
        return error
    }
}

export const managApproveorReject = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/management-approve-or-reject`, body);
        return payload;
    } catch (error) {
        return error
    }

}


export const getEnquiriesHistory = async (id) => {
    try {
        const payload = await Axios.get(`api/v1/enquiry/get-enquiries-history/${id}`);
        return payload;
    } catch (error) {

        return error
    }
}

export const addEnquiry = async (body) => {
    try {
        const payload = await Axios.post(`api/v1/enquiry/add-enquiries`, body);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateRm = async (reqObj) => {
    try {
        const payload = await Axios.post(`api/v1/enquiry/update-rm`, reqObj);
        return payload;
    } catch (error) {
        return error
    }

}

export const enquiryCategoryDropdown = async () => {
    try {
        const payload = await Axios.get(`api/v1/enquiry/getall-dropdown-enquiry`);
        return payload;
    } catch (error) {

        return error
    }
}

export const deleteEnquiry = async (req) => {
    try {
        const deleteStatus = await Axios.post(`api/v1/enquiry/delete-enquiries`, req);
        if (deleteStatus) return deleteStatus;
    } catch (error) {
        return error
    }

}

export const getAllNotesForProfile = async (prospectinfo) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/get-notes`, prospectinfo);
        return payload;
    } catch (error) {
        return error
    }
}

export const addNotesForProfile = async (prospectinfo) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/add-notes`, prospectinfo);
        return payload;
    } catch (error) {
        return error
    }
}

export const editNotesForProfile = async (prospectinfo) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/edit-notes`, prospectinfo);
        return payload;
    } catch (error) {
        return error
    }
}

export const submitClientInfo = async (clientinfo) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-details`, clientinfo, config);
        return payload;
    } catch (error) {
        return error
    }
}
export const columnChooserApi = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/add-column-list`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}
export const getHNIDetails = async (clientId, isProfile) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-hni-details/${clientId}/${isProfile}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const enquiryResponseSubmit = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/enquiry/responseSubmit`, reqBody);
        return payload;
    } catch (error) {
        return error
    }

}


export const updateEnquiry = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/enquiry/update-enquiries`, reqBody);
        return payload;
    } catch (error) {
        return error
    }

}

export const downloadEnquiry = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/enquiry/enquiries/export/excel`, reqBody, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}

export const downloadClients = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/clients/export/excel`, reqBody, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}

export const downloadMyClients = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/download-client-report`, reqBody, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}

export const saveFirebaseToken = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/save-firebasetoken`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}

export const gethniclientCampaign = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/get-client-campaign`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}
export const hniparticipateCampaign = async (reqBody) => {
    try {
        const payload = await Axios1.post(`/api/v1/campaign/participate-campaign`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}
export const gethniviewCampaign = async (campaignId, userID) => {
    try {
        const payload = await Axios1.get(`/api/v1/campaign/get-campaign/${campaignId}/${userID}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const downloadhniCampaign = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/client-campaign/export/excel`, reqBody, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}

export const addBankDetails = async (reqbody) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const payload = await Axios.post(`/api/v1/prospect/add-bank-details`, reqbody, config);
        return payload;
    } catch (error) {
        return error
    }

}

export const addClientBankDetails = async (reqbody) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const payload = await Axios.post(`/api/v1/client/add-client-bank-details`, reqbody, config);
        return payload;
    } catch (error) {
        return error
    }

}

export const getHniDashboardDetails = async (reqbody) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/get-dashboard-datas`, reqbody);
        return payload;
    } catch (error) {
        return error
    }

}

export const getBankAssetDetails = async (reqbody) => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/get-bank-dropdown`, reqbody);
        return payload;
    } catch (error) {
        return error
    }

}

export const getListOfAssetsInstitutions = async (prospectId, rmId) => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/get-bank-details/${prospectId}`);
        return payload;
    } catch (error) {
        return error
    }

}

export const getClientListOfAssetsInstitutions = async (clientid) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-client-bank-details/${clientid}`);
        return payload;
    } catch (error) {
        return error
    }

}

export const delinkBankDetails = async (reqbody) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/delete-bank-details`, reqbody);
        return payload;
    } catch (error) {
        return error
    }

}

export const delinkClientBankDetails = async (reqbody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/delete-client-bank-details`, reqbody);
        return payload;
    } catch (error) {
        return error
    }

}

export const getAssetHistory = async (reqbody) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/get-asset-details`, reqbody);
        return payload;
    } catch (error) {
        return error
    }

}

export const getClientAssetHistory = async (reqbody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/get-client-asset-details`, reqbody);
        return payload;
    } catch (error) {
        return error
    }

}

export const clientRefreshAssetInstitution = async (id) => {
    try {
        const payload = await Axios.get(`/api/v1/client/refresh-client-asset-details/${id}`);
        return payload;
    } catch (error) {
        return error
    }

}

export const prospectRefreshAssetInstitution = async (id) => {
    try {
        const payload = await Axios.get(`/api/v1/prospect/refresh-prospect-asset-details/${id}`);
        return payload;
    } catch (error) {
        return error
    }

}

export const updateAssetHistory = async (req) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const payload = await Axios.post(`/api/v1/prospect/update-asset-details`, req, config);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateClientAssetHistory = async (req) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-asset-details`, req, config);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateStatementCommentProspect = async (reqbody) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/update-prospect-asset-comment`, reqbody);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateStatementCommentClient = async (reqbody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-asset-comment`, reqbody);
        return payload;
    } catch (error) {
        return error
    }
}

export const getMainParent = async () => {
    try {
        const payload = await Axios.get(`/api/v1/admin/getsubtype-reference/0`);
        return payload;
    } catch (error) {
        return error
    }
}

export const getChildData = async (refKey) => {
    try {
        const payload = await Axios.get(`/api/v1/admin/getmaintype-reference/${refKey}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const getSubChildData = async (id) => {
    try {
        const payload = await Axios.get(`/api/v1/admin/getsubtype-reference/${id}`);
        return payload;
    } catch (error) {
        return error
    }
}


export const updateParent = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/update-reference`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}

export const addChild = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/add-reference`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}

export const addParent = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/add-reference`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}
export const addSubChild = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/admin/add-reference`, reqBody);
        return payload;
    } catch (error) {
        return error
    }
}


export const deleteChild = async (id) => {
    try {
        const deleteStatus = await Axios.get(`/api/v1/admin/update-reference-status/${id}`,);
        if (deleteStatus) return deleteStatus;
    } catch (error) {
        return error
    }

}

export const getadminDashboard = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-dashboard-datas`, req);
        return payload;
    } catch (error) {
        return error
    }

}

export const getAdminDashboardDataWIthSection = async (req) => {


    try {
        const payload = await Axios1.post(`api/v1/productservice/get-dashboard-datas`, req);
        return payload;
    } catch (error) {
        return error
    }

}

export const getPersonal = async (clientId) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-client-personal/${clientId}`);
        return payload;
    } catch (error) {
        return error
    }
}


export const getProfessional = async (clientId) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-client-professional/${clientId}`);
        return payload;
    } catch (error) {
        return error
    }
}


export const getInterest = async (clientId) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-client-interest/${clientId}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const getFinancial = async (clientId) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-client-financial/${clientId}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const getFamily = async (clientId) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-client-family/${clientId}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const editPersonal = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-personal`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}
export const editFinancial = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-financial`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const editProfessional = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-professional`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const editFamily = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-family`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}


export const editInterest = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/client/update-client-interest`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}



export const getAllOrders = async (reqBody) => {
    try {
        const payload = await Axios1.post(`/api/v1/order-management/get-all-orders`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const getDropdownValuesForOMS = async (id) => {
    try {
        const payload = await Axios1.get(`/api/v1/order-management/get-dropdown/${id}`)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const updateOrderChildStatus = async (reqbody) => {
    try {
        const payload = await Axios1.post(`/api/v1/order-management/update-child-status`, reqbody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const rmHistory = async (clientId) => {
    try {
        const payload = await Axios.get(`/api/v1/client/get-rm-history/${clientId}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const allocateApi = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/allocate-campaign`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const deleteCampaign = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/delete-campaign`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const campaignMakePayment = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/campaign/campaign-bid-make-payment`, req);
        return payload;
    } catch (error) {
        return error
    }
}


//chatgpt
export const sendChat = async (clientId) => {
    try {
        const payload = await Axios.post(`api/v1/admin/get-chat-gpt-answer`, clientId);
        return payload;
    } catch (error) {
        return error
    }
}

export const getAllChatgpt = async (userId, target) => {
    try {
        const payload = await Axios.get(`api/v1/admin/getall-chat-gpt-config/${userId}/${target}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const getAlldropdownChatgpt = async (userId) => {
    try {
        const payload = await Axios.get(`api/v1/admin/get-chat-gpt-dropdown/${userId}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const getAllhistoryChatgpt = async (obj) => {
    try {
        const payload = await Axios.post(`api/v1/admin/get-chat-gpt-history`, obj);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateBookmarkStatus = async (obj) => {
    try {
        const payload = await Axios.post(`api/v1/admin/update-chat-gpt-bookmark`, obj);
        return payload;
    } catch (error) {
        return error
    }
}
export const confirmTransaction = async (reqBody) => {
    try {
        const payload = await Axios1.post(`/api/v1/order-management/confirm-transaction-details`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const updatePaymentRefNumber = async (reqBody) => {
    try {
        const payload = await Axios1.post(`/api/v1/order-management/update-payment-reference`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const terminateOrder = async (reqBody) => {
    try {
        const payload = await Axios1.post(`/api/v1/order-management/update-terminate`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

export const generateStripeLink = async (reqBody) => {
    try {
        const payload = await Axios.post(`/api/v1/vendor/generate-stripe-account-link`, reqBody)
        return payload;
    }
    catch (error) {
        return error
    }
}

//payments

export const getAllHniPaymentDetails = async (userId) => {
    try {
        const payload = await Axios1.get(`api/v1/order-management/get-hni-payments-details/${userId}`);
        return payload;
    } catch (error) {
        return error
    }
}
export const JoiningFeeApi = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/joiningfee-and-subscription-payments`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const stopSubscription = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/stop-subscription-payments`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getHniTransactions = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-all-hni-transactions`, req);
        return payload;
    } catch (error) {
        return error
    }

}
export const downloadTransactiion = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/hni-transactions/export/excel`, req, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}

export const updateHniStatus = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/client/update-hni-status`, req);
        return payload;
    } catch (error) {
        return error
    }

}
export const getSurveyDetails = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/surveyservice/get-survey-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateProspectFamily = async (body) => {
    try {
        const payload = await Axios.post(`/api/v1/prospect/update-prospect-family`, body);
        return payload;
    } catch (error) {
        return error
    }
}
export const getSurvey = async () => {
    try {
        const payload = await Axios1.get(`api/v1/surveyservice/get-surveys`);
        return payload;
    } catch (error) {
        return error
    }

}
export const saveAnswerDetails = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/surveyservice/save-answer-details`, req);
        return payload;
    } catch (error) {
        return error
    }

}

export const hniGetDashboardList = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-dashboard-datas`, req);
        return payload;
    } catch (error) {
        return error
    }

}

export const campaignStatusSuccess = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/get-service-success-transaction-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const campaignStatusFailed = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/get-service-failure-transaction-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getJoiningAndAnnualSuccessPayments = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/get-joiningfee-transaction-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const paymentFailure = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/failure-transaction-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const subscriptionFeeWithoutAutoRenewal = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/get-joiningfee-transaction-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const subscriptionFeeWithAutoRenewal = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/order-management/get-subscription-transaction-details`, req);
        return payload;
    } catch (error) {
        return error
    }
}


export const downloadRmPerformance = async (passingObject) => {
    try {
        const payload = await Axios.post(`/api/v1/client/download-rm-performance-report`, passingObject, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }
}
export const getSurveyHniAdmin = async (rmId) => {
    try {
        const payload = await Axios.get(`api/v1/admin/get-admin-hni-list/${rmId}`);
        return payload;
    } catch (error) {
        return error
    }

}

export const sendNotification = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/admin/add-chat-gpt-notification`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getRmNotificationList = async (userId, target) => {
    try {
        const payload = await Axios.get(`api/v1/admin/get-rm-notification-list/${userId}/${target}`);
        return payload;
    } catch (error) {
        return error
    }

}
export const getAdminHniNotificationList = async (rmId, target) => {
    try {
        const payload = await Axios.get(`api/v1/admin/get-admin-hni-notification-list/${rmId}/${target}`);
        return payload;
    } catch (error) {
        return error
    }

}
export const deleteFamilyProspect = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/prospect/update-prospect-family-details-status`, req);
        return payload;
    } catch (error) {
        return error
    }

}
export const deleteFamilyProfile = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/client/update-client-family-details-status`, req);
        return payload;
    } catch (error) {
        return error
    }

}
export const hniAddEnquiry = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/add-enquiry`, req);
        return payload;
    } catch (error) {
        return error
    }

}
export const hniDownloadEnquiry = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/enquiry/export/excel`, req, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }

}
export const getAllHniEnquiry = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/getall-rm-hni-enquiry`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const getAllEnquiryDropdown = async () => {
    try {
        const payload = await Axios1.get(`api/v1/productservice/get-enquiry-dropdown`);
        return payload;
    } catch (error) {
        return error
    }

}
export const getAllEnquiryHistory = async (id) => {
    try {
        const payload = await Axios1.get(`api/v1/productservice/get-enquiry-history/${id}`);
        if (payload)
            return payload;
    } catch (error) {
        return error
    }

}
export const hniReopenenquiry = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/productservice/hni-reopen-enquiry`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const hniDeleteenquiry = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/delete-enquiry`, req);
        return payload;
    } catch (error) {
        return error
    }
}
export const rmResponse = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/rm-response-submit`, req);
        return payload;
    } catch (error) {
        return error
    }
}


export const addFeedback = async (req) => {
    try {
        const payload = await Axios.post(`api/v1/admin/add-feedback`, req);
        return payload;
    } catch (error) {
        return error
    }

}

export const getDashboardDataForRM = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-dashboard-datas`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getRecProductsForRM = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-rm-dashboard-recommended-products`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getRecServicesForRM = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-rm-dashboard-recommended-services`, req);
        return payload;
    } catch (error) {
        return error
    }
}


export const getAllFreightCharges = async (req) => {
    try {
        const payload = await Axios1.post(`/api/v1/biservice/get-all-freight-charges`, req);
        if (payload)
            return payload;
    } catch (error) {
        return error
    }

}


export const addConfigureFreightCharges = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/configure-freight-charges`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const updateConfigureFreightCharges = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/update-freight-charges`, req);
        return payload;
    } catch (error) {
        return error
    }
}

export const getHistoryFreightCharges = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/get-freight-charges-history`, req);
        if (payload)
            return payload;
    } catch (error) {
        return error
    }

}

export const getDropdownValuesForFreight = async (userId) => {
    try {
        const payload = await Axios1.get(`api/v1/biservice/get-lifestyle-details/${userId}`);
        return payload;
    } catch (error) {
        return error
    }
}

export const buyBordeauxProduct = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/buy-bi-products`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}
export const getRMPerfomanceReport = async (id) => {
    try {
        const payload = await Axios.get(`api/v1/client/rm-performance-report/${id}`);
        return payload;
    } catch (error) {
        return error
    }

}

export const sendRemainderEmail = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/send-email`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}

export const getBordeauxTransactionDetails = async (transactionId) => {
    try {
        const payload = await Axios1.get(`api/v1/biservice/get-bordeaux-transaction-details/${transactionId}`)
        return payload;
    } catch (error) {
        return error
    }
}

export const updateChildStatusBordeaux = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/update-bi-child-status`, reqBody)
        return payload;
    } catch (error) {
        return error;
    }
}

export const successBordeauxAPIcall = async (passingValue) => {
    var resultService = await Axios1.post(`/api/v1/biservice/get-bi-transaction-success`, passingValue)
    return resultService;
}

export const failureBordeauxAPIcall = async (passingValue) => {
    var resultService = await Axios1.post(`/api/v1/biservice/get-bi-transaction-failure`, passingValue)
    return resultService;
}


export const getBillofMaterials = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/get-bill-of-materials`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}
export const downloadBill = async (req) => {
    try {
        const payload = await Axios1.post(`api/v1/biservice/bill-of-materials/export/excel`, req, { responseType: 'blob' });
        return payload;
    } catch (error) {
        return error
    }
}

export const getClientDropdown = async () => {
    try {
        const payload = await Axios.post(`api/v1/client/get-all-dropdown-client`)
        return payload;
    } catch (error) {
        return error
    }
}

export const getFamilyMemberDetails = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/get-family-member-details`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}

export const updateFamilyMemberDetails = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/update-family-member-details `, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}

export const updateClientKyc = async (formData) => {
    try {
        const payload = await Axios.post(`api/v1/client/update-client-kyc`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
        })
        return payload;
    } catch (error) {
        return error
    }
}

export const deleteClientKyc = async (reqBody) => {
    try {
        const payload = await Axios.post(`api/v1/client/delete-client-kyc`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}

export const getPromote = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/get-promote`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}
export const updatePromote = async (reqBody) => {
    try {
        const payload = await Axios1.post(`api/v1/productservice/update-promote`, reqBody)
        return payload;
    } catch (error) {
        return error
    }
}
export const getEntityUserRights = async (request) => {
    try {
        const payload = await Axios.post(`api/v1/user_rights/get-entity-user-rights`, request);
        return payload;
    } catch (error) {
        return error;
    }
}
export const saveEntityUserRights = async (request) => {
    try {
        const payload = await Axios.post(`api/v1/user_rights/save-entity-user-rights`, request);
        return payload;
    } catch (error) {
        return error;
    }
}


