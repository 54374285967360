import * as React from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';// eslint-disable-next-line
import { openModal, setTableColumnData } from "../../../../redux/slices/dashboardModalSlice"
import { Typography, Grid } from '@mui/material';
import { createColumnDynamicPie } from "../../../../constant/DynamicTableColumsUtils"
import DonutCharts from "../../../common/ChartsComponent/DonutCharts"
import { getClientsAssestAllocations, getClientGeoGraphicalAllocations } from "../../../../services/DashboardAPI"


export default function PieChartController(props) {// eslint-disable-next-line
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('user'))
    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member") ? true : false;
    const apiCalls = async () => {
        let passingObject = {
            loginUserId: user?.userId,
            pageNo: 0,
            pageSize: 10
        }
        let dataFromResponse = props?.category === "Asset" ? await getClientsAssestAllocations(passingObject) : props?.category === "Geographical" ? await getClientGeoGraphicalAllocations(passingObject) : null;

        return props?.category === "Asset" ? dataFromResponse?.data?.body?.clientWiseAggregateAssetAllocation : props?.category === "Geographical" ? dataFromResponse?.data?.body?.clientWiseGeographicalAssetAllocation : dataFromResponse?.data?.body?.clientWiseAggregateAssetAllocation;
    }
    const dataModalReducers = async () => {
        let dataObjects = await apiCalls();
        if (dataObjects) {
            let columsDynamics = await createColumnDynamicPie(dataObjects[0])
            console.log(columsDynamics, "columsDynamics")
            dispatch(openModal(true))
            dispatch(setTableColumnData({ titleofModal: props?.title, data: dataObjects, column: columsDynamics, loading: false }))
        }
    }
    const dataSector = async () => {
        console.log("Not implemented")
    }


    return (
        <Box sx={{
            width: '100%', height: "100%", background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 8px #77777733",
            borderRadius: "12px",
            opacity: 1,
            border: "0.699999988079071px solid #DFD2D280"

        }}>
            <Grid container sx={{ justifyContent: "space-between", flexDirection: "row", display: "flex", p: "10px" }}>
                <Grid item lg={12} md={12} xs={12}>
                    <Typography sx={{
                        fontFamily: "Mona Sans",
                        fontSize: "11px",
                        fontWeight: 600,
                        color: "#000000",
                        opacity: 1,
                        letterSpacing: "0px",

                    }}>{props?.title}

                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {!isHni && <Typography sx={{
                        fontFamily: "Mona Sans",
                        fontSize: "10px",
                        textDecoration: "underline",
                        letterSpacing: "0px",
                        color: "#000000",
                        cursor: props?.category !== "Sector" ? "pointer" : "not-allowed",
                        opacity: 1
                    }} onClick={() => { props?.category !== "Sector" ? dataModalReducers() : dataSector() }}>{"See client wise details"}

                    </Typography>}

                </Grid>

            </Grid>
            <Grid container pt={"10px"} >

                {props?.data?.label && props?.data?.data && <DonutCharts data={props?.data} />}
                {!props.isloading && !props?.data?.label && !props?.data?.data && <p style={{ paddingLeft: '10px' }}>No Records Found</p>}
                {props.isloading && <p style={{ paddingLeft: '10px' }}>loading...</p>}
            </Grid>

        </Box>
    );
}