import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { generateNewColor } from "./RandomColor"
import { formatNumber } from "./DollorFormats"


export const createColumnDynamicBar = async (objectForHeader, aumorInvested) => {

    var headerArrays = Object.keys(objectForHeader)
    var tempArray = [];

    for (var i = 0; i < headerArrays.length; i++) {
        var temp;
        if (headerArrays[i] === "hniName") {
            temp = {
                field: headerArrays[i],
                headerName: "Client's Name",
                width: 200,
                renderCell: (params) => (
                    <>

                        {
                            <>   <Avatar alt="Remy Sharp" src={params?.row?.profilePhotoUrl} sx={{ bgcolor: generateNewColor(), width: 20, height: 20, border: "1px #FFFFFF" }} />&nbsp;<Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', }} title={params.value}>{params.value}</Typography></>

                        }


                    </>
                )

            };
        }

        else if (headerArrays[i] === "hniId" || headerArrays[i] === "profilePhotoUrl") {
            temp = {
                field: headerArrays[i],
                headerName: headerArrays[i],
                hide: true,
                valueGetter: (params) =>
                    `${params.value} `
            };
        }
        else {
            temp = {
                field: headerArrays[i],
                headerName: headerArrays[i],
                align: 'center',
                renderHeader: (params) => (<>

                    <div style={{ flexDirection: "column" }}>
                        <div style={{ marginTop: '13px', fontFamily: "Mona Sans" }}>
                            {params?.colDef?.headerName}
                        </div>
                        <div style={{ marginTop: '-35px', fontFamily: "Mona Sans", fontSize: "10px", textAlign: "center" }}>
                            {aumorInvested === "AUM" ? "AUM" : "INVESTED"}
                        </div>

                    </div>


                </>

                ),
                valueGetter: (params) =>
                    `${formatNumber(params.value)} `
            };
        }
        tempArray.push(temp)
    }
    return tempArray;
}

export const createColumnDynamicPie = async (objectForHeader) => {
    var tempArray = [];
    var headerArrays = Object.keys(objectForHeader)
    for (var i = 0; i < headerArrays.length; i++) {
        var temp;
        if (headerArrays[i] === "hniName") {
            temp = {
                field: headerArrays[i],
                headerName: "Client'S Name",
                width: 200,
                renderCell: (params) => (
                    <>

                        {
                            <>  <Avatar alt="Remy Sharp" src={params?.row?.profilePhotoUrl} sx={{ bgcolor: generateNewColor(), width: 20, height: 20, border: "1px #FFFFFF" }} />&nbsp;<Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', }} title={params.value}>{params.value}</Typography></>

                        }


                    </>
                )
                // valueGetter: (params) =>
                //     `${params.row.firstName || ""} ${params.row.lastName || ""}`
            };
        }
        else if (headerArrays[i] === "hniId" || headerArrays[i] === "profilePhotoUrl") {
            temp = {
                field: headerArrays[i],
                headerName: headerArrays[i],
                hide: true,
                valueGetter: (params) =>
                    `${params.value} `
            };
        }
        else {
            temp = {
                field: headerArrays[i],
                headerName: headerArrays[i],
                align: "left",
                valueGetter: (params) =>
                    `${params.value.toFixed(1) + "%"} `

            };
        }
        tempArray.push(temp)
    }
    return tempArray;
}