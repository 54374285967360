import React from 'react'
import { TextField as MuiTextField } from "@mui/material";

function Textfield({ title, textvalue, handleInputChange, formikTouchedValue, formikErrorValue, readOnly, index }) {
    // const [text, setText] = useState("")
    const handleInput = (event) => {
        handleInputChange(index, title, event.target.value);
    }
    return (
        <MuiTextField
            size="small"
            type={(title === "Age" || title === "postalcode" || title === "Net worth" || title === "Investable wealth" || title === "Joining fee" || title === "Subscription fee" || title === "Annual Subscrption fee" || title === "Spouse age" || title === "Turnover") ? "number" : "text"}
            step="any"
            sx={{
                width: { xs: '100%', sm: '100%', md: '80%', lg: '80%' },
                gridColumn: "span 2",
                fontFamily: "Mona Sans",
                height: '40px',
                '& input::placeholder': {
                    textOverflow: "ellipsis !important",
                    color: "#BBC5D5 !important",
                    fontFamily: "Mona Sans",
                    opacity: 1,
                },
                '& .MuiInputBase-root': {
                    fontFamily: "Mona Sans !important",
                    height: '40px !important',
                    maxWidth: "auto",
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: "8px",
                    fontWeight: 600
                },
                '& .Mui-disabled': {
                    backgroundColor: "#FFFFFF"
                },
                '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                    cursor: 'pointer',
                  },         
            }}
            InputProps={{
                disabled: readOnly ? true : false,
            }}
            onKeyDown={(e) => {
                if (e.key === '-') {
                    e.preventDefault();
                }
            }}
            inputProps={{ min: 0 }}
            value={textvalue}
            onChange={(event) => handleInput(event)}
            // fullWidth 
            id={title}
            name={title}
            error={formikTouchedValue && Boolean(formikErrorValue)}
            helperText={formikTouchedValue && formikErrorValue}
            placeholder={`Enter the ${title}`}
        />
    )
}

export default Textfield