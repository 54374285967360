import React, { useState } from 'react';
import { useField } from 'formik'
import { InputAdornment, IconButton,TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CustomPasswordTextField = ({ name, label, eye, ...otherProps }) => {
    const [field, meta] = useField(name)
    const configTextField = {
        helperText: '',
        error: false,
        ...field,
        ...otherProps,
    }
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const error = meta && meta.touched && meta.error ? true : false
    if (meta && meta.touched && meta.error) {
        configTextField.error = true
        configTextField.helperText = meta.error
    }

    return (
        <TextField
            label={label}
            type={showPassword ? "text" : "password"}
            // value={value}
            name={name}
            // fullWidth
            variant="outlined"
            InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                    { eye } && (<InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>)

                )
            }}
            sx={{
                // marginTop: '10%',
                // marginBottom: "3%",
                // gridColumn: "span 4",
                // height: '40px',
                width: '300px',
                '& input::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: '#363636',
                    opacity: 1,
                },
                '& .MuiInputBase-root': {
                    height: '45px !important',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '8px',
                    // border: '1px solid #DCDBDD'

                },
            }}
            {...configTextField}
            error={error} />
    );
}

export default CustomPasswordTextField;
