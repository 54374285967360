/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    setPrivateEquity
} from '../../../../redux/slices/ChatGptSlice'

import {
    Autocomplete,
    Typography,
    Grid,
    TextField
}
    from '@mui/material'

import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';


const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        color: "white",
    },
    "& .MuiInputLabel-shrink": {
        color: "white"
    },
    "& .MuiSvgIcon-root": {
        color: "white"
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "white"
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "white",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    }
});

const StyledTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({

    // input label when focused
    "& label.Mui-focused": {
        color: "#fff"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        borderColor: "#fff",
        "&.Mui-focused fieldset": {
            borderColor: "#fff"
        }
    }
}));


export default function PrivateEquity() {

    const dispatch = useDispatch();

    const privateEquity = useSelector((state) => state.chatGpt.privateEquity)

    const handlePEChange = (event) => {
        dispatch(setPrivateEquity(event.target.value))
    };

    //TODO replace by a queryAPI once implemented
    const [privateEquities, setPrivateEquities] = useState([
        'KKR',
        'X'
    ])

    return (
        <>
            <Grid display="flex" flexDirection="row" justifyContent="space-between" sx={{ marginX: 2 }}>
                <Typography color='white' mb={0}>Pick a PE</Typography>
                <Grid>
                    <AddIcon sx={{ cursor: "pointer", color: "white" }} />
                </Grid>
            </Grid>
            <StyledAutocomplete
                id="privateequity-selector"
                width="100%"
                size="small"
                sx={{ marginX: 2 }}
                options={privateEquities.map((pe) => pe)}
                renderInput={(params) => <StyledTextField {...params} label="Private Equity" />}
                onChange={handlePEChange}
            />
        </>
    )
}
