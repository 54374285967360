/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { Box, Stack, Divider, Grid, Typography, useMediaQuery } from "@mui/material"

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';        // eslint-disable-next-line
import { setmodalState, setText, setButton, setchatBot, setOpenSide, setTargetValue } from "../../redux/slices/navSlice"


import KeyboardArrowDownIcon from
    "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from
    "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import navButton from "./navButtons"
import "./layout.css" // eslint-disable-next-line 
import colorKairos from "../../theme/images/GuestKairos Logo.svg";
import liveIcon from "../../theme/images/blackBackgroundIcons/Live_Black BG.png"
import liveIconB from "../../theme/images/blackBackgroundIcons/Live_white BG.png"
const userChecks = JSON.parse(window.localStorage.getItem("user"))// eslint-disable-next-line
const isRmChecks = userChecks?.userTypeId?.description === "Entity RM" ? true : false
// const assistanceButtons = [
//     {
//         index: 26,
//         text: "FAQs",
//         path: "/enquiry"
//     },
//     {
//         index: 27,
//         text: isRmChecks ? "Chat" : "Chat with RM",
//         path: "/chatqueries"
//     },
//     {
//         index: 28,
//         text: "Chat with Kairos Genie",
//         path: "/chatgpt"
//     },

// ]

// eslint-disable-next-line
const bordeauxButtons = [{
    index: 35,
    text: "Bill Of Materials",
    path: "/billofmaterials"
},
{
    index: 36,
    text: "Configure Freight Charges",
    path: "/configureCharges"
},]


const newNavItem = {
    index: 27,
    text: "Live Trade - Bordeaux",
    icon: liveIconB,
    icon2: liveIcon,
    path: "/bordeaux"
};

function SideNavbar() {
    const navigate = useNavigate()
    const locationPath = window.location.pathname
    console.log(window.location.pathname)
    const text = useSelector((state) => state.nav.text);
    const Button = useSelector((state) => state.nav.Button)
    const modal = useSelector((state) => state.nav.modalState)

    const chat = useSelector((state) => state.nav.chatBot)// eslint-disable-next-line
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const unreadCount = useSelector((state) => state.nav.unreadCount)
    console.log(Button, "buttonclicked")
    const dispatch = useDispatch();
    console.log(text, "text")
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userRights = user?.userRights
    const hniUser = user?.userTypeId?.description;
    const isHni = (hniUser === "Client Head" || hniUser === "Client Family Member") ? true : false;
    console.log(userRights, "userRights")
    console.log(navButtons, "navButtons")
    const navButtons2 = (isHni || user?.userTypeId?.description === "Entity RM") ? navButton.filter((val) => val.text !== "Live Trade - Bordeaux").reduce((acc, item) => {
        acc.push(item);
        if (item.index === 3) {
            acc.push(newNavItem);
        }
        return acc;
    }, []) : navButtons

    console.log("updatedNavButton", navButtons2)
    const modulesWithPermissions = userRights?.filter((permissionObj) => (permissionObj.create || permissionObj.readOnly || permissionObj.approve)) || [];
    console.log(modulesWithPermissions, "modules")
    let moduleNames = modulesWithPermissions?.map((obj) => obj?.module?.replace(/\s/g, ''));
    console.log(moduleNames, "moduleNames")
    const NewButtons = navButtons2?.filter((buttonObj) => moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, '')) !== -1) || [];
    const bordButtons = bordeauxButtons?.filter((buttonObj) => moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, '')) !== -1) || [];
    console.log(bordButtons, "borddddddddd")
    console.log("NAV BUTTON==> 1", navButtons2?.filter((buttonObj) => moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, '')) !== -1))// eslint-disable-next-line
    // eslint-disable-next-line
    const [openBordeaux, setOpenBordeaux] = useState(false);
    // eslint-disable-next-line
    const [mobileSidebar, setMobileSidebar] = useState([]);

    useEffect(() => {
        dispatch(setButton(NewButtons[0]))
        console.log("NAV BUTTON==>", NewButtons)
        for (let i = 0; i < NewButtons.length; i++) {
            if (locationPath.includes(NewButtons[i]?.path)) {
                dispatch(setButton(NewButtons[i]))
                dispatch(setText(NewButtons[i].text))
                break;
            }
            else if (locationPath.includes(bordButtons[i]?.path)) {
                dispatch(setButton(bordButtons[i]))
                dispatch(setText(bordButtons[i].text))
                break;
            }
            else {
                const data = window.location.href.includes('/chatgpt')
                console.log(data, 'test')

                if (window.location.href.includes('/chatgpt')) {
                    dispatch(setTargetValue('ChatGpt'))
                    dispatch(setchatBot(true))
                }
                else if (window.location.href.includes('/kairosbot')) {
                    dispatch(setTargetValue('llm'))
                    dispatch(setchatBot(true))
                }
                else if (window.location.href.includes('/chatgpt/history')) {
                    dispatch(setTargetValue('ChatGpt'))
                    dispatch(setchatBot(true))

                } else if (window.location.href.includes('/kairosbot/history')) {
                    dispatch(setTargetValue('llm'))
                    dispatch(setchatBot(true))
                }
                else {
                    dispatch(setchatBot(false))
                }
                dispatch(setButton({}))
                dispatch(setText(""))
            }
        }
        // for (let i = 0; i < assistanceButtons.length; i++) {
        //     if (locationPath.includes(assistanceButtons[i].path)) {
        //         dispatch(setButton(assistanceButtons[i]))
        //         dispatch(setText(assistanceButtons[i].text))
        //     }
        // }
        // if (locationPath.includes("/prospectprofile")) {
        //     // const button = navButtons.filter(val => val.path === notification.path)[0]
        //     console.log("trrrrrrrr")
        //     dispatch(setButton({}))
        //     // console.log(button, "buttonnnnnn")
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        console.log("mobileuseffect")

        if (isMobile) {
            console.log("isMobile================", isMobile)
            // const newValue = assistanceButtons?.filter(val => val.text !== "Chat with RM" || val.text !== "Chat")
            // setMobileAssisstance(newValue)
            const newSidebarValue = NewButtons
            setMobileSidebar(newSidebarValue)
        } else {
            console.log("else part")
            // setMobileAssisstance(assistanceButtons)
            setMobileSidebar(NewButtons)
        }// eslint-disable-next-line


        // eslint-disable-next-line
    }, [isMobile])
    const handleSideClose = () => {
        dispatch(setOpenSide(false))
    }
    console.log(chat, 'chatssssssssssssss')

    console.log(mobileSidebar, "sideeeeee")

    return (
        <>
            <Box sx={{ background: "#171B24" }}>

                <Stack direction="column" alignItems="center">
                    <Box pr={4} >

                        <Grid container sx={{ marginTop: { sm: "37px", xs: "25px" } }}>
                            <Grid item xl={11} lg={11}   >
                                <Typography ml={3} sx={{ fontSize: "22px", fontWeight: "550", marginLeft: { xs: "-18%", sm: "4%" }, cursor: "pointer !important", display: "flex", justifyContent: "center" }} >
                                    {/* <img src={colorKairos} alt="KAIROS" sx={{ cursor: "pointer !important" }} onClick={() => { navigate("/dashboard") }} /> */}
                                    <img src={"https://www.indiumsoftware.com/wp-content/uploads/2023/11/logo_fixed.png"} alt="indium" style={{ cursor: "pointer !important", width: "70%", objectFit: "contain", margin: "auto" }} />

                                </Typography>

                            </Grid>



                        </Grid>
                    </Box>
                    <Stack spacing={0.1} mt={{ sm: 6, xs: 3 }} direction="column" sx={{
                        maxHeight: "80vh", overflowX: "hidden", width: "85%", overflowY: 'auto', '&::-webkit-scrollbar': {
                            width: '3px',
                        },

                    }}>
                        {mobileSidebar?.map((button) =>
                            button.text === "Divider" ? (<Divider key={button.index} style={{ margin: "20px 0px" }} />) : (

                                <Box key={button.index} onClick={() => {

                                    dispatch(setButton(button))
                                    if (button.text === !"RM Performance") {
                                        dispatch(setText(button.text))
                                    }
                                    handleSideClose()
                                    navigate(button.path)
                                    if (!button.path) {
                                        dispatch(setmodalState(true))
                                        // console.log("shan1",button.path)
                                    }
                                }} className="navList" sx={button.path === Button?.path ? { backgroundColor: (!locationPath.includes("/prospectprofile") && !modal) ? "#6442C4" : null, color: "#FFFFFF" } : { color: "#FFFFFF" }}>
                                    <Grid container pr={2} pl={2} >
                                        <Grid item xl={2} lg={2} sm={2} md={2} pt={0} pr={1}>
                                            <Typography className="icon"><img src={button?.icon2} alt="" style={{ width: "15px", height: "15px", fontFamily: "Mona Sans" }} /></Typography>
                                        </Grid>
                                        <Grid item lg={button.text === "Chatbot Queries" ? 9 : 10} xl={button.text === "Chatbot Queries" ? 9 : 10} sm={button.text === "Chatbot Queries" ? 9 : 10} md={button.text === "Chatbot Queries" ? 9 : 10} pt={0.2}>
                                            <Typography pt={0} style={{ fontSize: "12px", whiteSpace: "nowrap", fontFamily: "Mona Sans" }}>{button.text === "Live Trade - Bordeaux" ? (
                                                <>
                                                    <span className='liveWord'>Live</span>
                                                    <span style={{ color: "red" }}>trade </span>
                                                    - Bordeaux
                                                </>
                                            ) : (
                                                button.text
                                            )}
                                            </Typography>
                                        </Grid>
                                        {button.text === "Chatbot Queries" &&
                                            <Grid item lg={1} xl={1} sm={1} md={1} pt={0.2}>
                                                <Typography pt={0} style={{ fontFamily: "Mona Sans", fontSize: "12px", whiteSpace: "nowrap" }}>{unreadCount}</Typography>
                                            </Grid>
                                        }


                                    </Grid>
                                </Box>

                            )
                        )}

                        {bordButtons?.length > 0 && (<Box className="navlist" sx={{ color: "white" }}>
                            <Grid container pl={2}>

                                <Grid item xl={2} lg={2} sm={2} md={2} pt={0} pr={1}>
                                </Grid>
                                <Grid item lg={8} xl={8} sm={8} md={8} pt={0.2}>
                                    <Typography pt={0} style={{ fontSize: "12px", whiteSpace: "nowrap", cursor: "pointer", fontFamily: "Mona Sans" }} onClick={() => setOpenBordeaux(!openBordeaux)}
                                    >Bordeaux ({bordButtons?.length})</Typography>
                                </Grid>
                                <Grid item lg={2} xl={2} sm={2} md={2} pt={0.2}>
                                    <IconButton
                                        onClick={() => setOpenBordeaux(!openBordeaux)}
                                        aria-label="expand"
                                        size="small"
                                        sx={{ color: "white", paddingBlock: "0px", alignItems: "start", fontSize: "12px", marginTop: "-2px" }}
                                    >
                                        {openBordeaux ? <KeyboardArrowDownIcon />
                                            : <KeyboardArrowRightIcon />}
                                    </IconButton>
                                </Grid>

                                {openBordeaux && <Grid container pl={2}>
                                    {bordButtons?.map(button => {
                                        return (
                                            <Grid item key={button?.text} lg={12} xl={12} sm={12} md={12} xs={8} pt={0.5} pl={4.5} pb={1}>
                                                <Typography sx={button.path === Button?.path ? { backgroundColor: "#6442C4", color: "#FFFFFF", fontFamily: "Mona Sans", borderRadius: "5px", paddingInline: "8px", paddingBlock: "6px", cursor: "pointer", fontSize: 12 } : { color: "#FFFFFF", fontFamily: "Mona Sans", cursor: "pointer", fontSize: 12 }} onClick={() => {
                                                    navigate(button?.path)
                                                    dispatch(setText(button?.text))
                                                    dispatch(setButton(button))

                                                }}>{button?.text}</Typography>
                                            </Grid>
                                        )

                                    })}
                                </Grid>}
                            </Grid>
                        </Box>)}
                        {/* <Box className="navList" sx={{ color: "white" }}>
                            <Grid container pl={2}>
                                <Grid item xl={2} lg={2} sm={2} md={2} pt={0} pr={1}>
                                </Grid>
                                <Grid item lg={8} xl={8} sm={8} md={8} pt={0.2}>
                                    <Typography pt={0} style={{ fontSize: "12px", whiteSpace: "nowrap", fontFamily: "Mona Sans" }} onClick={() => setOpen(!open)}
                                    >{`Assistance (${isHni ? 3 : 2})`}</Typography>
                                </Grid>
                                <Grid item lg={2} xl={2} sm={2} md={2} pt={0.2}>
                                    <IconButton
                                        onClick={() => setOpen(!open)}
                                        aria-label="expand"
                                        size="small"
                                        sx={{ color: "white", paddingBlock: "0px", alignItems: "start", fontSize: "12px", marginTop: "-2px", fontFamily: "Mona Sans" }}
                                    >
                                        {open ? <KeyboardArrowDownIcon />
                                            : <KeyboardArrowRightIcon />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                            {open && <Grid container pl={2}>
                                                                {mobileAssisstance?.map(button => {

                                    return (
                                        <Grid item key={button?.text} lg={12} xl={12} sm={12} md={12} xs={8} pt={0.5} pl={4.5} pb={1}>
                                            {!(!isHni && button?.path === "/chatgpt") && (<Typography pt={0} style={{ fontSize: "12px", whiteSpace: "nowrap", fontFamily: "Mona Sans" }} onClick={() => {
                                                if (button.path === "/chatgpt") {
                                                    dispatch(setchatBot(true))
                                                    dispatch(setTargetValue('ChatGpt'))
                                                    navigate(button.path)

                                                }
                                                else if (button.text === "Chat with RM" || button.text === "Chat") {
                                                    dispatch(setButton(button))

                                                    if (!isHni) {
                                                        dispatch(setText(button.text))
                                                    }
                                                    if (isHni) {
                                                        dispatch(setChatWindowShow(true))
                                                    }
                                                    else {
                                                        navigate(button.path)
                                                    }

                                                }
                                                
                                                else if (targetValue === 'ChatGpt') {
                                                    if (button.path === "/chatgpt/history") {
                                                        dispatch(setchatBot(true))
                                                        dispatch(setTargetValue('ChatGpt'))
                                                        navigate(button.path)
                                                    }
                                                }

                                                else {
                                                    console.log(button, "buttonnnnnnnn")
                                                    dispatch(setButton(button))

                                                    dispatch(setText(button.text))

                                                    dispatch(setchatBot(false))
                                                    navigate(button.path)

                                                }
                                                handleSideClose()

                                            }

                                            } sx={button.path === Button?.path ? { backgroundColor: "#6442C4", color: "#FFFFFF", fontFamily: "Mona Sans", borderRadius: "5px", paddingInline: "8px", paddingBlock: "6px" } : { color: "#FFFFFF", fontFamily: "Mona Sans" }}>{button.text}</Typography>)}
                                        </Grid>
                                    )
                                })
                                }

                            </Grid>}

                        </Box> */}




                    </Stack>


                </Stack>
            </Box >

        </>
    )
}

export default SideNavbar
export const navButtons = navButton