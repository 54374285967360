import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom"
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { AES256_GCM_ENCRYPT } from "../../../constant/EncryptionDecryptionUtils"
import * as yup from 'yup';
import { toast } from 'react-toastify';
// import { result } from 'lodash';
import { forgotPassword } from '../../../services';// eslint-disable-next-line 
import { login } from '../../../redux/slices/authSlice';
import "./login.css"
import Loader from '../../common/Loader/Loader';
import '../../../theme/styles/globalStyles.css'


const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  // ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)\?\\"!@#%&\/\\,><':;|_~`=+\-])[a-zA-Z\d!@#\$%\^&\*\(\)\?\\"!@#%&\/\\,><':;|_~`=+\-]{8,}$

  password: yup
    .string('Enter your password')
    .matches(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-])[a-zA-Z\d!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character (^ $ * . [ ] { } ( ) ? \"! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)"
    )
    .required('Password is required'),
});
const forgotPasswordValidationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
});

const Login = () => {
  const [showVerified, setShowVerified] = useState(false);
  const [showinvalid, setShowinvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [fpassword, setFpassword] = useState(false);
  const [searchParams] = useSearchParams();
  const fcmTokenForMobile = searchParams?.get('fcmToken')
  // const [loginmessage, setLoginmessage] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));// eslint-disable-next-line 
  const isMobileTab = useMediaQuery(theme.breakpoints.down('md'));// eslint-disable-next-line 
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));// eslint-disable-next-line 

  const dispatch = useDispatch();// eslint-disable-next-line 
  const navigate = useNavigate();// eslint-disable-next-line 
  const isLoading = useSelector((state) => state?.auth.loading);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: fpassword ? forgotPasswordValidationSchema : validationSchema,
    onSubmit: async (values) => {
      // console.log(window.localStorage.getItem("fcmToken"), "window.localStorage.getItem")
      // values.fcmToken = JSON.stringify(await window.localStorage.getItem("fcmToken")) || "";
      // values.isMobile = await window.localStorage.getItem("isMobile") || false;
      let os = window.navigator.userAgent;

      let finalOs = "";
      if (os.search('Windows') !== -1) {
        finalOs = "Windows";
      }
      else if (os.search('Mac') !== -1) {
        finalOs = "MacOS";
      }
      else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
        finalOs = "UNIX";
      }
      else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
        finalOs = "Linux"
      }
      if (["Linux", "UNIX", "MacOS", "Windows"].includes(finalOs)) {
        values.isMobile = false
      }
      else {
        values.isMobile = true
      }
      //values.fcmToken = fcmTokenForMobile
      if (fpassword) {// eslint-disable-next-line
        const obj = {
          email: values.email,
        }
        // try{
        //   const response = await forgotPassword(obj);
        //   response.status === 200 && toast.success("A reset password link has been sent to your registered email")
        // }
        // catch(error) {
        //   toast.error(error.response.message || "Please enter a valid email")
        // }
        // alert(`Reset mail sent to ${values.email}`)
        // const forgotPassword = async () => {
        //   const forgotPasswordPromise = await logIn(obj);
        // const fetchPromise = await fetch('http://13.229.126.35:8080/api/v1/common/forgot-password', {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify(obj)
        // })
        // const result = await fetchPromise.json();
        //   const result = await forgotPasswordPromise.json();
        //   console.log("result forgot password", result)
        //   if(result.status === true) toast.success(`Reset password mail sent to ${values.email}`);
        //   if(result && result.status === false) toast.error(result?.statusMessage);
        // }
        // forgotPassword();
      }
      else {
        console.log(fcmTokenForMobile, "fcmTokenForMobile")
        let formvalues = {
          email: await AES256_GCM_ENCRYPT(values.email),
          password: await AES256_GCM_ENCRYPT(values.password),

        }
        localStorage.clear();
        setTimeout(async () => {
          if (fcmTokenForMobile) {
            formvalues.fcmToken = fcmTokenForMobile;
            await dispatch(login({ formvalues, navigate, toast }))
          }
          else {
            await dispatch(login({ formvalues, navigate, toast }))
          }
        }, 1000)





        // const fetchLogin = async () => {
        // const fetchLoginPromise = await logIn(formvalues);
        // const fetchPromise = await fetch('http://13.229.126.35:8080/api/v1/common/hni-login', {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify(formvalues)
        // })
        // const result = await fetchPromise.json();
        // const result = await fetchLoginPromise.json();
        // const fetchPromise = await logIn(JSON.stringify(formvalues));
        // console.log("result", result)
        // {result.status ? alert("Signed In successfully") : alert(result?.statusMessage)}
        // if(result.status === true) toast.success("Sign in successfull");
        // if(result && result.status === false) toast.error(result?.statusMessage);
        // }
        // fetchLogin()
        // .then(response => {
        //   console.log("response", response)
        // })
        // .catch(error => {
        //   console.log("error", error)
        //   if(error) toast.error("User name or password is incorrect");
        // });
      }
    },
  });
  // const notify = () => toast("Wow so easy!");
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickForgotPassword = () => {
    setFpassword(true);
    const obj = {
      email: formik.values.email,
    }
    if (formik.values.email !== "") {
      const handleForgotPassword = async () => {
        try {
          const response = await forgotPassword(obj);
          if (response.data.status === false) {
            toast.error(response.data.errorMessage)
            // setFpassword(true);
          }
          if (response.data.status === true) {
            toast.success("A reset password link has been sent to your primary email and secondary email")
          }
          setFpassword(false);
        }
        catch (error) {
          toast.error(error.response.message || "Please enter a valid email")
        }
      }
      handleForgotPassword();
    }
    else {
      formik.handleSubmit();
    }
  }
  // const handleClickVerifyPass = () => {
  //   alert("verification code alert")
  //   formik.handleSubmit();
  //   alert("clicked")
  // }
  // const handleForgotPasswordSubmit = () => {
  //   alert("Mail sent successfully")
  // }
  useEffect(() => {
    const verifyMail = () => {
      if (Object.keys(formik.errors).length === 1 && formik.errors.hasOwnProperty('password')) {
        setShowVerified(true)
        setShowinvalid(false)
      }
      else if (formik.errors.email !== "Email is required" && formik.errors.email !== "Enter a valid email" && formik.values.password.length > 7) {
        setShowVerified(true)
        setShowinvalid(false)
      }
      else if (Object.keys(formik.errors).length === 0 && formik.values.email) {
        setShowVerified(true)
        setShowinvalid(false)
      }
      else if (formik.errors.email === "Enter a valid email") {
        setShowVerified(false)
        setShowinvalid(true)
      }
      else {
        setShowVerified(false)
        setShowinvalid(false);
      }
    }
    verifyMail();

  }, [formik, fpassword])
  useEffect(() => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
  }, [])
  // if(fpassword) {
  //   return (
  //     <div>
  //     <Box
  //       sx={{
  //         width: 300,
  //         height: 300,
  //         marginLeft: "auto",
  //         marginRight: "auto",
  //         marginTop: "15%"
  //       }}>
  //       {/* {(loginmessage === "invalid username or password" && !fpassword) ? <p style={{ color: "red", textAlign: "center" }}>Invalid username or password</p> : null} */}
  //       {/* <form onSubmit={handleForgotPasswordSubmit}> */}
  //       <form onSubmit={formik.handleSubmit}>
  //         <section className='form-copy' style={{ textAlign: "left" }}>
  //           <h2>Forgot password?</h2>
  //         </section>
  //         <div className='input-container email'>
  //           <TextField
  //             size="small"
  //             fullWidth
  //             id="email"
  //             name="email"
  //             label="Email"
  //             value={formik.values.email}
  //             onChange={formik.handleChange}
  //             error={formik.touched.email && Boolean(formik.errors.email)}
  //             helperText={formik.touched.email && formik.errors.email}
  //             sx={{
  //               marginBottom: "10%",
  //             }}
  //             InputProps={{
  //               endAdornment: (
  //                 <InputAdornment position="end">
  //                   {showVerified ? <CheckSharpIcon sx={{ color: "green", fontSize: 20 }} /> : null}
  //                 </InputAdornment>
  //               ),
  //             }}
  //           />
  //         </div>

  //         <Button className='signin-button' fullWidth variant="contained" type="submit" style={{ backgroundColor: "#21ba56" }}>
  //           Get verification mail
  //         </Button>
  //       </form>
  //     </Box>
  //   </div>
  //   )
  // }
  return (

    <div style={{ display: "flex", justifyContent: "center" }}>

      {isLoading && <Loader isLoading={isLoading} />}
      <Box
        sx={{
          height: "340px",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",



          margin: isMobileTab ? "20%" : "10%"
        }}>

        {/* {(loginmessage === "invalid username or password" && !fpassword) ? <p style={{ color: "red", textAlign: "center" }}>Invalid username or password</p> : null} */}

        <form onSubmit={formik.handleSubmit}>
          <section className='form-copy' style={{ textAlign: "left", marginBottom: "25px", color: isSmallDevice ? "white" : null }}>
            <h2 style={{ fontFamily: "Mona Sans", fontSize: isSmallDevice ? "25px" : "16px", fontWeight: 600 }}>Sign in</h2>
            {/* <div className='login-container'>
                        <p>New user? Create an account</p>
                    </div> */}
          </section>
          <div className='input-container email'>
            {isMobileTab && <Typography variant="h6" style={{ marginBottom: "5px", marginTop: "5px", fontSize: "13px", fontFamily: "Mona Sans", color: "white" }}>
              Email address
            </Typography>}
            <TextField
              size="small"
              // autoFocus
              id="login_email"
              InputLabelProps={{ shrink: true }}
              name="email"
              label={isSmallDevice ? "" : "Email address"}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                marginBottom: "10%",
                width: "300px",
                borderColor: matches ? "white" : "black",

                '& input::placeholder': {
                  textOverflow: 'ellipsis !important',
                  color: matches ? "black !important" : "",
                  opacity: 1,
                },

                '& .MuiInputBase-root': {
                  height: '40px !important',
                  background: '#FFFFFF 0% 0% no-repeat padding-box',
                  borderRadius: '8px',

                },
                // input: {
                //   color: matches ? "white" : "#000000"
                // },
                // label: {
                //   color: matches ? "white" : "#000000"
                // }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showVerified && <CheckSharpIcon sx={{ color: "green", fontSize: 20 }} />}
                    {showinvalid && <CloseIcon sx={{ color: "red", fontSize: 20 }} />}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className='input-container password'>
            {isMobileTab && <Typography variant="h6" style={{ marginBottom: "5px", marginTop: "5px", fontSize: "13px", fontFamily: "Mona Sans", color: "white" }}>
              Password
            </Typography>}
            <TextField
              size="small"
              // autoFocus
              id="login_password"
              name="password"
              label={isSmallDevice ? "" : "Password"}
              InputLabelProps={{ shrink: true }}
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              disabled={fpassword === true ? true : false}
              error={fpassword ? null : (formik.touched.password && Boolean(formik.errors.password))}
              helperText={fpassword ? null : (formik.touched.password && formik.errors.password)}
              sx={{
                marginBottom: "10%",
                width: "300px",
                // input: {
                //   color: matches ? "white" : "#000000"
                // },
                // label: {
                //   color: matches ? "white" : "#000000"
                // },


                '& input::placeholder': {
                  textOverflow: 'ellipsis !important',
                  color: '#363636',
                  opacity: 1,
                },
                '& .MuiInputBase-root': {
                  height: '40px !important',
                  background: '#FFFFFF 0% 0% no-repeat padding-box',
                  borderRadius: '8px',

                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>

          <Button className='signin-button PrimaryColor' disabled={(formik.errors.email && formik.errors.password) || (fpassword === true) ? true : false} fullWidth variant="contained" type="submit" style={{ backgroundColor: "#21ba56", fontFamily: "Mona Sans" }}>
            Sign in
          </Button>
          <Link onClick={handleClickForgotPassword} color={isSmallDevice ? "#ffffff" : "#000000"} sx={{ display: "block", textAlign: "center", marginTop: "5%", cursor: "pointer", fontFamily: "Mona Sans" }}>
            {'Forgot password?'}
          </Link>
          {/* <div className='login-container' style={{ textAlign: "center" }}>
            <p>Forgot Password</p>
          </div> */}
        </form>
        {/* <ToastContainer /> */}
      </Box>
    </div>
  )
}

export default Login
