import React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Paper, Grid, Typography } from '@mui/material';
import { formatNumber } from "../../../constant/DollorFormats";

const DynamicDashboardCards = ({ data, cardsPerRow }) => {
    const Item = styled(Paper)(({ theme }) => ({
        background: 'transparent linear-gradient(180deg, #5312F7 0%, #A383F6 100%) 0% 0% no-repeat padding-box',
        textAlign: 'center',
        padding: '20px 0',
        height: '100%',
        color: '#FFF',
        borderRadius: '8px',
    }));

    const calculateGridSize = () => {
        const totalCards = data?.length;
        const defaultCardsPerRow = cardsPerRow || 6; // Change this value as needed
        console.log(defaultCardsPerRow, "defaultCardsPerRow")
        return Math.min(totalCards, defaultCardsPerRow);
    };

    return (
        <div>
            {data?.length > 0 && <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                {data?.map((item, index) => (
                    <Grid key={`gridItem_${index}`} item lg={12 / calculateGridSize()} xs={calculateGridSize()} >
                        <Item>
                            <Typography sx={{ fontFamily: 'Mona Sans' }}>{item?.header}</Typography>
                            <Typography sx={{ fontSize: '20px' }}>{item?.format === "currency" ? `US $${formatNumber(item?.value)}` : item?.format === "number" ? `${formatNumber(item?.value, 0)}` : item?.value}</Typography>
                        </Item>
                    </Grid>
                ))}
            </Grid>}

        </div>
    );
};

export default DynamicDashboardCards;
