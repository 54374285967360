import { Axios1 } from "./config";// eslint-disable-next-line

let productIDVALUE = {
    productIDDetails: {
        vendorId: "",
        productServiceId: "",
        productServiceName: "",
        productServiceDescription: "",
        productServiceTypeId: "",
        primaryCategoryId: "",
        secondaryCategoryId: "",
        startDate: "",
        endDate: "",
        returnsLevel: "",
        riskLevel: "",
        liquidity: "",
        hnisInvested: "",
        taxStatus: "",
        tags: "",
        regionId: "",
        investmentTerm: "",
        takeRate: "",
        takeRateParameterId: "",
        value: "",
        productServicePhotoUrl: ""
    }
};

function setProductIDDetails(value) {
    console.log("Values Passed===>", value)
    productIDVALUE.productIDDetails = value;
}

export {
    productIDVALUE,
    setProductIDDetails
}

export const productServicemanagementDropDownList = async () => {

    const promise1 = Axios1.get("/api/v1/productservice/getall-dropdown-productservice");
    var resultService = await Promise.all([promise1]).then(function (values) {

        return values;
    });

    return resultService;

}

export const productServiceManagementGetDatalist = async (passingValue) => {

    // 
    var resultService2 = await Axios1
        .post(`/api/v1/productservice/getall-products-services`, passingValue)

    return resultService2.data;
    // if (pageNumber <= 0) {
    //     var resultService1 = await Axios
    //         .get(`/api/v1/admin/getall-financialproducts/0/10`)

    //     return resultService1.data;
    // }
    // else {
    //     var resultService2 = await Axios
    //         .get(`/api/v1/admin/getall-financialproducts/${pageNumber}/10`)

    //     return resultService2.data;
    // }




}

export const productServiceManagementGetDataByID = async (id) => {

    var resultService = await Axios1
        .get(`/api/v1/productservice/get-productservice/${id}`)
    console.log(resultService, "resultService")
    if (resultService.data.status) {

        await setProductIDDetails(resultService.data.body);
        console.log("Values Passed===>", productIDVALUE.productIDDetails)
        return productIDVALUE.productIDDetails;
    }



}

export const addProductsServiceNew = async (passingValue) => {

    // var newPassing = passingValue;
    // newPassing.startDate = new Date(passingValue.startDate)
    // newPassing.endDate = new Date(passingValue.endDate)

    var resultService = await Axios1
        .post(`/api/v1/productservice/add-productservice`, passingValue)

    return resultService;
}

export const editProductservicesUpdate = async (passingValue) => {

    var resultService = await Axios1
        .post(`/api/v1/productservice/update-productservice`, passingValue)

    return resultService;
}
export const deleteProductserviceId = async (passingValue) => {

    var resultService = await Axios1
        .post(`/api/v1/productservice/update-productservice-status`, passingValue)

    return resultService;
}

export const applyPromoteByProductServiceID = async (passingValue) => {



    var resultService = await Axios1
        .post(`/api/v1/productservice/promote-by`, passingValue)
    console.log(resultService, "returnValuesFromAPI")
    return resultService;
}
export const startPromotionAPI = async (passingValue) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/start-promote`, passingValue)

    return resultService;
}

//

export const getAllProductTags = async () => {
    var resultService = await Axios1
        .get(`/api/v1/productservice/get-all-product-tags`)

    return resultService;
}

export const getAllTagsandLocation = async () => {
    var resultService = await Axios1
        .get(`/api/v1/productservice/get-all-tags`)

    return resultService;
}


export const stopPromotionAPI = async (passingValues) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/update-promote`, passingValues)
    // .post(`/api/v1/admin/update-promote-financial-products`, passingValues)

    return resultService;
}


export const rejectwithReasonsorApproval = async (passingValues) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/submit-productservice`, passingValues)
    // .post(`/api/v1/admin/update-promote-financial-products`, passingValues)

    return resultService;
}


export const resubbmitforApproval = async (passingValue) => {
    var resultService = await Axios1.post(`/api/v1/productservice/resubmit-productservice`, passingValue)
    return resultService;
}
export const downloadManagement = async (passingValue) => {

    const payload = await Axios1.post(`/api/v1/productservice/productservice/export/excel`, passingValue, { responseType: 'blob' });
    return payload;
}

export const searchFilterAutoComplete = async (searchParams, PID) => {
    var resultService = await Axios1
        .get(`api/v1/productservice/alphavantage-search-endpoint/${searchParams}/${PID != null ? PID : 0}`)

    return resultService;


}

export const assetAllocationAPI = async (passingValue) => {
    var resultService = await Axios1.post(`/api/v1/productservice/update-available-chosen-entity`, passingValue)
    return resultService;
}

export const submitDocProductService = async (formData) => {

    var resultService = await Axios1
        .post(`/api/v1/productservice/upload-file`, formData,
            {
                headers: { 'content-type': 'multipart/form-data' },//transformRequest: formData => formData,
            },
        )


    return resultService;



}



export const downloadDOCUMENTSProductService = async (passingValue) => {
    var resultAPI = await Axios1
        .post("/api/v1/productservice/download-file", passingValue, { responseType: 'arraybuffer' }
        )
    return resultAPI;

}

export const deleteDocumentsProductService = async (passingValues) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/delete-file`, passingValues)


    return resultService;
}





