import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import AuthReducer from "./slices/authSlice";
import UserManagementReducer from "./slices/userManagement";
import navReducer from "./slices/navSlice";
import loadingSlice from "./slices/loadingSlice";
import ChatGptSlice from './slices/ChatGptSlice';
import modalSlice from './slices/dashboardModalSlice'
import globalCurrencySlice from "./slices/globalCurrencySlice";

export default configureStore({
    reducer: {
        auth: AuthReducer,
        userManagement: UserManagementReducer,
        nav: navReducer,
        loader: loadingSlice,
        chatGpt: ChatGptSlice,
        modal: modalSlice,
        globalCurrency: globalCurrencySlice
    },
    middleware: [thunk, logger]
});
