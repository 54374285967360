import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from '@mui/material';
Chart.register(CategoryScale);

export default function BARWITHLINE(props) {

    const [chartData] = useState({
        responsive: true,
        // maintainAspectRatio: false,
        // aspectRatio: 0.6,
        labels: props?.data?.label,
        datasets: [
            {
                type: "line",

                fill: false,
                // label: "Peak",
                borderColor: "#6A3BE0",
                borderWidth: 1,
                borderDash: [5, 5],
                tension: 0.4,
                data: props?.data?.data,
                datalabels: {

                    // labels: true
                    labels: {

                        title: null
                    }
                },
            },
            {
                type: "bar",
                // label: "Revenue",
                backgroundColor: "#6A3BE0",
                data: props?.data?.data,
                datalabels: {
                    labels: {
                        title: null
                    }
                },
                borderColor: "white",
                borderWidth: 2
            }
        ]
    });
    const isMobileOrTablet = useMediaQuery('(max-width: 1279px)');
    // eslint-disable-next-line
    const [lightOptions] = useState({
        maintainAspectRatio: false,
        aspectRatio: 0.6,

        plugins: {
            datalabels: {
                labels: {
                    title: {
                        color: "#000000"
                    }
                },
                align: "end",
                anchor: "end",


                color: "black"

            },

        },
        scales: {
            x: {
                ticks: {
                    color: "#495057"
                },
                grid: {
                    color: "#ffffff"
                }
            },
            y: {
                ticks: {
                    color: "#495057"
                },
                grid: {
                    color: "#ebedef",
                    borderDash: [5, 5]
                }
            }
        }
    });// eslint-disable-next-line
    const [containerHeight, setContainerHeight] = useState("70%");

    const getContainerHeight = () => {
        const windowHeight = window.innerHeight;
        console.log(windowHeight, "windowHeight hook windowHeightv");
        if (windowHeight >= 1200 && windowHeight <= 1299) {
            return '80%';
        } else if (windowHeight >= 1300 && windowHeight <= 1400) {
            return '99%';
        }
        else if (windowHeight >= 1400 && windowHeight <= 2000) {
            return '100%';
        }
        else if (windowHeight >= 2001 && windowHeight <= 3000) {
            return '100%';
        }
        else if (windowHeight >= 800 && windowHeight <= 900) {
            return '75%';
        }
        else if (windowHeight >= 700 && windowHeight <= 799) {
            return '73%';
        }
        else if (windowHeight <= 500) {
            return '90%';
        }


        return '90%'; // Default height
    };

    // Update height on window resize
    useEffect(() => {
        const handleResize = () => {
            const newHeight = getContainerHeight();
            console.log(newHeight, props?.type, "windowHeight hook");
            setContainerHeight(newHeight);
        };

        // Initial call to set height
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };// eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    const footer = (tooltipItems) => {
        return tooltipItems[0].label;
    };
    const getTooltipLabel = (tooltipItem) => {
        console.log(tooltipItem, "tooltipItem")
        if (!tooltipItem.dataset.type) return `US ($) ${tooltipItem.raw}`;

        // const dataset = props.data.datasets[tooltipItem.datasetIndex];
        // const currentValue = dataset.data[tooltipItem.dataIndex];
        // const total = dataset.data.reduce((acc, val) => acc + val, 0);
        // const percentage = ((currentValue / total) * 100).toFixed(2) + "%";

        return `US ($) ${tooltipItem.formattedValue}`;
    };
    return (


        <div style={{ minHeight: "300px", maxHeight: "300px", width: "100%", display: 'flex', justifyContent: 'center' }}>
            <Bar
                data={chartData}
                height={!isMobileOrTablet ? containerHeight : "90%"}
                // height={'300px'} //props?.type === "admin" ? containerHeight : 
                width={"100%"}
                options={{
                    plugins: {
                        title: {
                            display: false,

                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return getTooltipLabel(tooltipItem);
                                }
                            }
                        }
                    }, scales: {
                        x: {
                            ticks: {
                                color: "#495057"
                            },
                            grid: {
                                color: "#ffffff"
                            }
                        },
                        y: {
                            ticks: {
                                color: "black"
                            },
                            grid: {
                                color: "#F6F6F6",
                                borderDash: [5, 5]
                            }
                        }
                    }
                }}
            />
        </div>

    );
}


// import Chart from "chart.js/auto";
// import { CategoryScale } from "chart.js";
// import { useState, useRef, useEffect } from "react";
// import { Bar } from "react-chartjs-2";

// Chart.register(CategoryScale);

// export default function BARWITHLINE(props) {
//     const chartRef = useRef(null);
//     const [chartData] = useState({
//         labels: props?.data?.label,
//         datasets: [
//             {
//                 type: "line",
//                 fill: false,
//                 borderColor: "#6A3BE0",
//                 borderWidth: 1,
//                 borderDash: [5, 5],
//                 tension: 0.4,
//                 data: props?.data?.data,
//                 datalabels: {
//                     labels: {
//                         title: null
//                     }
//                 },
//             },
//             {
//                 type: "bar",
//                 backgroundColor: "#6A3BE0",
//                 data: props?.data?.data,
//                 datalabels: {
//                     labels: {
//                         title: null
//                     }
//                 },
//                 borderColor: "white",
//                 borderWidth: 2
//             }
//         ]
//     });

//     useEffect(() => {
//         let resizeHandler;
//         const chartInstance = chartRef.current?.chartInstance;

//         if (chartInstance) {
//             resizeHandler = () => {
//                 const canvas = chartInstance.canvas;
//                 const container = canvas.parentNode;
//                 canvas.style.height = 'auto';
//                 canvas.style.width = '100%';
//                 const containerHeight = container.clientHeight;
//                 canvas.style.height = `${containerHeight}px`;
//             };

//             window.addEventListener('resize', resizeHandler);
//             resizeHandler();
//         }

//         return () => {
//             window.removeEventListener('resize', resizeHandler);
//         };
//     }, [chartRef]);

//     const getTooltipLabel = (tooltipItem) => {
//         if (!tooltipItem.dataset.type) return `US ($) ${tooltipItem.raw}`;
//         return `US ($) ${tooltipItem.formattedValue}`;
//     };

//     return (
//         <div style={{ width: "100%", height: "100%" }}>
//             <Bar
//                 ref={chartRef}
//                 data={chartData}
//                 options={{
//                     plugins: {
//                         title: {
//                             display: false,
//                         },
//                         legend: {
//                             display: false
//                         },
//                         tooltip: {
//                             callbacks: {
//                                 label: function (tooltipItem) {
//                                     return getTooltipLabel(tooltipItem);
//                                 }
//                             }
//                         }
//                     },
//                     scales: {
//                         x: {
//                             ticks: {
//                                 color: "#495057"
//                             },
//                             grid: {
//                                 color: "#ffffff"
//                             }
//                         },
//                         y: {
//                             ticks: {
//                                 color: "black"
//                             },
//                             grid: {
//                                 color: "#F6F6F6",
//                                 borderDash: [5, 5]
//                             }
//                         }
//                     }
//                 }}
//             />
//         </div>
//     );
// }

