import { createSlice } from "@reduxjs/toolkit";




const navSlice = createSlice({
    name: "nav",
    initialState: {
        text: "Dashboard",
        Button: {},
        profileButton: {},
        openLogout: false,
        loading: true,
        profileImg: "",
        unreadCount: 0,
        chatWindowShow: false,
        surveyTempValue: [],
        refreshAPI: false,
        binderId: "",//Ex: "BcLi1RWRNuEAhLKIeB26DhE"
        chatBot: false,
        openSide: false,
        guestOpen: false,
        surveyId: null,
        warning: false,
        connectWithRm: false,
        moxoAccessToken: '',
        targetValue: 'llm',
        modalState: false,
        fromDate: null,
        toDate: null,
        hniCall: false
    },
    reducers: {
        setmodalState: (state, action) => {
            state.modalState = action.payload;
        },
        setConnectWithRm: (state, action) => {
            state.connectWithRm = action.payload;
        },
        setText: (state, action) => {
            state.text = action.payload;
        },
        setButton: (state, action) => {
            state.Button = action.payload;
        },
        setProfileButton: (state, action) => {
            state.profileButton = action.payload;
        },
        setOpenLogout: (state, action) => {
            state.openLogout = true
        },
        setCloseLogout: (state, action) => {
            state.openLogout = false
        },
        setLoading: (state, action) => {
            return action.payload;
        },
        setProfileImg: (state, action) => {
            state.profileImg = action.payload;
        },
        setUnreadCount: (state, action) => {
            state.unreadCount = action.payload;
        },
        setChatWindowShow: (state, action) => {
            state.chatWindowShow = action.payload;
        },
        setBinderId: (state, action) => {
            state.binderId = action.payload;
        },
        setchatBot: (state, action) => {
            state.chatBot = action.payload;
        },
        setOpenSide: (state, action) => {
            state.openSide = action.payload;
        },
        setGuestOpen: (state, action) => {
            state.guestOpen = action.payload;
        },
        setWarning: (state, action) => {
            state.warning = action.payload;
        },
        setFromDate: (state, action) => {
            state.warning = action.payload;
        }, setToDate: (state, action) => {
            state.warning = action.payload;
        },
        setMoxoAccessToken: (state, action) => {
            state.moxoAccessToken = action.payload;
        },
        setTargetValue: (state, action) => {
            state.targetValue = action.payload;
        },
        setSurveyId: (state, action) => {
            state.surveyId = action.payload.surveyId;
            state.refreshAPI = action.payload.refreshAPI;

        },
        sethniCall: (state,action)=>{
          state.hniCall = action.payload
        },
        setSurveyIDsValue: (state, action) => {
            console.log(action.payload.surveyTempValue, "action.payload.surveyTempValue?.surveyId")
            const index = state?.surveyTempValue?.findIndex(x => x.surveyId === action.payload.surveyTempValue[0]?.surveyId)
            console.log(index, "action.payload.surveyTempValue?.surveyId")
            if (index >= 0) {
                state.surveyTempValue[index] = action.payload.surveyTempValue
                state.surveyId = action.payload.surveyId;
                state.refreshAPI = action?.payload?.refreshAPI;
            } else {
                state.surveyTempValue = [...state.surveyTempValue, ...action.payload.surveyTempValue]
                state.surveyId = action.payload.surveyId;
                state.refreshAPI = action?.payload?.refreshAPI;
            }


        },
        resetSurveryIDVALUES: (state) => {
            state.surveyTempValue = [];
        }

    },

});
export const { sethniCall,setmodalState, setMoxoAccessToken, setConnectWithRm, setText, setButton, setProfileButton, setOpenLogout, setCloseLogout, setProfileImg, setUnreadCount, setChatWindowShow, setBinderId, setchatBot, setOpenSide, setGuestOpen, setSurveyId, setSurveyIDsValue, resetSurveryIDVALUES, setWarning, setTargetValue, setFromDate, setToDate } = navSlice.actions

export default navSlice.reducer