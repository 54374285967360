import React from "react";
import images1 from "../../../theme/images/blackpatterns1.png"
import { Grid, Pagination } from '@mui/material';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import "./CatalogCards.css"
import moment from "moment"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TitleConverstion } from "../../../constant/DollorFormats"
import { formatDate } from "../../common/Utils/DateFormat"
import { UseCurrencyHook } from "../GlobalCurrency/useCurrencyHook";
import { useSelector } from 'react-redux';

let sortItems = [{ id: 1, description: "Price: Low to High" }, { id: 2, description: "Price: High to Low" }, { id: 3, description: "Newest Arrivals" }]
const CardsDisplay = (props) => {

    const [currentPage, setCurrentPage] = React.useState(1);
    const [dataListOnUI, setdataListOnUI] = React.useState([]);
    const rmUser = JSON.parse(localStorage.getItem('user'))
    const rmvalue = rmUser?.userTypeId?.description
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);

    const [filterParam, setFilterParam] = React.useState(sortItems[0].id);// eslint-disable-next-line
    React.useEffect(() => {
        setdataListOnUI(props?.dataList?.sort((a, b) => parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice)))
        // eslint-disable-next-line
    }, [])
    React.useEffect(() => {
        setdataListOnUI(props?.dataList)
    }, [props.dataList])


    const sortingTheItems = async (sortItems) => {

        // setLoading(true)
        setFilterParam(sortItems)
        if (sortItems === 1) {
            setdataListOnUI(props?.dataList?.sort((a, b) => parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice)))
        }
        if (sortItems === 3) {
            setdataListOnUI(props?.dataList?.sort(function (a, b) {
                return moment(b.updatedAt).format('X') - moment(a.updatedAt).format('X')
            }))
        }
        if (sortItems === 2) {
            setdataListOnUI(props?.dataList?.sort((a, b) => parseFloat(b.sellingPrice) - parseFloat(a.sellingPrice)))
        }
    }

    function localPagination(page_size, page_number, sortOrder, data) {
        return data.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };
    if (props.loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <div style={{ marginBottom: "50px" }}>
            <Grid container flexDirection={"row"} spacing={0} mt={"-5px"} mb={"5px"}>

                <Grid item xs={8} lg={4} container flexDirection={"row"} >
                    <Grid> <img alt="icon" src={props?.icon} loading="lazy" /></Grid>
                    <Grid p="5px" >  <Typography noWrap className="headerTypo">{props?.header}</Typography></Grid>

                </Grid>
                <Grid item xs={1} lg={4} p="10px" sx={{ display: { xs: "none", lg: "block" } }}>
                    <Divider orientation="horizontal" variant="fullWidth" flexItem />
                </Grid>
                <Grid xs={4} lg={4} item flexDirection={"row"} >
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth-Catalog"
                        value={filterParam}
                        sx={{
                            fontFamily: "Mona Sans",
                            width: "100%",
                            height: "24px",
                            fontSize: "12px",
                            backgroundColor: "#FEFFE3",
                            '& input::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: '#363636 !important',
                                fontFamily: "Mona Sans",
                                opacity: 1,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: '8px',
                            },

                            '& .MuiInputBase-root': {
                                fontFamily: "Mona Sans",
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                borderRadius: '8px',

                            },
                        }}
                        onChange={(e) => {
                            sortingTheItems(e.target.value)
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}  >
                        <MenuItem selected disabled default value="">
                            <em>Sort By</em>
                        </MenuItem>

                        {sortItems?.map((datas, index) =>
                            <MenuItem key={datas.id} value={datas.id}  >{datas.description}</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>

            <div>
                {Array.isArray(dataListOnUI) &&
                    <Grid container >
                        {localPagination(3, currentPage, filterParam, dataListOnUI)?.map((athlete, index) => (
                            <Grid key={index} container xs={12} md={6} lg={4} item justifyContent={"space-around"} sx={{
                                '& .MuiGrid-root': {
                                    marginLeft: "0px"
                                }
                            }}>
                                <Card variant="outlined" sx={{
                                    margin: "10px",
                                    backgroundImage: `url(${images1})`,
                                    "& .MuiCardContent-root": {
                                        paddingBottom: "0px !important",
                                        paddingLeft: '15px !important',
                                        paddingRight: '15px !important',
                                        paddingTop: "15px !important"
                                    },
                                }} className="cardContentStyle" >  <CardContent sx={{
                                    "& .MuiCardContent-root": {
                                        paddingBottom: "0px !important",
                                        padding: '8px !important'
                                    },
                                }}>
                                        <Grid container flexDirection="row" justifyContent={"space-between"}>
                                            <Grid item xs={rmvalue === "Entity RM" ? 9 : 11} lg={rmvalue === "Entity RM" ? 9 : 11} md={9} >
                                                {<Typography title={formatDate(athlete.endDate, "D MMMM  YYYY")} className="expiryTypo" gutterBottom>
                                                    <span style={{ fontFamily: "Mona Sans", color: "#D9D9D9" }}>Expiry</span>  {formatDate(athlete.endDate, "D MMMM  YYYY")}

                                                </Typography>}

                                            </Grid>
                                            {rmvalue === "Entity RM" &&

                                                <Grid item xs={3} flexDirection="row">
                                                    <AvatarGroup sx={{
                                                        '& .MuiAvatar-root': { width: 15, height: 15, fontSize: 12 },
                                                    }}>
                                                        {athlete?.hniDto?.slice(0, 3)?.map((item) => {
                                                            return <Avatar alt={item?.name} src={item?.profilePhotoUrl} />
                                                        })}

                                                        <Typography className="hniCountStyle">{(athlete?.hniCount - 3 > 0 ? athlete?.hniCount - 3 : null)}</Typography>
                                                    </AvatarGroup>

                                                </Grid>}
                                        </Grid>
                                        <Grid container style={{ marginTop: "15px" }} flexDirection="column">
                                            <Grid item xs={11} lg={11} md={11}>
                                                <Typography noWrap title={athlete.productServiceName} className="productDescriptions">
                                                    {athlete?.productServiceName}
                                                </Typography></Grid>
                                            <Grid item>
                                            </Grid>
                                        </Grid>
                                        <Grid container flexDirection="column">
                                            <Grid item xs={11} lg={11} md={11}>
                                                <Typography noWrap title={athlete?.primaryCategoryId?.description} >
                                                    <span className="productSpan">
                                                        {athlete?.primaryCategoryId?.description}
                                                    </span>
                                                </Typography></Grid>
                                            <Grid item>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "10px" }} flexDirection="row" justifyContent={"space-between"}>
                                            <Grid item>
                                                <Grid container flexDirection={"column"} sx={{ marginTop: "5px" }}>
                                                    <Grid item>
                                                        <Typography title={"Minimum Investment"} className="minInvestmentCSS">
                                                            Minimum Investment
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography title={TitleConverstion(currencyTitle?.currencyCode, athlete?.sellingPrice, rmUser, currency)} className="usDollar">
                                                            {/* USD {USDollar.format(athlete?.sellingPrice)} */}
                                                            <UseCurrencyHook val={athlete?.sellingPrice} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button className="viewButton" onClick={() => { props?.handleModalOpenWithResponse(athlete.productServiceId) }}>
                                                    View
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent></Card>
                            </Grid>
                        ))}
                    </Grid>}
                {!dataListOnUI?.length && <Grid container spacing={3}><p className="nodataMessage">No {(props?.header).toLowerCase()} details</p></Grid>}
            </div>
            <Pagination sx={{
                "& .MuiPaginationItem-root.Mui-selected ": {
                    backgroundColor: "black !important",
                    color: "white !important"
                }
            }}
                count={Math.ceil(props?.dataList?.length / 3)} page={currentPage} onChange={handleChange} style={{ float: "right" }} />
        </div>
    );
};
//
export default CardsDisplay;
