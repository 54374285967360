/* eslint-disable no-useless-escape */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import GuestLayout from "./guestLayout";
import { AppLogout } from "../../../routes/AppLogout";

const GuestPublicRoutes = () => {
//   const { user } = useSelector((state) => ({
//     ...state.auth.user,
//   }));

  const userLocalObj = localStorage.getItem("guestUser") ;
  return ( userLocalObj !== null) ? (
    <AppLogout>
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    </AppLogout>
  ) : (
    <Navigate to="/guestUser" />
  );
};

export default GuestPublicRoutes;