
import React from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BannerCard = ({ imagesPath, TitleBanner, id, productorService }) => {
    const navigate = useNavigate();

    const StyledBanner = styled('div')({
        position: 'relative',
        width: '100%',

        paddingTop: '16.5625%', // 265 / 1600 = 0.165625
        // To maintain the aspect ratio of 1600:265 for responsive behavior
        '& img': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%', borderRadius: "10px",
        },
    });

    return (
        <StyledBanner
            onClick={() => {
                if (productorService === 'product') {
                    navigate(`/dashboard/recommendedProducts/${btoa(id)}`);
                } else {
                    navigate(`/dashboard/recommendedServices/${btoa(id)}`);
                }
            }}
        >
            <img src={imagesPath} alt={TitleBanner} loading="lazy" />
        </StyledBanner>
    );
};

export default BannerCard;
