import React from 'react'
import withPermissionsHoc from "../../Layout/PermissionsHoc/PermissionsHoc"
// eslint-disable-next-line
import Cards from './AdminDashboardList/dashboardList'
//import AdminDashboardDemo from '../DemoDashboard/AdminDashboardDemo'
//import DashboardHni from './DashboardList/DashboardHni'
//import HniDashboadContainer from './HniDashboard/hniDashboadcontainer'
import LazyLoadingHNI from "./HniDashboard/LazyLoadingHNI"
//import HNIDashboardDemo from '../DemoDashboard/HNIDashboardDemo'
// import DashboardRM from './DashboardList/DashboardRM'
//import RMDashboard from './RmDashboard/RmDashboard'
import LazyLoadingRM from "./RmDashboard/LazyLoadingRM"
import ModalWithTable from "../../common/DashboardModal/ModalWithTable";
//import AdminOfficeEntity from "../AdminOfficeEntityCreations/AdminLayout"
//import BordeauxLiveData from "../../modules/ThirdPartySections/BordeauxLiveData"
//import RMDashboardDemo from "../DemoDashboard/RMDashboardDemo"
function Dashboard({ readPermission, writePermission, approvePermission }) {
    React.useEffect(() => {

        localStorage.setItem("entityId", btoa(JSON.parse(localStorage.getItem("user"))?.entityId))

    }, [])

    const rmUser = JSON.parse(localStorage.getItem('user'))
    const rmvalue = rmUser?.userTypeId?.description
    const isHni = (rmUser?.userTypeId?.description === "Client Head" || rmUser?.userTypeId?.description === "Client Family Member") ? true : false;
    return (
        <>
            <ModalWithTable />
            {/* //rmvalue === 'Kairos Admin' || */}
            {(rmvalue === 'Entity Admin' || rmvalue === 'Kairos Admin') ? <Cards /> : null}
            {rmvalue === 'Entity RM' ? <LazyLoadingRM /> : null}
            {/* {rmvalue === 'RM' ? <RMDashboard /> : null} */}
            {/* {isHni === true ? <HniDashboadContainer /> : null} */}
            {isHni === true ? <LazyLoadingHNI /> : null}
        </>

    )
}

export default withPermissionsHoc(Dashboard, "Dashboard");