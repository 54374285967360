import React from 'react'
// eslint-disable-next-line 
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function GridTable({ columns, rows, type, height, margintop, ...props }) {
    return (
        <Box
            height={height}
            
            // padding={height === "219" ? "0px 8px" : "15px 8px"}
            padding="0px 8px"
            className
            sx={{
                marginTop: margintop ? margintop : null,
                '& .MuiDataGrid-root': {
                    border: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                    fontFamily: "Mona Sans !important"
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                    backgroundColor: '#F1F1F1',
                    borderRadius: '8px',
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#000000',
                    color: 'white',
                    '& .MuiCheckbox-root': {
                        color: 'white',
                        // background: '#FFFFF' 
                    }
                },
            }}
        >
            <DataGrid
                disableColumnMenu
                sx={{

                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none !important',
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontFamily: "Mona Sans !important"
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Mona Sans",
                        fontWeight: "bold",
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                        width: '0.4em',
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                        backgroundColor: '#6A3BE2',
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    }
                }}
                // getRowId={(row) => row.hniId} 
                {...props}
                rows={rows}
                columns={columns}
                rowHeight={type === "marketdata" ? 35 : 28}
                headerHeight={type === "marketdata" ? 35 : 27}
                hideFooter={true}
                density={'standard'}
                disableColumnSelector
            // isRowSelectable={(params) =>viewlocation===true } 
            // isRowSelectable={viewlocation} 
            // selectionModel={selectionModel} 
            // onSelectionModelChange={handleSelectionModelChange} 
            // components={{ 
            // ColumnUnsortedIcon: CustomUnsortedIcon, 
            // }} 
            />
        </Box>
    )
}
export default GridTable;