
export const clientColumns = [
    {
        id: "userId",
        label: "Client ID",
        default: false

    },
    {
        id: "hniName",
        label: "Client Name",
        default: true

    },
    {
        id: "onboardDate",
        label: "Client Onboard Date",
        default: true

    },
    {
        id: "invested",
        label: "Invested Wealth ($)",
        default: true

    },
    {
        id: "investable",
        label: "Investable Wealth ($)",
        default: true

    },
    {
        id: "revenue",
        label: "Revenue ($)",
        default: true

    },

    {
        id: "status",
        label: "Status",
        default: true

    },
    {
        id: "rmUserId",
        label: "RM / Employee Id",
        default: true

    }


];
export const userRightsColumns = [
    {
        id: "name",
        label: "Screen Name",

    },
    {
        id: "Create",
        numeric: true,
        label: "Create"
    },
    {
        id: "ReadOnly",
        label: "Read Only"
    },
    {
        id: "Approve",
        label: "Approve",

    }
];

export const entityProfileColumns = [
    {
        id: "AllowEdit",
        label: "Allow Edit",
    },
    {
        id: "EditInformation",
        label: "Edit Information"
    }
]

export const hniTransaction = [
    {
        id: "ref",
        label: "Ref#",
        default: true
    },
    {
        id: "Date",
        label: "Date",
        default: true
    },
    {
        id: "Type",
        label: "Type",
        default: true
    },
    {
        id: "ExecutedBy",
        label: "Executed By",
        default: true
    },
    {
        id: "TransactionDescription",
        label: "Transaction Description",
        default: true
    },
    {
        id: "Amount ($)",
        label: "Amount",
        default: true
    },
    {
        id: "Balance ($)",
        label: "Balance",
        default: true
    },
]


export const prospectscolumnCells = [
    {
        id: 'prospectName',
        label: 'Prospect Name'
    },
    {
        id: 'netWorth',
        label: 'Net Worth ($)'
    }
]
export const clienctColumns = [
    {
        id: 'employeeId',
        label: 'Risk'
    },
    {
        id: 'clientName',
        label: 'Client Name'
    },
    {
        id: 'investable',
        label: 'Investable Fund ($)'
    },
    {
        id: 'actions',
        label: 'Action'
    }
]