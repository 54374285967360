import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    modalShow: false,
    titleofModal: '',
    data: [],
    column: [],
    loading: false,
}
const modalSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.modalShow = action.payload;
            state.loading = true
        },
        closeModal: () => initialState,
        // closeModal: (state, action) => {
        //     state.modalShow = action.payload;
        //     state.data = [];
        //     state.column = [];
        //     state.titleofModal = "";
        //     state.loading = false;

        // },
        setTableColumnData: (state, action) => {
            console.log("action on SetTableData", action)
            state.titleofModal = action.payload.titleofModal;
            state.data = action.payload.data;
            state.column = action.payload.column;
            state.loading = action.payload.loading;
        }
    }
});

export const { openModal, closeModal, setTableColumnData } = modalSlice.actions

export default modalSlice.reducer;
