import React, { useState, useEffect } from 'react'
import { Grid, Typography, TextField } from "@mui/material"
import "./layout.css"
// import Kairos from "../../theme/images/kairosLogo.svg"
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import { deepPurple } from '@mui/material/colors';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
// import Service from "../../theme/images/Service.svg"
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { setOpenLogout, setProfileButton, setText, setProfileImg, setButton, setOpenSide } from "../../redux/slices/navSlice"
import { useNavigate } from 'react-router-dom';
import Notification from "../../components/common/Notification/Notification"
import { getAllNotification, addFeedback, getAllNotificationBG } from "../../services/index"// eslint-disable-next-line
import { toast } from "react-toastify"
import navButton from "./navButtons"
// import colorKairos from "../../theme/images/GuestKairos Logo.svg";
import colorKairos from "../../theme/images/indium.png";

import MenuIcon from '@mui/icons-material/Menu';
import GlobalCurrency from '../common/GlobalCurrency/GlobalCurrency';
// import colorNotification from "../../theme/images/bell icon.svg"
import Box from '@mui/material/Box';
// import Button  as MuiButton from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { styled } from "@mui/material/styles";
import colorKairosForMobile from "../../theme/images/indium.png";
import useMediaQuery from '@mui/material/useMediaQuery';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const navButtons = navButton

const profileButtons = [{
    id: 0,
    text: "My Profile",
    path: "/profile",
}, {
    id: 1,
    text: "Change Password",
    path: "/changePassword",
}, {
    id: 2,
    text: "Logout",
    path: "/logout",
}]

const hniProfileButtons = [{
    id: 0,
    text: "My Profile",
    path: "/hniprofile",
}, {
    id: 1,
    text: "Change Password",
    path: "/changePassword",
},

{
    id: 3,
    text: "Feedback",
    // path: "/changePassword",
}, {
    id: 2,
    text: "Logout",
    path: "/logout",
}]

const hniMemberButtons = [
    {
        id: 0,
        text: "My Profile",
        path: "/memberProfile",
    }, {
        id: 1,
        text: "Change Password",
        path: "/changePassword",
    },

    {
        id: 3,
        text: "Feedback",
        // path: "/changePassword",
    }, {
        id: 2,
        text: "Logout",
        path: "/logout",
    }
]

const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#FFDFOO"
    },
    "& .MuiRating-iconHover": {
        color: "#FFDFOO"
    }
});


function Header() {
    const Button = useSelector((state) => state.nav.Button)
    const profileButton = useSelector((state) => state.nav.profileButton)
    const profileImg = useSelector((state) => state.nav.profileImg)

    const text = useSelector((state) => state.nav.text)
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userId = user?.userId
    const userRights = user?.userRights
    let ProfileButtons;
    if (user?.userTypeId?.description === "Client Head") {
        ProfileButtons = hniProfileButtons
    } else if (user?.userTypeId?.description === "Client Family Member") {
        ProfileButtons = hniMemberButtons
    } else {
        ProfileButtons = profileButtons
    }
    const firstName = user.firstName;
    const lastName = user.lastName;
    const name = lastName === null ? firstName : firstName + " " + lastName
    // const profilePhoto = user.profilePhotoUrl
    const openLogout = useSelector((state) => state.nav.openLogout)
    console.log(openLogout, "openLog")
    const dispatch = useDispatch()
    console.log(Button?.text, "title.text")
    console.log(profileButton, "profileButton")
    // const [selected, setSelected] = useState("")
    const navigate = useNavigate()        //eslint-disable-next-line
    const [anchorEl, setAnchorEl] = useState(null)
    // eslint-disable-next-line
    const [notificationCount, setNotificationCount] = useState(0);
    const [pushNotificationCount, setpushNotificationCount] = useState(0);

    const [rating, setRating] = useState(0)
    const [feedback, setFeedback] = useState("")
    const [warnings, setWarnings] = useState(false)
    console.log(anchorEl, setNotificationCount)
    const modulesWithPermissions = userRights?.filter((permissionObj) => (permissionObj.create || permissionObj.readOnly || permissionObj.approve)) || [];
    console.log(modulesWithPermissions, "modules")
    let moduleNames = modulesWithPermissions?.map((obj) => obj?.module.replace(/\s/g, ''));
    console.log(moduleNames, "moduleNames")
    const NewButtons = navButtons.filter((buttonObj) => moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, '')) !== -1) || [];

    console.log(NewButtons, "newButtons")

    const [openFeedback, setOpenFeedback] = React.useState(user?.feedbackEnable);
    const feedbackEnable = user?.feedbackEnable
    console.log(feedbackEnable, "feeeeeeeeee")

    const isXs = useMediaQuery("(max-width:400px)");

    function handleFeedClose() {
        setOpenFeedback(false);
    }
    const handleFeedbackClose = (e) => {
        console.log("closeeeeeeeeeeeeeee")
        handleFeedClose()
        e.preventDefault()

        const obj = {
            "userId": userId,
            "rating": rating,
            "feedback": feedback,
            "isSubmit": false
        }


        addFeedback(obj)?.then(res => {
            console.log(res, "resppp")
            handleFeedbackClose()
        })
        setRating(0)
        setFeedback("")

        handleFeedClose()

    }

    // every 5 minutes
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [anchorProfile, setAnchorProfile] = React.useState(null);

    const open = Boolean(anchorMenu);
    const handleClick = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorMenu(null);
    };
    const openProfile = Boolean(anchorProfile);
    const handleProfileClick = (event) => {
        setAnchorProfile(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorProfile(null);
    };
    const handleSideOpen = () => {
        dispatch(setOpenSide(true))
    }
    console.log(notificationCount, "notcount")
    useEffect(() => {
        for (const element of ProfileButtons) {
            if (window.location.pathname.includes(element.path)) {
                dispatch(setButton(element))
                dispatch(setText(element.text))
            }
        }

        for (const element of NewButtons) {
            if (window.location.pathname.includes(element.path)) {
                dispatch(setButton(element))
                dispatch(setText(element.text))
            }
        }// eslint-disable-next-line
    }, [window.location.pathname])

    useEffect(() => {
        const updatedProfilePhotoUrl = user?.profilePhotoUrl !== null ? `${user?.profilePhotoUrl}?${Date.now()}` : null;

        dispatch(setProfileImg(updatedProfilePhotoUrl))
        initialNotifications();
        const interval = setInterval(() => {
            getNotificationsBG();
        }, 965000);

        //Clearing the interval
        return () => clearInterval(interval);
        // eslint-disable-next-line 
    }, [])


    // useEffect(() => {

    //     initialNotifications();
    //     // eslint-disable-next-line 
    // }, [anchorMenu])
    const initialNotifications = async () => {
        console.log("Checking Notifications=======>1230", pushNotificationCount)
        const response = await getAllNotification({
            "userId": userId,
            "pageNo": 0,
            "pageSize": 3
        })
        if (response?.data?.totalUnreadCount) {
            console.log("Checking Notifications=======>1231", response?.data?.totalUnreadCount)
            await setpushNotificationCount(response?.data?.totalUnreadCount)
        }
        console.log("Checking Notifications=======>12312", pushNotificationCount)
    }
    const getNotificationsBG = async () => {
        const res = await getAllNotificationBG({
            "userId": userId, "pageSize": 3,
            "pageNo": 0,
        })
        if (res) {
            setpushNotificationCount(res?.data?.totalUnreadCount)
        }
    }

    // const getNotifications = async () => {
    //     console.log("Checking Notifications=======>12", pushNotificationCount)
    //     const res = await getAllNotification({
    //         "userId": userId,
    //         "pageNo": 0,
    //         "pageSize": 3
    //     })
    //     console.log("Checking Notifications=======>12", res?.data?.totalUnreadCount, pushNotificationCount)
    //     if (res) {
    //         setpushNotificationCount(res?.data?.totalUnreadCount)


    //     }


    // }
    const handleFeedback = (e) => {
        console.log(e.target.value, "vallllllll")
        setFeedback(e.target.value)
    }
    const handleRating = (e, newVal) => {
        console.log(newVal, "ratttttt")
        setRating(newVal)
        if (newVal > 0) {
            setWarnings(false)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("submittttttttttttt")
        const obj = {
            "userId": userId,
            "rating": rating,
            "feedback": feedback,
            "submit": true
        }
        if (rating > 0) {

            addFeedback(obj).then(res => {
                console.log(res, "resppp")
                handleFeedClose()
                if (res?.data?.status === true) {
                    toast.success(res?.data?.statusMessage)

                } else {
                    toast.error(res?.data?.errorMessage)

                }


            })
        } else {
            setWarnings(true)
        }


    }


    return (
        <>
            <Grid className="header" sx={{ background: { xs: "#171B24", sm: "white" } }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xl={2} lg={8} md={3} sm={4} xs={7}>
                        <Typography sx={{ fontSize: "16px", fontWeight: "600", letterSpacing: "0.16px", display: { xs: "none", sm: "block" } }}>{text === "Payments" ? "My Transactions" : text}</Typography>
                        <Typography ml={3} sx={{ fontSize: "22px", fontWeight: "550", marginLeft: { xs: "-9%" }, display: { xs: "flex", sm: "none" }, alignItems: { xs: "center", sm: "none" } }} ><MenuIcon onClick={handleSideOpen} sx={{ color: "white", marginRight: "10%", fontSize: "30px" }} />
                            <img src={isXs ? colorKairosForMobile : colorKairos} style={{ width: isXs ? '40px' : '40px', objectFit: 'contain', cursor: 'pointer' }} alt="indium" />
                        </Typography>
                    </Grid>

                    {/* <Grid item lg={4} md={7} xs={2} sm={4} display={{ lg: "block", md: "block", xs: "block", sm: "block" }}></Grid> */}



                    <Grid item xl={4} lg={4} md={5} sm={7} xs={5} >
                        <Grid sx={{ display: 'flex', alignItems: 'center', gap: { sm: '20px' }, marginLeft: { xs: "-35px" } }}>

                            <div>
                                <GlobalCurrency />
                            </div>
                            <Grid sx={{ display: 'flex', alignItems: "center", flexDirection: { xs: 'row-reverse', sm: 'row' }, gap: { xs: '5px', sm: '20px' } }}>
                                <div onClick={handleClick} style={{ cursor: "pointer" }}>
                                    <Badge badgeContent={pushNotificationCount} sx={{ "& .MuiBadge-badge": { backgroundColor: "red", color: "white" } }}

                                    // style={{ flexGrow: 1, float: "right", marginTop: "1.5%" }}
                                    >
                                        <NotificationsNoneOutlinedIcon sx={{ width: "18px", height: "18px", color: { xs: "white", sm: "black" }, cursor: "pointer" }} />
                                    </Badge>
                                </div>

                                <div onClick={handleProfileClick}
                                    style={{
                                        display: "flex", flexGrow: 0.5, alignItems: "center",
                                        cursor: "pointer"
                                        //  float: "right", marginLeft: "5%", marginRight: "7%" 
                                    }}>
                                    {console.log(profileImg, "proooooooooooooo")}
                                    {profileImg ? <Avatar alt="Remy Sharp" src={profileImg} sx={{ width: "20px", height: "20px" }} /> : <Avatar sx={{ width: "20px", height: "20px", bgcolor: deepPurple[500] }}>{name[0]}</Avatar>}
                                    {/* <Avatar sx={{ width: "25px", height: "25px", bgcolor: deepPurple[500] }}>{name[0]}</Avatar>
                                <Avatar alt="Remy Sharp" src={profileImg} sx={{ width: "25px", height: "25px" }} /> */}
                                    {console.log(name[0], "nameeeeeeeeeeeeeeeeeeeeeeeee")}
                                    {/* <Typography sx={{ fontSize: "12px" }} display={{ xs: "none", lg: "block", md: "block", sm: "block" }} pl={1}>{name}</Typography> */}

                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            display: { xs: "none", lg: "block", md: "block", sm: "block" },
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            width: { md: "45px", lg: "80px", xl: "200px" },
                                            height: "20px",
                                        }}
                                        pl={1}
                                    >
                                        {name}
                                    </Typography>


                                </div>
                            </Grid>



                        </Grid>
                    </Grid>
                </Grid>

                {open && <Notification onClick={handleClose} onClose={handleMenuClose} open={open} setpushNotificationCount={setpushNotificationCount} anchorEl={anchorMenu} />}



                <Menu
                    anchorEl={anchorProfile}
                    id="account-menu"
                    open={openProfile}
                    onClose={handleProfileClose}

                    // onClick={handleProfileClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,

                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: 0,
                                mr: 0,

                            },
                            '& .MuiButtonBase-root:hover': {
                                backgroundColor: "#693BE1",
                                color: "white"
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    {ProfileButtons.map((data) => (<MenuItem key={data.id} onClick={(() => {
                        dispatch(setProfileButton(data))
                        dispatch(setButton(data))
                        data.text === "Feedback" && setOpenFeedback(true)
                        data.text !== "Logout" && dispatch(setText(data.text))
                        data.text === "Logout" && dispatch(setOpenLogout())

                        data.text !== "Logout" && navigate(data.path)
                        handleProfileClose()
                    })
                    } >
                        {data.text}
                    </MenuItem>))}


                </Menu>


            </Grid >
            <Grid sx={{ display: { xs: "block", sm: "none" }, padding: "10px 14px ", background: "#F7F7F7", border: "1.1px solid #E0E0E0" }}>
                <div style={{ fontSize: "16px", fontWeight: "600", letterSpacing: "0.16px", }}>{text === "Payments" ? "My Transactions" : text}</div>
            </Grid>

            <Modal
                open={openFeedback}
                onClose={handleFeedbackClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit}>
                        <Typography id="modal-modal-title" sx={{ fontSize: "16px", fontFamily: "Mona Sans", fontWeight: 600 }}>
                            Send us your Feedback!
                        </Typography>
                        <Typography id="modal-modal-title" sx={{ fontSize: "13px", fontFamily: "Mona Sans", mt: 1.5 }}>
                            Rate your experience with Indium so far.
                        </Typography>
                        <StyledRating
                            name="customized-color"
                            value={rating}

                            onChange={(e, newVal) => handleRating(e, newVal)}
                            precision={1}
                            icon={<StarRoundedIcon fontSize="large" />}
                            emptyIcon={<StarOutlineRoundedIcon fontSize="large" />}
                        />
                        {warnings && (<p style={{ color: "red", fontSize: "12px", fontWeight: 600, fontFamily: "Mona Sans", marginTop: "-3px", marginLeft: "10px" }}>This field is required</p>)}
                        <Typography id="modal-modal-description" sx={{ mt: 0.5 }}>
                            Feedback
                        </Typography>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            name="feedback"
                            rows={4}
                            inputProps={{
                                maxLength: 1000,
                            }} placeholder="Type your enquiry here"
                            value={feedback}
                            onChange={(e) => handleFeedback(e)}
                            fullWidth
                        // className="enquiry-textField"
                        />
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            <button type="submit" style={{ marginRight: "20px", padding: "5px 15px", background: "#6A3BE2", border: "1px solid #6A3BE2", borderRadius: "4px", color: "white", cursor: "pointer" }}>Submit</button>
                            <button style={{ padding: "5px 15px", borderRadius: "4px", color: "#7A86A1", background: "white", border: "1px solid #7A86A1", cursor: "pointer" }} onClick={handleFeedbackClose} >Cancel</button>

                        </div>
                    </form>
                </Box>
            </Modal>

        </>
    )
}

export default Header