import React, { useState, useEffect } from 'react'

import DataUser from "../../../testUtility/data.json";
const withPermissionsHoc = (Component, name) => (props) => {
    const user = JSON.parse(window.localStorage.getItem("user")) || DataUser;
    const userRights = user?.userRights

    const [readPermission, setReadPermission] = useState(null)
    const [writePermission, setWritePermission] = useState(null)
    const [approvePermission, setApprovePermission] = useState(null)
    useEffect(() => {
        const permissions = userRights?.find(p => p?.module?.replace(/\s/g, '') === name?.replace(/\s/g, ''));

        if (permissions) {
            setReadPermission(permissions.readOnly)
            setWritePermission(permissions.create)
            setApprovePermission(permissions.approve)
        } // eslint-disable-next-line
    }, [])

    if (!(readPermission || writePermission || approvePermission)) {

        return null
    }


    return <Component readPermission={readPermission} writePermission={writePermission} approvePermission={approvePermission}  {...props} />
}

export default withPermissionsHoc