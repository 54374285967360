import {Axios} from "./config";
// import axios from "axios";
//authentication services
export const SendSms= async (data) => {
    try {
        const userData = await Axios.post("api/v1/common/send-sms", data)
        if (userData) return userData;
    }
    catch (error) {
        return error;
    }
}
export const addGuestUserEnquiries = async (data) => {
    try {
        const userData = await Axios.post("api/v1/common/add-guest-enquiries", data)
        if (userData) return userData;
    }
    catch (error) {
        return error;
    }
}
export const GetCountries = async (data) => {
    try {
        const Countries = await Axios.get("api/v1/common/get-countries")
        if (Countries) return Countries;
    }
    catch (error) {
        return error;
    }
}
