import React, { useState, useEffect, Suspense} from "react";
import "./App.css"
import { Route, Routes, Navigate, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { PublicRoute } from "./routes/PublicRoute"
import "react-toastify/dist/ReactToastify.css";
import { PrivateRouteConfig } from "./routes/privateRoutesConfig";
import { PublicRouteConfig, PublicGuestRouteConfig } from "./routes/publiceRoutesConfig";
import PrivateRoutes from "./components/Authentication/PrivateRoutes"
import TimeOut from "./components/SessionTimeOut/TimeOut";
import { CssBaseline, ThemeProvider } from "@mui/material";
//import NewPassword from '../components/modules/login/CreatePassword'
import { ColorModeContext, useMode } from "./theme";
import { fetchToken } from './constant/firebase';
import { saveFirebaseToken } from "./services";
// import {GuestPublicRoutes} from './components/modules/guestUser/GuestPublicRoutes'
import GuestPublicRoutes from './components/modules/guestUser/publicGuestUserRoute'
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { reactRouterUtility } from "./constant/helpersUtility";
import Loader from "./components/common/Loader/Loader";

function App() {
  const [searchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const guestUser = JSON.parse(localStorage.getItem("guestUser"));

  const encryptedTxt = searchParams.get('txt')
  const isSetPassword = (window.location.pathname.includes('setpassword') && encryptedTxt)
  // const accessToken = user?.accessToken;
  // (accessToken && (localStorage.getItem('accessToken') !== null)) && window.localStorage.setItem('accessToken', accessToken)
  // useEffect( async () => {
  //   // async function fetchData() {
  //     const logoutResponse = await logOut({ "accessToken": accessToken })
  //     if (logoutResponse?.data?.status) {
  //       localStorage.clear();
  //     }
  //   // }
  //   // fetchData()
  // }, [])
  // useEffect(() => {

  // }, [])

  // let os = window.navigator.userAgent;

  // let finalOs = "";
  // if (os.search('Windows') !== -1) {
  //   finalOs = "Windows";
  // }
  // else if (os.search('Mac') !== -1) {
  //   finalOs = "MacOS";
  // }
  // else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
  //   finalOs = "UNIX";
  // }
  // else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
  //   finalOs = "Linux"
  // }
  // console.log("TEST FIREBASE 0")
  // if (["Linux", "UNIX", "MacOS", "Windows"].includes(finalOs)) {
  // }
  Chart.register(ChartDataLabels);

  const [firebaseToken, setFirebaseToken] = useState({ available: false, fbToken: '' });
  const [refresh, setRefresh] = useState(false);
  const [locationKey, setLocationKey] = useState();
  const currentlocation = useLocation();
  reactRouterUtility.navigate = useNavigate();
  reactRouterUtility.location = (() => {
    setLocationKey(currentlocation.key);
    setRefresh(false);
  })
  reactRouterUtility.forceUpdate = ((val) => setRefresh(() => val));

  useEffect(() => {
    setRefresh(false);
  }, [locationKey]);

  const handleFireBaseSetup = async () => {
    if (user) {
      let fbToken = await fetchToken(setFirebaseToken);
      if (fbToken) {
        let reqBody = {
          userId: user?.userId,
          firebaseToken: fbToken
        }
        const response = await saveFirebaseToken(reqBody);
        console.log('fbtoken stored', response);

      }
    }
  }

  (user && firebaseToken?.fbToken === '' && handleFireBaseSetup());

  const [theme, colorMode] = useMode();


  return (


    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {(user && isSetPassword) && localStorage.clear('user')}
        <ToastContainer />
        {refresh && <Loader isLoading={refresh} />}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>

            {user && (
              <><Route element={<PrivateRoutes />}>
                {PrivateRouteConfig.map(({ name, path, exact, element }) => (
                  <Route key={name} exact={exact} path={path} element={element} />
                ))}
              </Route>
                {/* < Route path="*" element={<PageNotFound />} /> */}
                <Route path="/" element={<Navigate to="/dashboard" replace />} />

                <Route
                  path="*"
                  element={<Navigate to="/dashboard" />}
                />
              </>)}

            {!user && (<><Route element={<PublicRoute />} >
              {PublicRouteConfig.map(({ name, path, exact, element }) => (
                <Route key={name} exact={exact} path={path} element={element} />
              ))}
            </Route>
              {/* <Route path="*" element={<PageNotFound />} /> */}
              <Route
                path="*"
                element={<Navigate to="/login" />}
              /></>)}
            {guestUser &&
              (<><Route element={<GuestPublicRoutes />} >
                {PublicGuestRouteConfig.map(({ name, path, exact, element }) => (
                  <Route key={name} exact={exact} path={path} element={element} />
                ))}
              </Route>
                {/* <Route path="*" element={<PageNotFound />} /> */}
                <Route
                  path="*"
                  element={<Navigate to="/guestUser" />}
                /></>)

            }


          </Routes>
        </Suspense>
        {user && !user?.mobileApp && <TimeOut />}
      </ThemeProvider>
    </ColorModeContext.Provider>

  )
}

export default App;