import * as React from 'react';
import { Grid, Button, Card, CardContent, AvatarGroup, Typography, Avatar } from "@mui/material";

import images1 from "../../../theme/images/blackpatterns1.png"
import { useNavigate } from 'react-router-dom';

import { TitleConverstion } from "../../../constant/DollorFormats"
import '../../../theme/styles/ProductsCard.css'
import { formatDate } from "../../common/Utils/DateFormat"
import { UseCurrencyHook } from '../../common/GlobalCurrency/useCurrencyHook';
import { useSelector } from 'react-redux';
export default function ProductsCard(props) {
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);
    const currency = useSelector(state => state.globalCurrency.currency);

    const userObject = JSON.parse(localStorage.getItem("user"))

    console.log(currencyTitle, 'props', 'list')
    const rmUser = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate();
    const rmvalue = rmUser?.userTypeId?.description
    const navigateToproducts = async (value) => {

        if (props?.id) {

            navigate("/viewProductDetails/" + btoa(value), { state: { recommentation: "recommentation", hniId: props?.id } });

        }
        else {
            navigate('/viewProductDetails/' + btoa(value))
        }
    }

    return (
        <Card variant="outlined" sx={{
            backgroundImage: `url(${images1})`,
            "& .MuiCardContent-root": {
                paddingLeft: '15px !important', paddingBottom: "0px !important",
                paddingRight: '12px !important',
            },
        }} className='cardStyles' >  <CardContent sx={{
            "& .MuiCardContent-root": {
                padding: '15px !important',
                paddingBottom: "0px !important",
            },
        }}>
                <Grid container flexDirestion="row" justifyContent={"space-between"}>
                    <Grid item xs={rmvalue === "Entity RM" ? 9 : 11} lg={rmvalue === "Entity RM" ? 9 : 11} md={9} >
                        <Typography title={props?.data.changes} className='typoGraphyText' gutterBottom>
                            <span style={{ fontFamily: "Mona Sans" }}>Expiry</span>  {formatDate(props?.data?.endDate, "D MMMM  YYYY")} </Typography>


                    </Grid>
                    {rmvalue === "Entity RM" &&
                        <Grid item xs={3} flexDirection="row">
                            <AvatarGroup sx={{
                                '& .MuiAvatar-root': { width: 12, fontSize: 12, height: 14, },
                            }}>
                                {props?.data?.hniDto?.slice(0, 3).map((item) => {
                                    return <Avatar src={item?.profilePhotoUrl} alt={item?.name} />
                                })}
                                <Typography className='typographyTextStyles'>{(props?.data?.hniCount - 3 > 0 ? props?.data?.hniCount - 3 : null)}</Typography>
                            </AvatarGroup>
                        </Grid>}
                </Grid>
                <Grid container style={{ marginTop: "15px" }} flexDirection="column">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography noWrap title={props?.data.productServiceName} className='typoGraphyStylesProducts'>
                            {props?.data?.productServiceName}
                        </Typography></Grid>
                </Grid>
                <Grid container flexDirection="column">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography noWrap title={props?.data?.primaryCategoryId?.description} >
                            <span style={{
                                color: "white", fontFamily: "Mona Sans", fontSize: "12px",
                                letterSpacing: " 0.41px"
                            }}>
                                {props?.data?.primaryCategoryId?.description}
                            </span>
                        </Typography></Grid>
                </Grid>
                <Grid container style={{ marginTop: "17px" }} flexDirection="row" justifyContent={"space-between"} p={"5px"}>
                    <Grid item>
                        <Grid container flexDirection={"column"} sx={{ marginTop: "2px" }}>
                            <Grid item>
                                <Typography title={"Minimum Investment"} className='investmesntStyles'>
                                    Minimum Investment
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography title={TitleConverstion(currencyTitle?.currencyCode, props?.data?.sellingPrice, userObject, currency)} className='dollarText'>

                                    <UseCurrencyHook val={props?.data?.sellingPrice} />
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item className='gridStyle'>
                        <Button className='buttonStyles' onClick={() => navigateToproducts(props?.data?.productServiceId)}>
                            View
                        </Button>
                    </Grid>
                </Grid> </CardContent></Card>
    );
}
