import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../Layout/Layout";
import { AppLogout } from "../../routes/AppLogout";

const PrivateRoutes = () => {
  const { user } = useSelector((state) => ({
    ...state.auth.user,
  }));

  const userLocalObj = localStorage.getItem("user");
  return (user !== null || userLocalObj !== null) ? (
    <AppLogout>
      <Layout>
        <Outlet />
      </Layout>
    </AppLogout>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;