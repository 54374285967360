import React from 'react';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Pagination from '@mui/material/Pagination';

const PaginationComponent = ({ handlePageChange, page, totalCount, size, siblingCount }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'end', }}>
            <Pagination
                count={totalCount}

                sx={{
                    "& .MuiPaginationItem-root.Mui-selected ": {
                        backgroundColor: "black !important",
                        color: "white !important"
                    },
                    "& .MuiPagination-ul": {
                        flexWrap: "nowrap"
                    }
                }}
                page={page} onChange={handlePageChange}
                size={size}
                siblingCount={siblingCount}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                    />
                )}
            />
        </div>
    );
}

export default PaginationComponent;
