import React from "react";
import images2 from "../../../theme/images/sandalPattern.png"
import images3 from "../../../theme/images/blackpatterns1.png"
import { Grid,Pagination} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import moment from "moment"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import "./CatalogCards.css"
import { TitleConverstion } from "../../../constant/DollorFormats"
import { formatDate } from "../../common/Utils/DateFormat"
import { UseCurrencyHook } from '../../common/GlobalCurrency/useCurrencyHook';
import { useSelector } from 'react-redux';

let sortItems = [{ id: 1, description: "Price: Low to High" }, { id: 2, description: "Price: High to Low" }, { id: 3, description: "Newest Arrivals" }]
const CardsDisplayList = (props) => {
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);

    // eslint-disable-next-line 
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);
    const rmUser = JSON.parse(localStorage.getItem('user'))
    const rmvalue = rmUser?.userTypeId?.description
    const [dataListOnUI, setdataListOnUI] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filterParam, setFilterParam] = React.useState(sortItems[0].id);// eslint-disable-next-line
    React.useEffect(() => {
        setdataListOnUI(props?.dataList?.productServiceCatalogueResponseDto?.sort((a, b) => parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice)))
        // eslint-disable-next-line
    }, [])
    React.useEffect(() => {
        setdataListOnUI(props?.dataList?.productServiceCatalogueResponseDto)
        // eslint-disable-next-line
    }, [props?.dataList?.productServiceCatalogueResponseDto])
    const sortingTheItems = async (sortItems) => {
        setFilterParam(sortItems)
        if (sortItems === 1) {
            setdataListOnUI(props?.dataList?.productServiceCatalogueResponseDto?.sort((a, b) => parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice)))
        }
        if (sortItems === 3) {
            setdataListOnUI(props?.dataList?.productServiceCatalogueResponseDto?.sort(function (a, b) {
                return moment(b.updatedAt).format('X') - moment(a.updatedAt).format('X')
            }))
        }
        if (sortItems === 2) {
            setdataListOnUI(props?.dataList?.productServiceCatalogueResponseDto?.sort((a, b) => parseFloat(b.sellingPrice) - parseFloat(a.sellingPrice)))
        }
    }
    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    function localPagination(page_size, page_number, sortOrder, data) {
        return data.slice((page_number - 1) * page_size, page_number * page_size);
    }

    if (props.loading) {
        return <h2>Loading...</h2>;
    }


    return (
        <div style={{ marginBottom: "50px" }}>
            <Grid container flexDirection={"row"} spacing={0}>
                <Grid item xs={8} lg={4} container flexDirection={"row"} >
                    <Grid> <img loading="lazy" alt="icon" src={props?.dataList?.categoryImage} /></Grid>
                    <Grid p="5px" >  <Typography noWrap className="listTypoGRaphyCateogory">{props?.dataList?.category}</Typography></Grid>
                </Grid>
                <Grid item xs={4} p="10px" sx={{ display: { xs: "none", lg: "block" } }}>
                    <Divider variant="fullWidth" orientation="horizontal" flexItem />
                </Grid>
                <Grid xs={4} item flexDirection={"row"} >
                    <Select
                        labelId="demo-simple-select-autowidth-label-cardList"
                        value={filterParam}
                        id="demo-simple-select-autowidth"
                        sx={{
                            width: "100%",
                            height: "24px",
                            fontSize: "12px",
                            fontFamily: "Mona Sans",
                            backgroundColor: "#FEFFE3",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: '8px',
                            },
                            '& input::placeholder': {
                                color: '#363636 !important',
                                textOverflow: 'ellipsis !important',
                                opacity: 1,
                            },
                            '& .MuiInputBase-root': {
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                borderRadius: '8px',
                            },
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => {
                            sortingTheItems(e.target.value)
                        }} >
                        <MenuItem disabled selected default value="">
                            <em>Sort By</em>
                        </MenuItem>
                        {sortItems?.map((datas) =>
                            <MenuItem key={datas.id} value={datas.id}  >{datas.description}</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>
            <div>
                <Grid sx={{ display: { xs: "none", lg: "block", md: 'block' } }}>
                    {Array.isArray(dataListOnUI) && <>
                        {localPagination(10, currentPage, filterParam, dataListOnUI)?.map(athlete => (
                            <Grid container spacing={1} flexDirection={"row"} className="listGridStyle" >
                                <Grid item xs={12} lg={4} className="listGridStyles" style={{ backgroundImage: `url(${images2})`, filter: "brightness(90%)" }}>
                                    <Typography className="listTypoStyle" title={athlete?.productServiceName} gutterBottom>
                                        {athlete?.productServiceName}  </Typography>

                                    <Typography title={athlete?.primaryCategoryId?.description} className="listTypoDescriptions" gutterBottom>
                                        {athlete?.primaryCategoryId?.description}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} lg={7.9} sx={{
                                    "& .MuiGrid-root>.MuiGrid-item": {
                                        paddingTop: "0px !important"
                                    }
                                }} style={{ backgroundImage: `url(${images3})`, }} className="listWebStyles" flexDirection="row">
                                    <Grid item xs={5} lg={2.5}>
                                        <Typography title={"Expiry"} className="listExpiry" gutterBottom>
                                            {"Expiry"}
                                        </Typography>
                                        <Typography title={formatDate(athlete.endDate, "D MMMM  YYYY")} className="listDates" gutterBottom>
                                            {formatDate(athlete.endDate, "D MMMM  YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} lg={2.5}>
                                        <Typography title={"Minimum Investment"} className="minimumInvestedHeaderStyle" gutterBottom>
                                            {"Minimum Investment"}
                                        </Typography>
                                        <Typography title={TitleConverstion(currencyTitle?.currencyCode, athlete?.sellingPrice, rmUser, currency)} className="listDates" gutterBottom>
                                            {/* {USDollar.format(athlete?.sellingPrice)} */}
                                            <UseCurrencyHook val={athlete?.sellingPrice} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} lg={2}>
                                        {rmvalue === "Entity RM" &&
                                            <>
                                                <Typography title={"Client's Invested"} className="hnniInvested" gutterBottom>
                                                    {"Client's Invested"}
                                                </Typography>

                                                <AvatarGroup sx={{
                                                    flexDirection: "row !important",
                                                    '& .MuiAvatar-root': { width: 15, height: 15, fontSize: 12 },

                                                }}>
                                                    {athlete?.hniDto?.slice(0, 3)?.map((item) => {
                                                        return <Avatar alt={item?.name} src={item?.profilePhotoUrl} />
                                                    })}
                                                    <Typography className="listAvatorStyles">{(athlete?.hniCount - 3 > 0 ? athlete?.hniCount - 3 : null)}</Typography>
                                                </AvatarGroup>  </>
                                        }
                                    </Grid>
                                    <Grid item xs={5} lg={2} justifyContent="flex-end" style={{ paddingTop: "10px", marginRight: "2%" }}>
                                        <Button className="listViewButton" style={{ width: "90px" }} onClick={() => { props?.handleModalOpenWithResponse(athlete.productServiceId) }}>
                                            View
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}</>
                    }   </Grid>
                <Grid sx={{ display: { xs: "block", lg: "none", md: 'none' }, padding: "10px" }}>

                    {Array.isArray(dataListOnUI) && <>
                        {localPagination(10, currentPage, filterParam, dataListOnUI)?.map(athlete => (
                            <Grid container flexDirection={"row"} className="listMobileStyles" >
                                <Grid item xs={12} lg={4} className="gridSTylesMobile" style={{ backgroundImage: `url(${images2})`, filter: "brightness(90%)" }}>
                                    <Typography title={formatDate(athlete.endDate, "D MMMM  YYYY")} className="listExpiryMobile" gutterBottom>
                                        Expiry  {formatDate(athlete.endDate, "D MMMM  YYYY")}

                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} lg={8} className="innerGridListStyle" style={{
                                    backgroundImage: `url(${images3})`,
                                }} flexDirection="row">
                                    <Grid item xs={12}>
                                        <Typography title={athlete.productServiceName} className="mobileProductStyle" gutterBottom>
                                            {athlete?.productServiceName}
                                        </Typography>
                                        <Typography title={athlete?.primaryCategoryId?.description} className="mobileProductDetails" gutterBottom>
                                            {athlete?.primaryCategoryId?.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} lg={2}>
                                        <Typography title={"Minimum Investment"} className="mobileMinimumInvested" gutterBottom>
                                            {"Minimum Investment"}
                                        </Typography>
                                        <Typography title={TitleConverstion(currencyTitle?.currencyCode, athlete?.sellingPrice, rmUser, currency)} className="mobileDollor" gutterBottom>
                                            {/* USD {USDollar.format(athlete?.sellingPrice)} */}
                                            <UseCurrencyHook val={athlete?.sellingPrice} />

                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} lg={2} justifyContent="flex-end" style={{ paddingTop: "30px", }}>
                                        <Button className="viewMobileButtonStyle" style={{ width: "90px" }} onClick={() => { props?.handleModalOpenWithResponse(athlete.productServiceId) }}>
                                            View
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}</>
                    }   </Grid>
                {!dataListOnUI?.length && <Grid container spacing={3}><p className="nodataMessage"> No {(props?.dataList?.category)?.toLowerCase() || ''} details</p></Grid>}
            </div>
            <Pagination sx={{
                "& .MuiPaginationItem-root.Mui-selected ": {
                    backgroundColor: "black !important",
                    color: "white !important"
                }
            }} count={Math.ceil(props?.dataList?.productServiceCatalogueResponseDto?.length / 10) || 0} page={currentPage} onChange={handleChange} style={{ float: "right" }} />
        </div>
    );
};


export default CardsDisplayList;
