
import React, { lazy } from "react";
import DataUser from "../testUtility/data.json";

import { FormModal } from './../components/modules/VendorManagement/AddEditForm/FormModal/FormModals';
import { FormModalProducts } from "./../components/modules/Products/AddEditForm/FormModal/FormModals"
import { fieldValuesVendor } from "../components/modules/VendorManagement/AddEditForm/FormUtils/InitialValues"// eslint-disable-next-line
import { fieldValuesProduct } from "../components/modules/Products/AddEditForm/FormUtils/InitialValues"// eslint-disable-next-line
import { vendorIDVALUE } from "../services/vendormanagement"// eslint-disable-next-line
import { columns } from "../components/modules/VendorManagement/constantColumn";// eslint-disable-next-line
import { FormModalPromote } from "../components/modules/Products/PrmoteInputs/FormModal/FormModal";
import ProductsServiceCatalog from "../components/modules/Catalog/IndexCatalog.js"
import "../theme/styles/Catalog.css"
import "../theme/styles/AddEditForm.css"
import "../theme/styles/ProductsCard.css"

import Dashboard from "../components/modules/Dashboard/Dashboard.js";
import BillofMaterials from "../components/modules/BillofMaterials/BillofMaterials";
import MemberProfile from "../components/modules/Dashboard/Profilehni/MemberProfile.js";
const UserManagement = lazy(() => import("../components/modules/userManagement/userManagementTable"));
const VendorManagement = lazy(() => import("../components/modules/VendorManagement/VendorGridComponent"));
const FamilyAssetAllocations = lazy(() => import("../components/modules/FamilyHeadAssetAllocations/FamilyAssetAllocations"));
const Services = lazy(() => import("../components/modules/Services/Services.js"));;
const Products = lazy(() => import("../components/modules/Products/Products.js"));;
const ClientsContainer = lazy(() => import("../components/modules/Clients/ClientsContainer"));;
const Reports = lazy(() => import("../components/modules/Reports/Reports.js"));
const CustomerFeedback = lazy(() => import("../components/modules/CustomerFeedback/CustomerFeedback.js"));
const Enquiry = lazy(() => import("../components/modules/Enquiry/EnquiryComponent.js"));// eslint-disable-next-line
const PageNotFound = lazy(() => import("../components/common/PageNotFound/PageNotFound"));
const AddUser = lazy(() => import("../components/modules/userManagement/AddUser.js"));
const EditUser = lazy(() => import("../components/modules/userManagement/EditUser.js"));
const AddEditForm = lazy(() => import("../components/modules/VendorManagement/AddEditForm/AddEditForm"));;// eslint-disable-next-line
const AddEditFormProduct = lazy(() => import("../components/modules/Products/AddEditForm/AddEditForm"));// eslint-disable-next-line
const AddService = lazy(() => import("../components/modules/Services/AddService"));
const EditService = lazy(() => import("../components/modules/Services/EditService"));// eslint-disable-next-line
const CatalogIndex = lazy(() => import("../components/modules/Catalog/CatalogIndex"));
const ProductsServicePortFolioSubscriptions = lazy(() => import("../components/modules/CatalogPortfolioSubsciptions/IndexPortfolio"));
const OfficeEntityCreations = lazy(() => import("../components/modules/AdminOfficeEntityCreations/AdminLayout"))
const Profile = lazy(() => import("../components/modules/Profile/Profile.js"));
const ChangePassword = lazy(() => import("../components/modules/Profile/ChangePassword.js"));
const VerifyChangePassword = lazy(() => import("../components/modules/Profile/VerifyChangePassword.js"));
const UserRights = lazy(() => import("../components/modules/UserRights/UserRights"));
// const CampaignStatus = lazy(() => import("../components/modules/CampaignStatus/CampaignStatus"));
const CreateCampaign = lazy(() => import("../components/modules/CampaignStatus/CreateCampaign"));
const ChatQueries = lazy(() => import("../components/modules/Enquiry/chatbot/ChatQueries"));
const EditViewCampaign = lazy(() => import("../components/modules/CampaignStatus/EditViewCampaign"));
const Professional = lazy(() => import("../components/modules/Professional/Professional"));
const MyProspects = lazy(() => import("../components/modules/MyProspects/MyProspects"));
const ProductDetails = lazy(() => import("../components/modules/Catalog/ProductDetails"));
// eslint-disable-next-line
const Financial = lazy(() => import("../components/modules/Prospects/Financial/Financial"));
const HniTransactions = lazy(() => import("../components/modules/Dashboard/transactions/hniTransactions"));
const Index = lazy(() => import("../components/modules/Prospects/index"));// eslint-disable-next-line
const ProductsServices = lazy(() => import("../components/modules/Prospects/Hnirecommandations/ProductsServices"));
const Clientprofile = lazy(() => import("../components/modules/Dashboard/Profile/profile"));
const HNIprofile = lazy(() => import("../components/modules/Dashboard/Profilehni/hniprofile"));
// const Campaign = lazy(() => import("../components/modules/HniCampaign/Campaign"));
const Payments = lazy(() => import("../components/modules/Payments/Payments"));
const ReferenceDataManagement = lazy(() => import("../components/modules/ReferenceDataManagement/ReferenceDataManagement"));
const OrderManagement = lazy(() => import("../components/modules/OrderManagement/OrderManagement"));
const ChatGpt = lazy(() => import('../components/modules/ChatGpt/ChatGpt.js'));
const ChatGptHistory = lazy(() => import("../components/modules/ChatGpt/ChatGptHistory"));
const EndSubCategory = lazy(() => import("../components/modules/CatalogPortfolioSubsciptions/EndSubCategory"));
const PaymentStatus = lazy(() => import("../components/modules/Payments/PaymentStatus"));
const AdminCampaign = lazy(() => import("../components/modules/CampaignStatus/campaignComponent.js"))
const ConfigureCharges = lazy(() => import("../components/modules/ConfigureCharges/ConfigureCharges.js"))
const AddCountry = lazy(() => import("../components/modules/ConfigureCharges/AddCountry.js"))
const EditView = lazy(() => import("../components/modules/ConfigureCharges/EditView.js"))
const BordeauxLiveData = lazy(() => import("../components/modules/ThirdPartySections/BordeauxLiveData"))
const BordeauxProductView = lazy(() => import("../components/modules/Bordeaux/ProductDetails.js"))

//import EndSubCategory from "../components/modules/CatalogPortfolioSubsciptions/EndSubCategory"
// const Charts = lazy(() => import("../components/modules/Dashboard/HniDashboard/charts"));
//import PaymentStatus from "../components/modules/Payments/PaymentStatus";
// import Clients from "../components/modules/Clients/Clients.js";
// const UserManagement = lazy(() => import('../components/modules/userManagement/userManagementTable.js'));
// const Dashboard = lazy(() => import('../components/modules/Dashboard/Dashboard.js'));
// const VendorManagement = lazy(() => import('../components/modules/VendorManagement/VendorManagement.js'));
// const Services = lazy(() => import('../components/modules/Services/Services.js'));

// const Products = lazy(() => import('../components/modules/Products/Products.js'));
// const Clients = lazy(() => import('../components/modules/Clients/Clients.js'));
// const Reports = lazy(() => import('../components/modules/Reports/Reports.js'));
// const CustomerFeedback = lazy(() => import('../components/modules/CustomerFeedback/CustomerFeedback.js'));
// const Enquiry = lazy(() => import('../components/modules/Enquiry/Enquiry.js'));
// eslint-disable-next-line
let loadData = localStorage.getItem('columnslocal');
const isRm = JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Entity RM" ? true : false
const { formFieldProducts } = FormModalProducts;
const { formField } = FormModal;// eslint-disable-next-line
const { formFieldPromotes } = FormModalPromote;
export const handlePermissions = (CurrentComponent, moduleName) => {
    const user = JSON.parse(localStorage.getItem("user")) || DataUser;

    const userRights = user?.userRights;
    let permissionsObj = { readPermission: null, writePermission: null, approvePermission: null }
    const permissions = userRights?.find(p => p?.module.replace(/\s/g, '') === moduleName.replace(/\s/g, ''));
    if (permissions) {
        permissionsObj.readPermission = permissions.readOnly;
        permissionsObj.writePermission = permissions.create;
        permissionsObj.approvePermission = permissions.approve;
    }
    if (!(permissionsObj.readPermission || permissionsObj.writePermission || permissionsObj.approvePermission)) {
        return <PageNotFound />
    }
    const existingProps = CurrentComponent.props;
    let updatedProps = { ...permissionsObj, ...existingProps }
    let ClonnedComponent = React.cloneElement(CurrentComponent, updatedProps)

    return ClonnedComponent;
}
export const PrivateRouteConfig = [{
    name: "userManagement",
    path: "/userManagement",
    exact: true,
    element: handlePermissions(<UserManagement />, "User Management"),

},
{
    name: "Dashboard",
    path: "/dashboard",
    exact: true,

    element: <Dashboard />,
}, {
    name: "VendorManagement",
    path: "/vendorManagement",
    exact: true,
    element: handlePermissions(<VendorManagement />, "Vendor Management"),
    // element: <VendorManagement loadData={columns} />,

},
{
    name: "VendorManagement Add",
    path: "/vendorManagement/addvendor",
    exact: true,
    element: handlePermissions(<AddEditForm formField={formField} fieldValuesVendor={fieldValuesVendor} />, "Vendor Management"), //<AddEditForm formField={formField} fieldValuesVendor={fieldValuesVendor} />,


},
{
    name: "VendorManagement Edit",
    path: "/vendorManagement/editvendor/:id",
    exact: true,
    element: handlePermissions(<AddEditForm formField={formField} edit={"edit"} fieldValuesVendor={fieldValuesVendor} />, "Vendor Management"),



},
{
    name: "VendorManagement View",
    path: "/vendorManagement/viewVendor/:id",
    exact: true,
    element: handlePermissions(<AddEditForm formField={formField} edit={"edit"} view={"view"} fieldValuesVendor={fieldValuesVendor} />, "Vendor Management"),



},



{
    name: "Lifestyle Products",
    path: "/lifestyleproducts",
    exact: true,
    element: handlePermissions(<Products productIDS={51} valueListData={[]} url={"lifestyle"} />, "Lifestyle Products"),
},
// {
//     name: "VendorManagement View",
//     path: "/VendorManagement/viewVendor/:id",
//     exact: true,
//     element: <AddEditForm formField={formField} edit={"edit"} view={"view"} fieldValuesVendor={fieldValuesVendor} />,



// },


{
    name: "Financial Products",
    path: "/financialproducts",
    exact: true,
    element: handlePermissions(<Products productIDS={40} valueListData={[]} url={"financial"} />, "Financial Products"),


},
{
    name: "Financial Services",
    path: "/financialservices",
    exact: true,
    element: handlePermissions(<Services />, "Financial Services"),
},
{
    name: "Lifestyle Services",
    path: "/lifestyleservices",
    exact: true,
    element: handlePermissions(<Services />, "Lifestyle Services"),
},
{
    name: "Financial Products",
    path: "/financialproducts/addProduct",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={40} formFieldProducts={formFieldProducts} fieldValuesProduct={fieldValuesProduct} url={"financial"} />, "Financial Products"),


}, //
{
    name: "Financial Products",
    path: "/financialproducts/editProduct/:id",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={40} formFieldProducts={formFieldProducts} edit={"edit"} clone={false} fieldValuesProduct={fieldValuesProduct} url={"financial"} />, "Financial Products"),


},
{
    name: "Financial Products",
    path: "/financialproducts/cloneProducts/:id",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={40} formFieldProducts={formFieldProducts} edit={"edit"} clone={true} fieldValuesProduct={fieldValuesProduct} url={"financial"} />, "Financial Products"),


},
{
    name: "Financial Products",
    path: "/financialproducts/viewproducts/:id",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={40} view={"view"} formFieldProducts={formFieldProducts} edit={"edit"} clone={false} fieldValuesProduct={fieldValuesProduct} url={"financial"} />, "Financial Products"),



},
{
    name: "Lifestyle Products",
    path: "/lifestyleproducts/addProduct",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={51} formFieldProducts={formFieldProducts} fieldValuesProduct={fieldValuesProduct} url={"lifestyle"} />, "Lifestyle Products")


},
{
    name: "Lifestyle Products",
    path: "/lifestyleproducts/editProduct/:id",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={51} formFieldProducts={formFieldProducts} edit={"edit"} clone={false} fieldValuesProduct={fieldValuesProduct} url={"lifestyle"} />, "Lifestyle Products")


},
{
    name: "Lifestyle Products",
    path: "/lifestyleproducts/cloneProduct/:id",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={51} formFieldProducts={formFieldProducts} clone={true} edit={"edit"} fieldValuesProduct={fieldValuesProduct} url={"lifestyle"} />, "Lifestyle Products")


},
{
    name: "Lifestyle Products",
    path: "/lifestyleproducts/viewproducts/:id",
    exact: true,
    element: handlePermissions(<AddEditFormProduct productIDS={51} view={"view"} formFieldProducts={formFieldProducts} clone={false} edit={"edit"} fieldValuesProduct={fieldValuesProduct} url={"lifestyle"} />, "Lifestyle Products")



},
// 
{
    name: isRm ? "My Clients" : "Clients",
    path: isRm ? "/myClients" : "/clients",
    exact: true,

    element: handlePermissions(<ClientsContainer />, isRm ? "My Clients" : "Clients"),

}, {
    name: "Reports",
    path: "/reports",
    exact: true,

    element: <Reports /> //handlePermissions(<Reports />, 'Reports'),

}, {
    name: "CustomerFeedback",
    path: "/customerFeedback",
    exact: true,

    element: <CustomerFeedback />,

}, {
    name: "FAQs",
    path: "/enquiry",
    exact: true,
    element: handlePermissions(<Enquiry />, "FAQs"),

},

{
    name: "Adduser",
    path: "/userManagement/adduser",
    exact: true,

    element: <AddUser />,

}, {
    name: "Edituser",
    path: "/userManagement/edituser/:id",
    exact: true,

    element: handlePermissions(<EditUser />, "User Management"),

}, {
    name: "Viewuser",
    path: "/userManagement/viewuser/:id",
    exact: true,

    element: handlePermissions(<EditUser />, "User Management"),
}, {
    name: "Profile",
    path: "/profile",
    exact: true,

    element: <Profile />,

}, {
    name: "Change Password",
    path: "/changePassword",
    exact: true,

    element: <ChangePassword />,

},
{
    name: "verifyChangePassword",
    path: "/verifyChangePassword",
    exact: true,

    element: <VerifyChangePassword />,

},
{
    name: "userRights",
    path: "/userRights",
    exact: true,

    element: <UserRights />,

},
{
    name: "Campaign",
    path: "/campaignstatus",
    exact: true,

    element: handlePermissions(<AdminCampaign />, "Campaign"),

},
{
    name: "Campaign",
    path: "/campaignstatus/CreateCampaign",
    exact: true,
    element: <CreateCampaign />,
},
{
    name: "Campaign",
    path: "/campaignstatus/EditCampaign/:id",
    exact: true,
    element: <EditViewCampaign />,
},
{
    name: "Campaign",
    path: "/campaignstatus/ViewCampaign/:id",
    exact: true,
    element: <EditViewCampaign />,
},

{
    name: "AddServices",
    path: "/financialservices/addService",
    exact: true,
    element: <AddService />,

},
{
    name: "EditService",
    path: "/financialservices/editService/:id",
    exact: true,
    element: handlePermissions(<EditService edit={true} />, "Financial Services"),

},
{
    name: "EditService",
    path: "/financialservices/cloneService/:id",
    exact: true,
    element: handlePermissions(<EditService clone={true} />, "Financial Services"),

},
{
    name: "ViewService",
    path: "/financialservices/viewService/:id",
    exact: true,
    element: handlePermissions(<EditService />, "Financial Services"),

},
{
    name: "AddServices",
    path: "/lifestyleservices/addService",
    exact: true,
    element: <AddService />,

},
{
    name: "EditService",
    path: "/lifestyleservices/editService/:id",
    exact: true,
    element: handlePermissions(<EditService edit={true} />, "Lifestyle Services"),

},
{
    name: "EditService",
    path: "/lifestyleservices/cloneService/:id",
    exact: true,
    element: handlePermissions(<EditService clone={true} />, "Lifestyle Services"),

},
{
    name: "ViewService",
    path: "/lifestyleservices/viewService/:id",
    exact: true,
    element: handlePermissions(<EditService />, "Lifestyle Services"),

},
{
    name: "chatQueries",
    path: "/chatqueries",
    exact: true,
    element: <ChatQueries />,

},
{
    name: "professional",
    path: "/professional",
    exact: true,
    element: <Professional />,

},
{
    name: "My Prospects",
    path: "/prospects",
    exact: true,
    element: <MyProspects />,

}, {
    name: "transactions",
    path: "/transactions/:id",
    exact: true,
    element: <HniTransactions />,
}
    , {
    name: "AddProspects",
    path: "/dashboard/AddProspects",
    exact: true,
    element: <Index add={'add'} />,
}, {
    name: "EditProspects",
    path: "/dashboard/EditProspects/:id",
    exact: true,
    element: <Index edit={'edit'} />,
},
{
    name: "EditProspects",
    path: "/prospects/EditProspects/:id",
    exact: true,
    element: <Index edit={'edit'} />,
},
{
    name: "AddProspects",
    path: "/prospects/AddProspects",
    exact: true,
    element: <Index add={'add'} />,
},

{
    name: "Recommendation Product",
    path: "/dashboard/productsrecommentation/:id",
    exact: true,
    element: handlePermissions(<ProductsServiceCatalog url={"product"} recommentation={"recommentation"} />, "Recommendation Product")
},
{
    name: "Recommendation Service",
    path: "/dashboard/servicesrecommentation/:id",
    exact: true,
    element: handlePermissions(<ProductsServiceCatalog url={"service"} recommentation={"recommentation"} />, "Recommendation Service")
},
{
    name: "Portfolio",
    path: "/myPortfolio",
    exact: true,
    element: <ProductsServicePortFolioSubscriptions url={"product"} portfolio={true} portfoliaChanges={true} />
    //element: handlePermissions(<ProductsServiceCatalog url={"product"} portfolio={true} portfoliaChanges={true} />, "Portfolio"), recommentation={"recommentation"}
},
{
    name: "Portfolio",
    path: "/clientPortfolio/:id",
    exact: true,
    element: <ProductsServicePortFolioSubscriptions url={"product"} recommentation={"recommentation"} portfolio={true} />
    //element: handlePermissions(<ProductsServiceCatalog url={"product"} portfolio={true} portfoliaChanges={true} />, "Portfolio"), 
},
{
    name: "Recommended Products",
    path: "/myPortfolio/recommendedProducts/:id",
    exact: true,
    element: <EndSubCategory url={"product"} />
    //element: handlePermissions(<ProductsServiceCatalog url={"product"} portfolio={true} portfoliaChanges={true} />, "Portfolio"),
},
{
    name: "Recommended Services",
    path: "/mySubscriptions/recommendedServices/:id",
    exact: true,
    element: <EndSubCategory url={"service"} />
    //element: handlePermissions(<ProductsServiceCatalog url={"product"} portfolio={true} portfoliaChanges={true} />, "Portfolio"),
},
{
    name: "Recommended Products",
    path: "/dashboard/recommendedProducts/:id",
    exact: true,
    element: <EndSubCategory url={"product"} />
    //element: handlePermissions(<ProductsServiceCatalog url={"product"} portfolio={true} portfoliaChanges={true} />, "Portfolio"),
},
{
    name: "Recommended Services",
    path: "/dashboard/recommendedServices/:id",
    exact: true,
    element: <EndSubCategory url={"service"} />
    //element: handlePermissions(<ProductsServiceCatalog url={"product"} portfolio={true} portfoliaChanges={true} />, "Portfolio"),
},

{
    name: "Subscriptions",
    path: "/mySubscriptions",
    exact: true,
    element: <ProductsServicePortFolioSubscriptions url={"service"} portfolio={true} portfoliaChanges={true} recommentation={"recommentation"} />
    //  element: handlePermissions(<ProductsServiceCatalog url={"service"} portfolio={true} />, "Subscriptions")
},
{
    name: "Subscriptions",
    path: "/clientSubscriptions/:id",
    exact: true,
    element: <ProductsServicePortFolioSubscriptions url={"service"} recommentation={"recommentation"} portfolio={true} />
    //  element: handlePermissions(<ProductsServiceCatalog url={"service"} portfolio={true} />, "Subscriptions")
},

//
{
    name: "Clientprofile",
    path: "/clientprofile/:id",
    exact: true,
    element: <HNIprofile client={true} />,
},
{
    name: "Prospectprofile",
    path: "/prospectprofile/:id",
    exact: true,
    element: <Clientprofile />,
},
{
    name: "Product",
    path: "/productsCatalog",
    exact: true,
    element: handlePermissions(<ProductsServiceCatalog url={"product"} />, "Product"),
    // element: <ProductsServiceCatalog url={"product"} />,

},
{
    name: "Service",
    path: "/serviceCatalog",
    exact: true,
    element: handlePermissions(<ProductsServiceCatalog url={"service"} />, "Service"),
    // element: <ProductsServiceCatalog url={"service"} />,

},
{
    name: "HNIProfile",
    path: "/hniprofile",
    exact: true,
    element: <HNIprofile />,
},
// {
//     name: "Campaign",
//     path: "/hnicampaign",
//     exact: true,
//     element: handlePermissions(<Campaign />, 'Campaign'),
// }, 
{
    name: "Payments",
    path: "/payments",
    exact: true,
    element: <Payments />
},
{
    name: "Reference Data Management",
    path: "/referencedata",
    exact: true,
    element: handlePermissions(<ReferenceDataManagement />, "Reference Data Management")
},
{
    name: "View Product Details",
    path: "/viewProductDetails/:id",
    exact: true,
    element: <ProductDetails url={"product"} />

},
{
    name: "View Service Details",
    path: "/viewServiceDetails/:id",
    exact: true,
    element: <ProductDetails url={"service"} />
},
{
    name: "Order Management",
    path: "/orders",
    exact: true,
    element: handlePermissions(< OrderManagement />, "Order Management")
},
{
    name: "Chat with Gaia",
    path: "/chatgpt",
    exact: true,
    element: <ChatGpt />


},
{
    // name: "Kairos Research Assistant",
    name: "Ask Gaia",
    path: "/kairosbot",
    exact: true,
    element: handlePermissions(< ChatGpt />, "Ask Gaia")


},
{
    name: "Indium Bot History",
    path: "/kairosbot/history",
    exact: true,
    element: <ChatGptHistory />


},
{
    name: "Chat History",
    path: "/chatgpt/history",
    exact: true,
    element: <ChatGptHistory />


},
{
    name: "Configure Charges",
    path: "/configureCharges",
    exact: true,
    element: <ConfigureCharges />


},
{
    name: "Bill of Materials",
    path: "/billofmaterials",
    exact: true,
    element: <BillofMaterials />


},
{
    name: "Add Country",
    path: "/addCountry",
    exact: true,
    element: <AddCountry />


},
{
    name: "Edit View",
    path: "/editView/:id",
    exact: true,
    element: <EditView />


},

// {
//     name: "Chart",
//     path: "/charts",
//     exact: true,
//     element: <Charts />


// },
{
    name: "PaymentStatus",
    path: "/paymentSuccess",
    exact: true,
    element: <PaymentStatus />,
},
{
    name: "PaymentStatus",
    path: "/paymentFailed",
    exact: true,
    element: <PaymentStatus />,
},
{
    name: "Bordeaux",
    path: "/bordeaux",
    exact: true,
    element: handlePermissions(<BordeauxLiveData />, "Live Trade - Bordeaux"),
},
{
    name: "ProductDetails",
    path: "/bordeaux/productdetails",
    exact: true,
    element: <BordeauxProductView isOMS={false} />,
},
{
    name: "ProductDetails",
    path: "/bordeaux/productdetails/:id",
    exact: true,
    element: <BordeauxProductView isOMS={true} />,
},
{
    name: "Family Member",
    path: "/familyMember",
    exact: true,
    element: handlePermissions(<FamilyAssetAllocations />, "Manage Family Member"),

},
{
    name: "Member Profile",
    path: "/memberProfile",
    exact: true,
    element: <MemberProfile />
},
{
    name: "Family Office/Entity Creation",
    path: "/familyandentityCreations",
    exact: true,
    element: handlePermissions(<OfficeEntityCreations />, "Family Office/Entity Creation"),
    // element: <VendorManagement loadData={columns} />,

},

]
