

export const FormModalProducts = {

    formFieldProducts: {
        productServiceId: {
            name: 'productServiceId',
            label: 'Product Id *',

            requiredErrorMsg: 'Product Id is required'
        },
        vendorId: {
            name: 'vendorId',
            label: 'Vendor *',

            requiredErrorMsg: 'VendorId is required'
        },
        //Product Code
        productServiceSymbol: {
            name: 'productServiceSymbol',
            placeholder: 'Enter Product Code',
            label: 'Product Code',
            requiredErrorMsg: 'Product code is required'
        },
        productServiceName: {
            name: 'productServiceName',
            placeholder: 'Enter Product name',
            label: 'Product name *',
            requiredErrorMsg: 'Product name is required'
        },
        productServiceDescription: {
            name: 'productServiceDescription',
            label: 'Product Description *',
            placeholder: 'Enter product Description ',
            requiredErrorMsg: 'Product Description is required'
        },
        primaryCategoryId: {
            name: 'primaryCategoryId',
            label: 'Financial Primary Products * ',
            placeholder: 'Choose Primary',
            requiredErrorMsg: 'Product Primary is required'
        },
        secondaryCategoryId: {
            name: 'secondaryCategoryId',
            label: 'Financial Secondary Products  ',
            placeholder: 'Choose Secondary',
            requiredErrorMsg: 'Product Secondary is required'
        },
        productServiceTypeId: {
            name: 'productServiceTypeId',
            placeholder: 'Products and Service Type ',
            label: 'Products *',
            requiredErrorMsg: 'Products Type is required'
        },
        startDate: {
            name: 'startDate',
            label: 'Start Date *',
            placeholder: 'Enter start date ',
            requiredErrorMsg: 'Start date is required'
        },
        endDate: {
            name: 'endDate',
            placeholder: 'Enter end date ',
            label: 'End Date *',
            requiredErrorMsg: 'End date  is required'
        },
        paymentMethod: {
            name: 'paymentMethod',
            placeholder: 'Enter payment Details',
            label: 'Tax Id *',
            requiredErrorMsg: 'Tax Id is required',

        },
        currencyTypeId: {
            name: 'currencyTypeId',
            placeholder: 'Enter Currency ID',
            label: 'Currency ',
            requiredErrorMsg: 'Currency is required',
        },
        returnsLevel: {
            name: 'returnsLevel',
            label: 'Returns Level',
            placeholder: 'Select Returns Level',
            requiredErrorMsg: 'Returns Level is required'
        },
        riskLevel: {
            name: 'riskLevel',
            label: 'Risk Level *',
            placeholder: 'Select Risk Level',
            requiredErrorMsg: 'Risk Level is required'
        },
        liquidity: {
            name: 'liquidity',
            label: 'Liquidity *',
            placeholder: 'Select Liquidity',
            requiredErrorMsg: 'Liquidity is required'
        },
        hnisInvested: {
            name: 'hnisInvested',
            label: `Client's Invested`,

            requiredErrorMsg: 'Hnis Invested is required'
        },
        sellingPrice: {
            name: 'sellingPrice',
            label: `Selling Price ($)`,
            placeholder: 'Selling Price',
            requiredErrorMsg: 'Selling Price is required'
        },
        taxStatus: {
            name: 'taxStatus',
            label: `Taxable *`,

            requiredErrorMsg: 'TaxStatus is required'
        },
        tags: {
            name: 'tags',
            label: `Tags`,

            requiredErrorMsg: 'Tags is required'
        },
        regionId: {
            name: 'regionId',
            label: `Region *`,
            placeholder: 'Select Region',
            requiredErrorMsg: 'Region is required'
        },

        //regionId
        takeRate: {
            name: 'takeRate',
            label: "Take Rate *",
            placeholder: 'Enter take rate  ',
            requiredErrorMsg: 'TakeRate  is required',
            morethanHundredErrorMessage: "TakeRate must be less than or equal to 100"

        },

        investmentTerm: {
            name: 'investmentTerm',
            label: 'Investment Term (in Months)',
            placeholder: 'Enter investment term ',
            requiredErrorMsg: 'Investment term is required'
        },
        takeRateParameterId: {
            name: 'takeRateParameterId',
            placeholder: 'Select ',
            requiredErrorMsg: 'TakeRate Parameter Id is required'
        },
        value: {
            name: 'value',
            label: 'Value (USD-$) *',
            placeholder: 'Enter value ',
            requiredErrorMsg: 'Value is required'
        },
        actualPerQuantityPrice: {
            name: 'actualPerQuantityPrice',
            label: 'Actual Per Quantity Price *',
            placeholder: 'Enter value ',
            requiredErrorMsg: 'Actual Per Quantity Price is required'
        },
        inBuild: {
            name: 'inBuild',
            label: 'Inbuilt API?',

            requiredErrorMsg: 'InBuilt API is required'
        },
        apiUrl: {
            name: 'apiUrl',
            label: 'API URL',
            placeholder: 'Enter value ',
            requiredErrorMsg: 'API URL  is required'
        },
        token: {
            name: 'token',
            label: 'Token',
            placeholder: 'Enter value ',
            requiredErrorMsg: 'Token is required'
        },
        emailCheck: {
            name: 'emailCheck',
            label: 'Email',

            requiredErrorMsg: 'Email is required'
        },
        orderEmail: {
            name: 'orderEmail',
            label: 'Order Email',
            placeholder: 'Enter Email',
            requiredErrorMsg: 'Order email is required'
        },
        taxRate: {
            name: 'taxRate',
            label: 'Tax Rate',
            placeholder: 'Enter value ',
            requiredErrorMsg: 'TaxRate is required',
            morethanHundredErrorMessage: "TakeRate must be less than or equal to 100"
        },
        purchaseHappenThroughStripe: {
            name: 'purchaseHappenThroughStripe',
            label: 'Should the purchase happen through Stripe?',
            placeholder: 'Select checkbox if required ',
            requiredErrorMsg: 'purchase happen through is required',

        }



    }
};







