import * as React from 'react';
import { Grid, Button, Card, CardContent, Typography } from "@mui/material";
import images1 from "../../../theme/images/blackpatterns1.png"
import { useNavigate } from 'react-router-dom';
import '../../../theme/styles/ProductsCard.css'
import { UseCurrencyHook } from '../../common/GlobalCurrency/useCurrencyHook';

export default function BordeauxProductsCard(props) {

    const navigate = useNavigate();

    const navigateToproducts = async (value) => {

        console.log("navigation")

        navigate("/bordeaux/productdetails", { state: { value } });



    }

    return (
        <Card variant="outlined" sx={{
            backgroundImage: `url(${images1})`,
            "& .MuiCardContent-root": {
                paddingLeft: '15px !important', paddingBottom: "0px !important",
                paddingRight: '12px !important',
            },
        }} className='cardStyles' >  <CardContent sx={{
            "& .MuiCardContent-root": {
                padding: '15px !important',
                paddingBottom: "0px !important",
            },
        }}>
                <Grid container flexDirestion="row" justifyContent={"space-between"}>
                    <Grid item xs={11} lg={11} md={9} >
                        <Typography title={'Vintge ' + props?.data?.vintage} className='typoGraphyText' gutterBottom>
                            <span style={{ fontFamily: "Mona Sans" }}>Vintage</span>  {props?.data?.vintage} </Typography>


                    </Grid>

                </Grid>
                <Grid container style={{ marginTop: "15px" }} flexDirection="column">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography noWrap title={props?.data.name} id="Bordeaux-productName" className='typoGraphyStylesProducts'>
                            {props?.data?.name}
                        </Typography></Grid>
                </Grid>
                <Grid container flexDirection="column">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography noWrap title={`Pack: ${props?.data?.pack} - Size: ${props?.data?.size}cl`} >
                            <span style={{
                                color: "white", fontFamily: "Mona Sans", fontSize: "12px",
                                letterSpacing: " 0.41px"
                            }}>
                                Pack: {props?.data?.pack} - Size: {props?.data?.size}cl
                            </span>
                        </Typography></Grid>
                </Grid>
                <Grid container style={{ marginTop: "17px" }} flexDirection="row" justifyContent={"space-between"} p={"5px"}>
                    <Grid item>
                        <Grid container flexDirection={"column"} sx={{ marginTop: "2px" }}>
                            <Grid item>
                                <Typography title={"Offer Value"} className='investmesntStyles'>
                                    Offer Value
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography title={props?.data?.offer_price} className='dollarText'>

                                    <UseCurrencyHook val={props?.data?.offer_price} />
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item className='gridStyle'>
                        <Button className='buttonStyles'
                            onClick={() => navigateToproducts(props?.data)}
                        >
                            View
                        </Button>
                    </Grid>
                </Grid> </CardContent></Card>
    );
}
