import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Paper, Button } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';

import Tab from '@mui/material/Tab';
import Loader from "../../common/Loader/Loader";
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInputMultiple from "../../common/Select/SelectMultiple"
import { fieldValuesProduct } from "./FormUtiils/InitialValues"
import { Formik, useFormikContext } from "formik";
import { LoadingButton } from '@mui/lab';
import SelectSingleMultiple from "../../common/Select/SelectSingleMultiple"
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box';
import CardsDisplay from "../../common/CatalogCard/CatalogCard"
import CardsDisplayList from "../../common/CatalogCard/CatalogCardList"
// const BordeauxLiveData = lazy(() => import("../components/modules/ThirdPartySections/BordeauxLiveData"))
import BordeauxLiveData from "../ThirdPartySections/BordeauxLiveData"
//import "./Catalog.css"
import "../../../theme/styles/Catalog.css"
import ToggleButton from '@mui/material/ToggleButton';
import { getAllDetailsListCatalog } from "../../../services/catalogProductandService"
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';





// Custom Tab component using inline styles
const CustomTab = (props) => {

    return (<><Box sx={{ backgroundColor: "#EDEDED", border: "10px 12px 12px 10px" }}>
        <Paper sx={{ backgroundColor: "white", marginTop: "25px" }}> <Typography sx={{ fontWeight: "600px", mt: "5px", mb: "10px", p: "5px" }} variant='h5'>Live Trade</Typography>
            <Divider variant="fullWidth" orientation="horizontal" flexItem />
            <Tab  {...props} sx={{ width: "100%", mt: "5px" }} /></Paper> </Box></>)
};
function TabPanel(props) {
    const { children, value, index, listItems, description, ...other } = props;// eslint-disable-next-line

    console.log("VALUE ON PANEL", props)

    function generateRandomInteger(max) {
        return Math.floor(Math.random() * max) + 1;
    }

    function getParticularPrimaryData(position) {

        let filterTypes = listItems[position];

        let dataresult = props?.dataFull?.filter(function (e) {
            return e.category === filterTypes?.description;
        });

        if (dataresult) {
            return dataresult[0];
        }
        else {
            return { category: filterTypes?.description, productServiceCatalogueResponseDto: [] }
        }

    } return (<div
        role="tabpanel"
        {...other}
        hidden={value !== index}

        id={`vertical-tabpaneld-${index}`}
        aria-labelledby={`vertical-tabd-${index}`} >
        {props?.windowSize[0] < 1270 && <> {value === index && value < 1 && (<>
            {props?.dataFull?.map(function (item, i) {
                return <Box > <CardsDisplay url={props?.url} dataList={item?.productServiceCatalogueResponseDto} header={item.category} icon={item?.categoryImage} colorimage={generateRandomInteger(9)} loading={false} handleModalOpenWithResponse={props.handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} /></Box>
            })}
        </>
        )}
            {value === index && value >= 1 && children !== "BORDEAUX" && (
                <Box > <CardsDisplayList url={props?.url} dataList={getParticularPrimaryData(index)} loading={false} handleModalOpenWithResponse={props.handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} /> </Box>
            )}
            {/* {value === index && value >= 1 && children === "BORDEAUX" && (
                <Box > <CardsDisplayList url={props?.url} dataList={getParticularPrimaryData(index)} loading={false} handleModalOpenWithResponse={props.handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} /> </Box>
            )} */}

        </>
        }
        {props?.windowSize[0] > 1270 && <> {value === index && value === 1 && (<>
            {props?.dataFull?.map(function (item, i) {
                return <Box justifyContent={"center"} > <CardsDisplay url={props?.url} dataList={item?.productServiceCatalogueResponseDto} header={item.category} icon={item?.categoryImage} colorimage={generateRandomInteger(9)} loading={false} handleModalOpenWithResponse={props.handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} />
                </Box>
            })}
        </>
        )}
            {value === index && value !== 1 && children !== "BORDEAUX" && (
                <Box > {console.log("BORDEAUX if check ")}<CardsDisplayList url={props?.url} dataList={getParticularPrimaryData(index - 1)} loading={false} handleModalOpenWithResponse={props.handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} /> </Box>)}
            {/* {value === index && value !== 1 && children === "BORDEAUX" && (
                <Box >{console.log("BORDEAUX trigger else")} <CardsDisplayList url={props?.url} dataList={getParticularPrimaryData(2)} loading={false} handleModalOpenWithResponse={props.handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} /> </Box>)} */}

        </>

        }
    </div>
    );
}


function LabelWithProgress(props) {

    return (
        <Grid container flexDirection={"column"}>
            {!props?.portfolio && <Grid style={{ justifyContent: "space-between" }}><Typography style={{ float: "left", fontSize: "14px", fontFamily: "Mona Sans" }}>{props.Label[0].toUpperCase() + props.Label.slice(1).toLowerCase()} </Typography> </Grid>}
            {props?.portfolio && <Grid style={{ justifyContent: "space-between" }}><Typography style={{ float: "left", fontSize: "14px", fontFamily: "Mona Sans", }}>{props.Label[0].toUpperCase() + props.Label.slice(1).toLowerCase()} </Typography> <Typography style={{ float: "right", fontSize: "9px", fontFamily: "Mona Sans", }}>{props?.progressBar + "%"} </Typography></Grid>}
            {props?.portfolio && < Grid > <LinearProgress variant="determinate" sx={{
                height: 10,
                borderRadius: 5, [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: "#DFDFDF"
                },
                [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: "#66C824"
                }
            }} value={props?.progressBar} /> </Grid>}

        </Grid>

    )
}
function LabelWithoutProgress(props) {// eslint-disable-next-line

    return (
        <Grid container flexDirection={"column"}>
            {!props?.portfolio && <Grid style={{ justifyContent: "space-between" }}><Typography style={{ float: "left", fontSize: "14px", fontFamily: "Mona Sans", }}>{props.Label[0].toUpperCase() + props.Label.slice(1).toLowerCase()} </Typography> </Grid>}
            {props?.portfolio && <Grid style={{ justifyContent: "space-between" }}><Typography style={{ float: "left", fontSize: "14px", fontFamily: "Mona Sans", }}>{props.Label[0].toUpperCase() + props.Label.slice(1).toLowerCase()} ({props?.progressBar + "%"}) </Typography></Grid>}
        </Grid>

    )
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
// // eslint-disable-next-line
// function a11yProps(index) {
//     return {
//         id: `vertical-tab-${index}`,
//         'aria-controls': `vertical-tabpanel-${index}`,
//     };
// }
// // eslint-disable-next-line
// function a11yPropsH(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }
const Logger = (props) => {
    const formik = useFormikContext();// eslint-disable-next-line
    React.useEffect(() => {
        if (formik.values.locationId) {
            props.handleChangeLocation(formik.values.locationId);
        }
        else {
            props.handleChangeTag(formik.values.tags)
        }
        // eslint-disable-next-line
    }, [formik.values]);
    return null;
};

export default function CatalogIndex(props) {
    const [isLoadingInternal, setisLoadingInternal] = React.useState(true);
    // eslint-disable-next-line
    const [valueofProductID, setValueProductID] = React.useState(props.url === "product" ? props?.passingValueForrender?.producttypes[0].id : props.passingValueForrender?.servicetypes[0].id);// eslint-disable-next-line
    const [alignment, setAlignment] = React.useState(props.url === "product" ? props?.passingValueForrender?.producttypes[0].description : props.passingValueForrender?.servicetypes[0].description);// eslint-disable-next-line
    const [sidebarValue, setSidebarValue] = React.useState([]);
    const [dataFull, setFullData] = React.useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const rmUser = JSON.parse(localStorage.getItem('user'))
    const rmvalue = rmUser?.userTypeId?.description
    const [q, setQ] = React.useState("");// eslint-disable-next-line
    const [location, setLocations] = React.useState([]);// eslint-disable-next-line
    const [tagslist, settagslist] = React.useState([]);// eslint-disable-next-line
    const [buttonLoading, setButtonLoading] = React.useState(false);// eslint-disable-next-line
    let { formFieldProducts: { locationId, tags } } = props;
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);
    const [value, setValue] = React.useState(windowSize[0] < 1270 ? 0 : 1);
    const [bordeauxEnable, setbordeauxEnable] = React.useState(false)
    const navigate = useNavigate();
    const [condtion, setcondtion] = React.useState(false)
    const [regionList, setregionList] = React.useState(null)
    const [tagsLists, settagsLists] = React.useState(null)
    const [modeOfServices, setmodeOfServices] = React.useState("")


    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => { window.removeEventListener('resize', handleWindowResize); };
    });
    React.useEffect(() => {
        if (props.url === "product") {
            console.log(props?.passingValueForrender, "passingValueForrender")
            setSidebarValue([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.png" }, ...props?.passingValueForrender?.financialproducts])
            settagsLists(props?.passingValueForrender?.tags)
            changeObjectPropertiesRegion(props?.passingValueForrender?.location)
        }
        else {
            settagsLists(props?.passingValueForrender?.tags)
            setSidebarValue([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.png" }, ...props?.passingValueForrender?.financialservices]);
            changeObjectPropertiesRegion(props?.passingValueForrender?.location)

        }
        setTimeout(() => {
            const passingObject = {
                rmId: user?.userId,
                productServiceTypeId: valueofProductID,
                locationId: [],
                tags: [],
                search: ''
            }
            getDataDetails(passingObject)
            setcondtion(true)

        }, 900)

        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (condtion) {
            setTimeout(() => {
                const passingObject = {
                    rmId: user?.userId,
                    productServiceTypeId: valueofProductID,
                    locationId: location,
                    tags: tagslist,
                    search: q
                }
                getDataDetails(passingObject)
                setbordeauxEnable(false)

            }, 900)
        }

        // eslint-disable-next-line
    }, [valueofProductID])
    React.useEffect(() => {
        const timeOut = condtion ? setTimeout(() => getDataDetails({
            rmId: user?.userId,
            productServiceTypeId: valueofProductID,
            locationId: location,
            tags: tagslist,
            search: q
        }), 900) : null;


        return () => clearTimeout(timeOut);
        // eslint-disable-next-line
    }, [q])

    const getDataDetails = async (values) => {
        setisLoadingInternal(true)
        if (props?.recommentation === "recommentation") {
            if (rmvalue === "Entity RM") {
                values.promote = true
                values.hniId = props?.id
            }
            else {
                values.promote = true
                values.hniId = user?.userId
            }
        }
        if (props?.portfolio) {
            values.hniPortfolioAndSubscriptions = true;
            values.hniId = user?.userId
        }

        setFullData([])
        let resultOfData = await getAllDetailsListCatalog(values)
        if (resultOfData?.data?.status) {
            setFullData(resultOfData?.data?.body)
            setisLoadingInternal(false)
        }
    }

    const changeObjectPropertiesRegion = async (listOfData) => {
        const newArrayOfObj = await listOfData?.map(({
            countryName: description,
            ...rest
        }) => ({
            description,
            ...rest
        }));
        setregionList(newArrayOfObj)
    }
    const handleChangeButton = async (event) => {
        setAlignment(event.target.value);
        if (event.target.value !== alignment) {

            if (props.url === "product") {
                setisLoadingInternal(true)
                if (event.target.value === "FINANCIAL PRODUCTS") {
                    setSidebarValue([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.png" }, ...props?.passingValueForrender?.financialproducts])
                    setValue(windowSize[0] < 1270 ? 0 : 1);
                    setValueProductID(props?.passingValueForrender?.financialproducts[0].parentId)
                }
                else {
                    setSidebarValue([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.png" }, ...props?.passingValueForrender?.lifestyleproducts])
                    setValue(windowSize[0] < 1270 ? 0 : 1);
                    setValueProductID(props?.passingValueForrender?.lifestyleproducts[0].parentId)
                }
            }
            else {
                setisLoadingInternal(true)
                if (event.target.value === "FINANCIAL SERVICES") {
                    setSidebarValue([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.png" }, ...props?.passingValueForrender?.financialservices])
                    setValue(windowSize[0] < 1270 ? 0 : 1);
                    setValueProductID(props?.passingValueForrender?.financialservices[0].parentId)
                }
                else {
                    setSidebarValue([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.png" }, ...props?.passingValueForrender?.lifestyleservices])
                    setValue(windowSize[0] < 1270 ? 0 : 1);
                    setValueProductID(props?.passingValueForrender?.lifestyleservices[0].parentId)
                }
            }
        }
    };
    const handleFormSubmit = async (values) => {
        setButtonLoading(true)

        const passingObject = {
            rmId: user?.userId,
            productServiceTypeId: valueofProductID,
            locationId: location,
            tags: values.tags,
            search: q
        }
        await getDataDetails(passingObject)
        setTimeout(() => {
           // setValue(windowSize[0] < 1270 ? 0 : 1);
            setButtonLoading(false)

        }, 900)
    };

    const handleModalOpenWithResponse = async (id) => {
        if (props.url === "product") {
            if (props?.recommentation === "recommentation") {
                navigate("/viewProductDetails/" + btoa(id), { state: { recommentation: "recommentation", hniId: props?.id } });
            }
            else {
                navigate("/viewProductDetails/" + btoa(id))
            }
        }
        else {
            if (props?.recommentation === "recommentation") {
                navigate("/viewServiceDetails/" + btoa(id), { state: { recommentation: "recommentation", hniId: props?.id } });
            }
            else {
                navigate("/viewServiceDetails/" + btoa(id))
            }
        }
    }

    const handleChangeLocation = (values) => { setLocations(values) }

    const handleChangeTag = (values) => { settagslist(values) }


    const handleChangeHistory = async (event, newAlignment) => {
        setmodeOfServices(newAlignment);

        if (newAlignment === "Service") {
            const passingObjectService = {
                rmId: user?.userId,
                hniId: user?.userId,
                productServiceTypeId: valueofProductID,
                locationId: location,
                tags: tagslist,
                search: q,
                hniPortfolioAndSubscriptions: true,
                hniPortfolioAndSubscriptionsHistory: false
            }
            await getDataDetails(passingObjectService)
        }
        else {
            const passingObjectHistory = {
                rmId: user?.userId,
                hniId: user?.userId,
                productServiceTypeId: valueofProductID,
                locationId: location,
                tags: tagslist,
                search: q,
                hniPortfolioAndSubscriptions: false,
                hniPortfolioAndSubscriptionsHistory: true
            }
            await getDataDetails(passingObjectHistory)
        }
    };


    const handleChangeT = async (description, index) => {
        console.log("CHECK DESCRIPTION ON TAB", description)

        setValue(index);

        if (description === "BORDEAUX") {
            setbordeauxEnable(true)
        }
        else {
            setbordeauxEnable(false)
        }
    };

    return (
        <>
            <div style={{ display: windowSize[0] < 1270 ? "block" : "none", }}>
                <div>
                    <ToggleButtonGroup
                        color="secondary"
                        sx={{
                            fontFamily: "Mona Sans",
                            fontSize: "14px !important",
                            '& .MuiToggleButton-root.Mui-selected ': {
                                borderBottom: "2px solid green !important",
                                borderBottomWidth: "20px",
                                fontFamily: "Mona Sans !important",

                            }, '& .MuiToggleButton-root ': {
                                fontSize: "14px !important",

                                borderRadius: "0px",
                                border: "none"
                            },
                        }}
                        value={alignment}
                        exclusive
                        onChange={handleChangeButton}  >
                        {props.url === "product" ? props.passingValueForrender.producttypes?.map(function (item, i) {
                            return <ToggleButton key={item.description} value={item.description} style={{
                                fontFamily: "Mona Sans",
                                minWidth: "auto",
                            }}>{item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}</ToggleButton>
                        })
                            : props.passingValueForrender.servicetypes?.map(function (item, i) {
                                return <ToggleButton key={item.description} value={item.description} style={{ fontFamily: "Mona Sans", minWidth: "auto", }}>{item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}</ToggleButton>
                            })}
                    </ToggleButtonGroup>
                </div>
                <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={value} onChange={(event, newValue) => handleChangeT(sidebarValue[newValue]?.description, newValue)} className='tabsStyles'
                    sx={{
                        fontFamily: "Mona Sans !important",
                        fontSize: "14px",
                        '& .Mui-selected': {
                            fontFamily: "Mona Sans !important",
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: "green"
                        }, '& .MuiTabs-root': {
                            fontFamily: "Mona Sans !important",
                            borderRight: "none !important",
                        },
                        '& .MuiButtonBase-root': {
                            maxHeight: "60px",
                            minHeight: "55px",
                        },
                    }}  >
                    {sidebarValue?.map(function (item, i) {
                        return <Tab icon={<img alt="icons" src={item?.imagePath} loading="lazy" style={{ width: value !== i - 1 ? "45px" : "35px", height: value !== i - 1 ? "45px" : "35px" }} />} iconPosition="start"
                            label={<LabelWithoutProgress Label={item?.description} progressBar={item?.progressBar} portfolio={props?.portfolio} />
                            }
                        >  </Tab>
                    })}
                </Tabs>
                <div className='accordianDivStyle'>
                    {!bordeauxEnable && <div style={{ marginTop: "3px" }}>
                        <Accordion style={{ backgroundColor: "#F7F7F7" }}>
                            <AccordionSummary style={{ backgroundColor: "#F7F7F7", marginTop: "-1px" }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <TextField variant="outlined" type="text" value={q} placeholder={"Search"} className="inputSearchCSS" onChange={(e) => setQ(e.target.value)}
                                    InputProps={{ startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>, }}
                                    sx={{
                                        '& input::placeholder': {
                                            fontFamily: "Mona Sans",
                                            textOverflow: 'ellipsis !important',
                                            color: '#BBC5D5 !important',
                                            opacity: 1,
                                        },
                                        '& .MuiInputBase-root': {
                                            height: '40px !important',
                                            fontFamily: "Mona Sans",
                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                            borderRadius: '8px',

                                        },
                                    }} />
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: "#F7F7F7" }}>
                                <Formik onSubmit={handleFormSubmit} initialValues={fieldValuesProduct}  >
                                    {({// eslint-disable-next-line
                                        values, errors, touched, handleBlur, handleChange, handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit} style={{ marginTop: "-18px" }}>
                                            <Grid container flexDirection={"column"} spacing={1}>
                                                <Grid item xs={12} >
                                                    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                                                        <Grid item xs={5} >
                                                            <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={locationId.name} header={locationId.label} placeholder={locationId.placeholder} data={regionList} multiple={"multiple"} />
                                                        </Grid>
                                                        <Grid item xs={5} >
                                                            <SelectSingleMultiple fixedwidth={"100%"} fixedHeight={32} name={tags.name} header={tags.label} placeholder={tags.placeholder} data={tagsLists} multiple={"multiple"} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    {buttonLoading && <LoadingButton loading loadingIndicator="Loading…" className="loadingButtonCSS" variant="outlined">
                                                        Applying...
                                                    </LoadingButton>}
                                                    {!buttonLoading && <Button className="applyFiltersButton" variant="contained" type="submit">
                                                        Apply Filters
                                                    </Button>}
                                                </Grid> </Grid>
                                            <Logger handleChangeLocation={handleChangeLocation} handleChangeTag={handleChangeTag} />
                                        </form>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </div>}
                    <div className="divTabpanelcss">
                        {!isLoadingInternal && <>{sidebarValue?.map(function (item, i) {
                            return <TabPanel url={props?.url} value={value} index={i} description={item?.description} listItems={sidebarValue} dataFull={dataFull} isLoadingInternal={isLoadingInternal} handleModalOpenWithResponse={handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} windowSize={windowSize} >
                                {item.description}
                            </TabPanel>
                        })}</>
                        }
                        {isLoadingInternal && <Loader isLoading={isLoadingInternal} />}
                    </div>
                </div >
            </div>
            {/* {bordeauxEnable &&
                <BordeauxLiveData />
            } */}
            <Box className="boxCss" >
                <Paper className="paperStyleCSS" sx={{ display: windowSize[0] < 1270 ? "none" : "block", boxShadow: "0px 9px 22px #0000001F", }}   >
                    <Tabs orientation="vertical" style={{ minWidth: "200px", maxWidth: "auto", }} value={value} onChange={(event, newValue) => handleChangeT(sidebarValue[newValue - 1]?.description, newValue)} aria-label="Vertical tabs example"
                        sx={{
                            fontFamily: "Mona Sans !important",
                            fontSize: "14px",
                            boxShadow: "0px 9px 22px #0000001F",
                            '& .MuiTabs-root .MuiTab-root.Mui-selected': {
                                backgroundColor: "black", fontFamily: "Mona Sans",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                            },
                            '& .MuiTab-root.Mui-selected ': {
                                backgroundColor: "black", fontFamily: "Mona Sans",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                            },

                            '& .MuiTab-iconWrapper': {
                                width: "35px !important",
                                height: "35px !important"
                            },
                            '& .MuiTabs-root': {
                                borderRight: "none !important",
                                fontFamily: "Mona Sans !important",
                                height: "40px !important"
                            },
                            '& .MuiButtonBase-root': {
                                maxHeight: "60px",
                                minHeight: "55px",
                            },
                            '& .MuiTabs-indicator': {
                                width: "0px !important"
                            },
                            '& .MuiButtonBase-root.MuiTab-root': {
                                flex: 1,
                                flexDirection: "row !important",
                                paddingLeft: '10px',
                                maxHeight: "50px !important",
                                justifyContent: "flex-start !important",
                            },
                        }}   >

                        <div>
                            <ToggleButtonGroup
                                color="secondary"
                                sx={{
                                    fontFamily: "Mona Sans",
                                    fontSize: "14px !important",
                                    padding: "10px",
                                    '& .MuiToggleButton-root.Mui-selected ': {
                                        borderBottom: "2px solid green !important",

                                        borderBottomWidth: "20px",
                                        fontFamily: "Mona Sans",

                                    },
                                    '& .MuiToggleButton-root ': {
                                        fontFamily: "Mona Sans", fontSize: "14px !important",
                                        borderRadius: "0px",
                                        border: "none"
                                    },
                                }}
                                value={alignment} exclusive onChange={handleChangeButton}  >
                                {props.url === "product" ? props.passingValueForrender.producttypes?.map(function (item, i) {
                                    return <ToggleButton value={item.description}  >{item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}</ToggleButton>
                                })
                                    : props.passingValueForrender.servicetypes?.map(function (item, i) {
                                        return <ToggleButton value={item.description}  >{item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}</ToggleButton>
                                    })}
                            </ToggleButtonGroup>
                        </div>
                        {sidebarValue?.map(function (item, i) {
                            if (item.description === "BORDEAUX") {
                                return null;
                            }
                            return <Tab icon={<img alt="icons" src={item?.imagePath} loading="lazy" style={{ width: value !== i - 1 ? "45px" : "35px", height: value !== i - 1 ? "45px" : "35px" }} />} iconPosition="start"
                                label={value - 1 === i ? <LabelWithProgress Label={item?.description} progressBar={item?.progressBar} portfolio={props?.portfolio} selectedItem={true} /> : <LabelWithProgress Label={item?.description} progressBar={item?.progressBar} portfolio={props?.portfolio} selectedItem={false} />
                                }
                            >        </Tab>


                        })}
                        {sidebarValue?.map(function (item, i) {
                            if (item.description === "BORDEAUX") {
                                return <CustomTab
                                    key={item.description}
                                    icon={<img alt="icons" src={item?.imagePath} loading="lazy" style={{ width: value !== i - 1 ? "45px" : "35px", height: value !== i - 1 ? "45px" : "35px" }} />}
                                    iconPosition="start"
                                    label={value - 1 === i ? <LabelWithProgress Label={item?.description} progressBar={item?.progressBar} portfolio={props?.portfolio} selectedItem={true} /> : <LabelWithProgress Label={item?.description} progressBar={item?.progressBar} portfolio={props?.portfolio} selectedItem={false} />}
                                />

                            }
                            return null;
                        })}



                    </Tabs>
                </Paper>
                {!bordeauxEnable &&
                    <div className="divStylePortfolioCSS" style={{ display: windowSize[0] < 1270 ? "none" : "block" }}>
                        <Grid container flexDirection="column" style={{ marginBottom: "-80px", }}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography sx={{ fontFamily: "Mona Sans", fontSize: "14px" }}>Filters:</Typography>
                                </Grid>
                                <Grid item>
                                    {props?.portfolio && <ToggleButtonGroup value={modeOfServices} exclusive onChange={handleChangeHistory} style={{ float: "right" }}
                                        sx={{
                                            fontFamily: "Mona Sans",
                                            backgroundColor: "#683AE0",

                                            '& .MuiToggleButton-root.Mui-selected': {
                                                color: "#ffffff"
                                            }
                                        }}  >
                                        <ToggleButton value="Service">Service</ToggleButton>
                                        <ToggleButton value="History">History</ToggleButton>
                                    </ToggleButtonGroup>}
                                </Grid>
                            </Grid>
                            <Grid>
                                <Box className="formilkBoxStyle">
                                    <Formik onSubmit={handleFormSubmit}
                                        initialValues={fieldValuesProduct}  >
                                        {({// eslint-disable-next-line
                                            values, errors, touched, handleBlur, handleChange, handleSubmit,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container flexDirection={"row"}>
                                                    <Grid lg={7} spacing={1}>
                                                        <Grid container flexDirection={"row"} spacing={3} >
                                                            <Grid item >
                                                                <SelectInputMultiple fixedwidth={120} fixedHeight={32} name={locationId.name} header={locationId.label} data={regionList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                                            </Grid>
                                                            <Grid item >
                                                                <SelectSingleMultiple fixedwidth={120} fixedHeight={32} name={tags.name} header={tags.label} data={tagsLists} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                                            </Grid>
                                                            <Grid item >
                                                                <Grid container flexDirection={"column"}>
                                                                    <Grid item>
                                                                        <div className='whiteSpaceAllign'></div>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {buttonLoading && <LoadingButton loading loadingIndicator="Loading…" className='applyingButtonStyle' variant="outlined">
                                                                            Applying...
                                                                        </LoadingButton>}
                                                                        {!buttonLoading && <Button className='applyButtonFilterStyle' variant="contained" type="submit">
                                                                            Apply Filters
                                                                        </Button>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Divider orientation="vertical" flexItem sx={{ marginRight: "30px" }} />


                                                    <Grid lg={4} flexDirection="column" >
                                                        <Typography variant="h6" className='searchTextCSS'>
                                                            Search
                                                        </Typography>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            value={q}
                                                            placeholder={"Search"}
                                                            // onChange={(e) => setValuesForSearch(e.target.value)}
                                                            onChange={(e) => setQ(e.target.value)}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                            }}
                                                            className='textInput'
                                                            sx={{
                                                                '& input::placeholder': {
                                                                    fontFamily: "Mona Sans",
                                                                    textOverflow: 'ellipsis !important',
                                                                    color: '#BBC5D5 !important',
                                                                    opacity: 1,
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    fontFamily: "Mona Sans",
                                                                    height: '32px !important',
                                                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                                    borderRadius: '8px',
                                                                },
                                                            }} />
                                                    </Grid>
                                                </Grid>
                                                <Logger handleChangeLocation={handleChangeLocation} handleChangeTag={handleChangeTag} />
                                            </form>
                                        )}
                                    </Formik>
                                </Box>
                            </Grid>
                        </Grid >

                        <div style={{ marginTop: windowSize[0] > 1000 ? "10px" : "40px", }} className='tabPanelDivStyle'>

                            {!isLoadingInternal && <>{sidebarValue?.map(function (item, i) {
                                return <TabPanel url={props?.url} value={value} index={i + 1} description={item?.description} listItems={sidebarValue} dataFull={dataFull} isLoadingInternal={isLoadingInternal} handleModalOpenWithResponse={handleModalOpenWithResponse} portfoliaChanges={props.portfoliaChanges} windowSize={windowSize}>
                                    {item.description}
                                </TabPanel>
                            })}</>
                            }
                            {isLoadingInternal && <Loader isLoading={isLoadingInternal} />}
                        </div>
                    </div >
                }
                {bordeauxEnable && <div style={{ marginTop: windowSize[0] < 1000 ? "-120px" : null }}>
                    <BordeauxLiveData /></div>
                }
            </Box >
        </>
    );
}
