import * as React from 'react';
import Box from '@mui/material/Box';// eslint-disable-next-line
import { openModal, setTableColumnData } from "../../../../redux/slices/dashboardModalSlice"
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Grid } from '@mui/material';
import BARWITHLINE from "../../../common/ChartsComponent/BarWithLine"
import { getClientswiseAUMPaginationsData, getClientswiseInvestedPaginationsData } from "../../../../services/DashboardAPI"
import { createColumnDynamicBar } from "../../../../constant/DynamicTableColumsUtils"// eslint-disable-next-line

export default function BarChartController(props) {// eslint-disable-next-line
    // eslint-disable-next-line
    const checkStatusModal = useSelector((state) => state.modal)// eslint-disable-next-line
    const dispatch = useDispatch()
    const [pageNumber, setPageNumber] = React?.useState(1)
    const user = JSON.parse(localStorage.getItem('user'))
    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member") ? true : false;

    const apiCalls = async () => {
        let passingObject = {
            loginUserId: user?.userId,
            pageNo: pageNumber - 1,
            pageSize: 10
        }
        let dataFromResponse = props?.category === "AUM" ? await getClientswiseAUMPaginationsData(passingObject) : await getClientswiseInvestedPaginationsData(passingObject);

        return props?.category === "AUM" ? dataFromResponse?.data?.body?.clientsCurrentInvestedWealthAum : dataFromResponse?.data?.body?.clientsInvestedWealth;
    }
    const dataModalReducer = async () => {

        let dataObjects = await apiCalls();
        if (dataObjects) {
            let columsDynamics = await createColumnDynamicBar(dataObjects[0], props?.category)
            console.log(columsDynamics, "columsDynamics")
            await dispatch(openModal(true))
            await dispatch(setTableColumnData({ column: columsDynamics, titleofModal: props?.title, data: dataObjects, loading: false, }))
        }
    }
    return (
        // <Box sx={{
        //     width: '100%', height: "100%", background: "#FFFFFF 0% 0% no-repeat padding-box",
        //     boxShadow: "0px 3px 8px #77777733",
        //     border: "0.699999988079071px solid #DFD2D280",
        //     borderRadius: "12px",
        //     opacity: 1
        // }}>
        //     <Grid container sx={{ justifyContent: "space-around", flexDirection: "column" }}>


        //         <Grid item container sx={{ justifyContent: "space-between", flexDirection: "row", display: "flex", p: "10px" }}>
        //             <Grid item>
        //                 <Typography
        //                     sx={{
        //                         fontFamily: "Mona Sans", fontSize: "11px", fontWeight: "600 !important",
        //                         letterSpacing: "0px",
        //                         color: "#000000",
        //                         opacity: 1,
        //                     }}
        //                 >{props?.title}

        //                 </Typography>
        //             </Grid>
        //             {!isHni && <Grid item>
        //                 <Typography sx={{
        //                     fontFamily: "Mona Sans", fontSize: "10px", textDecoration: "underline", letterSpacing: "0px",
        //                     color: "#000000", cursor: "pointer",
        //                     opacity: 1
        //                 }} onClick={() => { dataModalReducer() }}>{"See client wise details"}

        //                 </Typography>
        //             </Grid>}

        //         </Grid>
        //         <Grid item container mt={"5px"} p={"10px"}>
        //             {props?.data?.label && props?.data?.data && <BARWITHLINE data={props?.data} category={props?.category} setPageNumber={setPageNumber} pageNumber={pageNumber} type={props?.type} />}
        //             {!props.isloading && !props?.data?.label && !props?.data?.data && <p style={{ paddingLeft: '10px' }}>No Records Found</p>}
        //             {props.isloading && <p style={{ paddingLeft: '10px' }}>loading...</p>}


        //         </Grid>
        //     </Grid>
        // </Box>
        <Box
            sx={{
                width: '100%',
                height: "100%",
                background: "#FFFFFF",
                boxShadow: "0px 3px 8px #77777733",
                border: "0.7px solid #DFD2D2",
                borderRadius: "12px",
                opacity: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Grid container sx={{ justifyContent: "space-between", flexDirection: "row", p: "10px" }}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: "Mona Sans",
                            fontSize: "11px",
                            fontWeight: "600",
                            color: "#000000",
                            opacity: 1,
                        }}
                    >
                        {props?.title}
                    </Typography>
                </Grid>
                {!isHni && (
                    <Grid item>
                        <Typography
                            sx={{
                                fontFamily: "Mona Sans",
                                fontSize: "10px",
                                textDecoration: "underline",
                                color: "#000000",
                                cursor: "pointer",
                                opacity: 1
                            }}
                            onClick={dataModalReducer}
                        >
                            See client wise details
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item sx={{ flexGrow: 1, p: "10px" }}>
                {/* Conditionally render BARWITHLINE component based on data availability */}
                {props?.data?.label && props?.data?.data ? (
                    <BARWITHLINE data={props?.data} category={props?.category} setPageNumber={setPageNumber} pageNumber={pageNumber} type={props?.type} />
                ) : (
                    <Typography sx={{ paddingLeft: '10px' }}>
                        {props.isloading ? 'Loading...' : 'No Records Found'}
                    </Typography>
                )}
            </Grid>
        </Box>
    );
}