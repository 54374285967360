import { memoize } from "lodash";

const aesKey = "Wk+Uzyyn8991w/2V5OIqiQ==";

const AESEncryptionKey = memoize(() => Promise.resolve(aesKey));

let iv = crypto.getRandomValues(new Uint8Array(12));
let secretKey;

const secretKeyGeneration = AESEncryptionKey().then((aesKey) => {
    const msgUint8 = new TextEncoder().encode(aesKey);
    return window.crypto.subtle.digest("SHA-256", msgUint8);
}).then((value) => {
    return window.crypto.subtle.importKey(
        "raw",
        value,
        "AES-GCM",
        false,
        ["encrypt", "decrypt"]
    );
}).then((key) => {
    secretKey = key;
}).catch((err) => {
    console.error(err);
});

export function AES256_GCM_ENCRYPT(params) {
    return secretKeyGeneration.then(() => {
        return crypto.subtle.encrypt(
            { name: "aes-gcm", iv: iv, tagLength: 128 },
            secretKey,
            asciiToUint8Array(params)
        );
    }).then((cipherText) => {
        const finalValue = _appendBuffer(iv, cipherText);
        return arrayBufferToBase64(finalValue);
    });
}

export function AES256_GCM_decrypt({ data: { encryptedValue } }) {
    return secretKeyGeneration.then(() => {
        if (!secretKey) {
            console.error("AES key is not available.");
            return Promise.reject("AES key is not available.");
        }

        const cipherTextIV = encryptedValue.substring(0, 16);
        const cipherTextValue = encryptedValue.substring(16);
        const arrayBufferCipherTextIV = base64ToArrayBuffer(cipherTextIV);
        const arrayBufferCipherTextValue = base64ToArrayBuffer(cipherTextValue);

        return crypto.subtle
            .decrypt(
                { name: "aes-gcm", iv: arrayBufferCipherTextIV, tagLength: 128 },
                secretKey,
                arrayBufferCipherTextValue
            )
            .then((plainText) => bytesToASCIIString(plainText))
            .catch((error) => {
                console.error("Decryption error:", error);
                return Promise.reject(error);
            });
    });
}

function bytesToASCIIString(bytes) {
    return new TextDecoder("utf-8").decode(new Uint8Array(bytes));
}

function arrayBufferToBase64(buffer) {
    const binary = new Uint8Array(buffer);
    return window.btoa(String.fromCharCode(...binary));
}

function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

function asciiToUint8Array(str) {
    const chars = [];
    for (let i = 0; i < str.length; ++i) {
        chars.push(str.charCodeAt(i));
    }
    return new Uint8Array(chars);
}

function _appendBuffer(buffer1, buffer2) {
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
}
