import React from 'react'
import { CircularProgress, Backdrop } from '@mui/material';;

function Loader({ isLoading, colorProp }) {

    // const [loading, setLoading] = React.useState(false)
    // console.log(isLoading, "abcddddddddddddddddd")
    return (
        <Backdrop open={isLoading || false} sx={{ background: 'rgba(0, 0, 10, 0.04)' }} >
            <div>
                <CircularProgress color="inherit" sx={{ color: colorProp ? "white" : 'black' }} />
            </div>
        </Backdrop>
    )
}

export default Loader