import Dashboard from "../../theme/images/whiteBackgroundIcons/Dashboard.svg"
import UserManagement from "../../theme/images/whiteBackgroundIcons/User Management.svg"
import VendorManagement from "../../theme/images/whiteBackgroundIcons/Vendor Management.svg"
import Clients from "../../theme/images/whiteBackgroundIcons/Clients.svg"
import Product from "../../theme/images/whiteBackgroundIcons/Products - Services.svg"// eslint-disable-next-line
import Portfolio from "../../theme/images/whiteBackgroundIcons/Clients.svg"// eslint-disable-next-line
import Subscriptions from "../../theme/images/whiteBackgroundIcons/Dashboard.svg"// eslint-disable-next-line
import services from "../../theme/images/whiteBackgroundIcons/Products - Services.svg"
import Enquiry from "../../theme/images/whiteBackgroundIcons/Enquiry.svg"
import Campaign from "../../theme/images/whiteBackgroundIcons/Campaign Status.svg"
import CustomerFeedback from "../../theme/images/whiteBackgroundIcons/Customer Feedback.svg"
import Reports from "../../theme/images/whiteBackgroundIcons/Reports.svg"
import userRights from "../../theme/images/whiteBackgroundIcons/User Rights.svg"
import Payment from "../../theme/images/whiteBackgroundIcons/My Performance.svg"
import OrderManagementB from "../../theme/images/blackBackgroundIcons/Order Management.png"

import familyOffice from "../../theme/images/Familyoffice.svg"
import DashboardB from "../../theme/images/blackBackgroundIcons/Dashboard.svg"

import UserManagementB from "../../theme/images/blackBackgroundIcons/User Management.png"
import VendorManagementB from "../../theme/images/blackBackgroundIcons/Vendor Management.png"
import ClientsB from "../../theme/images/blackBackgroundIcons/Clients.png"
import ProductB from "../../theme/images/blackBackgroundIcons/Product.svg"// eslint-disable-next-line
import PortfolioB from "../../theme/images/blackBackgroundIcons/Portfolio.svg"// eslint-disable-next-line
import SubscriptionsB from "../../theme/images/blackBackgroundIcons/Subscriptions.svg"// eslint-disable-next-line
import servicesB from "../../theme/images/blackBackgroundIcons/Service.svg"
import EnquiryB from "../../theme/images/blackBackgroundIcons/Enquiry.svg"
import CampaignB from "../../theme/images/blackBackgroundIcons/Campaign.svg"
import CustomerFeedbackB from "../../theme/images/blackBackgroundIcons/Customer Feedback.png"
import ReportsB from "../../theme/images/blackBackgroundIcons/Report.svg"
import userRightsB from "../../theme/images/blackBackgroundIcons/User Rights.png"
import ReferenceDataManagementB from "../../theme/images/blackBackgroundIcons/Report.svg"
import PaymentB from "../../theme/images/blackBackgroundIcons/Dashboard.svg"
import kairosBot from "../../theme/images/blackBackgroundIcons/kairosBot.png"
import liveIcon from "../../theme/images/blackBackgroundIcons/Live_Black BG.png"
import liveIconB from "../../theme/images/blackBackgroundIcons/Live_white BG.png"
import RMPerfomIcon from '../../theme/images/blackBackgroundIcons/RM perf_wihite BG.png'
import RMPerfomIconB from '../../theme/images/blackBackgroundIcons/RM perf_Black BG.png'
import FAQ from '../../theme/images/faq.png'
import Chat from '../../theme/images/chat.png'

const userChecks = JSON.parse(window.localStorage.getItem("user"))
const isRmChecks = userChecks?.userTypeId?.description === "Entity RM" ? true : false


const NavButton = [{
    index: 0,
    text: "Dashboard",
    icon: Dashboard,
    icon2: DashboardB,
    path: "/dashboard"
},
{
    index: 26,
    text: "Ask Gaia",
    icon: kairosBot,
    icon2: kairosBot,
    path: "/kairosbot"
},
{
    index: 1,
    text: "User Management",
    icon: UserManagement,
    icon2: UserManagementB,

    path: "/userManagement"
}, {
    index: 2,
    text: "Vendor Management",
    icon: VendorManagement,
    icon2: VendorManagementB,

    path: "/vendorManagement"

},
{
    index: 3,
    text: "Product",
    icon: Product,
    icon2: ProductB,

    path: "/productsCatalog"

}, {
    index: 4,
    text: "Service",
    icon: services,
    icon2: servicesB,

    path: "/serviceCatalog"

},


{
    index: 5,
    text: "Financial Products",
    icon: Product,
    icon2: ProductB,

    path: "/financialproducts"
},
{
    index: 6,
    text: "Lifestyle Products",
    icon: Product,
    icon2: ProductB,

    path: "/lifestyleproducts"
},
{
    index: 27,
    text: "Live Trade - Bordeaux",
    icon: liveIconB,
    icon2: liveIcon,

    path: "/bordeaux"
},
{
    index: 7,
    text: "Portfolio",
    icon: Portfolio,
    icon2: PortfolioB,

    path: "/myPortfolio"
},
// {
//     index: 28,
//     text: "Manage Family Member",
//     icon: VendorManagement,
//     icon2: VendorManagementB,

//     path: "/familyMember"
// },
{
    index: 8,
    text: "Subscriptions",
    icon: Subscriptions,
    icon2: SubscriptionsB,

    path: "/mySubscriptions"
},




{
    index: 10,
    text: "Financial Services",
    icon: services,
    icon2: servicesB,

    path: "/financialservices"
}, {
    index: 11,
    text: "Lifestyle Services",
    icon: services,
    icon2: servicesB,

    path: "/lifestyleservices"
}, {
    index: 12,
    text: "Campaign",
    icon: Campaign,
    icon2: CampaignB,

    path: "/campaignstatus"

},


{
    index: 13,
    text: "My Prospects",
    icon: Campaign,
    icon2: CampaignB,

    path: "/prospects"

}
    ,
{
    index: 14,
    text: "Divider",



},
// {
//     index: 15,
//     text: !isRmChecks?"Clients":"My Clients",
//     icon: Clients,
//     icon2: ClientsB,

//     path: "/clients"

// },
{
    index: 15,
    text: !isRmChecks?"Clients":"My Clients",
    icon: Clients,
    icon2: ClientsB,

    path: !isRmChecks?"/clients":"/myClients"

},
{
    index: 16,
    text: "Reports",
    icon: Reports,
    icon2: ReportsB,

    path: "/reports"

}, {
    index: 37,
    text: "RM Performance",
    icon: RMPerfomIcon,
    icon2: RMPerfomIconB,

    // path: ""
}, {
    index: 17,
    text: "Customer Feedback",
    icon: CustomerFeedback,
    icon2: CustomerFeedbackB,

    path: "/customerFeedback"

}, {
    index: 18,
    text: "General Enquiries",
    icon: Enquiry,
    icon2: EnquiryB,

    path: "/enquiry"

}, {
    index: 19,
    text: "User Rights",
    icon: userRights,
    icon2: userRightsB,

    path: "/userRights"
},
{
    index: 38,
    text: "Family Office/Entity Creation",
    icon: familyOffice,
    icon2: familyOffice,

    path: "/familyandentityCreations"
},

{
    index: 20,
    text: "Chatbot Queries",
    icon: Enquiry,
    icon2: EnquiryB,

    path: "/chatqueries"
},
// {
//     index: 21,
//     text: "Campaign",
//     icon: Campaign,
//     icon2: CampaignB,

//     path: "/hnicampaign"
// },
{
    index: 22,
    text: "Order Management",
    icon: Payment,
    icon2: OrderManagementB,

    path: "/orders"
},
{
    index: 23,
    text: "Reference Data Management",
    icon: Campaign,
    icon2: ReferenceDataManagementB,

    path: "/referencedata"
},
{
    index: 24,
    text: "Payments",
    icon: Payment,
    icon2: PaymentB,

    path: "/payments"
},
{
    index: 25,
    text: "Chart Gpt",
    icon: Payment,
    icon2: PaymentB,

    path: "/chatgpt"
},
{
    index: 46,
    text: "FAQs",
    path: "/enquiry",
    icon2: FAQ,
    icon: FAQ,
},
{
    index: 47,
    text: isRmChecks ? "Chat" : "Chat with RM",
    path: "/chatqueries",
    icon2: Chat,
    icon: Chat,
},

]

export default NavButton