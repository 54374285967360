import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { Box, Typography, Select } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from "@mui/material/MenuItem";// eslint-disable-next-line
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from "@mui/material/Chip";
export default function SelectSingleMultiple(props) {
    const [field, meta] = useField(props); const { label, data, ...rest } = props; const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;
    function _renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" style={{ fontSize: "14px", marginBottom: "15px", marginTop: "5px", fontFamily: props?.fontCustom ? props?.fontCustom : "Mona Sans" }}>
                {props.header}
            </Typography>
            <FormControl {...rest} error={isError}>
                <Select
                    multiple


                    sx={{
                        width: props?.fixedwidth ? props?.fixedwidth + "px" : "250px",
                        borderRadius: "8px", fontWeight: "bold",
                        "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                            fontFamily: "Mona Sans !important",
                            fontWeight: "600px"
                        },
                        '& .MuiSelect-select .notranslate::after': {
                            fontFamily: "Mona Sans !important",
                            textOverflow: 'ellipsis !important',
                            opacity: 1,
                            content: props?.placeholder ? `${JSON.stringify(props?.placeholder)}` : '"Select"',
                            color: '#BBC5D5 !important',
                        },
                        '& input::placeholder': {
                            fontFamily: "Mona Sans",
                            textOverflow: 'ellipsis !important',
                            color: '#BBC5D5 !important',
                            opacity: 1,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {



                            fontFamily: "Mona Sans !important",


                        },
                        height: props?.fixedHeight ? props?.fixedHeight + "px" : "40px",
                        fontFamily: "Mona Sans !important",
                        iconFilled: "filled",
                        backgroundColor: "#ffffff",


                    }}
                    {...field} value={selectedValue ? selectedValue : []}
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(selected) => (
                        selected.map((value) => (
                            <Chip key={value} label={data?.find(e => e === value)} />
                        ))
                    )} >

                    {/* <MenuItem selected disabled default value="">
                        <em style={{
                            fontFamily: "Mona Sans",
                            textOverflow: 'ellipsis !important',
                            color: '#BBC5D5 !important',
                            opacity: 1,
                        }}>{props?.placeholder ? props?.placeholder : "Select"}</em>
                    </MenuItem> */}
                    {data && data?.map((item) => (
                        <MenuItem key={item} value={item}>
                            <Checkbox checked={selectedValue?.indexOf(item) > -1} />
                            {item}
                        </MenuItem>
                    ))}


                </Select>

                {_renderHelperText()}
            </FormControl>
        </Box>



    );
}

